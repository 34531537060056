import { Box, Button } from "@mui/material";
import ActivityDevice from "components/ActivityDevice";
import UnfriendModal from "components/Modal/UnfriendModal";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoader } from "redux/appSlice";
import { closeModal, openModal } from "redux/modalSlice";
import {
  fetchUser,
  fetchUserDevices,
  logout,
  logoutAllDevices,
} from "redux/usersSlice";
import { getAccessToken } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";

import styles from "./ActivityHistory.module.scss";

const ActivityHistory = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const [userLocale, setUserLocale] = useState(null);

  const devices = useSelector((state) => state.users.devices.entities);
  const navigate = useNavigate();
  useEffect(() => {
    let unmounted = false;

    dispatch(setLoader(true));

    dispatch(fetchUser())
      .unwrap()
      .then((d) => !unmounted && setUserLocale(d.language));

    dispatch(fetchUserDevices()).finally(() => dispatch(setLoader(false)));

    return () => {
      unmounted = true;
    };
  }, []);

  const handleConfirmLogout = () => {
    dispatch(setLoader(true));
    dispatch(logoutAllDevices({ token: getAccessToken() }))
      .unwrap()
      .then((res) => {
        dispatch(logout());
        navigate("/login", { replace: true });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        dispatch(closeModal());
        dispatch(setLoader(false));
      });
  };

  const handleLogoutAllDevices = () => {
    dispatch(
      openModal({
        styles: {
          width: "auto",
        },
        children: (
          <UnfriendModal
            title={t("settings.devicesLogoutModalTitle")}
            description={t("settings.devicesLogoutModalText")}
            onConfirm={handleConfirmLogout}
            onCancel={() => dispatch(closeModal())}
            confirmBtnText={t("settings.confirmLogout")}
            cancelBtnText={t("default.cancel")}
          />
        ),
      })
    );
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        {/*<TEMPORARY DISABLED | BLOCKED BY BE*/}
        {/*<Button*/}
        {/*  variant="outlined"*/}
        {/*  className={styles.headerBtn}*/}
        {/*  onClick={handleLogoutAllDevices}*/}
        {/*>*/}
        {/*  {t("settings.endAllSessions")}*/}
        {/*</Button>*/}
      </Box>
      <Box className={styles.devices}>
        {devices?.map((d, i) => (
          <ActivityDevice
            key={`key-${i}`}
            browser={d.browser}
            date={d.date}
            device={d.device}
            location={d.location}
            isActive={i === 0}
            locale={userLocale}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ActivityHistory;
