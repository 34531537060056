import { useEffect } from "react";

import { useTelegram } from "../../utils/hooks/useTelegram";

export const Telegram = () => {
  const { tg, user } = useTelegram();

  useEffect(() => {
    if (tg && !tg.isExpanded && user) {
      tg.expand();
      tg.disableVerticalSwipes();
    }
  }, [tg, user]);

  return <></>;
};
