import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import TabPanel from "components/TabPanel";
import { FEED_POSTS_TYPES, PLAYING_FEED_VIDEO } from "constants/index";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPosts } from "redux/feedSlice";
import {
  getMyConnectionsFriendsWithPostsLengthSelector,
  getMyFollowersLengthSelector,
} from "redux/selectors/usersSelectors";
import { fetchMyFollows, fetchUserConnections } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "./Main.module.scss";

const TabForYou = React.lazy(() => import("./components/TabForYou/TabForYou"));
const TabClubs = React.lazy(() => import("./components/TabClubs/TabClubs"));
const TabFriends = React.lazy(() =>
  import("./components/TabFriends/TabFriends")
);

const tabsIndex = {
  forYou: 0,
  clubs: 1,
  // badgeClubs: 2,
  friends: 2,
};

export const Main = () => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);

  const clubsCommunityLength = useSelector(getMyFollowersLengthSelector);
  const friendsLength = useSelector(
    getMyConnectionsFriendsWithPostsLengthSelector
  );

  const [tabsValue, setTabsValue] = useState(tabsIndex.forYou);

  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  const fetchData = ({
    page,
    id,
    type,
    isIgnoreFirstPage = false,
    loadMore,
    ...rest
  }) => {
    sessionStorage.removeItem(PLAYING_FEED_VIDEO);
    if (isIgnoreFirstPage && page === 1) {
      return;
    }
    if (id) {
      if (type === FEED_POSTS_TYPES.FRIENDS) {
        dispatch(
          fetchPosts({
            friendId: id,
            params: { page, type, ...rest },
          })
        ).then(() => {
          if (loadMore) {
            loadMore();
          }
        });
      } else {
        dispatch(fetchPosts({ id, params: { page, type, ...rest } })).then(
          () => {
            if (loadMore) {
              loadMore();
            }
          }
        );
      }
    } else {
      dispatch(
        fetchPosts({
          params: {
            page,
            type,
            ...rest,
          },
        })
      ).then(() => {
        if (loadMore) {
          loadMore();
        }
      });
    }
  };

  useEffect(() => {
    sessionStorage.removeItem(PLAYING_FEED_VIDEO);
    dispatch(fetchUserConnections({ type: "whoPosted" }));
    dispatch(fetchMyFollows());

    return () => {
      sessionStorage.removeItem(PLAYING_FEED_VIDEO);
    };
  }, []);

  return (
    <Box>
      <Box className={styles.tabsWrap}>
        <Tabs
          value={tabsValue}
          onChange={handleTabChange}
          className={styles.tabs}
        >
          <Tab label={t("home.tabTitle1")} {...a11yProps(tabsIndex.forYou)} />
          <Tab label={t("home.tabTitle2")} {...a11yProps(tabsIndex.clubs)} />
          {/*<Tab*/}
          {/*  label={t("home.tabTitle3")}*/}
          {/*  {...a11yProps(tabsIndex.badgeClubs)}*/}
          {/*/>*/}
          <Tab label={t("home.tabTitle4")} {...a11yProps(tabsIndex.friends)} />
        </Tabs>
      </Box>
      <TabPanel
        value={tabsValue}
        index={tabsIndex.forYou}
        tabPanelClassName={styles.tabsPanel}
      >
        <Suspense
          fallback={
            <Box className={styles.suspenseLoader}>
              <CircularProgress size={24} />
            </Box>
          }
        >
          <TabForYou fetchData={fetchData} />
        </Suspense>
      </TabPanel>
      <TabPanel
        value={tabsValue}
        index={tabsIndex.clubs}
        tabPanelClassName={styles.tabsPanel}
      >
        <Suspense
          fallback={
            <Box className={styles.suspenseLoader}>
              <CircularProgress size={24} />
            </Box>
          }
        >
          <TabClubs fetchData={fetchData} />
        </Suspense>
      </TabPanel>
      {/*<TabPanel*/}
      {/*  value={tabsValue}*/}
      {/*  index={tabsIndex.badgeClubs}*/}
      {/*  tabPanelClassName={styles.tabsPanel}*/}
      {/*>*/}
      {/*  3*/}
      {/*</TabPanel>*/}
      <TabPanel
        value={tabsValue}
        index={tabsIndex.friends}
        tabPanelClassName={styles.tabsPanel}
      >
        <Suspense
          fallback={
            <Box className={styles.suspenseLoader}>
              <CircularProgress size={24} />
            </Box>
          }
        >
          <TabFriends fetchData={fetchData} />
        </Suspense>
      </TabPanel>
    </Box>
  );
};
