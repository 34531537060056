import { Box, Button, Typography } from "@mui/material";
import { PATHS } from "constants/index";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setTgOnboardingCompleted } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import { getUrlSearchParam } from "utils/index";

import { PageLayout } from "../../PageLayout/PageLayout";
import { TelegramPageWrapper } from "../TelegramPageWrapper";
import { CarouselCards } from "./CarouselCards/CarouselCards";
import { cards } from "./data";
import { HederaIcon } from "./icons";
import styles from "./TelegramOnboardingGift.module.scss";

export const TelegramOnboardingGift = () => {
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);

  const [isPickedGift, setPickedGift] = useState(false);

  const referralCode = getUrlSearchParam("startapp");

  const name = referralCode || "Hedera";

  const goTo = (path) => () => {
    navigate(path);
  };

  const pickGiftHandler = () => {
    setPickedGift(true);
    setTgOnboardingCompleted();
  };

  return (
    <TelegramPageWrapper>
      <PageLayout
        isShowHeader={false}
        isBottomMenuHide={true}
        pageWrapperClassName={styles.pageWrapper}
        pageClassName={styles.page}
        pageContentClassName={styles.content}
      >
        <Box className={styles.header}>
          <Typography variant="clashDisplayMedium40">
            <Box component="span" className={styles.headerName}>
              {!referralCode ? <HederaIcon /> : null}
              {name}
            </Box>
            {t("default.telegramGiftPageTitle")}
          </Typography>
          <Typography
            className={`opacity_05 ${styles.headerDescription}`}
            variant="body1"
          >
            {t("default.telegramGiftPageDescription", { name })}
          </Typography>
        </Box>
        <Box className={styles.carouselWrapper}>
          <CarouselCards
            cards={cards}
            isCarouselStart={true}
            isPickedGift={isPickedGift}
            carouselClass={styles.carousel}
          />
        </Box>
        {isPickedGift ? (
          <Button
            variant="contained"
            className={styles.submitButton}
            onClick={goTo(`${PATHS.PROFILE}?tab=1`)}
          >
            {t("default.telegramGiftPageSubmitButton2")}
          </Button>
        ) : (
          <Button
            variant="contained"
            className={styles.submitButton}
            onClick={pickGiftHandler}
          >
            {t("default.telegramGiftPageSubmitButton1")}
          </Button>
        )}
      </PageLayout>
    </TelegramPageWrapper>
  );
};
