import { Box, Typography } from "@mui/material";
import classnames from "classnames/bind";
import React from "react";

import styles from "./OnboardingModal.module.scss";

const cx = classnames.bind(styles);

export const OnboardingSlide = ({
  title,
  description,
  children,
  className,
}) => {
  return (
    <Box className={cx(styles.slide, className)}>
      {children}
      <Typography variant="h4" className={styles.slideTitle}>
        {title}
      </Typography>
      <Typography variant="body1Display" className={styles.slideDescription}>
        {description}
      </Typography>
    </Box>
  );
};
