import { Box } from "@mui/material";
import { FollowersBlock } from "components/FollowersBlock/FollowersBlock";
import React from "react";
import { useSelector } from "react-redux";

import { AboutInfluencer } from "../AboutInfluencer";

export const AboutTab = ({ influencerData }) => {
  const followers = useSelector((state) => state.users.followers.influencers);

  return (
    <Box>
      {/* <FollowersBlock influencerData={influencerData} followers={followers} /> */}
      <AboutInfluencer {...influencerData} />
    </Box>
  );
};
