export const MediaIcon = () => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.33398 6.6C2.33398 5.64522 2.71327 4.72955 3.3884 4.05442C4.06353 3.37928 4.9792 3 5.93398 3H16.734C17.6888 3 18.6044 3.37928 19.2796 4.05442C19.9547 4.72955 20.334 5.64522 20.334 6.6V17.4C20.334 18.3548 19.9547 19.2705 19.2796 19.9456C18.6044 20.6207 17.6888 21 16.734 21H5.93398C4.9792 21 4.06353 20.6207 3.3884 19.9456C2.71327 19.2705 2.33398 18.3548 2.33398 17.4V6.6Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.1875 11.1007C9.43014 11.1007 10.4375 10.0933 10.4375 8.85071C10.4375 7.60807 9.43014 6.60071 8.1875 6.60071C6.94486 6.60071 5.9375 7.60807 5.9375 8.85071C5.9375 10.0933 6.94486 11.1007 8.1875 11.1007Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6109 12.5557L5.9375 20.9968H16.8572C17.7802 20.9968 18.6655 20.6302 19.3181 19.9775C19.9708 19.3248 20.3375 18.4396 20.3375 17.5165V17.3968C20.3375 16.9774 20.18 16.8163 19.8965 16.5058L16.2695 12.5503C16.1005 12.3659 15.8948 12.2188 15.6658 12.1183C15.4367 12.0178 15.1892 11.9662 14.939 11.9667C14.6889 11.9672 14.4416 12.0198 14.2129 12.1213C13.9842 12.2227 13.7792 12.3706 13.6109 12.5557Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const YoutubeIcon = () => (
  <svg
    width="25"
    height="18"
    viewBox="0 0 25 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3695 3.50206C23.2419 3.01744 22.992 2.57558 22.6448 2.2207C22.2976 1.86583 21.8652 1.6104 21.3911 1.47997C20.0952 0.968295 7.54122 0.717609 3.899 1.49428C3.42477 1.62476 2.99239 1.88028 2.64517 2.23525C2.29795 2.59023 2.04806 3.03221 1.92053 3.51694C1.33533 6.13999 1.2911 11.8108 1.93509 14.4928C2.06271 14.9774 2.31263 15.4192 2.65985 15.7741C3.00706 16.129 3.43939 16.3844 3.91356 16.5148C6.48003 17.1187 18.6812 17.2034 21.4056 16.5148C21.8798 16.3844 22.3121 16.129 22.6593 15.7741C23.0065 15.4192 23.2565 14.9774 23.3841 14.4928C24.0079 11.6351 24.0522 6.3157 23.3695 3.50206Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.498 5.86297L15.6269 8.80324L10.498 11.7877V5.86297Z"
      fill="white"
      stroke="white"
    />
  </svg>
);
