import { createSlice } from "@reduxjs/toolkit";

import { PLAYING_FEED_VIDEO } from "../constants";

const initialState = {
  mediaParams: {
    postIndex: null,
    mediaIndex: null,
  },
};
const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    setActiveMedia: (state, action) => {
      const { postIndex = null, mediaIndex = null } = action.payload;
      state.mediaParams = {
        postIndex,
        mediaIndex,
      };
      sessionStorage.removeItem(PLAYING_FEED_VIDEO);
    },
  },
});

export default mediaSlice.reducer;

export const { setActiveMedia } = mediaSlice.actions;
