import { Box, Skeleton } from "@mui/material";
import ChallengeItem from "components/ChallengeItem";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { fetchChallenges } from "redux/challengesSlice";
import {
  clubChallengesById,
  clubChallengesByIdLoader,
} from "redux/selectors/challengesSelector";
import { LocalizationContext } from "services/localizationContext";

import { getInfluencerProfileLink } from "../../utils";
import styles from "./ClubChallenges.module.scss";

export const ClubChallenges = () => {
  const { t } = useContext(LocalizationContext);
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const challenges = useSelector(clubChallengesById(id));
  const loader = useSelector(clubChallengesByIdLoader);

  const backLink =
    !location.key || location.key === "default"
      ? getInfluencerProfileLink({ id })
      : null;

  useEffect(() => {
    if (!challenges?.length && id !== null && id !== undefined) {
      dispatch(fetchChallenges({ influencerId: id }));
    }
  }, [id]);

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <BackBtn
          className={styles.backBtn}
          title={t("challenges.clubChallengesTitle")}
          link={backLink}
        />
      </Box>
      <Box className={styles.list}>
        {loader && !challenges?.length ? (
          <>
            <Box className={styles.listItem}>
              <Skeleton
                variant="rectangular"
                className={styles.listItemSkeleton}
              />
            </Box>
            <Box className={styles.listItem}>
              <Skeleton
                variant="rectangular"
                className={styles.listItemSkeleton}
              />
            </Box>
            <Box className={styles.listItem}>
              <Skeleton
                variant="rectangular"
                className={styles.listItemSkeleton}
              />
            </Box>
          </>
        ) : null}
        {challenges?.map((item) => (
          <Box key={item.id} className={styles.listItem}>
            <ChallengeItem challenge={item} key={item.id} isClub={true} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
