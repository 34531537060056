import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError } from "redux/appSlice";
import { setOnfidoToken } from "redux/appSlice";
import { fetchUserMe } from "redux/usersSlice";
import { onfidoCheck } from "services/api/paymentsApi";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";

export default function LaunchOnfido() {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const token = useSelector((state) => state.app.onfidoToken);
  const redirectUrl = useSelector((state) => state.app.onfidoRedirectComplete);
  const navigate = useNavigate();

  const init = async () => {
    const onfido = window.Onfido?.init({
      token,
      containerId: "onfido-mount",
      onComplete: handleWidgetComplete,
      steps: [
        "welcome",
        {
          type: "document",
          options: {
            forceCrossDevice: true,
          },
        },
        {
          type: "face",
          options: {
            requestedVariant: "motion",
            recordMotionAudio: true,
          },
        },
        "complete",
      ],
      useModal: true,
      isModalOpen: true,
      onModalRequestClose: function () {
        onfido.setOptions({ isModalOpen: false });
        dispatch(setOnfidoToken(null));
      },
    });

    return onfido;
  };

  const handleError = (e) => {
    dispatch(setOnfidoToken(null));
    dispatch(
      setError({
        open: true,
        subtitle: errorResponseMessages({
          message: e.message || e.data?.message || e,
          t,
        }),
      })
    );
  };

  const handleWidgetComplete = async (res) => {
    try {
      await onfidoCheck(res);
      dispatch(setOnfidoToken(null));
      dispatch(fetchUserMe());
      if (redirectUrl) {
        navigate(redirectUrl, { replace: true });
      }
    } catch (e) {
      handleError(e.data || e);
    }
  };

  useEffect(() => {
    const onfidoInitialize = async () => {
      let unmounted = false;
      let onfido = null;

      if (token && !unmounted) {
        onfido = await init();
      }
      return () => {
        unmounted = true;
        onfido && onfido.tearDown();
      };
    };
    onfidoInitialize();
  }, [token]);

  return <div id="onfido-mount"></div>;
}
