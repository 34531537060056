import { Box, Container, Skeleton, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import hiddenBadgeImg from "assets/img/badges-hidden.png";
import { ReactComponent as GoalIcon } from "assets/svg/goal-icon.svg";
import { ReactComponent as GradeIcon } from "assets/svg/grade-icon.svg";
import { ReactComponent as ZoopIcon } from "assets/svg/zoop-icon.svg";
import ShareBtn from "components/Buttons/ShareBtn/ShareBtn";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import { SliderSyncing } from "components/SliderSyncing/SliderSyncing";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchChallengeDetails,
  initialChallengeDetailsSubState,
  updateChallengeDetails,
} from "redux/challengesSlice";
import { getChallengeDetails } from "redux/selectors/challengesSelector";
import { LocalizationContext } from "services/localizationContext";

import { useIsMobile } from "../../utils/hooks";
import { PageLayout } from "../PageLayout/PageLayout";
import { ChallengeProgress } from "./ChallengeProgress/ChallengeProgress";
import styles from "./NewChallengeDetails.module.scss";
import { UsersList } from "./UsersList/UsersList";

const USERS_LIMIT = 25;

export const NewChallengeDetails = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const params = useParams();
  const details = useSelector(getChallengeDetails(params.id));
  const isMobile = useIsMobile();
  const isMd = useMediaQuery("(max-width:960px)");
  const isS = useMediaQuery("(max-width:768px)");
  const isXS = useMediaQuery("(max-width:480px)");

  const appUserTrack = details.challenge.appUserTrack;
  const challengeStageId = appUserTrack?.challengeStageId;
  const shareLink = `${window.origin}/challenges/${params.id}`;
  const isSingleStage = details?.challenge?.stages?.length === 1;
  const slidesToShow = isXS ? 3 : isS ? 4 : isMd ? 6 : isMobile ? 5 : 6;

  const [loader, setLoader] = useState(true);
  const [usersLoader, setUsersLoader] = useState(true);
  const [page, setPage] = useState(1);

  const [currentStageIndex, setCurrentStageIndex] = useState(0);

  const activeStage = details.challenge?.stages
    ? details.challenge?.stages[currentStageIndex]
    : null;

  const zoopPoints = activeStage?.rewardPoints;
  const hasUsersMore = page * USERS_LIMIT <= details.users?.total;

  const sliderData =
    details.challenge?.stages?.map((item) => ({
      src:
        appUserTrack?.challengeStage?.stageNum >= item.stageNum
          ? details.challenge?.imageUrl
          : hiddenBadgeImg,
      value:
        appUserTrack?.challengeStage?.stageNum >= item.stageNum
          ? item.metadata?.goalCount
          : null,
      description: (
        <Box className={styles.challengeGrade}>
          <GradeIcon />
          <Typography variant="body2">
            {t("challenges.stage", { rank: item.stageNum })}
          </Typography>
        </Box>
      ),
      isShow: appUserTrack?.challengeStage?.stageNum >= item.stageNum,
      stylesPreviewItem: {
        opacity:
          appUserTrack?.challengeStage?.stageNum >= item.stageNum ? 1 : 0.5,
        pointerEvents:
          appUserTrack?.challengeStage?.stageNum >= item.stageNum
            ? "initial"
            : "none",
        cursor:
          appUserTrack?.challengeStage?.stageNum >= item.stageNum
            ? "pointer"
            : "initial",
      },
    })) || [];

  const handleAfterSlideChange = (index) => {
    setCurrentStageIndex(index);
  };

  useEffect(() => {
    const index = details.challenge?.stages?.findIndex(
      (item) => item.id === challengeStageId
    );
    setCurrentStageIndex(index);
  }, [details.challenge?.stages]);

  useEffect(() => {
    setUsersLoader(true);
    dispatch(
      fetchChallengeDetails({ id: +params.id, limit: USERS_LIMIT, page })
    ).finally(() => {
      setLoader(false);
      setUsersLoader(false);
    });
  }, [page]);

  useEffect(() => {
    return () =>
      dispatch(
        updateChallengeDetails({
          id: params.id,
          data: initialChallengeDetailsSubState,
        })
      );
  }, []);
  return (
    <PageLayout isBack={true} pageClassName={styles.page}>
      <Container className={styles.container}>
        <Box className={styles.containerLeft}>
          {loader ? (
            <Skeleton
              variant="rectangular"
              className={styles.containerLeftSkeleton}
            />
          ) : (
            <Box className={styles.containerBox}>
              <Box>
                <ChallengeProgress
                  stage={activeStage}
                  appUserTrack={appUserTrack}
                  isSingleStage={isSingleStage}
                />
                <Typography variant="h2" className={styles.challengeName}>
                  {t(`challenges.${details.challenge?.code}-title`)}
                </Typography>
                {details.challenge?.influencer ? (
                  <Typography variant="body5" className={styles.challengeFrom}>
                    {t("default.from")}
                    <img
                      src={details.challenge?.influencer.profileImageUrl}
                      alt="avatar"
                    />
                    {details.challenge?.influencer.name}
                  </Typography>
                ) : null}
              </Box>
              <Box>
                <Typography className={styles.containerLabel} variant="body1">
                  {t("challenges.descriptionLabel")}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={styles.containerDescription}
                >
                  {t(`challenges.${details.challenge?.code}-description`, {
                    name: details.challenge?.influencer?.name,
                  })}
                </Typography>
              </Box>
              <Box>
                <Typography
                  className={`${styles.containerLabel} ${styles.containerLabelMultiple}`}
                >
                  <Box className={styles.containerLabelItem}>Details</Box>
                  <ShareBtn shareLink={shareLink} />
                </Typography>
                <Box className={styles.challengeSubtitleItem}>
                  <GoalIcon className={styles.challengeSubtitleItemIcon} />
                  <Typography
                    variant="subtitle1"
                    className={styles.challengeSubtitleItemValue}
                  >
                    {t(`challenges.${details.challenge?.code}-stage-goal`, {
                      count: activeStage?.metadata?.goalCount,
                      useSingular: true,
                      ...(details.challenge?.metadata?.name && {
                        influencerName: details.challenge?.metadata.name,
                      }),
                    })}
                  </Typography>
                </Box>
                <Box className={styles.challengeSubtitleItem}>
                  <ZoopIcon className={styles.challengeSubtitleItemIcon} />
                  <Typography
                    variant="subtitle1"
                    className={styles.challengeSubtitleItemValue}
                  >
                    {`${zoopPoints} ${t("challenges.zoopPoints")}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          {loader ? (
            <Skeleton
              variant="rectangular"
              className={styles.containerLeftSkeleton}
            />
          ) : (
            <Box
              className={`${styles.containerBox} ${styles.containerBoxList}`}
            >
              <UsersList
                data={details.users?.rows || []}
                loader={usersLoader}
                loadMore={() => setPage((prevState) => prevState + 1)}
                hasMore={hasUsersMore}
              />
            </Box>
          )}
        </Box>
        <Box className={styles.containerRight}>
          {loader ? (
            <Skeleton
              variant="rectangular"
              className={styles.containerRightSkeleton}
            />
          ) : (
            <SliderSyncing
              main={sliderData.filter((item) => item.isShow)}
              preview={sliderData}
              mainClassname={styles.mainImg}
              mainValueClassName={styles.mainValue}
              previewValueClassName={styles.previewValue}
              slidesToShow={slidesToShow}
              activeSlide={currentStageIndex}
              handleAfterChange={handleAfterSlideChange}
            />
          )}
        </Box>
      </Container>
    </PageLayout>
  );
};
