import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FormControl, InputLabel, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as EditIcon } from "assets/svg/edit-icon.svg";
import InputPhoneNumber from "components/Inputs/PhoneNumber";
import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { LocalizationContext } from "services/localizationContext";
import * as Yup from "yup";

import styles from "./MFA.module.scss";

const PhoneNumber = (props) => {
  const {
    setCanEditPhone,
    canEditPhone,
    sendCode,
    isCodeSent,
    openChangePhoneNumberModal,
    isPhoneChanging,
    setPhoneValue,
    setPhoneError,
  } = props;

  const { t } = useContext(LocalizationContext);
  const theme = useTheme();

  const user = useSelector((state) => state.users.entities);
  const is2faEnabled = useSelector(
    (state) => state.users.entities.is2faEnabled
  );

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .trim()
      .required(t("error.required"))
      .matches(/^\+[0-9]\d{6,14}$/, t("error.invalidPhoneNumber")),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setFocus,
    setValue,
    getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onBlur",
  });

  const phoneNumberExist = user?.phoneNumber && user?.isPhoneVerified;

  const invalid = !!Object.keys(errors).length;

  useEffect(() => {
    if (phoneNumberExist) {
      setValue("phone", user?.newPhoneNumber || user?.phoneNumber || "");
    }
    if (isPhoneChanging) {
      setValue("phone", "+1");
      setFocus("phone");
    }
  }, [isPhoneChanging, phoneNumberExist, user]);

  useEffect(() => {
    setPhoneError(invalid);
  }, [invalid, setPhoneError]);

  const onEditClick = () => {
    if (phoneNumberExist) {
      openChangePhoneNumberModal();
    } else {
      setCanEditPhone(true);
      setFocus("phone");
    }
  };

  const resetChange = () => {
    setCanEditPhone(false);
    setValue("phone", user?.phoneNumber || "");
    clearErrors("phone");
  };

  const getEditIconStroke = () => {
    if (invalid) {
      return theme.palette.coral.main;
    }

    return theme.palette.secondary.main;
  };

  return (
    <form onSubmit={handleSubmit(sendCode)} className={styles.form}>
      <FormControl className={styles.inputWrap}>
        <Controller
          control={control}
          name="phone"
          defaultValue={getValues("phone")}
          render={({ field: { onChange, value, ref } }) => (
            <>
              <InputPhoneNumber
                ref={ref}
                value={value}
                onChange={(v) => {
                  onChange(v);
                  setPhoneValue(v);
                }}
                className={`${styles.phoneInput} ${
                  invalid ? styles.error : ""
                } ${value?.length > 0 && canEditPhone ? styles.filled : ""} ${
                  !canEditPhone ? styles.disabled : ""
                }`}
              />
              <InputLabel className={styles.phoneInputLabel}>
                {isPhoneChanging
                  ? t("settings.newPhoneNumber")
                  : t("auth.phoneNumber")}
              </InputLabel>
              {!canEditPhone || !isCodeSent ? (
                <EditIcon
                  className={styles.editIcon}
                  onClick={onEditClick}
                  style={{ stroke: getEditIconStroke() }}
                />
              ) : null}
              {invalid ? (
                <Typography variant="error">{errors?.phone.message}</Typography>
              ) : null}
            </>
          )}
        />
      </FormControl>
      {!isPhoneChanging && !isCodeSent && !is2faEnabled ? (
        <FormControl className={styles.buttons}>
          <Button type="submit" variant="contained" disabled={invalid}>
            {t("auth.sendCode")}
          </Button>
          <Button variant="outlined" onClick={resetChange}>
            {t("default.cancel")}
          </Button>
        </FormControl>
      ) : null}
    </form>
  );
};

export default PhoneNumber;
