import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { roundToTwo } from "utils/math";

import Card from "../../components/Card";
import { useIsMobile } from "../../utils/hooks";
import styles from "./buy-edition.module.scss";

const Submitted = ({ edition, priceValue, platformFee, paymentMethod }) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const params = useParams();
  const isMobile = useIsMobile();

  return (
    <Box className={styles.reviewDetailsContainer}>
      {!isMobile ? (
        <Card
          className={styles.mainCard}
          imageUrl={edition.card.imageUrl}
          editionsDropped={edition.card.series}
          name={edition.card.influencer.name}
          size="large"
        />
      ) : null}
      <Box className={styles.reviewDetailsInfo}>
        <Typography variant="h1" className={styles.submittedTitle}>
          {paymentMethod?.type === "wallet"
            ? t("default.success")
            : t("discover.sumbittedUpper")}
        </Typography>
        {paymentMethod?.type === "wallet" ? (
          <Typography variant="body2" className={styles.submittedSubtitle}>
            {t("drops.buyWalletSuccessInfo")}
          </Typography>
        ) : null}
        <Box className={styles.reviewDetailsRow}>
          <Typography variant="h6" className={styles.reviewDetailsRowKey}>
            {/* missed translation */}
            {t("default.influencer")}
          </Typography>
          <Typography variant="h6" className={styles.reviewDetailsRowValue}>
            {edition.card.influencer.name}
          </Typography>
        </Box>
        {/*<Box className={styles.reviewDetailsRow}>*/}
        {/*  <Typography variant="h6" className={styles.reviewDetailsRowKey}>*/}
        {/*    {t("default.edition")}*/}
        {/*  </Typography>*/}
        {/*  <Typography variant="h6" className={styles.reviewDetailsRowValue}>*/}
        {/*    {`#${edition.card.series}`}*/}
        {/*  </Typography>*/}
        {/*</Box>*/}
        <Box className={styles.reviewDetailsRow}>
          <Typography variant="h6" className={styles.reviewDetailsRowKey}>
            {t("discover.platformFee")}
          </Typography>
          <Typography variant="h6" className={styles.reviewDetailsRowValue}>
            {edition.currencySymbol}
            {roundToTwo(+platformFee)}
          </Typography>
        </Box>
        <Divider variant="fullWidth" className={styles.reviewDetailsDivider} />
        <Box
          className={`${styles.reviewDetailsRow} ${styles.reviewDetailsPriceRow}`}
        >
          <Typography
            variant="h6"
            className={`${styles.reviewDetailsRowKey} ${styles.reviewDetailsPriceKey}`}
          >
            {t("discover.total")}
          </Typography>
          <Typography
            variant="h6"
            className={`${styles.reviewDetailsRowValue} ${styles.reviewDetailsPriceValue}`}
          >
            {edition.currencySymbol}
            {roundToTwo(+priceValue + +platformFee)}
          </Typography>
        </Box>
        {/*<Paper className={styles.reviewDetailsCompleteInfo}>*/}
        {/*  <Typography*/}
        {/*    variant="subtitle1"*/}
        {/*    className={styles.reviewDetailsCompleteInfoText}*/}
        {/*  >*/}
        {/*    {t("trading.orderCompleteInfo")}*/}
        {/*  </Typography>*/}
        {/*</Paper>*/}
        <Button
          variant="contained"
          className={styles.doneBtn}
          onClick={() => navigate(`/pass/${params.id}`, params.id)}
        >
          {t("default.done")}
        </Button>
      </Box>
    </Box>
  );
};

export default Submitted;
