import { ReactComponent as IconAllStart } from "assets/svg/all-start-icon.svg";
import styles from "./card.module.scss";

const INFLUENCER_LEVEL = {
  SMALLER: "smaller",
  BIGGER: "bigger",
};

const CardLevelIcon = ({ level }) => {
  if (!level || level !== INFLUENCER_LEVEL.BIGGER) return null;

  return <IconAllStart className={styles.levelIcon} />;
};

export default CardLevelIcon;
