import { useTheme } from "@emotion/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentStatus, {
  PAYMENT_INTENT_STATUSES,
} from "components/SubscribeInfluencer/PaymentStatus";
import SubscribeInfluencerModal from "components/SubscribeInfluencer/SubscribeInfluencerModal";
import { INFLUENCER_ACTIVE_ID_STORAGE_KEY, MODAL_TYPE } from "constants/index";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const SubscribeInfluencer = ({
  clientSecret,
  influencerData,
  onCloseSubscribeModal,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const showSubscribeModal = !!clientSecret;
  const clientSecretFromUrl = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );
  const clearPaymentStatusFromUrl = () => {
    navigate(location.pathname, { replace: true });
    sessionStorage.removeItem(INFLUENCER_ACTIVE_ID_STORAGE_KEY);
  };
  const { t } = useContext(LocalizationContext);
  const secretKey = clientSecret || clientSecretFromUrl;
  if (!secretKey) return null;

  const stripeOptions = {
    clientSecret: secretKey,
    appearance: {
      theme: "night",
      variables: {
        colorPrimary: theme.palette.dark.main,
        colorBackground: theme.palette.surface.main,
        colorText: theme.palette.dark.main.contrastText,
        colorDanger: theme.palette.coral.main,
        fontFamily: theme.typography.fontFamily,
        spacingUnit: "4px",
        borderRadius: "8px",
      },
    },
  };

  const paymentsStatusModalData = influencerData
    ? {
        influencer: influencerData,
        data: [
          {
            text: t("default.influencer"),
            value: influencerData?.name,
          },
          {
            text: t("subscription.renewal"),
            value: t("subscription.renewEachMonth"),
          },
          {
            text: t("subscription.price"),
            value: `${influencerData?.currencySymbol}${influencerData?.community?.subscriptionPrice}`,
          },
        ],
      }
    : null;

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <SubscribeInfluencerModal
        open={showSubscribeModal}
        onClose={onCloseSubscribeModal}
      />
      {clientSecretFromUrl &&
      sessionStorage.getItem(INFLUENCER_ACTIVE_ID_STORAGE_KEY) ===
        `${influencerData?.id}` ? (
        <PaymentStatus
          onClose={clearPaymentStatusFromUrl}
          clientSecret={clientSecretFromUrl}
          modalData={paymentsStatusModalData}
          modalType={MODAL_TYPE.SUBSCRIBE_CREATOR_SUCCESS_MODAL}
          statusesTexts={{
            [PAYMENT_INTENT_STATUSES.SUCCEEDED]: t(
              "subscription.subscribeSuccessDesc",
              {
                name: influencerData?.name,
              }
            ),
          }}
        />
      ) : null}
    </Elements>
  );
};
export default SubscribeInfluencer;
