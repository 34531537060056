import { Box, Button, Typography } from "@mui/material";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { getUsersMeRewardPoints } from "redux/selectors/usersSelectors";
import { LocalizationContext } from "services/localizationContext";

import styles from "./InfoBlock.module.scss";
export const InfoBlock = () => {
  const { t } = useContext(LocalizationContext);

  const rewardPoints = useSelector(getUsersMeRewardPoints);

  return (
    <Box className={styles.info}>
      <Box className={styles.infoHeader}>
        <Typography className={`opacity_07`} variant="body3">
          {t("account.zoopPoints")}
        </Typography>
        <Typography variant="h2_32">{rewardPoints}</Typography>
      </Box>
      <Typography className={`opacity_07`} variant="body3">
        {t("default.airdropInfoDescription")}
      </Typography>
      <Button variant="contained" disabled={true}>
        {t("default.airdropInfoWalletButton")}
      </Button>
    </Box>
  );
};
