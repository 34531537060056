import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as IconRight } from "assets/svg/icon-right.svg";
import MenuTitle from "components/MenuTitle";
import { HELP_STATES } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import styles from "./Settings.module.scss";

const Help = () => {
  const { t } = useContext(LocalizationContext);
  // const navigate = useNavigate();

  const [state, setState] = useState(HELP_STATES.default);

  const handleChatClick = () => {
    window.zE("messenger", "open");
  };

  useEffect(() => {
    return () => {
      window.zE("messenger", "close");
    };
  }, []);

  return (
    <Box className={styles.helpContainer}>
      <Box className={styles.helpContent}>
        <MenuTitle
          states={HELP_STATES}
          currentState={state}
          onBack={() => setState(HELP_STATES.default)}
        />
        <Box className={styles.tabs}>
          {/*<IconButton*/}
          {/*  className={styles.tabOption}*/}
          {/*  onClick={() => navigate("/settings/help/faq")}*/}
          {/*>*/}
          {/*  <Typography variant="h6">{t("settings.faq")}</Typography>*/}
          {/*  <IconRight className={styles.icon} />*/}
          {/*</IconButton>*/}
          <IconButton className={styles.tabOption} onClick={handleChatClick}>
            <Typography variant="h6">
              {t("settings.chatWithSupport")}
            </Typography>
            <IconRight className={styles.icon} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Help;
