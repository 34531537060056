import { Box } from "@mui/material";
import Card from "components/Card";
import { PATHS } from "constants";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { goToInfluencer } from "utils";

import { SubHeader } from "../SubHeader/SubHeader";
import styles from "./AllStars.module.scss";
import { AllStarsSkeleton } from "./AllStarsSkeleton";

export const AllStars = ({
  data,
  loading,
  isHideHeader,
  className,
  isHideViewAll,
  subHeaderTitle,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useContext(LocalizationContext);

  if (!loading && !data.length) {
    return null;
  }

  return (
    <Box className={`${styles.wrapper} ${className}`}>
      {loading ? (
        <AllStarsSkeleton isHideHeader={isHideHeader} />
      ) : (
        <>
          {!isHideHeader ? (
            <SubHeader
              path={PATHS.DISCOVER_ALL_STARS}
              title={subHeaderTitle || t("discover.allStars")}
              isHideViewAll={isHideViewAll}
            />
          ) : null}
          <Box className={styles.grid}>
            {data.map((card) => (
              <Card
                className={styles.card}
                key={card.id}
                name={card.name}
                imageUrl={card.profileImageUrl}
                isWatchlisted={card.isWatchlisted}
                isHot={card.isHot}
                onClick={goToInfluencer({
                  id: card.id,
                  handle: card.handle,
                  navigate,
                  location,
                })}
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};
