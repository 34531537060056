import { api } from "../api.service";

const API_URL = process.env.REACT_APP_API_HOST;

export const postUnlockReward = async ({ cardId, ...params }) => {
  try {
    return api.post(`${API_URL}/cards/${cardId}/unlock-reward`, params);
  } catch (error) {
    console.error(error);
  }
};

export const getRewardMessages = async ({ cardId, orderId, ...params }) => {
  try {
    return api.get(
      `${API_URL}/cards/${cardId}/reward-orders/${orderId}/messages`,
      { params }
    );
  } catch (error) {
    console.error(error);
  }
};

export const postRewardMessage = async ({ cardId, orderId, ...params }) => {
  try {
    return api.post(
      `${API_URL}/cards/${cardId}/reward-orders/${orderId}/messages`,
      params
    );
  } catch (error) {
    console.error(error);
  }
};
