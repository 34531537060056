import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsAppIcon,
} from "./icons";
export const socialNetworks = [
  {
    id: 4,
    icon: <TelegramIcon />,
    name: "Telegram",
    title: "",
    ActionButton: TelegramShareButton,
  },
  {
    id: 5,
    icon: <WhatsAppIcon />,
    name: "WhatsApp",
    title: "",
    ActionButton: WhatsappShareButton,
  },
  {
    id: 1,
    icon: <TwitterIcon />,
    name: "X",
    title: "",
    ActionButton: TwitterShareButton,
  },
  {
    id: 2,
    icon: <FacebookIcon />,
    name: "Facebook",
    title: "",
    ActionButton: FacebookShareButton,
  },
  {
    id: 3,
    icon: <EmailIcon />,
    name: "Email",
    title: "",
    ActionButton: EmailShareButton,
  },
];
