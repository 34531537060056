import { Box, Button, Container, Typography } from "@mui/material";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import { WITHDRAW_TYPE } from "constants/";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import styles from "views/Wallet/Funds.module.scss";

import { PageLayout } from "../../PageLayout/PageLayout";

const SelectBankType = () => {
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);
  const location = useLocation();
  const amount = location.state?.amount;
  const title = t("payments.withdrawFundsTitle");

  return (
    <PageLayout title={title} isBack={true} backBtnProps={{ isPlain: true }}>
      <Container className={styles.container}>
        <Box className={styles.wrapper}>
          <Box className={styles.buttonList}>
            <Button
              variant="outlined"
              onClick={() =>
                navigate("/add-bank-account", {
                  state: {
                    bankType: WITHDRAW_TYPE.US,
                    amount,
                  },
                })
              }
            >
              {t("payments.usBankAccount")}
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                navigate("/add-bank-account", {
                  state: {
                    bankType: WITHDRAW_TYPE.IBAN_SUPPORTED,
                    amount,
                  },
                })
              }
            >
              {t("payments.nonUsBankIban")}
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                navigate("/add-bank-account", {
                  state: {
                    bankType: WITHDRAW_TYPE.IBAN_NOT_SUPPORTED,
                    amount,
                  },
                })
              }
            >
              {t("payments.nonUsBankNoIban")}
            </Button>
          </Box>
        </Box>
      </Container>
    </PageLayout>
  );
};
export default SelectBankType;
