import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as EmptyPlaceholder } from "assets/svg/empty-tabs-placeholder.svg";
import { PATHS } from "constants/index";
import Proptypes from "prop-types";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import styles from "./placeholder.module.scss";

const CollectionsEmptyPlacaholder = ({ title, showBtn = true }) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Box className={styles.placeholder}>
      <EmptyPlaceholder />
      <Typography className={styles.placeholderText} variant="h5">
        {title || ""}
      </Typography>
      {showBtn && (
        <Button
          component={Link}
          className={styles.placeholderBtn}
          variant="contained"
          to={PATHS.DROPS}
        >
          {t("account.beginCollectingEditions")}
        </Button>
      )}
    </Box>
  );
};

CollectionsEmptyPlacaholder.defaultProps = {
  title: null,
};

CollectionsEmptyPlacaholder.propTypes = {
  title: Proptypes.string,
};

export default CollectionsEmptyPlacaholder;
