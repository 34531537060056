import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "redux/appSlice";
import { fetchUserReferralsInfluencers } from "redux/usersSlice";
import {
  getStripeAccount,
  getStripeAccountLink,
  getStripeLoginLink,
} from "services/api/paymentsApi";
import { LocalizationContext } from "services/localizationContext";
import { roundToTwo } from "utils/math";

import { PageLayout } from "../../PageLayout/PageLayout";
import ReferralCard from "../ReferralCard";
import styles from "./ReferralsInfluencers.module.scss";

export const ReferralsInfluencers = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const referralsInfluencers = useSelector(
    (state) => state.users.referrals.influencers.entities
  );
  const user = useSelector((state) => state.users.entities);
  const [stripeData, setStripeData] = useState(null);
  const [isStripeDataLoading, setIsStripeDataLoading] = useState(false);

  const totalEarnings = useMemo(
    () =>
      roundToTwo(
        referralsInfluencers.reduce((a, b) => a + (b?.capitalization || 0), 0)
      ),
    [referralsInfluencers]
  );

  const showConfigurePayoutBtn = totalEarnings >= 1;
  const onStripeError = () => {
    dispatch(
      setError({
        open: true,
        title: "Error",
      })
    );
  };
  const openStripeUrl = (url) => {
    if (url) window.open(url, "_blank");
  };
  const getStripeUrlMethod = () => {
    return stripeData && stripeData?.details_submitted
      ? getStripeLoginLink()
      : getStripeAccountLink();
  };
  const onConfigurePayout = () => {
    setIsStripeDataLoading(true);
    getStripeUrlMethod()
      .then((res) => {
        openStripeUrl(res?.url);
      })
      .catch(onStripeError)
      .finally(() => {
        setIsStripeDataLoading(false);
      });
  };

  useEffect(() => {
    dispatch(fetchUserReferralsInfluencers());
    setIsStripeDataLoading(true);
    getStripeAccount().then((res) => {
      setStripeData(res?.stripeData);
      setIsStripeDataLoading(false);
    });
  }, []);

  return (
    <PageLayout
      title={t("referral.myInfluencersRefferrals")}
      customMobileHeaderMenu={<></>}
      isBack={true}
    >
      <Box className={styles.header}>
        <Box className={styles.subtitleWrap}>
          <Box className={styles.subtitle}>
            <Typography variant="h5" className={styles.earningsTitle}>
              {t("referral.totalEarnings")}:
            </Typography>
            <Typography variant="h5" className={styles.earnings}>
              ${totalEarnings}
            </Typography>
          </Box>
          {showConfigurePayoutBtn ? (
            <LoadingButton
              onClick={onConfigurePayout}
              variant="contained"
              className={styles.configureBtn}
              loading={isStripeDataLoading}
            >
              {t("referral.configurePayout")}
            </LoadingButton>
          ) : null}
        </Box>
      </Box>
      <Box className={styles.content}>
        {!!referralsInfluencers.length ? (
          <Box className={styles.cardsWrap}>
            {referralsInfluencers.map((item, i) => (
              <ReferralCard
                key={item.name}
                className={styles.card}
                name={item.name}
                earnings={item.capitalization}
                joinedAt={item.joinedAt}
                imageUrl={item.profileImageUrl}
                locale={user.language}
                isLarge
                isInfluencer={true}
              />
            ))}
          </Box>
        ) : (
          <Box className={styles.placeholder}>
            <Typography className={styles.placeholderTitle} variant="h4">
              {t("referral.invitedCelebritiesWillAppearHere")}
            </Typography>
          </Box>
        )}
      </Box>
    </PageLayout>
  );
};

export default ReferralsInfluencers;
