import { Box, Button, CircularProgress, Tooltip } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ReactComponent as DotsEditIcon } from "assets/svg/dots-edit-icon.svg";
import classNames from "classnames/bind";
import React from "react";

import styles from "./ActionTooltip.module.scss";

const Loader = ({ isLoading }) =>
  isLoading ? (
    <Box className={styles.loader}>
      <CircularProgress color="inherit" size={16} />
    </Box>
  ) : null;

export const ActionTooltip = ({
  isLoading,
  children,
  open,
  setOpen,
  disabled,
  buttonClassName,
}) => {
  const buttonClass = classNames(styles.button, {
    [buttonClassName]: buttonClassName,
  });

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box>
        <Tooltip
          open={open}
          title={
            <Box className={styles.tooltip}>
              <Loader isLoading={isLoading} />
              {children}
            </Box>
          }
        >
          <Button
            variant="text"
            className={buttonClass}
            onClick={() => setOpen(true)}
            disabled={disabled}
          >
            <DotsEditIcon />
          </Button>
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
};
