import { Box, Button, Skeleton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Badge } from "components/Badge/Badge";
import { CategoryRow } from "components/CategoryRow/CategoryRow";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchChallenges } from "redux/challengesSlice";
import {
  getChallengesLoadingSelector,
  getChallengesSelector,
} from "redux/selectors/challengesSelector";
import { LocalizationContext } from "services/localizationContext";

import { PageLayout } from "../PageLayout/PageLayout";
import styles from "./Badges.module.scss";

const Badges = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:1024px)");

  const challenges = useSelector(getChallengesSelector);
  const loading = useSelector(getChallengesLoadingSelector);
  const challengesArray = Object.entries(challenges);

  const [activeCategory, setActiveCategory] = useState("all");

  const setCategoryHandler = (value) => () => setActiveCategory(value);

  useEffect(() => {
    dispatch(fetchChallenges());
  }, []);

  return (
    <PageLayout title={t("badges.sliderTitle")} isBack={true}>
      <Box className={styles.list}>
        {loading && !challengesArray.length ? (
          <>
            <Skeleton variant="rectangular" className={styles.rowSkeleton} />
            <Skeleton variant="rectangular" className={styles.rowSkeleton} />
          </>
        ) : isMobile ? (
          <>
            <Box className={styles.filter}>
              <Button
                variant="outlined"
                key="all"
                className={`${styles.filterBtn} ${
                  activeCategory === "all" ? styles.filterBtnActive : ""
                }`}
                onClick={setCategoryHandler("all")}
              >
                {t("default.allStr")}
              </Button>
              {challengesArray.map((item) => (
                <Button
                  variant="outlined"
                  key={item[0]}
                  className={`${styles.filterBtn} ${
                    activeCategory === item[0] ? styles.filterBtnActive : ""
                  }`}
                  onClick={setCategoryHandler(item[0])}
                >
                  {t(`challenges.${item[0]}`)}
                </Button>
              ))}
            </Box>
            <Box className={styles.listMobile}>
              {activeCategory === "all"
                ? challengesArray.map((challenge) => {
                    return challenge[1].map((item) => {
                      const badge = item?.appUserTrack?.challengeStage?.badge;
                      const currentBadge = item?.currentBadge;
                      if (!badge || !currentBadge) {
                        return null;
                      }
                      return (
                        <Badge
                          key={badge.id}
                          challengeId={item.id}
                          imageUrl={badge.imageUrl}
                          stageNum={currentBadge.stageNum}
                          title={t(`badges.${badge.code}`)}
                          goalCount={currentBadge.goalCount}
                          wrapperClassName={styles.badgeItem}
                        />
                      );
                    });
                  })
                : challenges[activeCategory]?.map((item) => {
                    const badge = item?.appUserTrack?.challengeStage?.badge;
                    const currentBadge = item?.currentBadge;
                    if (!badge || !currentBadge) {
                      return null;
                    }
                    return (
                      <Badge
                        key={badge.id}
                        challengeId={item.id}
                        imageUrl={badge.imageUrl}
                        stageNum={currentBadge.stageNum}
                        title={t(`badges.${badge.code}`)}
                        goalCount={currentBadge.goalCount}
                        wrapperClassName={styles.badgeItem}
                      />
                    );
                  })}
            </Box>
          </>
        ) : (
          <>
            {challengesArray.map((challenge) => (
              <CategoryRow
                title={t(`challenges.${challenge[0]}`)}
                data={challenge[1]}
                key={challenge[0]}
                category={challenge[0]}
                rowContentClassName={styles.rowContent}
              >
                {challenge[1].map((item) => {
                  const badge = item?.appUserTrack?.challengeStage?.badge;
                  const currentBadge = item?.currentBadge;
                  if (!badge || !currentBadge) {
                    return null;
                  }
                  return (
                    <Badge
                      key={badge.id}
                      challengeId={item.id}
                      imageUrl={badge.imageUrl}
                      stageNum={currentBadge.stageNum}
                      title={t(`badges.${badge.code}`)}
                      goalCount={currentBadge.goalCount}
                      wrapperClassName={styles.badgeItem}
                    />
                  );
                })}
              </CategoryRow>
            ))}
          </>
        )}
      </Box>
    </PageLayout>
  );
};

export default Badges;
