import { AppBar, Box, Skeleton, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as IconAdminClub } from "assets/svg/icon-admin.svg";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import classNames from "classnames/bind";
import { GLOBAL_HEADER_CLASSNAME, PATHS } from "constants/index";
import { throttle } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { fetchNotificationsStatus } from "redux/notificationsSlice";
import {
  getProfileId,
  getProfileIsInfluencer,
  getUsersLoading,
} from "redux/selectors/usersSelectors";
import { getAuthUsername } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import { goToDashboard } from "utils/auth";
import {
  getFilteredInfluencers,
  initialDataState,
  initialLoaders,
} from "utils/getFilteredInfluencers";
import { useTelegram } from "utils/hooks/useTelegram";

import ActiveLink from "../../ActiveLink";
import { SearchBar } from "../../SearchBar/SearchBar";
import { ProfileBox } from "../../Telegram/components/ProfileBox/ProfileBox";
import { MessagesIcon } from "./components/MessagesIcon";
import { NavMenu } from "./components/NavMenu/NavMenu";
import { NotificationsIcon } from "./components/NotificationsIcon";
import { ProfileNav } from "./components/ProfileNav/ProfileNav";
import { SearchResultList } from "./components/SearchResultList/SearchResultList";
import styles from "./Header.module.scss";

const AdminMenuItem = () => {
  const { t } = useContext(LocalizationContext);
  const isInfluencer = useSelector(getProfileIsInfluencer);
  const profileId = useSelector(getProfileId);
  const profileLoader = useSelector(getUsersLoading);
  const location = useLocation();
  const { user: userTg } = useTelegram();

  const isAdminMenuItem = location.pathname === PATHS.HOME;

  if (!profileId && profileLoader) {
    return (
      <Skeleton
        variant="rectangular"
        style={{ width: "110px" }}
        className={styles.menuItem}
      />
    );
  }

  if (!isAdminMenuItem || userTg || !profileId) {
    return null;
  }

  return (
    <>
      {isInfluencer ? (
        <ActiveLink
          isExternal
          onClick={goToDashboard}
          className={styles.menuItemMobile}
          activeClass={styles.menuItemActive}
        >
          <IconAdminClub />
          {t("default.clubAdmin")}
        </ActiveLink>
      ) : (
        <ActiveLink
          to={getAuthUsername() ? null : PATHS.SIGN_UP}
          onClick={goToDashboard}
          className={styles.menuItemMobile}
        >
          <IconAdminClub />
          {t("default.becomeCreator")}
        </ActiveLink>
      )}
    </>
  );
};

export default function Header(props) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const headerRef = useRef();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg_small"));
  const { user: userTg } = useTelegram();

  const [loaders, setLoaders] = useState(initialLoaders);
  const [data, setData] = useState(initialDataState);
  const [isResultOpened, setResultOpen] = useState(false);
  const [isSearchActive, setActiveCallback] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleSearch = (searchTerm) => {
    getFilteredInfluencers({ params: { searchTerm }, setLoaders, setData });
    setResultOpen(true);
  };

  const headerClassName = classNames(styles.header, GLOBAL_HEADER_CLASSNAME, {
    [styles.headerHidden]: !showHeader,
    [props.headerClass]: props.headerClass,
  });

  if (!showHeader) {
    document.body.classList.add("header-hidden");
  } else {
    document.body.classList.remove("header-hidden");
  }

  useEffect(() => {
    dispatch(fetchNotificationsStatus());
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const headerHeight = headerRef.current?.offsetHeight || 0;

      if (currentScrollY > lastScrollY && currentScrollY > headerHeight) {
        setShowHeader(false);
      } else if (currentScrollY < lastScrollY) {
        setShowHeader(true);
      }

      setLastScrollY(currentScrollY);
    };

    const throttledHandleScroll = throttle(handleScroll, 300);

    window.addEventListener("scroll", throttledHandleScroll);

    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [lastScrollY]);

  if (
    !isMobile &&
    (window.location.href.includes(PATHS.SIGN_UP) ||
      window.location.href.includes(PATHS.LOGIN) ||
      window.location.href.includes(PATHS.FORGOT_PASSWORD))
  ) {
    return null;
  }

  return (
    <Box className={headerClassName} ref={headerRef}>
      {props.backBtn ? (
        <Box className={styles.backBtnContainer}>
          {props.backBtn}
          {props.customMobileHeaderMenu && isMobile ? (
            <Box className={styles.iconsWrapper}>
              {props.customMobileHeaderMenu}
            </Box>
          ) : null}
        </Box>
      ) : (
        <AppBar
          className={styles.headerAppBar}
          position="static"
          color="dark"
          elevation={0}
        >
          <Toolbar className={styles.headerToolbar}>
            {!isMobile || props.isLogo ? (
              <Link to="/" className={styles.logo}>
                <Logo />
              </Link>
            ) : props.title ? (
              <>{props.title}</>
            ) : null}
            {isMobile ? (
              props.isSearch ? (
                <Box className={styles.mobileSearchWrapper}>
                  {userTg ? <ProfileBox /> : null}
                  <SearchBar
                    onSeacrhChanged={handleSearch}
                    classNameWrapper={styles.search}
                    isAnimate
                    isResultOpened={isResultOpened}
                    setResultOpen={setResultOpen}
                    classNameResult={styles.searchResult}
                    className={styles.searchInput}
                    classNameInputAnimatedActive={styles.searchInputActive}
                    isShowMainSearchIcon={!isSearchActive}
                    setActiveCallback={setActiveCallback}
                    isFocus={isSearchActive}
                  >
                    <SearchResultList data={data} loaders={loaders} />
                  </SearchBar>
                </Box>
              ) : props.customMobileHeaderMenu ? (
                <Box className={styles.iconsWrapper}>
                  {props.customMobileHeaderMenu}
                </Box>
              ) : (
                <Box className={styles.iconsWrapper}>
                  <AdminMenuItem />
                  {userTg ? (
                    <ProfileBox />
                  ) : (
                    <MessagesIcon className={styles.messagesIcon} />
                  )}
                  <NotificationsIcon className={styles.notificationIcon} />
                </Box>
              )
            ) : (
              <Box className={styles.headerMainSection}>
                <SearchBar
                  onSeacrhChanged={handleSearch}
                  classNameWrapper={styles.search}
                  isAnimate
                  isResultOpened={isResultOpened}
                  setResultOpen={setResultOpen}
                  classNameResult={styles.searchResult}
                >
                  <SearchResultList data={data} loaders={loaders} />
                </SearchBar>
                <NavMenu>
                  <Box className={styles.iconsWrapper}>
                    <MessagesIcon className={styles.messagesIcon} />
                    <NotificationsIcon className={styles.notificationIcon} />
                  </Box>
                  <ProfileNav />
                </NavMenu>
              </Box>
            )}
          </Toolbar>
          {props.children}
        </AppBar>
      )}
    </Box>
  );
}
