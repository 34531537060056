export const SearchIcon = () => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.15322 6.12558C8.34367 5.69503 8.44948 5.22347 8.45477 4.72629C8.47594 2.66581 6.77241 1 4.65093 1C2.54533 1 0.841797 2.65044 0.841797 4.69041C0.841797 6.7714 2.63526 8.44234 4.78319 8.3757C5.30695 8.36033 5.80954 8.23731 6.25923 8.03229L8.47594 9.28806C8.79336 9.4367 8.99969 9.75448 8.99969 10.0979C8.99969 10.5951 8.58175 11 8.06857 11H8.06328"
      stroke="#70E8FC"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

export const PlusIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 0V10"
      stroke="#70E8FC"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M0 5H10"
      stroke="#70E8FC"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);
