import * as React from "react";
import classnames from "classnames/bind";
import styles from "./PhoneNumber.module.scss";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
const cx = classnames.bind(styles);

/* TODO:: setup defaultCountry from the browser */

export default React.forwardRef(({ children, className, ...rest }, ref) => {
  const classNames = cx(styles.PhoneInput, className);

  return (
    <PhoneInput
      ref={ref}
      {...rest}
      className={classNames}
      defaultCountry="US"
      international
      countryCallingCodeEditable
      limitMaxLength
    >
      {children}
    </PhoneInput>
  );
});
