import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchUser, updateUserProfile } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";
import { getUserLocale } from "utils/langHelper";

const LangService = () => {
  const { setLocalization } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser())
      .unwrap()
      .then((d) => {
        if (d.language) {
          setLocalization(d.language);
        } else {
          const language = getUserLocale();
          dispatch(updateUserProfile({ language }));
          setLocalization(language);
        }
      });
  }, []);

  return null;
};

export default LangService;
