import { Box, LinearProgress, Typography } from "@mui/material";
import { ReactComponent as GradeIcon } from "assets/svg/grade-icon.svg";
import { PATHS } from "constants/index";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { roundToTwo } from "utils/math";

import styles from "./ChallengeItemMobile.module.scss";

export const ChallengeItemMobile = ({ challenge }) => {
  const { appUserTrack } = challenge;
  const navigate = useNavigate();

  const { t } = useContext(LocalizationContext);

  const status =
    !+appUserTrack?.progress && appUserTrack?.stageNum === 1 ? (
      t("challenges.notStarted")
    ) : +appUserTrack?.progress >= 100 ? (
      t("challenges.completed")
    ) : (
      <Box className={styles.challengeGrade}>
        <GradeIcon />
        {t("challenges.stage", {
          rank: appUserTrack?.challengeStage?.stageNum,
        })}
      </Box>
    );

  return (
    <Box
      className={styles.challenge}
      onClick={() => navigate(PATHS.CHALLENGES_ID.replace(":id", challenge.id))}
    >
      {challenge.imageUrl ? (
        <img
          src={challenge.imageUrl}
          alt="challenge"
          className={styles.challengeImg}
        />
      ) : null}
      <Typography className={styles.challengeTitle} variant="textMediumBold">
        {t(`challenges.${challenge.code}-title`)}
      </Typography>
      <Typography
        variant="body3"
        className={`${styles.challengeStatus} opacity_07`}
      >
        {status}
      </Typography>
      <Box className={styles.challengeBar}>
        <LinearProgress
          variant="determinate"
          value={Math.min(roundToTwo(+appUserTrack.progress), 100) || 0}
          classes={{
            root: styles.progressBarRoot,
            bar: styles.progressBar,
          }}
        />
      </Box>
    </Box>
  );
};
