import { Box, Skeleton } from "@mui/material";
import { FollowersBlock } from "components/FollowersBlock/FollowersBlock";
import SubscribeInfluencer from "components/SubscribeInfluencer";
import UnsubscribeInfluencer from "components/UnsubscribeInfluencer/ConfirmDlg";
import { INFLUENCER_ACTIVE_ID_STORAGE_KEY } from "constants/index";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getInfluencerFollowers } from "services/userApi";
import { goToInfluencer } from "utils";
import { useIsMobile } from "utils/hooks";

import styles from "./Card.module.scss";
import { CardMiddleBlock } from "./components/CardMiddleBlock/CardMiddleBlock";
import { CardTopBlock } from "./components/CardTopBlock/CardTopBlock";

export const Card = ({ card }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();

  const [followers, setFollowers] = useState([]);
  const [followersLoader, setFollowersLoader] = useState(true);
  const [showUnsubscribeConfirm, setShowUnsubscribeConfirm] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [isFollowing, setFollowing] = useState(card.isFollowing);
  const [isSubscribedCancelled, setSubscribedCanceled] = useState(
    card?.subscriptionData?.isCancelled
  );

  useEffect(() => {
    if (!isMobile) {
      getInfluencerFollowers({
        influencerId: card?.id,
        limit: 5,
        page: 1,
      })
        .then((data) => setFollowers(data))
        .finally(() => setFollowersLoader(false));
    }
  }, [card?.id, isMobile]);

  return (
    <>
      <Box
        className={styles.card}
        key={card.id}
        onClick={goToInfluencer({
          id: card.id,
          handle: card.handle,
          navigate,
          location,
        })}
      >
        <Box className={styles.cardInfo}>
          <img src={card.profileImageUrl} alt="avatar" />
          <Box className={styles.cardMainContent}>
            <CardTopBlock
              card={card}
              classNameBtn={styles.subscribeBtn}
              setShowUnsubscribeConfirm={setShowUnsubscribeConfirm}
              setClientSecret={setClientSecret}
              isFollowing={isFollowing}
              isSubscribed={card.isSubscribed}
              isSubscribedCancelled={isSubscribedCancelled}
              setFollowing={setFollowing}
              setSubscribedCanceled={setSubscribedCanceled}
            />
            <CardMiddleBlock card={card} />
            {!isMobile ? (
              <>
                {followersLoader ? (
                  <Skeleton
                    variant="rectangular"
                    className={styles.followersSkeleton}
                  />
                ) : (
                  <FollowersBlock
                    influencerData={{ id: card.id, handle: card.handle }}
                    followers={followers}
                    className={styles.followers}
                    followersClassNameBlock={styles.followersBlock}
                  />
                )}
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
      <SubscribeInfluencer
        clientSecret={clientSecret}
        influencerData={card}
        onCloseSubscribeModal={() => {
          setClientSecret(null);
          sessionStorage.removeItem(INFLUENCER_ACTIVE_ID_STORAGE_KEY);
        }}
      />
      {card?.subscriptionData && (
        <UnsubscribeInfluencer
          open={showUnsubscribeConfirm}
          influencerId={card.id}
          onClose={(isSuccess) => {
            if (isSuccess) {
              setSubscribedCanceled((prev) => !prev);
            }
            setShowUnsubscribeConfirm(false);
          }}
          influencerData={card}
          {...(card?.subscriptionData || {})}
        />
      )}
    </>
  );
};
