import { Box, Skeleton, Typography } from "@mui/material";
import { ReactComponent as ExpandMoreIcon } from "assets/svg/arrow-down.svg";
import stylesCard from "components/Card/card.module.scss";
import Tokens from "components/Card/Tokens";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import { CARD_TYPES } from "../../constants/card";

export const SellCards = ({
  data,
  handleBuyBtnClick,
  id,
  loading,
  currencySymbol,
  defaultExpanded,
}) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  return (
    <>
      {loading ? (
        <Skeleton
          variant="rectangular"
          style={{ height: "250px", marginTop: "24px" }}
        />
      ) : data?.tokens?.length ? (
        <Box sx={{ marginTop: "24px" }} key={defaultExpanded}>
          <Tokens
            data={data}
            cardsCount={data.tokens.length}
            currencySymbol={currencySymbol}
            title={t("discover.editionsForSale")}
            subTitle={
              data?.type !== CARD_TYPES.CUSTOM
                ? t("discover.editionNumber")
                : null
            }
            onSubmit={handleBuyBtnClick}
            defaultExpanded={!defaultExpanded}
            expandButton={
              <Box className={stylesCard.collectionDetailsBottom}>
                <Typography
                  variant="body2"
                  className={`${stylesCard.collectionDetailsBottomTitleLink}`}
                  onClick={() => navigate(`/pass/${id}/pass-for-sale`)}
                >
                  {t("discover.viewAllCards")}
                  <ExpandMoreIcon />
                </Typography>
              </Box>
            }
            actionTitle={t("edition.buyCard")}
          />
        </Box>
      ) : null}
    </>
  );
};
