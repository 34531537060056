import { Button, Typography } from "@mui/material";
import MediaResetPostModal from "components/PostCreator/components/ResetModal/MediaResetPostModal";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";

import { FILE_TYPES, handleChangeFile } from "../../utils";
import styles from "./AddMediaButton.module.scss";

export const AddMediaButton = ({
  files,
  setFiles,
  setErrorType,
  className = "",
  isActive,
  icon,
  isAnotherMediaType,
  handleMediaChange,
  setShouldHideTooltips,
}) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalConfirmed, setIsModalConfirmed] = useState(false);

  const fileInputRef = useRef(null);

  useEffect(() => {
    setShouldHideTooltips && setShouldHideTooltips(isModalOpen);
  }, [isModalOpen]);

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleModalConfirmation = () => {
    setIsModalConfirmed(true);
    triggerFileInput();
  };

  const handleConfirmationModal = () => {
    if (isAnotherMediaType) {
      setIsModalOpen(true);
    } else {
      setIsModalConfirmed(true);
      triggerFileInput();
    }
  };

  const handleChangeFileWrapper = (event) => {
    if (isModalConfirmed) {
      handleChangeFile({
        dispatch,
        t,
        setFiles,
        filesInState: files,
        setErrorType,
      })(event);
      handleMediaChange && handleMediaChange();
    }
  };

  return (
    <>
      <MediaResetPostModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={t("discover.alertReplaceYtbVideoTitle")}
        description={t("discover.alertReplaceYtbVideoDesc")}
        handleModalConfirmation={handleModalConfirmation}
      />
      <Button
        variant="contained"
        size="small"
        startIcon={icon}
        className={`${className} ${styles.addPhotoBtn} ${
          isActive ? styles.addPhotoBtnActive : ""
        }`}
        onClick={handleConfirmationModal}
      >
        <Typography variant="body4_groteskMedium" component="span">
          {isMobile ? t("default.media") : t("default.addMedia")}
        </Typography>
      </Button>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        accept={FILE_TYPES}
        hidden
        onChange={handleChangeFileWrapper}
      />
    </>
  );
};
