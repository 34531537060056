import { PATHS } from "constants/index";

export const getIndicators = ({ navigate }) => {
  return [
    {
      id: 1,
      action: () => navigate(PATHS.TELEGRAM_SLIDE_EARN),
    },
    {
      id: 2,
      action: () => navigate(PATHS.TELEGRAM_SLIDE_POINTS),
    },
    {
      id: 3,
      action: () => navigate(PATHS.TELEGRAM_SLIDE_FRIENDS),
    },
    {
      id: 4,
      action: () => navigate(PATHS.TELEGRAM_SLIDE_AIRDROP),
    },
  ];
};
