import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as CompletedCheckmark } from "assets/svg/completed-checkmark.svg";
import { ReactComponent as WarnIcon } from "assets/svg/warn-icon.svg";
import UnfriendModal from "components/Modal/UnfriendModal";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoader, setSnackbar } from "redux/appSlice";
import { closeModal, openModal } from "redux/modalSlice";
import {
  getMFACode,
  updateUserProfile,
  verifyPhoneNumber,
} from "redux/usersSlice";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";

import styles from "./MFA.module.scss";
import PhoneNumber from "./PhoneNumber";
import VerificationCode from "./VerificationCode";

const MFA = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const theme = useTheme();

  const user = useSelector((state) => state.users.entities);
  const is2faEnabled = useSelector(
    (state) => state.users.entities.is2faEnabled
  );

  const [resendCountdown, setResendCountdown] = useState(0);
  const [canEditPhone, setCanEditPhone] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [phoneInputValue, setPhoneInputValue] = useState("");
  const [shouldDisableMFA, setShouldDisableMFA] = useState(false);
  const [isPhoneChanging, setIsPhoneChanging] = useState(false);
  const [isPhoneChangingTmp, setIsPhoneChangingTmp] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [codeSentTo, setCodeSentTo] = useState(user?.phoneNumber || "");
  const loader = <CircularProgress className={styles.loader} />;

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const generateCode = (isPhoneChange) => {
    const params = isPhoneChangingTmp ? { newPhoneNumber: true } : {};
    dispatch(getMFACode(params))
      .unwrap()
      .then((r) => {
        setShowCodeInput(true);
        if (!isPhoneChange) {
          setCanEditPhone(false);
        }

        if (!r.payload) {
          dispatch(
            setSnackbar({
              open: true,
              message: t("settings.newVerificationCodeSent"),
            })
          );
        }
      })
      .catch((e) =>
        dispatch(
          setError({
            open: true,
            title: t("default.oops"),
            subtitle: e?.message || e,
          })
        )
      )
      .finally(() => dispatch(setLoader(false)));
  };

  const handleDisableMFA = () => {
    dispatch(closeModal());
    setShouldDisableMFA(true);
    sendCode({ phone: user?.phoneNumber });
  };

  const handleChangePhoneNumber = () => {
    dispatch(closeModal());
    setIsPhoneChanging(true);
    setCanEditPhone(true);
    setPhoneInputValue("");
    sendCode({ phone: user?.phoneNumber, isPhoneChange: true });
  };

  const reset = () => {
    setShowCodeInput(false);
    setCanEditPhone(false);
    setShouldDisableMFA(false);
    setIsPhoneChanging(false);
    // setPhoneInputValue("");
    setCodeSentTo(user?.phoneNumber || "");
  };

  const openChangePhoneNumberModal = () => {
    dispatch(
      openModal({
        children: (
          <UnfriendModal
            title={t("settings.changePhoneNumber?")}
            description={t("settings.changePhoneNumberDescription")}
            onConfirm={handleChangePhoneNumber}
            onCancel={() => dispatch(closeModal())}
            cancelBtnText={t("default.cancel")}
            confirmBtnText={t("settings.change")}
            isWarn
          />
        ),
      })
    );
  };

  const openDisableMFAModal = () => {
    dispatch(
      openModal({
        children: (
          <UnfriendModal
            title={t("default.areYouSure")}
            description={t("settings.disable2FAModalDescription")}
            onConfirm={handleDisableMFA}
            onCancel={() => dispatch(closeModal())}
            cancelBtnText={t("default.cancel")}
            confirmBtnText={t("settings.disable2FA")}
          />
        ),
      })
    );
  };

  const toggleMFA = ({ code, shouldDisableMFA }) => {
    dispatch(
      updateUserProfile({
        authCode: +code,
        is2faEnabled: !shouldDisableMFA,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          setSnackbar({
            open: true,
            message: shouldDisableMFA
              ? t("settings.twoFADeactivated")
              : t("settings.twoFAActivated"),
          })
        );
        reset();
      })
      .catch((err) => {
        dispatch(
          setError({
            open: true,
            title: t("default.oops"),
            subtitle: errorResponseMessages(err.data || err, t),
          })
        );
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const sendCode = ({ phone, isResend, isPhoneChange }) => {
    dispatch(setLoader(true));
    if (!user.isPhoneVerified) {
      dispatch(updateUserProfile({ phoneNumber: phone }))
        .unwrap()
        .then(() => {
          setCodeSentTo(phone);
          setShowCodeInput(true);
          setCanEditPhone(false);

          dispatch(
            setSnackbar({
              open: true,
              message: t("settings.newVerificationCodeSent"),
            })
          );
        })
        .catch((err) => {
          dispatch(
            setError({
              open: true,
              title: t("default.oops"),
              subtitle: errorResponseMessages(err.data || err, t),
            })
          );
        })
        .finally(() => dispatch(setLoader(false)));
    } else {
      generateCode(isPhoneChange);
    }

    if (isResend) {
      setResendCountdown(60);
    }
  };

  const verifyHandler = (code) => {
    dispatch(verifyPhoneNumber({ authCode: +code }))
      .unwrap()
      .then((data) => {
        setIsPhoneChangingTmp(false);
        setPhoneInputValue(user.newPhoneNumber);
        dispatch(
          setSnackbar({
            open: true,
            message: t("settings.twoFAActivated"),
          })
        );
        if (data.statusCode !== 400) {
          reset();
        }
      })
      .catch((err) => {
        dispatch(
          setError({
            open: true,
            title: t("default.oops"),
            subtitle: errorResponseMessages(err.data || err, t),
          })
        );
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const onSubmitCode = ({ code }) => {
    dispatch(setLoader(true));

    if (!user.isPhoneVerified) {
      verifyHandler(code);
      return;
    }

    if (isPhoneChanging) {
      if (!isPhoneChangingTmp) {
        dispatch(
          updateUserProfile({
            newPhoneNumber: phoneInputValue,
            authCode: +code,
          })
        )
          .unwrap()
          .then(() => setIsPhoneChangingTmp(true))
          .catch((err) => {
            dispatch(setLoader(false));
            // reset();
            dispatch(
              setError({
                open: true,
                title: t("default.oops"),
                subtitle: errorResponseMessages(err.data || err, t),
              })
            );
          })
          .finally(() => dispatch(setLoader(false)));
      } else {
        verifyHandler(code);
      }
    } else {
      toggleMFA({ code, shouldDisableMFA });
    }
  };

  const TitleBlock = () => (
    <Box className={styles.titleBlock}>
      <Box className={styles.statusLabel}>
        {is2faEnabled ? (
          <CompletedCheckmark
            className={`${styles.statusLabelIcon} ${styles.enabled}`}
          />
        ) : (
          <WarnIcon
            className={`${styles.statusLabelIcon} ${styles.disabled}`}
          />
        )}
        <Typography
          variant="h4"
          className={styles.statusLabelText}
          sx={{
            color: is2faEnabled
              ? theme.palette.tertiary.main
              : theme.palette.coral.main,
          }}
        >
          {is2faEnabled ? t("settings.2FAEnabled") : t("settings.2FADisabled")}
        </Typography>
      </Box>
      {is2faEnabled ? (
        <Button
          variant="outlined-red"
          className={styles.disableBtn}
          onClick={openDisableMFAModal}
          disabled={isPhoneChanging}
        >
          {t("settings.disable2FA")}
        </Button>
      ) : null}
    </Box>
  );

  const Header = () => (
    <>
      {isPhoneChanging || shouldDisableMFA ? (
        <Box className={styles.changesInfoBlock}>
          <WarnIcon className={styles.changesInfoBlockIcon} />
          {isPhoneChanging ? (
            <Typography
              variant="subtitle1"
              className={styles.changesInfoBlockText}
            >
              {t("settings.changePhoneNumberInfo")}
            </Typography>
          ) : null}
          {shouldDisableMFA ? (
            <Typography
              variant="subtitle1"
              className={styles.changesInfoBlockText}
            >
              {t("settings.disable2FAInfo")}
            </Typography>
          ) : null}
        </Box>
      ) : (
        <Box className={styles.descriptionWrap}>
          <Typography
            variant="subtitle1"
            component="p"
            className={styles.description}
          >
            {t("settings.mFADescription")}
          </Typography>
        </Box>
      )}
    </>
  );

  if (Object.keys(user).length === 0) {
    return loader;
  }

  return (
    <>
      <TitleBlock />
      <Box className={styles.container}>
        <Header />
        <PhoneNumber
          canEditPhone={canEditPhone}
          setCanEditPhone={setCanEditPhone}
          sendCode={sendCode}
          openChangePhoneNumberModal={openChangePhoneNumberModal}
          isCodeSent={showCodeInput}
          isPhoneChanging={isPhoneChanging}
          setPhoneValue={setPhoneInputValue}
          setPhoneError={setPhoneError}
        />
        {showCodeInput ? (
          <VerificationCode
            onResend={sendCode}
            onSubmit={onSubmitCode}
            resendCountdown={resendCountdown}
            setResendCountdown={setResendCountdown}
            phone={phoneInputValue}
            codeSentTo={codeSentTo}
            autoFocus={!isPhoneChanging || !canEditPhone}
            isPhoneChanging={isPhoneChanging}
            phoneError={phoneError}
          />
        ) : null}
      </Box>
    </>
  );
};

export default MFA;
