import { Box, MenuItem, Select, Typography } from "@mui/material";
import { GlobeIcon } from "assets/svg/countries";
import { LANGUAGES_LIST } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "services/localizationContext";

import { getUserLocale } from "../../utils/langHelper";
import styles from "./LanguageSelect.module.scss";

export const LanguageSelect = ({ className }) => {
  const languages = { ...LANGUAGES_LIST, language: "language" };
  const { t, setLocalization } = useContext(LocalizationContext);
  const userLocale = getUserLocale();

  const [language, setLanguage] = useState(languages.language);

  const handleSubmitLanguage = (_, data) => {
    setLocalization(LANGUAGES_LIST[data.props.name]);
    setLanguage(LANGUAGES_LIST[data.props.name]);
    localStorage.setItem("selected-language", LANGUAGES_LIST[data.props.name]);
  };

  useEffect(() => {
    setLocalization(localStorage.getItem("selected-language") || userLocale);
  }, []);

  return (
    <Box className={className}>
      <Select
        onChange={handleSubmitLanguage}
        value={language}
        defaultValue={language}
        className={styles.select}
        MenuProps={{
          classes: { paper: styles.dropdown },
        }}
        renderValue={(selectedValue) => (
          <Typography
            variant="body5"
            sx={{ fontSize: "16px", letterSpacing: "1.28px" }}
            className={styles.optionValue}
          >
            <GlobeIcon />
            {t(
              `account.${
                Object.entries(languages).find(
                  ([_, value]) => value === selectedValue
                )[0]
              }`
            )}
          </Typography>
        )}
      >
        {Object.entries(languages).map((arr) => (
          <MenuItem
            key={arr[1]}
            value={arr[1]}
            name={arr[0]}
            className={styles.option}
            selected={arr[1] === language}
          >
            <Typography
              variant="body5"
              sx={{ fontSize: "16px", letterSpacing: "1.28px" }}
              className={styles.optionValue}
            >
              {t(`account.${arr[0]}`)}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
