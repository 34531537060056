import { Box, Button, Snackbar } from "@mui/material";
import classNames from "classnames/bind";
import CommentItem from "components/Feed/Comment/CommentItem";
import React, { forwardRef, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePostCommentsCount } from "redux/feedSlice";
import { LocalizationContext } from "services/localizationContext";
import useDelayedExecution from "utils/hooks/useDelayedExecution";

import styles from "../../post.module.scss";
import { AUTO_HIDE_TIMEOUT } from "../../utils";

export const CommentBox = forwardRef(
  (
    {
      renderComments,
      isNested,
      comment,
      handleReply,
      loadingReplyIds,
      parentId,
      activeCommentReplyIds,
      setActiveCommentReplyIds,
      setActiveReplyId,
      parentRepliesCount,
      moderationCommentData,
      accessGranted,
    },
    ref
  ) => {
    const dispatch = useDispatch();

    const { t } = useContext(LocalizationContext);
    const cx = classNames.bind(styles);
    const post = useSelector((state) => state.feed.post.entities);

    const isActiveReplies = activeCommentReplyIds.includes(comment.id);
    const { id: postId, commentsCount } = post;

    const [isDeleteParentMode, setDeleteParentMode] = useState(false);
    const [isSnackBarDelete, setSnackBarDelete] = useState(false);
    const [isDeleteMode, setDeleteMode] = useState(false);
    const [isActionLoading, setActionLoading] = useState(false);

    const { stopExecute, startExecute } =
      useDelayedExecution(AUTO_HIDE_TIMEOUT);

    const undoDelete = () => {
      stopExecute();
      setDeleteMode(false);
      setDeleteParentMode(false);
      setSnackBarDelete(false);
      setActionLoading(false);
    };

    return (
      <>
        {!isDeleteParentMode ? (
          <Box
            className={cx(isNested && styles.commentReplyWrapper)}
            key={comment.id}
            ref={ref}
          >
            <CommentItem
              comment={comment}
              handleReply={handleReply}
              isNested={isNested}
              activeCommentReplyIds={activeCommentReplyIds}
              setActiveCommentReplyIds={setActiveCommentReplyIds}
              setActiveReplyId={setActiveReplyId}
              isReplyLoading={loadingReplyIds.includes(comment.id)}
              appUserId={comment.appUserId}
              setDeleteParentMode={setDeleteParentMode}
              setSnackBarDelete={setSnackBarDelete}
              startExecute={startExecute}
              setDeleteMode={setDeleteMode}
              setActionLoading={setActionLoading}
              isActionLoading={isActionLoading}
              isDeleteMode={isDeleteMode}
              moderationCommentData={moderationCommentData}
              accessGranted={accessGranted}
            />
            {comment?.replies && isActiveReplies
              ? renderComments({
                  comments: comment.replies,
                  isNested: true,
                  parentId: comment.id,
                  parentRepliesCount: comment.countReplies,
                  moderationCommentData,
                })
              : null}
          </Box>
        ) : null}
        <Snackbar
          open={isSnackBarDelete}
          message={t("discover.deleteCommentSuccess")}
          autoHideDuration={AUTO_HIDE_TIMEOUT}
          severity={"success"}
          onClose={() => setSnackBarDelete(false)}
          action={
            <Button onClick={undoDelete} className="snackbar-undo-button ">
              {t("discover.undo")}
              <svg
                width="28"
                height="12"
                viewBox="0 0 28 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27 10.75V7.25C27 3.93629 24.3137 1.25 21 1.25H1.5"
                  stroke="#111322"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </Button>
          }
        />
      </>
    );
  }
);
