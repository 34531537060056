import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ReactComponent as ExpandMoreIcon } from "assets/svg/arrow-down.svg";
import classnames from "classnames/bind";
import React from "react";

import styles from "./DrowDown.module.scss";

const cx = classnames.bind(styles);

export const DropDown = ({ children, title, className }) => {
  return (
    <Accordion className={cx(styles.framedBox, className)}>
      <AccordionSummary
        className={styles.titleWrapper}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="h5_medium">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.children}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
