import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import styles from "./SliderSyncing.module.scss";
export const SliderSyncing = ({
  main,
  preview,
  mainClassname,
  previewItemClassName,
  mainValueClassName,
  previewValueClassName,
  previewDescriptionClassName,
  slidesToShow = 6,
  activeSlide,
  handleAfterChange = () => {},
}) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  return (
    <Box>
      <Slider
        asNavFor={nav2}
        ref={(slider) => (sliderRef1 = slider)}
        className={`${styles.slider} ${mainClassname}`}
        infinite={false}
        initialSlide={activeSlide}
        afterChange={handleAfterChange}
      >
        {main.map(({ src, value, isShow }) => (
          <Box className={styles.mainContainer}>
            <img src={src} alt="badge" />
            {value && isShow ? (
              <Box className={mainValueClassName}>{value}</Box>
            ) : null}
          </Box>
        ))}
      </Slider>
      {preview.length > 1 ? (
        <Slider
          asNavFor={nav1}
          ref={(slider) => (sliderRef2 = slider)}
          slidesToShow={slidesToShow}
          swipeToSlide={true}
          focusOnSelect={true}
          arrows={false}
          className={styles.sliderPreview}
          initialSlide={activeSlide}
          infinite={false}
        >
          {preview.map(({ src, value, description, stylesPreviewItem }) => (
            <Box>
              <Box
                className={`${styles.sliderPreview} ${previewItemClassName}`}
                style={stylesPreviewItem}
              >
                <Box className={styles.sliderPreviewContent}>
                  <img src={src} alt="badge" />
                  {value ? (
                    <Box className={previewValueClassName}>{value}</Box>
                  ) : null}
                </Box>
                {description ? (
                  <Box className={previewDescriptionClassName}>
                    {description}
                  </Box>
                ) : null}
              </Box>
            </Box>
          ))}
        </Slider>
      ) : null}
    </Box>
  );
};
