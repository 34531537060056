import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import styles from "./TabPanel.module.scss";

const TabPanel = (props) => {
  const { children, value, index, tabPanelClassName, isStatic, ...other } =
    props;

  return (
    <div
      role="tabpanel"
      hidden={!isStatic && value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {isStatic ? (
        <Box
          className={`${styles.tabPanel} ${
            tabPanelClassName ? tabPanelClassName : ""
          }`}
        >
          {children}
        </Box>
      ) : (
        value === index && (
          <Box
            className={`${styles.tabPanel} ${
              tabPanelClassName ? tabPanelClassName : ""
            }`}
          >
            {children}
          </Box>
        )
      )}
    </div>
  );
};

export default TabPanel;

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
