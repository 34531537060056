import { getInfluencers } from "../services/discoverApi";

export const initialDataState = {
  allStars: [],
  risingStars: [],
  groups: [],
};

export const initialLoaders = {
  allStarsLoader: true,
  risingStarsLoader: true,
  groupsLoader: true,
};

export const LEVEL_BIGGER = "bigger";
export const LEVEL_SMALLER = "smaller";
export const LEVEL_GROUP = "group";

export const getFilteredInfluencers = ({
  params,
  setLoaders,
  setData,
  preventGroups = false,
  preventBigger = false,
  preventSmaller = false,
}) => {
  setLoaders(initialLoaders);
  if (!preventBigger) {
    getInfluencers({ level: LEVEL_BIGGER, limit: 15, ...params })
      .then((data) => {
        if (data?.data) {
          setData((prevState) => ({ ...prevState, allStars: data.data }));
        }
      })
      .finally(() =>
        setLoaders((prevState) => ({ ...prevState, allStarsLoader: false }))
      );
  }
  if (!preventSmaller) {
    getInfluencers({ level: LEVEL_SMALLER, limit: 15, ...params })
      .then((data) => {
        if (data?.data) {
          setData((prevState) => ({ ...prevState, risingStars: data.data }));
        }
      })
      .finally(() =>
        setLoaders((prevState) => ({ ...prevState, risingStarsLoader: false }))
      );
  }
  if (!preventGroups) {
    getInfluencers({
      level: LEVEL_GROUP,
      limit: 12,
      ...params,
    })
      .then((data) => {
        if (data?.data) {
          setData((prevState) => ({ ...prevState, groups: data.data }));
        }
      })
      .finally(() =>
        setLoaders((prevState) => ({ ...prevState, groupsLoader: false }))
      );
  }
};
