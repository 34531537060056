import { Box, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AuthLeftSide from "components/AuthLeftSide";
import styles from "./KYC.module.scss";

import { fetchKycStatus, fetchUser } from "redux/usersSlice";
import { useIsMobile } from "utils/hooks";

import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import StepAddress from "./StepAddress";
import Analytics from "services/Analytics.service";

const steps = [
  {
    step: 0,
    title: "Verify your identity",
    hidden: true,
  },
  {
    step: 1,
    title: "General Information",
  },
  {
    step: 2,
    title: "Email Verification",
  },
  {
    step: 3,
    title: "Personal Information",
  },
  {
    step: 4,
    title: "Address",
  },
];

const Kyc = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedCountry, setCountry] = useState("");
  const [loading, setLoading] = useState(true);
  const [mainError, setMainError] = useState(null);

  const onMainError = (e) => {
    if (e?.ssn) {
      setMainError(e.ssn);
      setActiveStep(1);
    }
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  useEffect(() => {
    dispatch(fetchUser());
    //TODO::remove this
    dispatch(fetchKycStatus()).then(() => {
      setLoading(false);
    });
    Analytics.track("Start Complete Profile");
  }, []);

  const renderStep = (step) => {
    switch (step) {
      case 0:
        return <Step0 handleNext={handleNext} handleBack={handleBack} />;
      case 1:
        return (
          <Step1
            mainError={mainError}
            handleNext={handleNext}
            handleBack={handleBack}
            setSelectedCountry={setCountry}
          />
        );
      case 2:
        return <Step2 handleNext={handleNext} handleBack={handleBack} />;
      case 3:
        return (
          <Step3
            handleNext={handleNext}
            handleBack={handleBack}
            selectedCountry={selectedCountry}
          />
        );
      case 4:
        return (
          <StepAddress
            setMainError={onMainError}
            handleNext={handleNext}
            handleBack={handleBack}
            selectedCountry={selectedCountry}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box className={styles.container}>
      {!isMobile && <AuthLeftSide step={activeStep} steps={steps} />}

      <Box className={styles.rightSide}>
        {loading ? (
          <Box className={styles.center}>
            <CircularProgress />
          </Box>
        ) : (
          renderStep(activeStep)
        )}
      </Box>
    </Box>
  );
};
export default Kyc;
