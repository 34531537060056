import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as CloseIcon } from "assets/svg/close-icon.svg";
import { ReactComponent as ExitFullScreenIcon } from "assets/svg/feed/exit-fullscreen.svg";
import { ReactComponent as FullScreenIcon } from "assets/svg/feed/full-screen.svg";

import styles from "./MediaControls.module.scss";
export const MediaControls = ({
  onClose,
  isFullScreen,
  onSwicthFullScreen,
  showFullScreenBtn,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg_small"));

  const close = () => {
    onClose && onClose();
  };

  return (
    <Box className={styles.controls}>
      {onSwicthFullScreen && showFullScreenBtn ? (
        <>
          {isFullScreen ? (
            <IconButton className={styles.btn} onClick={onSwicthFullScreen}>
              <ExitFullScreenIcon className={styles.btnIcon} />
            </IconButton>
          ) : !isMobile ? (
            <IconButton className={styles.btn} onClick={onSwicthFullScreen}>
              <FullScreenIcon className={styles.btnIcon} />
            </IconButton>
          ) : null}
        </>
      ) : null}
      {onClose ? (
        <IconButton className={styles.btn} onClick={close}>
          <CloseIcon className={styles.btnIcon} />
        </IconButton>
      ) : null}
    </Box>
  );
};
