export const SearchEmptyIcon = () => (
  <svg
    width="110"
    height="64"
    viewBox="0 0 110 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.5336 27.7354C23.9551 29.3139 21.7628 30.3077 19.3366 30.3077C14.4843 30.3077 10.5673 26.3908 10.5673 21.5384C10.5673 16.686 14.4843 12.7691 19.3366 12.7691C24.189 12.7691 28.106 16.686 28.106 21.5384M4.72113 50.7691C-2.58664 30.3073 11.2689 39.0766 19.3367 39.0766C22.4252 39.0766 26.362 37.7914 29.635 37.3531"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M65.4728 25.4491C62.9996 27.9223 59.5647 29.4794 55.7634 29.4794C48.1607 29.4794 42.0237 23.3424 42.0237 15.7397C42.0237 8.13707 48.1607 2 55.7634 2C63.366 2 69.5031 8.13707 69.5031 15.7397M32.8639 61.5382C21.4141 29.4788 43.1229 43.2185 55.7634 43.2185C68.4039 43.2185 90.1127 29.4788 78.6629 61.5382"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M96.8589 27.7354C95.2804 29.3139 93.0881 30.3077 90.6619 30.3077C85.8095 30.3077 81.8926 26.3908 81.8926 21.5384C81.8926 16.686 85.8095 12.7691 90.6619 12.7691C95.5143 12.7691 99.4312 16.686 99.4312 21.5384M105.277 50.7691C112.585 30.3073 98.7297 39.0766 90.6619 39.0766C88.2312 39.0766 85.2752 38.2807 82.5309 37.7279"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);
