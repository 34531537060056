import { Box, CircularProgress, Skeleton, Tab, Tabs } from "@mui/material";
import { CustomSelect } from "components/CustomSelect/CustomSelect";
import { Card } from "components/NewCard";
import { CARD_VARIANTS } from "constants/card";
import {
  CHANNELS,
  EVENTS,
  PATHS,
  REWARDS_ACTION_TYPE,
  SCROLL_THRESHOLD,
} from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { setError } from "redux/appSlice";
import { updateDrop } from "redux/dropsSlice";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { getMarketplaceCards } from "services/discoverApi";
import { LocalizationContext } from "services/localizationContext";
import PusherService from "services/Pusher.service";
import { useSetUrlParams } from "utils/hooks/useSetUrlParams";

import styles from "../../../Drops.module.scss";

const tabs = {
  live: 0,
  resale: 1,
};

const LIMIT = 30;
const SEARCH_PARAM = "reward";

export const PhaseListBody = () => {
  const params = useParams();
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { setUrlParams } = useSetUrlParams();
  // const section2Cards = useSelector((state) => selectDropsByPhase(state, "2"));

  const [resaleCards, setResaleCards] = useState([]);
  const [resaleCardsLoading, setResaleCardsLoading] = useState(true);
  const [resaleCardsParams, setResaleCardsParams] = useState({
    page: 1,
    rewardType: null,
  });
  // const [section2CardsLoading, setSection2CardsLoading] = useState(true);
  const [limitedEditionCards, setLimitedEditionCards] = useState([]);
  const [limitedEditionParams, setLimitedEditionParams] = useState({
    page: 1,
    rewardType: null,
  });
  const [limitedEditionCardsLoading, setLimitedEditionCardsLoading] =
    useState(true);
  const [value, setValue] = React.useState(
    parseInt(params?.activeTab) || tabs.live
  );

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    navigate(PATHS.DROPS_TAB.replace(":activeTab", newValue), {
      replace: true,
    });
  };

  const setTabProps = (index) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  const fetchLeCards = ({ page, rewardTypeValue }) => {
    getMarketplaceCards({
      type: "custom",
      page,
      limit: LIMIT,
      orderBy: "-dropStartDate",
      ...(rewardTypeValue && { rewardType: rewardTypeValue }),
    })
      .then((res) => {
        if (page === 1) {
          setLimitedEditionCards(res?.cards);
        } else {
          setLimitedEditionCards((prevState) => prevState.concat(res?.cards));
        }
      })
      .finally(() => setLimitedEditionCardsLoading(false));
  };

  const fetchResaleCards = ({ page, rewardTypeValue }) => {
    getMarketplaceCards({
      page,
      limit: LIMIT,
      orderBy: "-createdAt",
      type: "resale",
      ...(rewardTypeValue && { rewardType: rewardTypeValue }),
    })
      .then((res) => {
        if (page === 1) {
          setResaleCards(res?.cards);
        } else {
          setResaleCards((prevState) => prevState.concat(res?.cards));
        }
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              title: t("error.error"),
              subtitle: errorResponseMessages(err, t),
            })
          );
        }
      })
      .finally(() => {
        setResaleCardsLoading(false);
      });
  };

  const hasMoreLE =
    limitedEditionCards.length && limitedEditionCards.length % LIMIT === 0;
  const hasMoreResale = resaleCards.length && resaleCards.length % LIMIT === 0;
  const loadMore = (cb) => () =>
    cb((prevState) => ({ ...prevState, page: prevState.page + 1 }));

  const rewardTypes = [
    { value: null, label: t("default.allRewards") },
    {
      value: REWARDS_ACTION_TYPE.HIDDEN_CONTENT,
      label: t("default.hiddenContent"),
    },
    {
      value: REWARDS_ACTION_TYPE.PREMIUM_CHAT,
      label: t("default.premiumChat"),
    },
    { value: REWARDS_ACTION_TYPE.FOLLOW_BACK, label: t("default.followBack") },
    { value: REWARDS_ACTION_TYPE.VIDEO_CALL, label: t("default.videoCall") },
  ];

  const searchHandler = (rewardType) => {
    setResaleCardsParams({
      page: 1,
      rewardType,
    });
    setLimitedEditionParams({
      page: 1,
      rewardType,
    });
    fetchLeCards({
      page: 1,
      rewardTypeValue: rewardType,
    });
    fetchResaleCards({
      page: 1,
      rewardTypeValue: rewardType,
    });
  };

  const selectHandler = (e) => {
    const value = e.target.value;
    setUrlParams({
      params: [{ name: SEARCH_PARAM, value }],
      isReset: !value,
    });
    searchHandler(value);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const value = searchParams.get(SEARCH_PARAM);
    searchHandler(value);
  }, [location.search]);

  useEffect(() => {
    if (limitedEditionParams.page > 1) {
      fetchLeCards({
        page: limitedEditionParams.page,
        rewardTypeValue: limitedEditionParams.rewardType,
      });
    }
  }, [limitedEditionParams.page]);

  useEffect(() => {
    if (resaleCardsParams.page > 1) {
      fetchResaleCards({
        page: resaleCardsParams.page,
        rewardTypeValue: resaleCardsParams.rewardType,
      });
    }
  }, [resaleCardsParams.page]);

  useEffect(() => {
    const channelDrop = PusherService.subscribe(CHANNELS.DROPS);

    channelDrop.bind(EVENTS.STATUS_CHANGED, (data) => {
      dispatch(updateDrop(data));
    });
    channelDrop.bind(EVENTS.PRICE_CHANGED, (data) => {
      dispatch(updateDrop(data));
    });
    channelDrop.bind(EVENTS.BUY, (data) => {
      dispatch(updateDrop(data));
    });

    return () => {
      PusherService.unsubscribe(CHANNELS.DROPS);
    };
  }, []);

  return (
    <Box className={styles.container}>
      <Box className={styles.body}>
        <Box className={styles.bodyTabs}>
          {resaleCards.length && limitedEditionCards.length ? (
            <Tabs
              value={value}
              onChange={handleChangeTab}
              className={`${styles.tabsPanel} global-tabs-class`}
            >
              <Tab {...setTabProps(tabs.live)} label={t("drops.tab1Title")} />
              <Tab {...setTabProps(tabs.resale)} label={t("drops.tab2Title")} />
            </Tabs>
          ) : (
            <Box />
          )}
          <CustomSelect
            variant="standard"
            options={rewardTypes}
            displayEmpty
            defaultValue={resaleCardsParams.rewardType || rewardTypes[0].value}
            controlClass={styles.selectControl}
            onChange={selectHandler}
            key={resaleCardsParams.rewardType} // the key is needed for the first render
          />
        </Box>
        <Box
          role="tabpanel"
          hidden={value !== tabs.live}
          id={`tabpanel-${tabs.live}`}
          aria-labelledby={`tab-${tabs.live}`}
          className={`${styles.tabPanel} ${
            value === tabs.live ? styles.tabPanelActive : ""
          }`}
        >
          {!limitedEditionCards.length && limitedEditionCardsLoading ? (
            <Box className={styles.bodyList}>
              <Skeleton
                variant="rectangular"
                className={`${styles.card} ${styles.cardSkeleton}`}
              />
              <Skeleton
                variant="rectangular"
                className={`${styles.card} ${styles.cardSkeleton}`}
              />
              <Skeleton
                variant="rectangular"
                className={`${styles.card} ${styles.cardSkeleton}`}
              />
              <Skeleton
                variant="rectangular"
                className={`${styles.card} ${styles.cardSkeleton}`}
              />
            </Box>
          ) : null}
          {limitedEditionCards.length ? (
            <InfiniteScroll
              dataLength={limitedEditionCards.length}
              next={loadMore(setLimitedEditionParams)}
              hasMore={hasMoreLE}
              loader={<CircularProgress />}
              scrollThreshold={SCROLL_THRESHOLD}
              scrollableTarget="scrollableEl_LE"
              className={styles.bodyList}
            >
              {limitedEditionCards.map((drop) => (
                <Card
                  key={drop.id}
                  variant={CARD_VARIANTS.FULL}
                  data={drop}
                  link={PATHS.CARD_ID.replace(":id", drop.id)}
                  overrideStyles={{ root: styles.card, img: styles.cardImg }}
                />
              ))}
            </InfiniteScroll>
          ) : null}
        </Box>
        <Box
          role="tabpanel"
          hidden={value !== tabs.resale}
          id={`tabpanel-${tabs.resale}`}
          aria-labelledby={`tab-${tabs.resale}`}
          className={`${styles.tabPanel} ${styles.tabPanelFeed} ${
            value === tabs.resale ? styles.tabPanelActive : ""
          }`}
        >
          {!resaleCards.length && resaleCardsLoading ? (
            <Box className={styles.bodyList}>
              <Skeleton
                variant="rectangular"
                className={`${styles.card} ${styles.cardSkeleton}`}
              />
              <Skeleton
                variant="rectangular"
                className={`${styles.card} ${styles.cardSkeleton}`}
              />
              <Skeleton
                variant="rectangular"
                className={`${styles.card} ${styles.cardSkeleton}`}
              />
              <Skeleton
                variant="rectangular"
                className={`${styles.card} ${styles.cardSkeleton}`}
              />
            </Box>
          ) : null}
          {resaleCards.length ? (
            <InfiniteScroll
              dataLength={resaleCards.length}
              next={loadMore(setResaleCardsParams)}
              hasMore={hasMoreResale}
              scrollThreshold={SCROLL_THRESHOLD}
              scrollableTarget="scrollableEl_Resale"
              className={styles.bodyList}
              loader={<CircularProgress />}
            >
              {resaleCards.map((drop) => (
                <Card
                  key={drop.id}
                  variant={CARD_VARIANTS.FULL}
                  data={drop}
                  link={PATHS.CARD_ID.replace(":id", drop.id)}
                  overrideStyles={{ root: styles.card, img: styles.cardImg }}
                />
              ))}
            </InfiniteScroll>
          ) : null}
        </Box>
      </Box>
      <Outlet />
    </Box>
  );
};
