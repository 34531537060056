import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import classNames from "classnames/bind";
import styles from "components/NewCard/Card.module.scss";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import { LinearProgressStyled } from "../../LinearProgress";
import { Price } from "./Price/Price";

export const LiveDrop = ({
  className,
  quantityAllocated,
  saleTokensLeft,
  currencySymbol,
  priceMovement,
  price,
}) => {
  const { t } = useContext(LocalizationContext);
  const cx = classNames.bind(styles);
  const theme = useTheme();
  const progressVal = (saleTokensLeft / quantityAllocated) * 100;

  return (
    <Box className={cx(className, styles.liveDrop)}>
      <Box className={styles.allocated}>
        <Typography variant="textNormal_14" className={styles.label}>
          {t("edition.availability")}
        </Typography>
        <Box className={styles.allocatedProgressBarWrapper}>
          <LinearProgressStyled
            color={theme.palette.lime}
            value={progressVal}
          />
        </Box>
      </Box>

      <Price
        currencySymbol={currencySymbol}
        priceMovement={priceMovement}
        price={price}
      />
    </Box>
  );
};
