import { Box, IconButton } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/svg/close-icon.svg";

import { MEDIA_TYPES } from "../../../constants";
import { MediaSlider } from "../../MediaSlider/MediaSlider";
import styles from "./MediaModal.module.scss";

export const MediaModal = ({ isOpen, media, index, setOpen }) => {
  const closeHandler = () => setOpen(false);

  const mediaSliderClickHandler = (e) => {
    if (
      e.target.closest(".meta-video") ||
      e.target.tagName === "IMG" ||
      e.target.closest(".global-audio-class")
    ) {
      return;
    }
    closeHandler();
  };

  if (!isOpen || media[index]?.type === MEDIA_TYPES.FILE) {
    return false;
  }

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.overlay} onClick={closeHandler} />
      <IconButton className={styles.close} onClick={closeHandler}>
        <CloseIcon className={styles.btnIcon} />
      </IconButton>
      <Box className={styles.sliderWrapper} onClick={mediaSliderClickHandler}>
        <MediaSlider
          data={media}
          isFullScreen={true}
          overrideStyles={{ sliderItem: styles.sliderItem }}
          alt="slide"
          index={index}
          accessGranted={true}
          isMultipleType={true}
        />
      </Box>
    </Box>
  );
};
