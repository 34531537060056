import { Box, Skeleton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import EmptyBlock from "components/Layout/EmptyBlock";
import React, { useContext, useEffect, useState } from "react";
import { isAuthenticated } from "services/auth.service";
import { getInfluencerLimitedEditionCards } from "services/influencerApi";
import { LocalizationContext } from "services/localizationContext";

import stylesInfluencer from "../../influencer.module.scss";
import { LimitedEdition } from "../LimitedEdition/LimitedEdition";
// import { SeasonItem } from "./components/SeasonItem/SeasonItem";

export const SeasonTab = ({ seasons, isSeasonsLoading, influencerId }) => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();

  const [limitedEdition, setLimitedEdition] = useState({
    cards: [],
    total: 0,
  });
  const [limitedEditionPage, setLimitedEditionPage] = useState(1);
  const [limitedEditionLoading, setLimitedEditionLoading] = useState(false);

  const getLimitedEditionCardsHandler = (id) => {
    if (id) {
      setLimitedEditionLoading(true);
      getInfluencerLimitedEditionCards({
        id,
        page: limitedEditionPage,
        limit: 30,
      })
        .then((res) => {
          if (limitedEditionPage === 1) {
            setLimitedEdition(res);
          } else {
            setLimitedEdition((prevState) => ({
              cards: [...prevState.cards, ...res.cards],
              total: res.total,
            }));
          }
        })
        .finally(() => setLimitedEditionLoading(false));
    }
  };

  useEffect(() => {
    getLimitedEditionCardsHandler(influencerId, limitedEditionPage);
  }, [limitedEditionPage, influencerId]);

  // if (isSeasonsLoading) {
  //   return (
  //     <Skeleton
  //       className={stylesInfluencer.seasonsSkelet}
  //       variant="rectangular"
  //       sx={{ bgcolor: theme.palette.surface.main }}
  //     />
  //   );
  // }

  if (!isAuthenticated()) {
    return <EmptyBlock text={t("discover.seasonSignUpInvitation")} />;
  }

  // const isEmptySeason =
  //   !seasons?.length || !seasons?.filter((item) => Boolean(item)).length;

  return (
    <Box>
      {limitedEdition.total ? (
        <LimitedEdition
          cards={limitedEdition.cards}
          loadMore={() => setLimitedEditionPage((prevState) => prevState + 1)}
          hasMore={limitedEdition.total > limitedEdition.cards.length}
        />
      ) : limitedEditionLoading ? (
        <Skeleton
          className={stylesInfluencer.seasonsSkelet}
          variant="rectangular"
          sx={{ bgcolor: theme.palette.surface.main }}
        />
      ) : (
        <EmptyBlock text={t("default.noData")} />
      )}
      {/*{isEmptySeason && !limitedEdition.total ? (*/}
      {/*  <EmptyBlock text={t("discover.noSeason")} />*/}
      {/*) : null}*/}
      {/*{seasons.map(({ seasonCards, season }, index) => (*/}
      {/*  <SeasonItem*/}
      {/*    seasonCards={seasonCards}*/}
      {/*    season={season}*/}
      {/*    key={season.id}*/}
      {/*    isDefaultActive={index === 0}*/}
      {/*  />*/}
      {/*))}*/}
    </Box>
  );
};
