import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as CameraOffIcon } from "assets/svg/camera-off-icon.svg";
import { ReactComponent as CameraOnIcon } from "assets/svg/camera-on-icon.svg";
import { ReactComponent as HandsetIcon } from "assets/svg/handset-icon.svg";
import { ReactComponent as MicrophoneOffIcon } from "assets/svg/microphone-off-icon.svg";
import { ReactComponent as MicrophoneOnIcon } from "assets/svg/microphone-on-icon.svg";
import CountdownDate from "components/CountdownDate/CountdownDate";
import { useContext, useState } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./Controls.module.scss";

export const Controls = ({
  meetingSession,
  leave,
  inMeeting,
  callInitialized,
  endData,
}) => {
  const { t } = useContext(LocalizationContext);
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [isMicOn, setIsMicOn] = useState(true);

  const toggleCamera = async () => {
    if (callInitialized) {
      if (isCameraOn) {
        await meetingSession.audioVideo.stopLocalVideoTile();
      } else {
        await meetingSession.audioVideo.startLocalVideoTile();
      }
      setIsCameraOn(!isCameraOn);
    }
  };

  const toggleMicrophone = async () => {
    if (callInitialized) {
      if (isMicOn) {
        await meetingSession.audioVideo.stopAudioInput();
      } else {
        const audioInputDevices =
          await meetingSession.audioVideo.listAudioInputDevices();
        await meetingSession.audioVideo.startAudioInput(
          audioInputDevices[0].deviceId
        );
      }
      setIsMicOn(!isMicOn);
    }
  };

  const leaveHandler = () => {
    if (inMeeting && callInitialized) {
      leave();
    }
  };

  return (
    <Box className={styles.controlsWrapper}>
      <Box className={styles.controls}>
        <IconButton
          onClick={toggleCamera}
          className={!isCameraOn ? styles.controlsInactive : ""}
        >
          {isCameraOn ? <CameraOnIcon /> : <CameraOffIcon />}
        </IconButton>
        <IconButton
          onClick={toggleMicrophone}
          className={!isMicOn ? styles.controlsInactive : ""}
        >
          {isMicOn ? <MicrophoneOnIcon /> : <MicrophoneOffIcon />}
        </IconButton>
        <IconButton onClick={leaveHandler} className={styles.controlsWaiting}>
          <HandsetIcon />
          <Typography variant="body6_medium_dark">
            {!inMeeting ? (
              t("default.waiting")
            ) : (
              <CountdownDate
                endDate={endData}
                hoursStyling={{
                  variant: "body6_medium_dark",
                }}
                onEnd={() => leaveHandler()}
                isAbbr={false}
              />
            )}
          </Typography>
        </IconButton>
      </Box>
    </Box>
  );
};
