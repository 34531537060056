import { Typography, Button, Popover, Paper, IconButton } from "@mui/material";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./toaster.module.scss";

import { ReactComponent as CloseIcon } from "assets/svg/close-icon.svg";

const Toaster = ({
  name,
  anchorEl,
  onUndoPress,
  isOpen,
  message,
  handleClose,
  anchorOrigin,
  transformOrigin,
  isTooltip,
  isAbove,
  isOnRight,
}) => {
  const { t } = useContext(LocalizationContext);

  const vertPos = (() => {
    if (isAbove) {
      return "top";
    }

    if (anchorOrigin?.vertical) {
      return anchorOrigin?.vertical;
    }

    return "bottom";
  })();

  const horPos = (() => {
    if (isOnRight) {
      return "right";
    }

    if (anchorOrigin?.horizontal) {
      return anchorOrigin?.horizontal;
    }

    return "left";
  })();

  const tOV = (() => {
    if (transformOrigin?.vertical) {
      return transformOrigin?.vertical;
    }

    if (isAbove || isOnRight) {
      return "bottom";
    }

    return "top";
  })();

  const tOH = (() => {
    if (transformOrigin?.horizontal) {
      return transformOrigin?.horizontal;
    }

    return "left";
  })();

  const isHCentered =
    transformOrigin?.horizontal === "center" &&
    anchorOrigin?.horizontal === "center";

  const isVCentered =
    transformOrigin?.vertical === "center" &&
    anchorOrigin?.vertical === "center";

  return (
    <Popover
      id={name}
      open={isOpen}
      anchorEl={anchorEl}
      className={styles.popover}
      anchorOrigin={{
        vertical: vertPos,
        horizontal: horPos,
      }}
      transformOrigin={{
        vertical: tOV,
        horizontal: tOH,
      }}
      disableScrollLock={true}
      onClose={isTooltip ? null : handleClose}
      disableEnforceFocus
    >
      <Paper
        className={`${styles.paper} ${isTooltip ? styles.hasTriangle : ""} ${
          isAbove ? styles.isAbove : ""
        } ${isOnRight ? styles.isOnRight : ""} ${
          isHCentered ? styles.isHCentered : ""
        } ${isVCentered ? styles.isVCentered : ""}`}
      >
        <Typography
          variant={isTooltip ? "body2" : "body1"}
          component="span"
          className={styles.popoverText}
          sx={{ pr: isTooltip ? 1.5 : 0 }}
        >
          {message}
        </Typography>
        {isTooltip ? (
          <IconButton onClick={handleClose} className={styles.closeBtn}>
            <CloseIcon className={styles.closeBtnIcon} />
          </IconButton>
        ) : null}
        {!!onUndoPress ? (
          <Button
            onClick={onUndoPress}
            variant="text"
            className={styles.undoBtn}
          >
            {t("default.undo")}
          </Button>
        ) : null}
      </Paper>
    </Popover>
  );
};

export default Toaster;
