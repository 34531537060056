import { PATHS } from "constants/index";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { LocalizationContext } from "../../../services/localizationContext";
import { PageLayout } from "../../PageLayout/PageLayout";
import { LottieAnimation } from "../components/LottieAnimation/LottieAnimation";
import { SlideTutorial } from "../components/SlideTutorial/SlideTutorial";
import { getIndicators } from "../components/utils";
import { TelegramPageWrapper } from "../TelegramPageWrapper";
import styles from "./TelegramSlide.module.scss";

export const TelegramSlide4 = () => {
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);

  const indicators = getIndicators({ navigate });

  return (
    <TelegramPageWrapper>
      <PageLayout
        isShowHeader={false}
        isBottomMenuHide={true}
        pageWrapperClassName={styles.pageWrapper}
        pageClassName={styles.page}
      >
        <LottieAnimation
          url={"https://cdn.lottielab.com/l/Dc9za8u8dD2zce.json"}
          speed={0.5}
        />
        <SlideTutorial
          buttonText={t("default.next")}
          title={t("default.telegramSlidePage4Title")}
          description={t("default.telegramSlidePage4Description")}
          activeIndex={3}
          indicators={indicators}
          action={() => navigate(PATHS.TELEGRAM_ONBOARDING_GIFT)}
          closeAction={() => navigate(PATHS.TELEGRAM_ONBOARDING_GIFT)}
        />
      </PageLayout>
    </TelegramPageWrapper>
  );
};
