import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { closeModal, openModal } from "redux/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconSuccess } from "assets/svg/icon-success.svg";
import styles from "./Modal.module.scss";
import { MODAL_TYPE } from "constants/";
import { LocalizationContext } from "services/localizationContext";
import DeleteAccountModal from "./DeleteAccountModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 260,
  bgcolor: "#111322",
  border: "2px solid #21264E",
  boxSizing: "border-box",
  borderRadius: "10px",
  padding: "16px",
};

const DeleteAccountSuccess = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Box sx={style} className={styles.modalSuccess}>
      <IconSuccess />
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        className={styles.modalTitle}
      >
        {t("settings.yourAccountWillBeDeleted")}
      </Typography>
      <Box className={styles.modalBody}>
        <Typography variant="body2" className={styles.modalDescription}>
          {t("settings.deleteAccountSuccess")}
        </Typography>
      </Box>

      <Button
        variant="contained"
        className={styles.continueButton}
        onClick={handleClose}
      >
        {t("settings.goToSettings")}
      </Button>
    </Box>
  );
};

export default DeleteAccountSuccess;
