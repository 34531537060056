import { Box, Typography } from "@mui/material";
import Countdown from "components/Countdown";
import { differenceInSeconds, intervalToDuration, isValid } from "date-fns";
import { useContext, useEffect, useRef, useState } from "react";
import { LocalizationContext } from "services/localizationContext";

const CountdownDate = ({
  children,
  endDate,
  className,
  onEnd,
  hoursStyling,
  isAbbr = true,
  isShowDays,
}) => {
  const { timerLocalize: localize } = useContext(LocalizationContext);
  const getFormattedValue = () => {
    if (!endDate) return "";
    const end =
      differenceInSeconds(new Date(endDate), new Date()) > 0
        ? new Date(endDate)
        : new Date();
    const duration = intervalToDuration({
      start: new Date(),
      end,
    });
    const { days = 0, hours = 0, minutes = 0, seconds = 0 } = duration;
    let hoursLeft = isShowDays ? hours : days * 24 + hours;
    const daysText = isAbbr ? localize.days : "";
    const hoursText = isAbbr ? localize.hours : "";
    const minutesText = isAbbr ? localize.minutes : "";
    const secondsText = isAbbr ? localize.seconds : "";

    const daysPart = days > 0 ? `${days}${daysText} ` : "";
    const isDaysDisplayed = isShowDays && days > 0;
    const isHoursDisplayed = isDaysDisplayed || hoursLeft > 0;

    let hoursPart = hoursLeft > 0 ? `${hoursLeft}${hoursText} ` : "";
    if (isDaysDisplayed && !hoursLeft) hoursPart = `00${hoursText} `;

    let minutesPart =
      minutes > 0
        ? `${minutes < 10 ? `0${minutes}` : minutes}${minutesText} `
        : "00 ";
    if (isHoursDisplayed && !minutes) minutesPart = `00${minutesText} `;

    let secondsPart =
      seconds > 0
        ? `${seconds < 10 ? `0${seconds}` : seconds}${secondsText}`
        : "00";
    if ((isDaysDisplayed || isHoursDisplayed || minutes > 0) && !seconds)
      secondsPart = `00${secondsText}`;

    return (
      <Box>
        {isShowDays ? daysPart : ""}
        <Box
          component={hoursStyling ? Typography : "span"}
          variant={hoursStyling?.variant}
          color={hoursStyling?.color?.main}
        >
          {hoursPart}
        </Box>
        {minutesPart}
        {secondsPart}
      </Box>
    );
  };

  const onSetTimer = () => {
    setSecondsLeft(differenceInSeconds(new Date(endDate), new Date()));
    setTimeFormatted(getFormattedValue);

    if (onEnd && differenceInSeconds(new Date(endDate), new Date()) <= 0) {
      onEnd();
      setIsEnd(true);
    }
  };
  const [secondsLeft, setSecondsLeft] = useState(
    differenceInSeconds(new Date(endDate), new Date())
  );
  const [timeFormatted, setTimeFormatted] = useState(getFormattedValue);
  const [isEnd, setIsEnd] = useState(false);
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (!endDate) return;
    let isSubscribed = true;
    isSubscribed &&
      setIsEnd(differenceInSeconds(new Date(endDate), new Date()) <= 0);

    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (isSubscribed) {
      onSetTimer();
    }
    return () => (isSubscribed = false);
  }, [endDate]);

  if (isEnd) return <>- : -</>;

  return (
    <Countdown
      value={secondsLeft}
      onSetValue={onSetTimer}
      className={className}
    >
      {timeFormatted}
      {children}
    </Countdown>
  );
};
const validDate = (props, propName, componentName) => {
  if (!isValid(new Date(props[propName])))
    return new Error(
      `Invalid prop ${propName} passed to ${componentName}. Expected a valid Date.`
    );
};
CountdownDate.propTypes = {
  endDate: validDate,
};

export default CountdownDate;
