import { PATHS } from "constants/index";
import { Navigate } from "react-router-dom";
import { useTelegram } from "utils/hooks/useTelegram";

export const TelegramPageWrapper = ({ children }) => {
  const { user } = useTelegram();

  if (!user) {
    // return <Navigate to={PATHS.HOME} />;
  }

  return <>{children}</>;
};
