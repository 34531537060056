import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as GradeIcon } from "assets/svg/grade-icon.svg";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { roundToTwo } from "utils/math";

import styles from "./ChallengeProgress.module.scss";

export const ChallengeProgress = ({
  stage,
  isSingleStage,
  appUserTrack = {},
}) => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const { progress, challengeStage } = appUserTrack;

  const getProgress = ({ currentStageNum, stageNum, progress }) => {
    if (currentStageNum === stageNum) {
      return Math.min(roundToTwo(progress), 100);
    }

    if (currentStageNum > stageNum) {
      return 100;
    }

    return 0;
  };

  const currentRankProgressPct = getProgress({
    currentStageNum: challengeStage?.stageNum,
    stageNum: stage?.stageNum,
    progress: progress,
  });

  const progressState = (() => {
    let color = theme.palette.purple.main;
    let text = t("challenges.notStarted");

    if (+currentRankProgressPct > 0 && +currentRankProgressPct < 100) {
      color = theme.palette.tertiary.main;
      text = t("challenges.complete");
    }
    if (+currentRankProgressPct >= 100) {
      color = theme.palette.lime.main;
      text = t("challenges.completed");
    }

    return {
      color,
      text,
    };
  })();

  if (!stage) {
    return null;
  }

  return (
    <Typography
      variant="body4_groteskMedium"
      className={styles.progress}
      sx={{ color: progressState.color }}
    >
      <Box className={styles.progressStage}>
        <GradeIcon />
        {isSingleStage
          ? t("default.singleStage")
          : t("challenges.stage", { rank: stage.stageNum })}
      </Box>
      {`${+currentRankProgressPct ? +currentRankProgressPct + "%" : ""} ${
        progressState.text
      }`}
    </Typography>
  );
};
