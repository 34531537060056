import { api } from "./api.service";
const API_URL = process.env.REACT_APP_API_HOST;

export const createSwapRequest = async (params) => {
  try {
    return api.post(`${API_URL}/swaps`, params);
  } catch (e) {
    console.error("Cannot create the swap request", e);
  }
};

export const getSwapRequestById = async (params) => {
  try {
    return api.get(`${API_URL}/swaps/${params.id}`);
  } catch (e) {
    console.error("Cannot get the swap request", e);
  }
};

export const rejectSwapById = async (params) => {
  try {
    return api.post(`${API_URL}/swaps/${params.swapId}/reject`, params);
  } catch (e) {
    console.error("Cannot reject the swap request", e);
  }
};

export const approveSwapById = async (params) => {
  try {
    return api.post(`${API_URL}/swaps/${params.swapId}/confirm`, params);
  } catch (e) {
    console.error("Cannot approve the swap request", e);
  }
};

export const cancelSwapById = async (params) => {
  try {
    return api.post(`${API_URL}/swaps/${params.swapId}/cancel`, params);
  } catch (e) {
    console.error("Cannot cancel the swap request", e);
  }
};

export const getSwaps = async (params) => {
  try {
    const formattedParams = (() => {
      const paramsArray = Object.entries(params).map(([k, v], i) => {
        return `${k}=${v}`;
      });

      const str = paramsArray.join("&");
      return str;
    })();

    return api
      .get(`${API_URL}/swaps?${formattedParams}`)
      .then((response) => response);
  } catch (e) {
    console.error("Cannot get swaps", e);
  }
};
