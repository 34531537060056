import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { setLoader } from "redux/appSlice";
import { getInfluencerIdByHandle } from "services/influencerApi";
import { LocalizationContext } from "services/localizationContext";

export const CheckInfluencerHandle = () => {
  const { t } = useContext(LocalizationContext);
  const location = useLocation();
  const [isDataLoading, setDataLoading] = useState(true);
  const handle = location.pathname.split("/")[1];
  const [id, setId] = useState(null);
  const dispatch = useDispatch();

  const getInfluencerId = async () => {
    try {
      dispatch(
        setLoader({
          title: t("default.loading"),
          subtitle: t("default.itMayTakeAFewSeconds"),
        })
      );
      const res = await getInfluencerIdByHandle(handle);
      setId(res?.id);
      dispatch(setLoader(false));
      setDataLoading(false);
    } catch (e) {
      if (e?.status !== 404) {
        setDataLoading(false);
      }
      dispatch(setLoader(false));
      console.error("error", e);
    }
  };
  useEffect(() => {
    getInfluencerId();
  }, [handle]);

  if (isDataLoading) return null;
  if (id) {
    return <Outlet context={{ id }} />;
  } else {
    return <Navigate to="/404" replace />;
  }
};
