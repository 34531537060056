export const nameValidation = function (errorMessage) {
  return this.test(`is-valid-string`, errorMessage, function (value) {
    const result = /^[aA-zZ-\s]+$/i.test(value);
    return result;
  });
};
export const isAlphanumeric = function (errorMessage) {
  const pattern = /^[0-9a-zA-Z,-\s]+$/i;
  return this.test("is-alphanumeric", errorMessage, (value) =>
    pattern.test(value)
  );
};
