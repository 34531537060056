import { Box, Typography } from "@mui/material";
import { ReactComponent as IconRight } from "assets/svg/icon-right.svg";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import { PATHS } from "constants";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";

import styles from "./MenuTitle.module.scss";

export const getStringFromPathname = (pathname) => {
  const pattern = /\/settings\/([^\/]+)/;
  const matches = pathname.match(pattern);

  return matches ? matches[1] : matches;
};

const MenuTitle = ({ states, currentState, onBack }) => {
  const { t } = useContext(LocalizationContext);
  const location = useLocation();
  const isMobile = useIsMobile();

  const titleText = (() => {
    if (currentState === 0) {
      return t("settings.settings");
    }
    return t(`settings.${Object.keys(states).at(currentState).toLowerCase()}`);
  })();

  const getDefaultTitle = () => {
    return isMobile ? (
      <Box className={styles.defaultTitleWrap}>
        <BackBtn
          link={PATHS.SETTINGS}
          title={t(`settings.${getStringFromPathname(location.pathname)}`)}
        />
      </Box>
    ) : (
      <Typography variant="h3" className={styles.defaultTitle}>
        {t(`settings.${getStringFromPathname(location.pathname)}`)}
      </Typography>
    );
  };

  if (currentState === (states?.default || 0)) {
    return getDefaultTitle();
  }

  return (
    <Box className={styles.titleWrap} onClick={onBack}>
      <IconRight className={styles.backArrow} />
      <Typography className={styles.title} variant="h3">
        {titleText}
      </Typography>
    </Box>
  );
};

export default MenuTitle;
