import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setPayments3ds } from "./redux/appSlice";

export const Verification3ds = () => {
  const dispatch = useDispatch();
  const iframeRef = useRef();
  const redirectUrl3ds = useSelector((state) => state.app.redirectUrl3ds);

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data.messageType === "3ds") {
        dispatch(setPayments3ds(null));
      }
    });
  }, []);

  return (
    <>
      {redirectUrl3ds ? (
        <iframe
          className={"payments-3ds-verification-iframe"}
          ref={iframeRef}
          src={redirectUrl3ds}
        />
      ) : null}
    </>
  );
};
