import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import Countdown from "components/Countdown";
import React, { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { LocalizationContext } from "services/localizationContext";
import styles from "styles/auth/Auth.module.scss";
import roundedInputStyles from "styles/input/rounded.module.scss";
import * as Yup from "yup";

const VerificationCode = ({
  email,
  onSubmit,
  onResend,
  isMainLoading,
  mainServerError,
  resendCountdown,
  setResendCountdown,
}) => {
  const { t } = useContext(LocalizationContext);

  const [currentCode, setCurrentCode] = useState("");
  const inputRef = useRef(null);

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(t("error.required")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onCodeSubmit = (data) => {
    onSubmit(data.code);
  };

  const resend = () => {
    onResend(email, true);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onCodeSubmit)}
        autoComplete="off"
        className={`${styles.forgotPasswordForm} ${styles.form}`}
      >
        <Box>
          <Typography variant="h3" component="h1" className={styles.title}>
            {t("auth.enterVerificationCode")}
          </Typography>
          <Typography variant="body1">
            {t("auth.verificationPhoneNumberInfo")} {email}
          </Typography>
          <FormControl className={styles.inputWrap} sx={{ mt: 4 }}>
            {mainServerError && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="error">{mainServerError}</Typography>
              </Box>
            )}
            <TextField
              label={t("kyc.verificationCode")}
              error={!!errors.code}
              className={`${roundedInputStyles.input} ${
                roundedInputStyles.cyan
              } ${styles.codeInput} ${
                !!currentCode.length ? roundedInputStyles.filled : null
              }`}
              inputProps={{
                ref: inputRef,
                onWheel: () => inputRef.current?.blur(),
                onScroll: () => inputRef.current?.blur(),
                onChange: () => setCurrentCode(inputRef.current?.value),
                pattern: "[0-9]*",
              }}
              autoComplete="off"
              type="number"
              autoFocus
              {...register("code")}
            />
            {!!errors.code ? (
              <Typography variant="error" sx={{ mt: 1 }}>
                {errors.code?.message}
              </Typography>
            ) : null}
          </FormControl>
          {!resendCountdown > 0 ? (
            <Typography variant="body2" className={styles.sendCode}>
              {t("auth.didntReceiveCode")}&nbsp;&nbsp;
              <Button
                disabled={isMainLoading}
                onClick={resend}
                variant="text"
                className={styles.sendCodeBtn}
              >
                {t("auth.resend")}
              </Button>
            </Typography>
          ) : (
            <Box className={styles.sendCode}>
              <Countdown
                value={resendCountdown}
                onSetValue={setResendCountdown}
              >
                <Typography variant="body2" component="span">
                  {t("auth.codeResent")}
                </Typography>
              </Countdown>
            </Box>
          )}
        </Box>
        <Button
          disabled={isMainLoading}
          variant="contained"
          type="submit"
          className={styles.submitBtn}
        >
          {t("auth.verify")}
        </Button>
      </form>
    </>
  );
};
export default VerificationCode;
