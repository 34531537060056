import { Box, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import { SearchEmptyIcon } from "../../Layout/Header/icons";
import styles from "./EmptyState.module.scss";

export const EmptyState = ({ className }) => {
  const { t } = useContext(LocalizationContext);

  const openChat = (e) => {
    if (e.target.tagName === "A" && e.target.href.includes("#request-us")) {
      e.preventDefault();
      // window.zE("messenger:set", "zIndex", 99999);
      window.zE("messenger", "show");
      window.zE("messenger", "open");
    }
  };

  return (
    <Box className={`${styles.emptyState} ${className}`}>
      <SearchEmptyIcon />
      <Typography variant="h5" className={styles.title}>
        {t("discover.discoverEmptyStateSearch")}
      </Typography>
      <Typography
        onClick={openChat}
        className={styles.description}
        variant="body2"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            t("discover.discoverEmptyStateSearchDescription"),
            {
              USE_PROFILES: { html: true },
            }
          ),
        }}
      />
    </Box>
  );
};
