import { Box, Button, Typography } from "@mui/material";
import { MODAL_TYPE } from "constants/";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setError } from "redux/appSlice";
import { closeModal, openModal } from "redux/modalSlice";
import { fetchUser } from "redux/usersSlice";
import { getOnfidoToken } from "services/api/paymentsApi";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { getKycStatus } from "services/dropsApi";
import { LocalizationContext } from "services/localizationContext";
import styles from "views/KYC/KYC.module.scss";

export const CheckKycStatus = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useContext(LocalizationContext);

  const getTokenOnfidoKyc = async () => {
    try {
      const sdkToken = await getOnfidoToken();
      return sdkToken;
    } catch (e) {
      dispatch(setError({ open: true, subtitle: errorResponseMessages(e, t) }));
    }
  };

  const onGetKycStatus = async () => {
    try {
      return await getKycStatus();
    } catch (e) {
      return null;
    }
  };

  const goToCompleteProfile = () => {
    localStorage.setItem("completeProfileBackRoute", location.pathname);
    navigate("/complete-profile");
  };

  const denyStatusTexts = {
    title: t("kyc.verifaicationFailed"),
    body: t("kyc.applicationFailedBody"),
  };
  const inProgressStatusTexts = {
    title: t("kyc.inReview"),
    body: t("kyc.inReviewBody"),
  };
  const getProfile = async () => {
    try {
      return await dispatch(fetchUser()).unwrap();
    } catch (e) {
      return null;
    }
  };
  const checkStatus = async ({ hideModals = false } = {}) => {
    const profile = await getProfile();
    if (profile && !profile.isCompleted) return goToCompleteProfile();
    const res = await onGetKycStatus();
    if (res?.state === "DENY") {
      return hideModals
        ? denyStatusTexts
        : dispatch(
            openModal({
              type: MODAL_TYPE.MODAL,
              children: (
                <Box className={[styles.modal, styles.error]}>
                  <Typography
                    variant="textMediumBold"
                    className={styles.modalTitle}
                  >
                    {denyStatusTexts.title}
                  </Typography>
                  <Typography variant="body1" className={styles.modalSubtitle}>
                    {denyStatusTexts.body}
                  </Typography>
                  <Box className={styles.buttonContainer}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        dispatch(closeModal());
                        navigate("/");
                      }}
                    >
                      {t("kyc.goToHome")}
                    </Button>
                  </Box>
                </Box>
              ),
            })
          );
    }
    if (res?.state === "IN_PROGRESS" || res?.state === "MANUAL_REVIEW") {
      return hideModals
        ? inProgressStatusTexts
        : dispatch(
            openModal({
              type: MODAL_TYPE.MODAL,
              children: (
                <Box className={[styles.modal, styles.error]}>
                  <Typography
                    variant="textMediumBold"
                    className={styles.modalTitle}
                  >
                    {inProgressStatusTexts.title}
                  </Typography>
                  <Typography variant="body1" className={styles.modalSubtitle}>
                    {inProgressStatusTexts.body}
                  </Typography>
                  <Box className={styles.buttonContainer}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        dispatch(closeModal());
                        navigate("/");
                      }}
                    >
                      {t("kyc.goToHome")}
                    </Button>
                  </Box>
                </Box>
              ),
            })
          );
    }
    if (
      res?.state === "NODATA" ||
      res?.state === "REVERIFY" ||
      res?.state === "REQUIRED" ||
      !res
    ) {
      const sdkToken = await getTokenOnfidoKyc();
      return { sdkToken };
    }
    return 1;
  };
  return checkStatus;
};
