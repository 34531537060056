import { Box, Button, Typography } from "@mui/material";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { setSelectedUser } from "redux/messengerSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "./UserProfileBox.module.scss";

export const UserProfileBox = (data) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const { profileImageUrl, username, category, isInfluencer } = data || {};

  const setActiveChat = () => {
    dispatch(setSelectedUser(data));
  };

  return (
    <Box className={styles.cardWrapper}>
      <Box
        className={`${styles.card} ${
          isInfluencer ? styles.cardInfluencer : ""
        }`}
      >
        <img src={profileImageUrl} alt="avatar" />
        <Typography variant="textNormal_14" className={styles.cardName}>
          {username}
        </Typography>
        <Typography variant="textNormal_14" className={styles.cardCategory}>
          {category || <Box style={{ opacity: 0 }}>.</Box>}
        </Typography>
        <Button
          className={styles.cardSendBtn}
          variant="outlined"
          size="small"
          onClick={setActiveChat}
        >
          {t("messages.sendMessage")}
        </Button>
      </Box>
    </Box>
  );
};
