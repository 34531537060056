import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as PointsIcon } from "assets/svg/challenges/points-dark.svg";
import { ReactComponent as CloseIcon } from "assets/svg/close-icon.svg";
import { ReactComponent as GradeIcon } from "assets/svg/grade-icon.svg";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSeparateModal } from "redux/modalSlice";
import { getSeparateModal } from "redux/selectors/modalSelectors";
import { LocalizationContext } from "services/localizationContext";

import ShareBtn from "../../Buttons/ShareBtn/ShareBtn";
import styles from "./ChallengeSuccessModal.module.scss";

export const ChallengeSuccessModal = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const modal = useSelector(getSeparateModal("challengeSuccess"));

  const shareLink = `${window.origin}/challenges/${modal?.data?.challengeId}`;

  const handleClose = () =>
    dispatch(
      toggleSeparateModal({
        type: "challengeSuccess",
        params: {
          isOpen: false,
        },
      })
    );

  if (!modal.isOpen) {
    return <></>;
  }

  return (
    <Box className={styles.modalWrapper}>
      <Box className={styles.modalOverlay} onClick={handleClose} />
      <Box className={styles.modal}>
        <Button onClick={handleClose} className={styles.close} variant="text">
          <CloseIcon />
        </Button>
        <Typography className={styles.modalTitle}>
          {t("default.challengeModalTitle")}
        </Typography>
        <Box className={styles.modalMainImgWrapper}>
          <img
            alt="badge"
            src={modal?.data?.challengeImageUrl}
            className={styles.modalMainImg}
          />
          {modal?.data?.goalCount ? (
            <Typography variant="body4" className={styles.modalMainValue}>
              {modal?.data?.goalCount}
            </Typography>
          ) : null}
        </Box>
        <Typography className={styles.modalChallengeTitle} variant="h4">
          {t(`challenges.${modal?.data?.challengeCode}-title`)}
        </Typography>
        <Typography className={styles.modalChallengeStage} variant="body1">
          <GradeIcon />
          {t("challenges.stage", { rank: modal?.data?.challengeStageNum })}
        </Typography>
        <Box className={styles.points}>
          <PointsIcon className={styles.pointsIcon} />
          <Typography variant="body1" className={styles.points}>
            +{modal?.data?.rewardPoints}
          </Typography>
        </Box>
        <Box className={styles.modalActions}>
          {/*<Button*/}
          {/*  variant="contain"*/}
          {/*  className={`${styles.modalActionButton} ${styles.modalActionButtonContain}`}*/}
          {/*>*/}
          {/*  {t("badges.goToBadge")}*/}
          {/*</Button>*/}
          <ShareBtn
            shareLink={shareLink}
            btnClassName={`${styles.modalActionButton} ${styles.modalActionButtonOutlined} ${styles.modalActionButtonShare}`}
          />
        </Box>
      </Box>
    </Box>
  );
};
