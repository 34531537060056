import { Box } from "@mui/material";
import classNames from "classnames/bind";
import { useEffect, useRef, useState } from "react";

import { Card } from "../Card/Card";
import styles from "./CarouselCards.module.scss";

export const CarouselCards = ({
  cards,
  isPickedGift,
  carouselClass,
  isCarouselStart,
}) => {
  const containerRef = useRef(null);

  const [radius, setRadius] = useState(0);

  const totalItems = cards.length;
  const angleStep = 360 / totalItems;

  const carouselClassName = classNames(styles.carousel, {
    [styles.carouselStart]: isCarouselStart && !isPickedGift,
    [styles.carouselHide]: isPickedGift,
    [carouselClass]: carouselClass,
  });

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const containerRect = container.getBoundingClientRect();
      const radius = Math.min(containerRect.width, containerRect.height) / 2;

      setRadius(radius);
    }
  }, []);

  return (
    <Box className={styles.wrapper}>
      <Box className={carouselClassName}>
        <Box className={styles.container} ref={containerRef}>
          {cards.map((item, index) => {
            const angle = angleStep * index;
            return (
              <Box
                className={styles.cardWrapper}
                key={item.id}
                style={{
                  transform: `rotate(${angle}deg) translate(-50%, ${
                    -1 * radius
                  }px)`,
                  zIndex: totalItems - index,
                }}
              >
                <Card {...item} />
              </Box>
            );
          })}
        </Box>
      </Box>
      {isPickedGift ? (
        <Box className={`${styles.cardWrapper} ${styles.cardWrapperActive}`}>
          <Card
            isActive={true}
            {...cards[Math.floor(Math.random() * cards.length)]}
          />
        </Box>
      ) : null}
    </Box>
  );
};
