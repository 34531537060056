import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as CalendarEditIcon } from "assets/svg/calendar-edit-icon.svg";
import { CustomErrorModal } from "components/CustomErrorModal/CustomErrorModal";
import { PATHS } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getScheduledCalls,
  patchScheduledCallsCancel,
  postScheduledCalls,
} from "services/influencerApi";
import { LocalizationContext } from "services/localizationContext";
import { useShowWithDelay } from "utils/hooks/useShowWithDelay";
import { getConvertedTimeZoneDate } from "utils/index";

import { UndoSnackbar } from "../../../UndoSnackbar/UndoSnackbar";
import { ScheduledModal } from "./components/ScheduledModal/ScheduledModal";
import styles from "./Scheduled.module.scss";

export const Scheduled = ({ isShow = true, influencerId, rewardOrderId }) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const [isOpenModal, setOpenModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [time, setTime] = useState({ value: "", serverStartDate: null });
  const [calls, setCalls] = useState([]);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [confirmLoader, setConfirmLoader] = useState(false);
  const [isUndoSnackBar, setUndoSnackBar] = useState(false);

  const getCurrentCall = ({ calls }) => {
    if (calls.length) {
      return calls.reduce((acc, current) => {
        acc =
          new Date(acc.date).getTime() < new Date(current.date).getTime()
            ? acc
            : current;
        return acc;
      }, calls[0]);
    }
  };

  const currentCall = getCurrentCall({ calls });
  const startTimeCall = currentCall?.date;

  const isShowJoinButton = useShowWithDelay({
    checkDelay: 1500,
    targetDate: startTimeCall,
    minutes: new Date().getTime() < new Date(startTimeCall) ? 1 : 15,
  });

  const isAbleToEdit = startTimeCall
    ? new Date(startTimeCall) - new Date() > 24 * 60 * 60 * 1000
    : false; // 24 hours

  const join = () => {
    if (currentCall?.extMeetingId) {
      navigate(
        PATHS.MEETING_ID.replace(":meetingId", currentCall.extMeetingId)
      );
    }
  };

  const confirm = () => {
    if (isAbleToEdit) {
      setConfirmOpen(true);
    }
  };

  const cancelConfirm = () => {
    setConfirmOpen(false);
  };

  const submit = (date) => {
    return postScheduledCalls({
      orderId: rewardOrderId,
      influencerId,
      date,
    }).then((res) => {
      if (res?.call) {
        setCalls((prevState) => [...prevState, res.call]);
        setUndoSnackBar(true);
      }
    });
  };

  const cancelCall = () => {
    if (currentCall?.id) {
      setConfirmLoader(true);
      patchScheduledCallsCancel({ callId: currentCall.id })
        .then(() => {
          setOpenModal(true);
          setTime(null);
          setStartDate(null);
          setCalls((prevState) =>
            prevState.filter((item) => item.id !== currentCall.id)
          );
        })
        .finally(() => {
          setConfirmOpen(false);
          setConfirmLoader(false);
        });
    }
  };

  useEffect(() => {
    if (rewardOrderId) {
      getScheduledCalls({ orderId: rewardOrderId }).then((res) => {
        if (res?.calls) {
          setCalls(res.calls);
        }
      });
    }
  }, []);

  if (!isShow) {
    return null;
  }

  return (
    <>
      <Box className={styles.scheduled}>
        {startTimeCall ? (
          <Button
            variant="contained"
            className={`${styles.scheduledButton} ${
              styles.scheduledButtonState2
            } ${isAbleToEdit ? styles.scheduledButtonState2Edit : ""}`}
            onClick={confirm}
            style={{ cursor: isAbleToEdit ? "pointer" : "initial" }}
          >
            {t("messages.scheduledState2")}
            <Typography variant="body1">
              {getConvertedTimeZoneDate(startTimeCall, "MMM dd, yyyy HH:mm")}
            </Typography>
            {isAbleToEdit ? <CalendarEditIcon /> : null}
          </Button>
        ) : (
          <Button
            variant="contained"
            className={styles.scheduledButton}
            onClick={() => setOpenModal(true)}
          >
            {t("messages.scheduledState1")}
          </Button>
        )}
        {isShowJoinButton ? (
          <Button
            variant="contained"
            className={styles.scheduledButton}
            onClick={join}
          >
            {t("messages.scheduledState3")}
          </Button>
        ) : null}
      </Box>
      <ScheduledModal
        isShow={isOpenModal}
        setShow={setOpenModal}
        influencerId={influencerId}
        setTime={setTime}
        time={time}
        startDate={startDate}
        setStartDate={setStartDate}
        submit={submit}
      />
      <CustomErrorModal
        isOpen={isConfirmOpen}
        setOpen={setConfirmOpen}
        isAlertIcon={true}
        title={t("messages.rescheduleModalTitle")}
        subtitle={t("messages.rescheduleModalSubTitle")}
      >
        <Button
          className="custom-error-modal-cancel"
          variant="outlined"
          onClick={cancelConfirm}
        >
          {t("default.cancel")}
        </Button>
        <LoadingButton
          className="custom-error-modal-submit"
          variant="contained"
          onClick={cancelCall}
          loading={confirmLoader}
        >
          {t("kyc.okay")}
        </LoadingButton>
      </CustomErrorModal>
      <UndoSnackbar
        isOpen={isUndoSnackBar && currentCall}
        onClose={() => setUndoSnackBar(false)}
        undoHandler={cancelCall}
        message={
          <>
            {t("messages.privateCallUndoTitle")}
            <Box>
              {startTimeCall
                ? getConvertedTimeZoneDate(startTimeCall, "MMM dd")
                : null}
            </Box>
          </>
        }
      />
    </>
  );
};
