import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Input,
  Typography,
} from "@mui/material";
import { ReactComponent as IconBack } from "assets/svg/arrow-left.svg";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError } from "redux/appSlice";
import { fetchUser, verifyCode, verifyEmail } from "redux/usersSlice";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";
import * as Yup from "yup";

import { PATHS } from "../../constants";
import styles from "./KYC.module.scss";

const Step2 = ({ handleNext, handleBack, isSingle = false }) => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const [activeStep, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.users.entities);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.emailVerified) {
      handleNext && handleNext();
    }
  }, [user]);

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  const validationSchemaCode = Yup.object().shape({
    verificationCode: Yup.string().required(t("error.required")),
  });

  const {
    register: registerCode,
    handleSubmit: handleSubmitCode,
    formState: { errors: errorsCode, isValid: isCodeValid },
    setError: setCodeError,
  } = useForm({
    resolver: yupResolver(validationSchemaCode),
    mode: "onChange",
    defaultValues: {
      verificationCode: "",
    },
  });

  const handleFromSubmit = (data) => {
    setLoading(true);
    dispatch(verifyCode(data)).then((res) => {
      setLoading(false);
      if (res.payload.status !== 403) {
        dispatch(fetchUser()).finally(() => isSingle && navigate(PATHS.HOME));
      }
      if (res.payload.data.error) {
        setCodeError("verificationCode", {
          type: "custom",
          message: res.payload.data.message[0],
        });
      } else {
        handleNext && handleNext();
      }
    });
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    onVerify(setStep(1));
  };

  const onVerify = (next) => {
    setLoading(true);
    dispatch(verifyEmail({ email: user.email }))
      .unwrap()
      .then((res) => {
        const { status } = res;
        if (status === 202) {
          dispatch(
            setError({
              open: true,
              title: t("default.oops"),
              subtitle: t("kyc.resendCodeNotification"),
            })
          );
        }
        setLoading(false);
        next && next();
      })
      .catch((e) => {
        setLoading(false);
        dispatch(
          setError({
            open: true,
            title: t("error.error"),
            subtitle: errorResponseMessages(e, t),
          })
        );
      });
  };

  const resendCode = () => {
    onVerify();
  };

  const onBack = () => {
    if (activeStep === 0) {
      handleBack();
    } else {
      setStep(activeStep - 1);
    }
  };

  const renderSteps = () => {
    switch (activeStep) {
      case 0: {
        return (
          <>
            <Typography variant="h3" component="h1" className={styles.title}>
              {t("kyc.emailAddress")}
            </Typography>
            <Typography variant="textNormal" className={styles.description}>
              {t("kyc.emailAddressWillSent")}:
            </Typography>
            <Box className={styles.form}>
              <form onSubmit={handleEmailSubmit} autoComplete="off">
                <FormControl className={styles.formControlInput}>
                  <Typography variant="subtitle1">{user?.email}</Typography>
                </FormControl>
                <FormControl>
                  <LoadingButton
                    type="submit"
                    variant="outlined"
                    className={styles.btnContinue}
                    loading={loading}
                  >
                    {t("kyc.continueButton")}
                  </LoadingButton>
                </FormControl>
              </form>
            </Box>
          </>
        );
      }
      case 1: {
        return (
          <>
            <Typography variant="h3" component="h1" className={styles.title}>
              {t("kyc.verifyingYourEmailAddress")}
            </Typography>
            <Typography variant="textNormal" className={styles.description}>
              {t("auth.verifyPhoneNumberSplashDescription")} {user?.email}
            </Typography>
            <Box className={styles.form}>
              <form
                onSubmit={handleSubmitCode(handleFromSubmit)}
                autoComplete="off"
              >
                <FormControl className={styles.formControlInput}>
                  <label className={styles.label}>
                    {t("kyc.verificationCode")}
                  </label>
                  <Input
                    autoFocus
                    placeholder={"XXXX"}
                    {...registerCode("verificationCode")}
                    error={!!errorsCode.verificationCode}
                    autoComplete="off"
                    className={styles.input}
                  />
                  <Typography variant="error">
                    {errorsCode.verificationCode?.message}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={styles.sendCode}
                    mt={2}
                  >
                    {t("auth.didntReceiveCode")}&nbsp;&nbsp;
                    <Button
                      disabled={loading}
                      onClick={resendCode}
                      variant="text"
                      className={styles.sendCodeBtn}
                    >
                      {t("kyc.resend")}
                    </Button>
                  </Typography>
                </FormControl>

                <FormControl>
                  <LoadingButton
                    type="submit"
                    variant="outlined"
                    className={styles.btnContinue}
                    loading={loading}
                    disabled={!isCodeValid}
                  >
                    {t("kyc.continueButton")}
                  </LoadingButton>
                </FormControl>
              </form>
            </Box>
          </>
        );
      }
      default: {
        return null;
      }
    }
  };
  return (
    <>
      {!isMobile && (
        <IconButton className={styles.back} onClick={onBack}>
          <IconBack />
        </IconButton>
      )}
      <Box className={[styles.containerStep2, styles.containerCenter]}>
        {renderSteps()}
      </Box>
    </>
  );
};

export default Step2;
