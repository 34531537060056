import coin from "assets/img/telegram/Coin@2x.png";
import coin1 from "assets/img/telegram/Coin@2x-1.png";
import coin2 from "assets/img/telegram/Coin@2x-2.png";
import coin3 from "assets/img/telegram/Coin@2x-3.png";
import coin4 from "assets/img/telegram/Coin@2x-4.png";
import coin5 from "assets/img/telegram/Coin@2x-5.png";
import coin6 from "assets/img/telegram/Coin@2x-6.png";
import coin7 from "assets/img/telegram/Coin@2x-7.png";
import coin8 from "assets/img/telegram/Coin@2x-8.png";
import coin9 from "assets/img/telegram/Coin@2x-9.png";
import coin10 from "assets/img/telegram/Coin@2x-10.png";

export const images = [
  {
    src: coin,
    style: { top: "15%", left: "67%", width: "100px", height: "auto" },
  },
  {
    src: coin1,
    style: { top: "37%", left: "21%", width: "58px", height: "auto" },
  },
  {
    src: coin2,
    style: { top: 0, left: "5.3%", width: "193px", height: "auto" },
  },
  {
    src: coin3,
    style: { top: "17.4%", left: "2%", width: "82px", height: "auto" },
  },
  {
    src: coin4,
    style: { top: "34.2%", left: "78.5%", width: "75px", height: "auto" },
  },
  {
    src: coin5,
    style: { top: "38.3%", left: "31%", width: "124px", height: "auto" },
  },
  {
    src: coin6,
    style: { top: "51%", right: 0, width: "85px", height: "auto" },
  },
  {
    src: coin7,
    style: { top: "20%", left: "44%", width: "90px", height: "auto" },
  },
  {
    src: coin8,
    style: { top: "2.7%", left: "74%", width: "73px", height: "auto" },
  },
  {
    src: coin9,
    style: { top: "36.5%", left: "18%", width: "63px", height: "auto" },
  },
  {
    src: coin10,
    style: { top: "43%", left: 0, width: "76px", height: "auto" },
  },
];
