import {
  Box,
  Button,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as EditIcon } from "assets/svg/edit-icon.svg";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "redux/appSlice";
import { updateUserProfile } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";
import roundedInputStyles from "styles/input/rounded.module.scss";

import { goToDashboard } from "../../../../utils/auth";
import styles from "./Username.module.scss";

function isUsernameValid(username) {
  return /^[a-zA-Z0-9_]{1,20}$/.test(username);
}

const SUCCESS_MSG_LIFETIME = 7000;

const Username = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const theme = useTheme();

  const user = useSelector((state) => state.users.entities);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [usernameInputValue, setUsernameInputValue] = useState("");
  const usernameInputRef = useRef(null);
  const timer = useRef(null);
  const [usernameInputIsFocused, setUsernameInputIsFocused] = useState(false);
  const [canEditUsername, setCanEditUsername] = useState(false);
  const inputValueIsInvalid =
    !!usernameInputValue.length && !isUsernameValid(usernameInputValue);
  const [successfulEdits, setSuccessfulEdits] = useState(false);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);

      setUsernameInputValue("");
      setCanEditUsername(false);
      setShowAlert(false);
    };
  }, []);

  const onUsernameChangeSubmit = async () => {
    if (isUsernameValid(usernameInputValue)) {
      dispatch(setLoader(true));
      dispatch(updateUserProfile({ username: usernameInputValue }))
        .unwrap()
        .then(() => {
          setShowAlert(true);
          setAlertMessage(t("settings.editsSaved"));
          setUsernameInputValue("");
          setCanEditUsername(false);
          setSuccessfulEdits(true);

          timer.current = setTimeout(() => {
            setShowAlert(false);
            setSuccessfulEdits(false);
            setAlertMessage("");
          }, SUCCESS_MSG_LIFETIME);
        })
        .catch((err) => {
          setShowAlert(true);
          setAlertMessage(t("settings.usernameNotAvailable"));
          console.error("Cannot change username:", err);
        })
        .finally(() => {
          dispatch(setLoader(false));
        });
    } else {
      setShowAlert(true);
      setAlertMessage(t("settings.usernameRequirements"));
    }
  };

  const handleUsernameInputChange = (e) => {
    setUsernameInputValue(e.target.value);

    if (!!e.target.value.length && !isUsernameValid(e.target.value)) {
      setShowAlert(true);
      setAlertMessage(t("settings.usernameRequirements"));
    } else {
      setShowAlert(false);
      setAlertMessage("");
    }
  };

  const getEditIconStroke = () => {
    if (inputValueIsInvalid) {
      return theme.palette.coral.main;
    }

    if (usernameInputIsFocused) {
      return theme.palette.transparent;
    }

    return theme.palette.purple.main;
  };

  const onEditClick = () => {
    if (user?.appUser?.isInfluencer) {
      goToDashboard({ pathname: "/profile" });
    } else {
      setCanEditUsername(true);
      usernameInputRef?.current?.focus();
    }
  };

  const resetChange = () => {
    setShowAlert(false);
    setAlertMessage("");
    setUsernameInputValue("");
    setCanEditUsername(false);
    setSuccessfulEdits(false);
    clearTimeout(timer.current);
  };

  return (
    <>
      <Box className={styles.container}>
        <Box className={styles.usernameWrap}>
          <FormControl className={styles.inputWrap}>
            {!usernameInputIsFocused && !usernameInputValue.length ? (
              <InputLabel
                className={roundedInputStyles.label}
                id="username-input-label"
              >
                {user?.username || t("settings.username")}
              </InputLabel>
            ) : null}
            <TextField
              id="username-input"
              label={t("settings.username")}
              inputProps={{ ref: usernameInputRef }}
              sx={{ pointerEvents: canEditUsername ? "all" : "none" }}
              error={inputValueIsInvalid}
              className={`${roundedInputStyles.input} ${roundedInputStyles.filled}`}
              onChange={handleUsernameInputChange}
              onFocus={() => setUsernameInputIsFocused(true)}
              onBlur={() => setUsernameInputIsFocused(false)}
              value={usernameInputValue}
              type="text"
              autoComplete="off"
              name="username-input"
            />
            <EditIcon
              className={styles.editIcon}
              onClick={onEditClick}
              style={{ stroke: getEditIconStroke() }}
            />
          </FormControl>
          {showAlert && !!alertMessage.length ? (
            <Box className={styles.errorBlock}>
              <Typography
                variant="body1"
                component="span"
                sx={{
                  lineHeight: "20px",
                  color: successfulEdits
                    ? theme.palette.tertiary.main
                    : theme.palette.coral.main,
                }}
                className={styles.errorBlockText}
              >
                {alertMessage}
              </Typography>
            </Box>
          ) : //   usernameInputIsFocused ? (
          //   <Box className={styles.errorBlock}>
          //     <Typography
          //       variant="body1"
          //       component="span"
          //       sx={{
          //         lineHeight: "20px",
          //         color: theme.palette.purple.secondary,
          //       }}
          //       className={styles.errorBlockText}
          //     >
          //       {t("settings.usernameChangeLimit")}
          //     </Typography>
          //   </Box>
          // ) :
          null}
        </Box>
        {canEditUsername && (
          <FormControl className={styles.buttons}>
            <Button
              type="submit"
              variant="contained"
              disabled={inputValueIsInvalid || !usernameInputValue.length}
              onClick={onUsernameChangeSubmit}
            >
              {t("default.save")}
            </Button>
            <Button variant="outlined" onClick={resetChange}>
              {t("default.cancel")}
            </Button>
          </FormControl>
        )}
      </Box>
    </>
  );
};

export default Username;
