import { Box, Typography } from "@mui/material";
import { ReactComponent as IconTrophy } from "assets/svg/icon-trophy.svg";
import classNames from "classnames/bind";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./EmptyTrophyCard.module.scss";

export const EmptyTrophyCard = ({ className, onClick }) => {
  const { t } = useContext(LocalizationContext);

  const cardClass = classNames(styles.card, {
    [className]: className,
  });

  return (
    <Box className={cardClass} onClick={onClick}>
      <IconTrophy />
      <Typography className={styles.cardTitle} variant="textMediumBold">
        {t("account.chooseYourTrophyCard")}
      </Typography>
    </Box>
  );
};
