import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as Icon } from "assets/svg/icon-mail.svg";
import { PATHS } from "constants/index";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getGlobalCountUnreadMessages } from "redux/selectors/messengerSelectors";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1,
    height: "23px",
    padding: "0px 7px",
    minWidth: "23px",
    borderRadius: "23px",
    top: "-3px",
    left: "4px",
  },
}));
export const MessagesIcon = ({ className, text }) => {
  const messages = useSelector(getGlobalCountUnreadMessages);

  return (
    <Link to={PATHS.CHAT} className={className}>
      <StyledBadge
        badgeContent={messages}
        color="coral"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Icon />
        {text}
      </StyledBadge>
    </Link>
  );
};
