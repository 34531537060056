import { api } from "./api.service";
const API_URL = process.env.REACT_APP_API_HOST;

export const getChallenges = async (params) => {
  try {
    return api.get(`${API_URL}/engagement/challenges`, { params });
  } catch (error) {
    console.log("Cannot get challenges", error);
  }
};

export const getChallengeDetails = async ({ id, ...params }) => {
  try {
    return api.get(`${API_URL}/engagement/challenges/${id}/users`, { params });
  } catch (error) {
    console.log("Cannot get challenge details", error);
  }
};

export const getCompletedChallenges = async () => {
  try {
    return api.get(`${API_URL}/completed-challenges`);
  } catch (error) {
    console.log("Cannot get completed challenges", error);
  }
};

export const getChallengeUsers = async (params) => {
  try {
    const formattedParams = (() => {
      const paramsArray = Object.entries(params)
        .filter(([k, _v]) => k !== "id")
        .map(([k, v], i) => {
          return `${k}=${v}`;
        });

      const str = paramsArray.join("&");
      return str;
    })();

    return api.get(
      `${API_URL}/challenges/${params.id}/users?${formattedParams}`,
      params
    );
  } catch (error) {
    console.log("Cannot get participating users", error);
  }
};

export const markChallengeAsSeen = async (params) => {
  try {
    await api.post(
      `${API_URL}/app-users/me/challenge/${params.id}/seen`,
      params
    );
  } catch (error) {
    console.error("Cannot mark the challenge as seen", error);
  }
};
