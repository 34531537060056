import {
  Box,
  CircularProgress,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setError } from "redux/appSlice";
import { deletePaymentCard, getPaymentMethod } from "services/api/paymentsApi";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";

import styles from "./DonationModal.module.scss";
import { CloseIcon } from "./icons";

export const PaymentCardsList = ({
  activeCardId,
  setActiveCardId,
  paymentMethods,
  setPaymentMethods,
}) => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);

  const [loading, setLoading] = useState(true);
  const [loadingCard, setLoadingCard] = useState(null);

  const deleteCardHandler = (id) => () => {
    setLoadingCard(id);
    deletePaymentCard({ paymentMethodId: id })
      .then(() => {
        setPaymentMethods((prevState) =>
          prevState.filter((item) => item.id !== id)
        );
        if (activeCardId === id) {
          if (paymentMethods.length > 1) {
            setActiveCardId(paymentMethods.find((item) => item.id !== id)?.id);
          } else {
            setActiveCardId(null);
          }
        }
      })
      .catch((e) =>
        dispatch(
          setError({
            open: true,
            subtitle: errorResponseMessages(e.data || e, t),
          })
        )
      )
      .finally(() => setLoadingCard(null));
  };

  useEffect(() => {
    getPaymentMethod({ filterFor: "donation" })
      .then((data) => {
        if (data) {
          setPaymentMethods(data);
          setActiveCardId(data[0]?.id);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Box>
      {loading ? (
        <Skeleton variant="rectangular" className={styles.skeleton} />
      ) : null}
      {paymentMethods.length ? (
        <Box>
          {paymentMethods.map(({ name, id }) => (
            <Box className={styles.cardWrapper} key={id}>
              <Box
                className={`${styles.card} ${
                  id === activeCardId ? styles.cardActive : ""
                }`}
                onClick={() => setActiveCardId(id)}
              >
                <Typography variant="textNormal_14">
                  {t("payments.card")}
                </Typography>
                <Typography variant="textNormal_14">...{name}</Typography>
              </Box>
              <IconButton onClick={deleteCardHandler(id)}>
                {loadingCard === id ? (
                  <CircularProgress size={14} />
                ) : (
                  <CloseIcon />
                )}
              </IconButton>
            </Box>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};
