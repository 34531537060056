import { IconButton } from "@mui/material";
import React from "react";

import styles from "./Arrow.module.scss";

import { ReactComponent as SliderArrow } from "assets/svg/arrow-left.svg";

export const PrevArrow = (props) => {
  const { onClick, arrowClassName } = props;
  return (
    <IconButton
      className={`${styles.sliderBtn} ${arrowClassName ? arrowClassName : ""}`}
      onClick={onClick}
    >
      <SliderArrow className={styles.sliderBtnIcon} />
    </IconButton>
  );
};

export const NextArrow = (props) => {
  const { onClick, arrowClassName } = props;
  return (
    <IconButton
      className={`${styles.sliderBtn} ${styles.sliderNext} ${
        arrowClassName ? arrowClassName : ""
      }`}
      onClick={onClick}
    >
      <SliderArrow
        className={styles.sliderBtnIcon}
        style={{
          transformOrigin: "50% 50%",
          transform: "rotate(180deg)",
        }}
      />
    </IconButton>
  );
};
