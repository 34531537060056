import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import { ReactComponent as NoFollowingIcon } from "assets/svg/no-following-icon.svg";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
  clearInfluencerFollower,
  fetchInfluencerFollowers,
} from "redux/usersSlice";
import { getInfluencerMetadata } from "services/api/metatagsApi";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";

import { SCROLL_THRESHOLD } from "../../constants";
import { setLoader } from "../../redux/appSlice";
import { PageLayout } from "../PageLayout/PageLayout";
import styles from "./influencerFollowers.module.scss";

const InfluencerFollowers = () => {
  const isAuth = isAuthenticated();
  const { id } = useOutletContext() || {};
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const limit = 50;
  const [page, setPage] = useState(1);
  const [titleTag, setTitleTag] = useState(null);
  const influencerId = id || params.id;

  const { t } = useContext(LocalizationContext);
  const followers = useSelector((state) => state.users.followers.influencers);
  const profile = useSelector((state) => state.users.entities);

  const hasMore = followers.rows.length < followers.total;

  useEffect(() => {
    if (isAuth) {
      dispatch(setLoader(true));
      dispatch(
        fetchInfluencerFollowers({
          influencerId,
          page: page,
          limit: limit,
        })
      ).finally(() => dispatch(setLoader(false)));
    }
  }, [influencerId, page]);

  useEffect(() => {
    const getMetaData = async () => {
      try {
        const res = await getInfluencerMetadata(influencerId);
        setTitleTag(res?.title);
      } catch (e) {}
    };

    if (influencerId) getMetaData();
  }, [influencerId]);

  useEffect(() => {
    if (isAuth) {
      dispatch(clearInfluencerFollower());
      return () => {
        dispatch(clearInfluencerFollower());
      };
    }
  }, []);

  const fetchFollowersNext = () => {
    setPage((prevState) => prevState + 1);
  };

  const Placeholder = ({ text, buttonText, onBtnClick, icon }) => {
    return (
      <Box className={styles.placeholder}>
        {icon}
        <Typography
          variant="h5"
          component="h5"
          className={styles.placeholderText}
        >
          {text}
        </Typography>
        {buttonText && (
          <Button variant="contained" onClick={onBtnClick}>
            {buttonText}
          </Button>
        )}
      </Box>
    );
  };

  const handleRedirect = (username) => {
    if (profile.username === username) {
      navigate(`/profile`);
      return;
    }
    navigate(`/public/user/${username}`);
  };

  const renderFriendButtons = (username) => {
    return (
      <IconButton
        className={styles.addFriendBtn}
        onClick={() => handleRedirect(username)}
      >
        <Typography variant="body1" className={styles.friendTitle}>
          {t("tradehistory.viewProfile")}
        </Typography>
      </IconButton>
    );
  };

  return (
    <PageLayout
      title={t("account.followers")}
      isBack={true}
      titleTag={titleTag}
    >
      <Container
        className={`${styles.container} ${
          !followers || followers.length === 0 ? styles.empty : ""
        }`}
      >
        <Box>
          {followers && followers.total !== 0 ? (
            <InfiniteScroll
              dataLength={followers.rows.length}
              next={fetchFollowersNext}
              hasMore={hasMore}
              style={{ overflow: "hidden" }}
              endMessage=""
              scrollThreshold={SCROLL_THRESHOLD}
              className={styles.followersContainer}
            >
              {followers.rows.map((follower) => {
                return (
                  <Box className={styles.followerItem} key={follower.id}>
                    <Box className={styles.followerWrap}>
                      <Box className={styles.followerImageWrap}>
                        <img
                          className={styles.image}
                          src={follower.appUser.profileImageUrl}
                          alt={follower.appUser.username}
                          onClick={() =>
                            handleRedirect(follower.appUser.username)
                          }
                        />
                        {follower.appUser.trophyCardURL && (
                          <img
                            className={styles.followerTrophyCard}
                            src={follower.appUser.trophyCardURL}
                            alt={follower.appUser.username}
                          />
                        )}
                      </Box>
                      <Typography
                        variant="body1"
                        className={styles.followerItemName}
                        onClick={() =>
                          handleRedirect(follower.appUser.username)
                        }
                      >
                        {follower.appUser.username}
                      </Typography>
                    </Box>
                    {profile.appUserID === follower.appUser.id
                      ? null
                      : renderFriendButtons(follower.appUser.username)}
                  </Box>
                );
              })}
            </InfiniteScroll>
          ) : (
            <Placeholder
              text={t("discover.influencerNoFollowers")}
              onBtnClick={() => navigate("/discover")}
              icon={
                <NoFollowingIcon
                  className={styles.placeholderNoFollowingIcon}
                />
              }
            />
          )}
        </Box>
      </Container>
    </PageLayout>
  );
};

export default InfluencerFollowers;
