import { HeaderTooltip } from "components/HeaderTooltip/HeaderTooltip";
import { TUTORIALS_PROPS } from "constants";
// import { TUTORIALS_PROPS } from "constants/index.js";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import { PageLayout } from "../../PageLayout/PageLayout";
import { PhaseListBody } from "./components/PhaseListBody/PhaseListBody";
import styles from "./PhaseList.module.scss";

const PhaseList = () => {
  const { t } = useContext(LocalizationContext);
  return (
    <PageLayout
      title={
        <HeaderTooltip
          title={t("default.marketplaceTutorialTitle")}
          subtitle={t("default.marketplaceSubTutorialTitle")}
          header={t("drops.drops")}
          isHidden={true}
          seenTutorialsValue={TUTORIALS_PROPS.MARKETPLACE_TUTORIAL_1}
        />
      }
      subtitle={t("drops.marketplaceSubtitle")}
      isSearch={true}
      headerSubtitleClass={styles.subtitle}
      pageWrapperClassName={styles.page}
    >
      <PhaseListBody />
    </PageLayout>
  );
};
export default PhaseList;
