import React, { useEffect, useState } from "react";

import { CommentEditBlock } from "../CommentEditBlock/CommentEditBlock";
import { CommentTextBlock } from "../CommentTextBlock/CommentTextBlock";

export const CommentMainSection = ({
  isEditMode,
  message,
  setEditMode,
  postId,
  commentId,
  setActionLoading,
  isCommentEdited,
  setCommentEdited,
  moderationCommentData,
  tipAmount,
  isMessageLoading,
  replyToUser,
  replyToId,
}) => {
  const [messageValue, setMessageValue] = useState(message);

  useEffect(() => {
    setMessageValue(message);
  }, [message]);

  useEffect(() => {
    if (commentId === moderationCommentData?.commentId) {
      setMessageValue(moderationCommentData.cleanText);
    }
  }, [
    commentId,
    moderationCommentData?.cleanText,
    moderationCommentData?.commentId,
  ]);

  return (
    <>
      <CommentTextBlock
        message={messageValue}
        isEditMode={isEditMode}
        isCommentEdited={isCommentEdited}
        tipAmount={tipAmount}
        isMessageLoading={isMessageLoading}
        replyToUser={replyToUser}
        replyToId={replyToId}
      />
      <CommentEditBlock
        setEditMode={setEditMode}
        message={messageValue}
        setMessageValue={setMessageValue}
        postId={postId}
        setActionLoading={setActionLoading}
        commentId={commentId}
        isEditMode={isEditMode}
        oldMessageVersion={message}
        setCommentEdited={setCommentEdited}
      />
    </>
  );
};
