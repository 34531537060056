import { api } from "./api.service";
const API_URL = process.env.REACT_APP_API_HOST;

export const getInfluencerData = async ({ params, isAuth = false }) => {
  const url = isAuth
    ? `${API_URL}/discover/influencer/${params.id}`
    : `${API_URL}/public/discover/influencer/${params.id}`;

  const response = await api.get(url);

  return response.data;
};
export const getInfluencerIdByHandle = async (handle) => {
  const response = await api.get(
    `${API_URL}/public/discover/influencer-id/${handle}`
  );

  return response.data;
};

export const getInfluencerStats = async (params) => {
  const response = await api.get(
    `${API_URL}/discover/influencer/${params.id}/stats`,
    {
      params: params,
    }
  );

  return response.data;
};

export const getInfluencerActivity = async (params) => {
  const response = await api.get(
    `${API_URL}/public/discover/influencer/${params.id}/activity`,
    {
      params: params,
    }
  );

  return response.data;
};

export const getInfluencerSeason = async ({ id, params }) => {
  const response = await api.get(
    `${API_URL}/discover/influencer/${id}/season`,
    {
      params: params,
    }
  );

  return response.data;
};

export const getInfluencerLimitedEditionCards = async ({ id, ...params }) => {
  const response = await api.get(`${API_URL}/influencer/${id}/custom-cards`, {
    params,
  });

  return response.data;
};

export const getInfluencerCalendar = async ({ influencerId, ...params }) => {
  try {
    const result = await api.get(
      `${API_URL}/influencer/${influencerId}/calendar`,
      { params }
    );
    return result.data;
  } catch (error) {
    console.error("Get calendar error:", error);
  }
};

export const getScheduledCalls = async (params) => {
  try {
    const result = await api.get(`${API_URL}/scheduled-calls`, { params });
    return result.data;
  } catch (error) {
    console.error("Get calendar error:", error);
  }
};

export const postScheduledCalls = async (params) => {
  try {
    const result = await api.post(`${API_URL}/scheduled-calls`, params);
    return result.data;
  } catch (error) {
    console.error("Post calendar error:", error);
  }
};

export const patchScheduledCallsCancel = async ({ callId }) => {
  try {
    const result = await api.patch(
      `${API_URL}/scheduled-calls/${callId}/cancel`
    );
    return result.data;
  } catch (error) {
    console.error("Cancel calendar error:", error);
  }
};
