import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { TRANSFER_STATUSES } from "constants/";
import { Link } from "react-router-dom";
import { isNumber } from "lodash";

const BuyBtn = ({ card, transfers, onClick, children, className, ...rest }) => {
  const { t } = useContext(LocalizationContext);

  const token = isNumber(card.saleTokensLeft)
    ? card.saleTokensLeft
    : parseInt(card.saleTokensLeft.replace(/^\D+/g, ""));

  if (token > 0) {
    return (
      <LoadingButton
        className={className}
        onClick={onClick}
        variant="outlined"
        {...rest}
      >
        {children}
      </LoadingButton>
    );
  }

  if ([TRANSFER_STATUSES.pending].includes(transfers.status)) {
    return (
      <Button variant="outlined" disabled>
        {t("drops.transferPending")}
      </Button>
    );
  }

  if (
    [TRANSFER_STATUSES.succeeded, TRANSFER_STATUSES.processed].includes(
      transfers.status
    )
  ) {
    return (
      <Button variant="outlined" component={Link} to="/profile">
        {t("drops.seeInCollection")}
      </Button>
    );
  }
  return (
    <LoadingButton
      className={className}
      onClick={onClick}
      variant="outlined"
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};
export default BuyBtn;
