import { Box, Button, Typography } from "@mui/material";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as DotsEditIcon } from "assets/svg/dots-edit-icon.svg";
import classNames from "classnames/bind";
import { CustomTooltip } from "components/CustomTooltip/CustomTooltip";
// import { ShareModal } from "components/Modal/ShareModal/ShareModal";
import React, { useContext } from "react";
// import { useDispatch } from "react-redux";
// import { openModal } from "redux/modalSlice";
import { LocalizationContext } from "services/localizationContext";
import { usePublicUserConnect } from "utils/hooks/usePublicUserConnect";

import styles from "./MenuTooltip.module.scss";

export const MenuTooltip = ({
  openTooltip,
  setOpenTooltip,
  isPublic,
  isInfluencer,
  username,
  influencerHandle,
  actionBtnClassName,
}) => {
  // const isS = useMediaQuery("(max-width:960px)");
  // const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);

  const { isBlocked, handleUnblock, showBlockModal } = usePublicUserConnect();

  const blockUserHandler = () => {
    if (isBlocked) {
      handleUnblock();
    } else {
      showBlockModal();
    }
    setOpenTooltip(false);
  };

  // const profileShare = () => {
  //   const publicLink = isInfluencer
  //     ? `${window.origin}/${influencerHandle || username}`
  //     : `${window.origin}/public/user/${username}`;

  //   dispatch(
  //     openModal({
  //       children: <ShareModal url={publicLink} title={t("edition.share")} />,
  //       styles: {
  //         width: "100%",
  //         maxWidth: isS ? "280px" : "352px",
  //         padding: isS ? "16px 16px 16px 16px" : "44px 48px 48px 48px",
  //       },
  //     })
  //   );
  //   setOpenTooltip(false);
  // };

  const actionBtnClass = classNames(styles.actionBtn, {
    [actionBtnClassName]: actionBtnClassName,
  });

  return (
    <CustomTooltip
      open={openTooltip}
      setOpen={setOpenTooltip}
      isClick={true}
      placement="bottom-end"
      title={
        <Box className={styles.tooltip}>
          {/* ToDo: temporarily commented out, share module needs to be fixed*/}
          {/*<Typography*/}
          {/*  variant="body2"*/}
          {/*  className={styles.tooltipItem}*/}
          {/*  onClick={profileShare}*/}
          {/*>*/}
          {/*  {t("edition.share")}*/}
          {/*</Typography>*/}
          {isPublic ? (
            <Typography
              variant="body2"
              className={styles.tooltipItem}
              onClick={blockUserHandler}
            >
              {isBlocked ? t("account.unblock") : t("account.block")}
            </Typography>
          ) : null}
        </Box>
      }
    >
      <Button variant="contained" className={actionBtnClass}>
        <DotsEditIcon />
      </Button>
    </CustomTooltip>
  );
};
