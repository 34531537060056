import React, { useContext, useState, useLayoutEffect } from "react";
import { LocalizationContext } from "services/localizationContext";
import { Box, Typography, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlacklist, unblockUser } from "redux/usersSlice";
import BlockedAccountItem from "components/BlockedAccountItem";
import { setLoader } from "redux/appSlice";

import styles from "./BlockedAccounts.module.scss";

const BlockedAccounts = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.app.loader);

  const [blockedAccounts, setBlockedAccounts] = useState(null);

  useLayoutEffect(() => {
    let unmounted = false;
    dispatch(setLoader(true));
    dispatch(fetchBlacklist())
      .unwrap()
      .then((d) => !unmounted && setBlockedAccounts(d?.blacklist))
      .finally(() => dispatch(setLoader(false)));

    return () => {
      unmounted = true;
    };
  }, []);

  const handleUnblockUser = (userId) => {
    dispatch(setLoader(true));

    dispatch(unblockUser(userId))
      .then(() => {
        setBlockedAccounts(blockedAccounts?.filter((ba) => ba.id !== userId));
      })
      .finally(() => dispatch(setLoader(false)));
  };

  return (
    <Box className={styles.container}>
      {loading ? (
        Array.from(new Array(6)).map((_, i) => (
          <Skeleton key={i} variant="rectangular" className={styles.skeleton} />
        ))
      ) : !!blockedAccounts?.length ? (
        blockedAccounts.map((ba, i) => (
          <BlockedAccountItem
            key={`key${ba.id}-${i}`}
            user={ba}
            onUnblockClick={() => handleUnblockUser(ba.id)}
          />
        ))
      ) : (
        <Typography variant="h5">{t("settings.noOneIsBlocked")}</Typography>
      )}
    </Box>
  );
};

export default BlockedAccounts;
