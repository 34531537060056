import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";

import styles from "./CardMiddleBlock.module.scss";

export const CardMiddleBlock = ({ card }) => {
  const { t } = useContext(LocalizationContext);
  const isMobile = useIsMobile();

  const { numComments, numFollowers, numPosts } = card?.community || {
    numComments: 0,
    numFollowers: 0,
    numPosts: 0,
  };

  return (
    <Box className={styles.row}>
      <Box className={styles.col}>
        <Typography variant="h6" className={styles.title}>
          {t("discover.members")}
        </Typography>
        <Typography variant="body1Display" className={styles.value}>
          {numFollowers}
        </Typography>
      </Box>
      {!isMobile ? (
        <>
          <Box className={styles.col}>
            <Typography variant="h6" className={styles.title}>
              {t("discover.posts")}
            </Typography>
            <Typography variant="body1Display" className={styles.value}>
              {numPosts}
            </Typography>
          </Box>
          <Box className={styles.col}>
            <Typography variant="h6" className={styles.title}>
              {t("discover.commentsTitle")}
            </Typography>
            <Typography variant="body1Display" className={styles.value}>
              {numComments}
            </Typography>
          </Box>
        </>
      ) : null}
    </Box>
  );
};
