import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import mixpanel from "mixpanel-browser";

const Analytics = () => {
  const GTM_ID = process.env.REACT_APP_GTM_ID;
  const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

  useEffect(() => {
    TagManager.initialize({
      gtmId: GTM_ID,
    });

    mixpanel.init(MIXPANEL_TOKEN);
  }, [GTM_ID, MIXPANEL_TOKEN]);

  return <></>;
};

export default Analytics;
