import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as Icon } from "assets/svg/feed/explore-icon.svg";
import { PATHS } from "constants";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import styles from "./EmptyState.module.scss";
export const NoContent = ({
  title,
  subtitle,
  buttonTxt,
  containerClassName,
}) => {
  const { t } = useContext(LocalizationContext);
  return (
    <Box
      className={`${styles.container} ${
        containerClassName ? containerClassName : ""
      }`}
    >
      <Icon className={styles.icon} width={60} height={60} />
      <Typography variant="h5" className={styles.title}>
        {title || t("discover.noContentTitle")}
      </Typography>
      <Typography variant="body2" className={styles.subtitle}>
        {subtitle || t("discover.noContentDesc")}
      </Typography>
      <Button
        component={Link}
        className={styles.btn}
        variant={"contained"}
        to={PATHS.DISCOVER}
      >
        {buttonTxt || t("discover.discoverCommunities")}
      </Button>
    </Box>
  );
};
