import { api } from "./api.service";
const API_URL = process.env.REACT_APP_API_HOST;

export const getCountries = async () => {
  try {
    const result = await api.get(`${API_URL}/countries`);
    return result;
  } catch (e) {
    console.error("GET countries", e);
  }
};
