import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { togglePromptEvent } from "./redux/appSlice";

export const Prompt = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      const handleBeforeInstallPrompt = (event) => {
        event.preventDefault();
        window.deferredPrompt = event;
        dispatch(togglePromptEvent(true));
      };

      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

      return () => {
        window.removeEventListener(
          "beforeinstallprompt",
          handleBeforeInstallPrompt
        );
      };
    }
  }, []);

  return <></>;
};
