import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as CommentsOff } from "assets/svg/feed/no-comments.svg";
import { ReactComponent as CommentIco } from "assets/svg/icon-comment.svg";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import styles from "views/Influencer/components/FeedTab/feed.module.scss";

const CommentsCount = ({ count, allowComments, wrapperClass, isShortened }) => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      className={`${styles.postsBottomTitle} ${styles.postsBottomTitleCount} ${
        wrapperClass || ""
      }`}
    >
      {allowComments ? (
        <>
          <CommentIco />
          {!isMd ? (
            <Typography variant="body1" className={styles.postsBottomTitleText}>
              {isShortened
                ? count
                : t("discover.comments", { count, useSingular: true })}
            </Typography>
          ) : (
            <Typography variant="body1" className={styles.postsBottomTitleText}>
              {count}
            </Typography>
          )}
        </>
      ) : (
        <>
          <CommentsOff />
          {!isMd ? (
            <Typography variant="body1" className={styles.postsBottomTitleText}>
              {t("discover.commentsOff")}
            </Typography>
          ) : null}
        </>
      )}
    </Box>
  );
};
export default CommentsCount;
