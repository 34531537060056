import { Avatar, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import IconAvatar from "assets/svg/avatar-icon.svg";
import { ReactComponent as IconWallet } from "assets/svg/payments/wallet-icon.svg";
import { ReactComponent as IconPoints } from "assets/svg/points-icon.svg";
import classNames from "classnames/bind";
import React, { useContext } from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import { roundToTwo } from "utils/math";

import { Level } from "../../../../Level/Level";
import { ProfileNavPopover } from "./PrifleNavPopover";
import styles from "./ProfileNav.module.scss";

export const ProfileNav = ({ overrideStyles, ...rest }) => {
  const userWallet = useSelector((state) => state.users.wallet.entities);
  const user = useSelector((state) => state.users.me.entities);
  const theme = useTheme();
  const profile = useSelector((state) => state.users.entities);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "header-menu" : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const cx = classNames.bind(overrideStyles);
  const isLoggedIn = isAuthenticated();
  const avatar = (isLoggedIn && profile.userPicCroppedUrl) || IconAvatar;

  const { t } = useContext(LocalizationContext);
  const userName = isLoggedIn ? profile.username : t("account.noUsername");
  // useEffect(() => {
  //   if (_.isEmpty(userWallet)) dispatch(fetchUserWallet());
  // }, []);

  return (
    <>
      <Box
        className={cx(overrideStyles?.profileNav, styles.profileNav)}
        aria-describedby={id}
        onClick={handleClick}
        {...rest}
      >
        <Avatar
          src={avatar}
          className={`${styles.profileNavAvatar} ${
            !isLoggedIn ? styles.profileNavAvatarLogout : ""
          }`}
        />
        <Box
          className={`${styles.userName} ${
            !isLoggedIn ? overrideStyles?.userNameLogout : ""
          }`}
        >
          <Typography
            variant="h4"
            component="span"
            className={cx(overrideStyles?.userNameName, styles.userNameName)}
          >
            {userName}
            <Level level={user?.level} />
          </Typography>
          {isLoggedIn ? (
            <Box className={styles.profileNavInfoWrap}>
              {/*{userWallet.hasOwnProperty("cash") ? (*/}
              {/*  <Typography*/}
              {/*    variant="body2"*/}
              {/*    className={styles.profileNavInfo}*/}
              {/*    sx={{ color: theme.palette.accent.main }}*/}
              {/*  >*/}
              {/*    <IconWallet className={styles.profileNavIconWallet} />*/}
              {/*    <NumberFormat*/}
              {/*      value={roundToTwo(userWallet?.cash || 0)}*/}
              {/*      displayType={"text"}*/}
              {/*      thousandSeparator={true}*/}
              {/*      prefix={userWallet?.currencySymbol}*/}
              {/*    />*/}
              {/*  </Typography>*/}
              {/*) : null}*/}
              <Typography
                variant="body2"
                className={styles.profileNavInfo}
                sx={{ color: theme.palette.lime.main }}
              >
                <IconPoints className={styles.profileNavIcon} />
                {user?.rewardPoints}
              </Typography>
            </Box>
          ) : (
            <Box className={styles.profileNavInfoWrap}>
              <Typography variant="textMediumBold2">
                {t("account.loginPrompt")}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <ProfileNavPopover
        onClose={handleClose}
        id={id}
        open={open}
        anchorEl={anchorEl}
      />
    </>
  );
};
