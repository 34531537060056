import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ReactComponent as CheckedIcon } from "assets/svg/success-icon.svg";
import { SUCCESS_MSG_LIFETIME } from "constants/";
import CountriesJson from "constants/Countries.json";
import { STATE_KEYS } from "constants/states";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setError, setLoader } from "redux/appSlice";
import {
  fetchUser,
  updateUserProfile,
  updateZendeskUserInfo,
} from "redux/usersSlice";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";
import roundedInputStyles from "styles/input/rounded.module.scss";
import { transliterateToAlphaNum } from "utils/stringHelper";
import PlaceAutocomplete from "views/KYC/PlaceAutocomplete";
import * as Yup from "yup";

import styles from "./Address.module.scss";

const countries = CountriesJson.Countries;

const Address = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.users.entities);
  const loading = useSelector((state) => state.app.loader);
  const timer = useRef(null);

  const [successfulEdits, setSuccessfulEdits] = useState(false);

  const validationSchemaAddress = Yup.object().shape({
    addressLine1: Yup.string()
      .trim()
      .required(t("error.required"))
      .isAlphanumeric(t("error.isAlphanumeric")),
    city: Yup.string()
      .trim()
      .required(t("error.required"))
      .validateLatinLetter(t("error.onlyEnglishLetter")),
    county: Yup.string()
      .trim()
      .when(["country"], {
        is: (country) => {
          return country?.id === 232;
        },
        then: (schema) => {
          return Yup.string().oneOf(STATE_KEYS, t("error.stateError"));
        },
        otherwise: Yup.string().required(t("error.required")),
      })
      .isAlphanumeric(t("error.isAlphanumeric")),
    zipPostcode: Yup.string()
      .trim()
      .required(t("error.required"))
      .isAlphanumeric(t("error.isAlphanumeric")),
    country: Yup.object()
      .shape({
        id: Yup.number(),
        name: Yup.string(),
        code: Yup.string(),
      })
      .required(t("error.required"))
      .nullable(),
  });
  const userCountry = countries.find((c) => c.id === user?.countryId);

  const {
    register: registerAddress,
    handleSubmit: handleSubmitAddress,
    formState: { errors: errorsAddress },
    getValues: getValuesAddress,
    setValue: setAddressValue,
    setError: setAddressError,
    clearErrors,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(validationSchemaAddress),
    defaultValues: {
      addressLine1: user?.addressLine1 || "",
      addressLine2: user?.addressLine2 || "",
      city: user?.city,
      county: user?.county,
      country: userCountry,
      zipPostcode: user?.zipPostcode,
    },
  });

  const watchCity = watch("city");
  const watchRegion = watch("county");
  const watchCountry = watch("country");
  const watchZip = watch("zipPostcode");
  const watchAddressLine2 = watch("addressLine2");
  const onAddressChange = (addressDetails) => {
    clearErrors();
    if (!addressDetails) return;
    const { city, streetNumber, streetAddress, region, country } =
      addressDetails;
    setAddressValue("city", city);
    setAddressValue("county", region);
    setAddressValue(
      "country",
      countries.find((c) => c.name === country)
    );
    if (streetNumber || streetAddress) {
      setAddressValue(
        "addressLine1",
        `${streetNumber || ""} ${
          transliterateToAlphaNum(streetAddress || "") || ""
        }`
      );
    } else {
      setAddressValue("addressLine1", " ");
    }
  };

  const handleSubmit = (data) => {
    if (!_.isEmpty(errorsAddress)) {
      return;
    }
    dispatch(setLoader(true));
    let payload = {
      ...data,
      ...{ country: data.country?.name, countryId: data.country?.id },
    };
    dispatch(updateUserProfile(payload))
      .unwrap()
      .then(() => {
        setSuccessfulEdits(true);
        dispatch(fetchUser());
        dispatch(
          updateZendeskUserInfo({
            email: user.email,
            ...payload,
          })
        );
        dispatch(setLoader(false));
        timer.current = setTimeout(() => {
          setSuccessfulEdits(false);
        }, SUCCESS_MSG_LIFETIME);
      })
      .catch((e) => {
        dispatch(setLoader(false));
        dispatch(
          setError({
            open: true,
            title: t("error.error"),
            subtitle: errorResponseMessages(e, t),
          })
        );
      });
  };
  useEffect(() => {
    setAddressValue("addressLine1", user?.addressLine1 || "");
    setAddressValue("addressLine2", user?.addressLine2 || "");
    setAddressValue("city", user?.city);
    setAddressValue("county", user?.county);
    setAddressValue("country", userCountry);
    setAddressValue("zipPostcode", user?.zipPostcode);
  }, [
    user?.addressLine1,
    user?.addressLine2,
    user?.city,
    user?.countryId,
    user?.county,
    user?.zipPostcode,
  ]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <Box className={styles.container}>
      <form onSubmit={handleSubmitAddress(handleSubmit)} autoComplete="off">
        <Box className={styles.grid}>
          <FormControl className={styles.inputWrap}>
            <Controller
              control={control}
              name="addressLine1"
              rules={{ required: t("error.required") }}
              render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { invalid, isTouched, isDirty, error },
              }) => (
                <PlaceAutocomplete
                  placeholder={t("kyc.streetAddressField")}
                  onChange={onChange}
                  onOptionPress={onAddressChange}
                  error={!!errorsAddress.addressLine1}
                  errorText={errorsAddress.addressLine1?.message}
                  defaultValue={getValuesAddress("addressLine1")}
                  label={t("kyc.streetAddressField")}
                  rounded
                />
              )}
            />
          </FormControl>
          <FormControl className={styles.inputWrap}>
            <TextField
              label={t("kyc.streetAddress2Field")}
              error={!!errorsAddress.addressLine2}
              className={`${roundedInputStyles.input} ${
                watchAddressLine2?.length ? roundedInputStyles.filled : ""
              }`}
              type="text"
              autoComplete="off"
              name="addressLine2-input"
              {...registerAddress("addressLine2", {
                onChange: (e) => {
                  setAddressValue(
                    "addressLine2",
                    transliterateToAlphaNum(e.target.value)
                  );
                },
              })}
            />
            {!!errorsAddress.addressLine2 ? (
              <Typography variant="error" sx={{ mt: 1 }}>
                {errorsAddress.addressLine2?.message}
              </Typography>
            ) : null}
          </FormControl>

          <FormControl className={styles.inputWrap}>
            <TextField
              label={t("kyc.cityField")}
              error={!!errorsAddress.city}
              className={`${roundedInputStyles.input} ${
                watchCity?.length ? roundedInputStyles.filled : ""
              }`}
              type="text"
              autoComplete="off"
              name="city-input"
              {...registerAddress("city")}
            />
            {!!errorsAddress.city ? (
              <Typography variant="error" sx={{ mt: 1 }}>
                {errorsAddress.city?.message}
              </Typography>
            ) : null}
          </FormControl>

          <FormControl className={styles.inputWrap}>
            <TextField
              label={t("kyc.stateRegionField")}
              error={!!errorsAddress.county}
              className={`${roundedInputStyles.input} ${
                watchRegion?.length ? roundedInputStyles.filled : ""
              }`}
              type="text"
              autoComplete="off"
              name="region-input"
              {...registerAddress("county")}
            />
            {!!errorsAddress.county ? (
              <Typography variant="error" sx={{ mt: 1 }}>
                {errorsAddress.county?.message}
              </Typography>
            ) : null}
          </FormControl>

          <FormControl className={styles.inputWrap} autoComplete="off">
            <TextField
              label={t("kyc.postalCodeField")}
              error={!!errorsAddress.zipPostcode}
              className={`${roundedInputStyles.input} ${
                watchZip?.length ? roundedInputStyles.filled : ""
              }`}
              type="text"
              autoComplete="off"
              name="zipPostcode-input"
              {...registerAddress("zipPostcode")}
            />
            {!!errorsAddress.zipPostcode ? (
              <Typography variant="error" sx={{ mt: 1 }}>
                {errorsAddress.zipPostcode?.message}
              </Typography>
            ) : null}
          </FormControl>
          <FormControl
            className={`${styles.inputWrap} ${
              user.isCompleted ? styles.inputDisable : ""
            }`}
          >
            <Controller
              control={control}
              name="country"
              rules={{ required: t("error.required") }}
              disabled={user.isCompleted}
              render={({
                field: { onChange, onBlur, value, ref },
                fieldState: { invalid, isTouched, isDirty, error },
              }) => (
                <Autocomplete
                  disabled={loading || user.isCompleted}
                  loading={loading}
                  value={watchCountry}
                  onChange={(e, val) => onChange(val)}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("kyc.countryField")}
                      error={!!errorsAddress.country}
                      className={`${roundedInputStyles.input} ${
                        watchCountry ? roundedInputStyles.filled : ""
                      }`}
                      type="text"
                      autoComplete="off"
                      disabled={user.isCompleted}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="accent" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  className={styles.inputWrap}
                  options={countries}
                  getOptionLabel={(option) => {
                    return option.name || "";
                  }}
                  componentsProps={{
                    paper: {
                      sx: {
                        backgroundColor: "#21264e",
                        border: "none",
                        padding: "15px",
                      },
                      variant: "outlined",
                    },
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      {...props}
                      className={styles.inputOption}
                    >
                      {option.name}
                    </Box>
                  )}
                />
              )}
            />
            {!!errorsAddress.country ? (
              <Typography variant="error" sx={{ mt: 1 }}>
                {errorsAddress.country?.message}
              </Typography>
            ) : null}
          </FormControl>
        </Box>

        <Box className={styles.row}>
          {successfulEdits ? (
            <Box className={styles.changesSavedMsg}>
              <CheckedIcon className={styles.changesSavedMsgIcon} />
              <Typography component="span" variant="h6">
                {t("settings.changesSaved")}
              </Typography>
            </Box>
          ) : (
            <LoadingButton
              type="submit"
              variant="contained"
              className={styles.btn}
              loading={loading}
            >
              {t("settings.saveChanges")}
            </LoadingButton>
          )}
        </Box>
        {/*<Typography variant="body3">*/}
        {/*  {t("settings.toChangePersonalInfoContact")}*/}
        {/*</Typography>*/}
      </form>
    </Box>
  );
};

export default Address;
