import { Box, Button, TextField, Typography } from "@mui/material";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";

import styles from "./FakePostForm.module.scss";
import { MediaIcon, YoutubeIcon } from "./icons";

export const FakePostForm = ({ setOpenModal }) => {
  const { t } = useContext(LocalizationContext);
  const isMobile = useIsMobile();

  const userPicCroppedUrl = useSelector(
    (state) => state.users.entities?.userPicCroppedUrl
  );
  const postingRights = useSelector(
    (state) => state.influencer.entities?.community?.postingRights
  );

  const openModal = () => setOpenModal(true);

  if (postingRights === "only_me") {
    return null;
  }

  return (
    <Box className={styles.wrapper} onClick={openModal}>
      <Box className={styles.topSection}>
        {userPicCroppedUrl ? (
          <img src={userPicCroppedUrl} alt="avatar" className={styles.avatar} />
        ) : null}
        <TextField placeholder="Create a post" className={styles.textField} />
      </Box>
      <Box className={styles.actionSection}>
        <Button variant="contain">
          <MediaIcon />
          <Typography variant="body1" component="span">
            {isMobile ? t("default.media") : t("default.addMedia")}
          </Typography>
        </Button>
        <Button variant="contain">
          <YoutubeIcon />
          <Typography variant="body1" component="span">
            {isMobile
              ? t("default.youtubeVideo")
              : t("default.addYoutubeVideo")}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
