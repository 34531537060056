import { Box, Typography } from "@mui/material";
import React from "react";
import { useIsMobile } from "utils/hooks";

import BackBtn from "../Layout/BackBtn/BackBtn";
import styles from "./Title.module.scss";

const Title = ({ backLink, title }) => {
  const isMobile = useIsMobile();
  return isMobile ? (
    <Box className={styles.titleWrap}>
      <BackBtn link={backLink} title={title} />
    </Box>
  ) : (
    <Typography variant="h3" className={styles.titleWrap}>
      {title}
    </Typography>
  );
};

export default Title;
