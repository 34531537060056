import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { ReactComponent as Visibility } from "assets/svg/visibility.svg";
import { ReactComponent as VisibilityOff } from "assets/svg/visibility-off.svg";
import AuthLeftSide from "components/AuthLeftSide";
import AuthTabPanel from "components/AuthTabPanel";
import { LanguageSelect } from "components/LanguageSelect/LanguageSelect";
import PrivacyPolicyModal from "components/Modal/PrivacyPolicyModal.js";
import { PATHS, zoopPolicyHost } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setLoader, setPixel } from "redux/appSlice";
import {
  fetchUser,
  followInfluencer,
  updateUserProfile,
} from "redux/usersSlice";
import Analytics from "services/Analytics.service";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import {
  isAuthenticated,
  setAccessToken,
  setAuthUsername,
  setIdToken,
  setInviteCode,
  setRefreshToken,
} from "services/auth.service";
import { postPreSignupCheck } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import UserPool from "services/userPool";
import styles from "styles/auth/Auth.module.scss";
import roundedInputStyles from "styles/input/rounded.module.scss";
import {
  useIsMobile,
  usePolicyHostUrl,
  useRedirectAfterAuth,
} from "utils/hooks";
import { nameValidation } from "utils/validators";
import * as Yup from "yup";

import { getInfluencerProfileLink } from "../../utils";
// import { googleAuth } from "../../utils/auth";
import { useTelegram } from "../../utils/hooks/useTelegram";

Yup.addMethod(Yup.string, "validateLatinLetter", nameValidation);

const SignUp = () => {
  const location = useLocation();
  const { email } = location?.state || {};
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { code } = useParams();
  const getDynamicUrl = usePolicyHostUrl();
  const { user: userTg } = useTelegram();

  useEffect(() => {
    setInviteCode(code);
  }, [code]);

  const loading = useSelector((state) => state.app.loader);

  const [data, setData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [serverError, setServerError] = useState(false);

  const { t } = useContext(LocalizationContext);
  const [openAgreements, setOpenAgreements] = useState(false);
  const influencerData = useSelector((state) => state.influencer.entities);
  const { id, handle, community } = influencerData || {};

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(t("error.required"))
      .validateLatinLetter(t("error.onlyEnglishLetter")),
    lastName: Yup.string()
      .trim()
      .required(t("error.required"))
      .validateLatinLetter(t("error.onlyEnglishLetter")),
    email: Yup.string()
      .required(t("error.required"))
      .email(t("error.invalidEmail")),
    password: Yup.string()
      .required(t("error.required"))
      .min(8, t("error.shortText"))
      .matches(/\d/, t("error.shouldContainNumbers"))
      .matches(/^(?=(.*[a-z]){1})/, t("error.shouldContainLowercaseLetter"))
      .matches(/^(?=(.*[A-Z]){1})/, t("error.shouldContainUppercaseLetter"))
      .matches(
        /^(?=(.*[!@#$%^&*()_+\-=\[\]{};~`':"\\|,.<>\/?]){1})/,
        t("error.shouldContainSpecialCharacter")
      ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      email,
    },
  });

  const redirect = useRedirectAfterAuth(userTg ? PATHS.PROFILE : PATHS.HOME);

  //ToDO: need refactor this func in future (SOLID)
  const redirectTo = () => {
    if (isAuthenticated() && (id || handle)) {
      if (!community?.subscriptionPrice) {
        dispatch(followInfluencer({ influencerId: id }))
          .then(() => {
            const influencerProfileLink = getInfluencerProfileLink({
              id,
              handle,
            });
            dispatch(setPixel(true));
            navigate(influencerProfileLink);
          })
          .finally(() => dispatch(setLoader(false)));
      }
    } else {
      dispatch(setLoader(false));
      dispatch(setPixel(true));
      redirect();
    }
  };

  const doSignUp = (data) => {
    const setAuthUser = (data) => {
      setAccessToken(data.getAccessToken().getJwtToken());
      setIdToken(data.getIdToken().getJwtToken());
      setRefreshToken(data.getRefreshToken().getToken());
      setAuthUsername(data.accessToken.payload.username);
      return dispatch(fetchUser());
    };

    const authDetails = new AuthenticationDetails({
      Username: data.email,
      Password: data.password,
    });

    UserPool().signUp(
      data.email,
      data.password,
      [
        {
          Name: "given_name",
          Value: data.firstName,
        },
        {
          Name: "family_name",
          Value: data.lastName,
        },
      ],
      [],
      (err) => {
        if (err) {
          dispatch(setLoader(false));
          setServerError(errorResponseMessages(err, t));
        } else {
          const cognitoUser = new CognitoUser({
            Username: data.email,
            Pool: UserPool(),
          });
          cognitoUser.authenticateUser(authDetails, {
            onSuccess: (data) => {
              setAuthUser(data).then(() => {
                Analytics.identify({ userId: data.email });
                Analytics.track("sign_up");
                if (code) {
                  dispatch(updateUserProfile({ referralCode: code }));
                }
                redirectTo();
              });
            },
            onFailure: (error) => {
              dispatch(setLoader(false));
              setServerError(errorResponseMessages(error, t));
            },
          });
        }
      }
    );
  };

  const onSubmit = (data) => {
    setServerError(null);
    dispatch(setLoader(true));

    postPreSignupCheck({ email: data.email })
      .then((res) => {
        if (!res?.isSupportedCountry || !res?.isSupportedEmailDomain) {
          dispatch(setLoader(false));
          return setServerError(t("auth.preSignCheckErrorCountry"));
        }

        doSignUp(data);
      })
      .catch((e) => {
        setServerError(errorResponseMessages(e, t));
        dispatch(setLoader(false));
      });
  };

  const watchEmail = watch("email") || "";

  return (
    <>
      <Box className={`${styles.container} ${styles.signContainer}`}>
        {!isMobile && <AuthLeftSide className={styles.authLeftSide} />}
        <Box className={`${styles.rightSide} ${styles.signRightSide}`}>
          {!isMobile ? <LanguageSelect className={styles.langSelect} /> : null}
          <Box
            className={`${styles.rightSideContent} ${styles.signRightSideContent}`}
          >
            <Box className={styles.signRightSideContentBox}>
              <Typography variant="h3" component="h1" className={styles.title}>
                {t("auth.signUpTitle")}
              </Typography>
              <Typography variant="subtitle1" className={styles.subtitle}>
                {t("auth.signupDescription")}
              </Typography>
              <AuthTabPanel />
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={`${styles.form}  ${styles.formSign}`}
                autoComplete="off"
              >
                <Box className={styles.nameWrap}>
                  <FormControl>
                    <TextField
                      label={t("default.firstName")}
                      error={!!errors.firstName}
                      className={`${roundedInputStyles.input} ${
                        roundedInputStyles.cyan
                      } ${
                        !!data.firstName.length ? roundedInputStyles.filled : ""
                      }`}
                      inputProps={{
                        onChange: (ev) =>
                          setData({ ...data, firstName: ev.target?.value }),
                      }}
                      type="text"
                      autoComplete="off"
                      {...register("firstName")}
                    />
                    {!!errors.firstName ? (
                      <Typography
                        variant="error"
                        className={roundedInputStyles.error}
                      >
                        {errors.firstName?.message}
                      </Typography>
                    ) : null}
                  </FormControl>
                  <FormControl>
                    <TextField
                      label={t("default.lastName")}
                      error={!!errors.lastName}
                      className={`${roundedInputStyles.input} ${
                        roundedInputStyles.cyan
                      } ${
                        !!data.lastName.length ? roundedInputStyles.filled : ""
                      }`}
                      inputProps={{
                        onChange: (ev) =>
                          setData({ ...data, lastName: ev.target?.value }),
                      }}
                      type="text"
                      autoComplete="off"
                      {...register("lastName")}
                    />
                    {!!errors.lastName ? (
                      <Typography
                        variant="error"
                        className={roundedInputStyles.error}
                      >
                        {errors.lastName?.message}
                      </Typography>
                    ) : null}
                  </FormControl>
                </Box>
                <FormControl>
                  <TextField
                    label={t("kyc.email")}
                    error={!!errors.email}
                    inputProps={{
                      onChange: (ev) =>
                        setData({ ...data, email: ev.target?.value }),
                    }}
                    className={`${roundedInputStyles.input} ${
                      roundedInputStyles.cyan
                    } ${watchEmail.length ? roundedInputStyles.filled : ""}`}
                    type="text"
                    autoComplete="off"
                    {...register("email")}
                  />
                  {!!errors.email ? (
                    <Typography
                      variant="error"
                      className={roundedInputStyles.error}
                    >
                      {errors.email?.message}
                    </Typography>
                  ) : null}
                  {serverError && (
                    <Typography
                      variant="error"
                      className={roundedInputStyles.error}
                    >
                      {serverError}
                    </Typography>
                  )}
                </FormControl>
                <FormControl>
                  <TextField
                    label={t("auth.password")}
                    error={!!errors.password}
                    className={`${roundedInputStyles.input} ${
                      roundedInputStyles.cyan
                    } ${
                      !!data.password.length ? roundedInputStyles.filled : null
                    }`}
                    inputProps={{
                      enterKeyHint: "go",
                      onChange: (ev) =>
                        setData({ ...data, password: ev.target?.value }),
                    }}
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    {...register("password")}
                  />
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    className={roundedInputStyles.showPasswordToggle}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  {!!errors.password ? (
                    <Typography
                      variant="error"
                      className={roundedInputStyles.error}
                    >
                      {errors.password?.message}
                    </Typography>
                  ) : null}
                </FormControl>
                <FormControl>
                  <Typography
                    variant="body2"
                    component="p"
                    className={styles.authTerms}
                  >
                    {t("auth.agreementText")}{" "}
                    <a
                      href={getDynamicUrl("terms-of-service")}
                      target="_blank"
                      className={styles.authTermsLink}
                      rel="noreferrer"
                    >
                      {t("auth.termsAndConditions")}
                    </a>
                    {", "}
                    <Box
                      component="span"
                      onClick={() => setOpenAgreements(true)}
                      className={styles.authTermsLink}
                    >
                      {t("auth.thirdPartyServiceAgreements")}
                    </Box>
                    {", "}
                    <a
                      href={getDynamicUrl("privacy-policy")}
                      target="_blank"
                      className={styles.authTermsLink}
                      rel="noreferrer"
                    >
                      {t("auth.tosCBLabelPrivacyPolicy")}
                    </a>{" "}
                    {t("auth.tosLabelAndOur")}{" "}
                    <a
                      href={`${zoopPolicyHost}/guidelines/community-guidelines`}
                      target="_blank"
                      className={styles.authTermsLink}
                      rel="noreferrer"
                    >
                      {t("auth.communityGuidelines")}
                    </a>
                    {"."}
                  </Typography>
                </FormControl>
                <Box className={styles.signSubmitContainer}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={loading}
                    className={styles.submitBtn}
                    disabled={!isValid}
                  >
                    {t("auth.signUp")}
                  </LoadingButton>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
      <PrivacyPolicyModal
        privacyPolicyLink={getDynamicUrl("privacy-policy")}
        open={openAgreements}
        onClose={() => setOpenAgreements(false)}
      />
    </>
  );
};

export default SignUp;
