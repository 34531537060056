import { api } from "./api.service";
const API_URL = process.env.REACT_APP_API_HOST;

export const getInfluencers = async (params) => {
  try {
    return api.get(`${API_URL}/discover/feed`, {
      params: { ...params },
    });
  } catch (error) {
    console.error(error);
  }
};

export const getTrendingEditions = async (params) => {
  try {
    return api.get(`${API_URL}/discover/trending-cards`, params);
  } catch (error) {
    console.error(error);
  }
};

export const getSeasons = async () => {
  try {
    return api.get(`${API_URL}/discover/home-seasons`);
  } catch (error) {
    console.error(error);
  }
};

export const getMarketplaceCards = async (params) => {
  try {
    return api
      .get(`${API_URL}/marketplace/feed`, { params })
      .then((res) => res.data);
  } catch (error) {
    console.error(error);
  }
};
