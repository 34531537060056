import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getMyConnections,
  getPublicUserFriendsTotal,
} from "redux/selectors/usersSelectors";
import { LocalizationContext } from "services/localizationContext";

import { PageLayout } from "../PageLayout/PageLayout";
import { ConnectionsRequestsWrapper } from "./ConnectionsRequests/ConnectionsRequestsWrapper";
import { FriendsList } from "./FriendsList/FriendsList";
import styles from "./NewConnections.module.scss";

export const NewConnections = () => {
  const { id } = useParams();
  const { t } = useContext(LocalizationContext);

  const connections = useSelector(getMyConnections);
  const publicConnectionsTotal = useSelector(getPublicUserFriendsTotal);

  return (
    <PageLayout
      title={
        <Box className={styles.header}>
          {t("account.connections")}
          <Typography className={`opacity_07 ${styles.headerSubTitle}`}>
            ({id ? publicConnectionsTotal : connections.total})
          </Typography>
        </Box>
      }
      isBack={true}
      isBottomMenuHide={true}
    >
      <Box className={styles.main} key={id}>
        {!id ? <ConnectionsRequestsWrapper /> : null}
        <FriendsList />
      </Box>
    </PageLayout>
  );
};
