export const FireIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5332 15.0508C11.5965 14.7972 11.63 14.5329 11.63 14.2613C11.63 12.7951 10.4531 11.6128 9.37511 10.5297C9.33928 10.4938 9.30356 10.4579 9.268 10.4221L7.92003 9.06731L6.57205 10.4221C6.53797 10.4564 6.50373 10.4907 6.4694 10.5252C5.39018 11.6085 4.21001 12.7931 4.21001 14.2613C4.21001 14.895 4.39244 15.489 4.71096 16C2.21993 15.1756 0.5 13.3385 0.5 10.7178V10.6453C0.5 8.89909 1.60311 7.50247 3.28251 6.59558C4.88936 5.72826 5.83912 4.13048 5.60168 2.47334L5.13752 0L6.85731 0.655436C9.95476 1.8344 12.5345 3.88067 14.2064 6.48098C14.9416 7.61054 15.3352 8.92821 15.3401 10.2759V10.7178C15.3401 12.0056 14.9245 13.1046 14.2064 13.9868L11.5332 15.0508Z"
      fill="#F1FC70"
    />
  </svg>
);
