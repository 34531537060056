import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";
import { ReactComponent as IconPoll } from "assets/svg/icon-poll-indicator.svg";
import { ReactComponent as IconPollSelected } from "assets/svg/icon-poll-indicator-selected.svg";
import { PATHS } from "constants/index";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError } from "redux/appSlice";
import { onPostPollVote } from "redux/feedSlice";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";

import TimeAgo from "../TimeAgo";
import styles from "./Poll.module.scss";

const Poll = ({
  post,
  locale,
  shouldShowTime = false,
  accessGranted = true,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = isAuthenticated();

  const handle = post?.influencer?.handle;
  const { poll, pollVote, id, createdAt } = post;
  const { options, totalVotes, status } = poll || {
    options: [],
    totalVotes: 0,
  };

  const [loading, setLoading] = useState(false);
  const [vote, setVote] = useState(pollVote);
  const [total, setTotal] = useState(totalVotes);
  const [list, setList] = useState(options);

  const isFinilized = status === "finalized";
  const shouldShowResults = isFinilized || vote;

  const calculatePercentage = (votes) => {
    return votes && total > 0 ? Math.round((votes / total) * 100) : 0;
  };

  const handleVote = (option) => {
    if (!isAuth) {
      navigate(PATHS.SIGN_UP);
      return;
    } else if (!accessGranted && handle) {
      handle && navigate(`/${handle}`);
      return;
    }
    setLoading(true);
    setVote({
      option,
    });
    setTotal((prevState) => prevState + 1);
    setList((prevState) =>
      prevState.map((item) =>
        item.option === option
          ? { ...item, votes: (item.votes || 0) + 1 }
          : item
      )
    );
    dispatch(
      onPostPollVote({
        id,
        params: {
          option,
        },
      })
    )
      .then((res) => {
        if (res?.error) {
          dispatch(
            setError({
              open: true,
              title: t("error.error"),
              subtitle: res.payload?.message,
            })
          );
        }
      })
      .finally(() => setLoading(false));
  };

  const Loader = () =>
    loading ? (
      <Box className={styles.loader}>
        <CircularProgress color="secondary" size={24} />
      </Box>
    ) : null;

  return (
    <Box
      className={`${styles.pollContainer} ${
        accessGranted ? "" : styles.pollBockAccess
      }`}
    >
      <Loader />
      {list.map((option, index) => (
        <Box
          key={index}
          className={`${styles.pollOption} ${
            shouldShowResults ? "" : styles.noVote
          }`}
        >
          {option.option === vote?.option ? (
            <IconPollSelected
              className={`${styles.iconPoll} ${styles.iconPollSelected}`}
              width={24}
              height={24}
            />
          ) : (
            <IconPoll
              className={`${styles.iconPoll} ${styles.iconPollNonSelected}`}
              width={24}
              height={24}
              onClick={() => !vote && !isFinilized && handleVote(option.option)}
            />
          )}

          <Box className={styles.optionVotes}>
            <Box className={styles.optionData}>
              <Typography variant="body2" className={styles.optionText}>
                {option.option}
              </Typography>
              {shouldShowResults ? (
                <Typography variant="body2" className={styles.percentageText}>
                  {`${calculatePercentage(option.votes)}%`}
                </Typography>
              ) : null}
            </Box>
            {shouldShowResults ? (
              <LinearProgress
                className={`${styles.progress} ${
                  option.votes > 0
                    ? option.option === vote?.option
                      ? styles.progressUserVoted
                      : styles.progressVoted
                    : styles.progressRegular
                }`}
                variant="determinate"
                value={calculatePercentage(option.votes)}
              />
            ) : null}
          </Box>
        </Box>
      ))}
      <Box className={styles.bottomInfo}>
        <Box className={styles.pollInfo}>
          <Typography valiant="body3" className={styles.pollInfoItem}>
            {total === 1
              ? t("default.pollVotesSingular", {
                  number: total,
                })
              : t("default.pollVotes", { number: total })}
          </Typography>
          {poll?.deadline && new Date(poll.deadline).getTime() < Date.now() ? (
            <>
              <span className={styles.durationSpacing}></span>
              <Typography valiant="body3" className={styles.pollInfoItem}>
                {t("default.pollIsClosed")}
              </Typography>
            </>
          ) : poll?.duration ? (
            <Typography valiant="body3" className={styles.pollInfoItem}>
              <span className={styles.durationSpacing}></span>
              {poll?.duration === 1
                ? t("default.pollVotingDurationSingular", {
                    duration: poll?.duration,
                  })
                : t("default.pollVotingDuration", {
                    duration: poll?.duration,
                  })}
            </Typography>
          ) : null}
        </Box>
        {shouldShowTime ? (
          <Typography variant="body3" className={styles.pollInfoItem}>
            <TimeAgo value={createdAt} locale={locale} />
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default Poll;
