import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  cancelStripeCheckoutSession,
  createStripeCheckoutSession,
  renewStripeCheckoutSession,
} from "services/api/paymentsApi";
import {
  getInfluencerActivity,
  getInfluencerData,
  getInfluencerStats,
} from "services/influencerApi";
const initialState = {
  loading: false,
  processingSubscription: false,
  entities: null,
  statsLoading: false,
  statistic: null,
  activity: [],
};
function isPendingSubscriptionAction(action) {
  return action.type.endsWith("subscription/pending");
}
function isProcessedSubscriptionAction(action) {
  return action.type.endsWith(
    "subscription/fulfilled" || "subscription/rejected"
  );
}
const influencerSlice = createSlice({
  name: "influencer",
  initialState,
  reducers: {
    changeIsFollowing: (state, action) => {
      state.entities.isFollowing = action.payload;
    },
    changePostNumbers: (state, action) => {
      const { entities } = state;
      const { numPosts } = action.payload;
      if (entities && entities.community && numPosts) {
        state.entities.community.numPosts = numPosts;
      }
    },
    resetInfluencerData: (state, action) => {
      state.entities = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfluencerData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchInfluencerData.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(fetchInfluencerData.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(fetchInfluencerStats.pending, (state, action) => {
        state.statsLoading = true;
      })
      .addCase(fetchInfluencerStats.fulfilled, (state, action) => {
        state.statsLoading = false;
        state.statistic = action.payload;
      })
      .addCase(fetchInfluencerStats.rejected, (state, action) => {
        state.statsLoading = false;
      })

      .addCase(fetchInfluencerActivity.fulfilled, (state, action) => {
        state.activity = action.payload;
      })
      .addMatcher(isPendingSubscriptionAction, (state) => {
        state.processingSubscription = true;
      })
      .addMatcher(isProcessedSubscriptionAction, (state) => {
        state.processingSubscription = false;
      });
  },
});
export const { changeIsFollowing, changePostNumbers, resetInfluencerData } =
  influencerSlice.actions;
export default influencerSlice.reducer;

export const fetchInfluencerData = createAsyncThunk(
  "influencer/profile",
  async (params) => {
    const response = await getInfluencerData(params);
    return response;
  }
);

export const fetchInfluencerStats = createAsyncThunk(
  "influencer/stats",
  async (params) => {
    const response = await getInfluencerStats(params);
    return response;
  }
);

export const fetchInfluencerActivity = createAsyncThunk(
  "influencer/activity",
  async (params) => {
    const response = await getInfluencerActivity(params);
    return response;
  }
);

export const cancelSubscription = createAsyncThunk(
  "influencer/unsubscribe/subscription",
  async (influencerId, { rejectWithValue }) => {
    try {
      return await cancelStripeCheckoutSession(influencerId);
    } catch (err) {
      return rejectWithValue(err?.data || err);
    }
  }
);
export const subscribe = createAsyncThunk(
  "influencer/subscribe/subscription",
  async (influencerId, { rejectWithValue }) => {
    try {
      return await createStripeCheckoutSession(influencerId);
    } catch (err) {
      return rejectWithValue(err?.data || err);
    }
  }
);
export const resubscribe = createAsyncThunk(
  "influencer/resubscribe/subscription",
  async (influencerId, { rejectWithValue }) => {
    try {
      return await renewStripeCheckoutSession(influencerId);
    } catch (err) {
      return rejectWithValue(err?.data || err);
    }
  }
);
