import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createSwapRequest,
  getSwapRequestById,
  approveSwapById,
  rejectSwapById,
  cancelSwapById,
  getSwaps,
} from "services/swapsApi";

export const fetchCreateSwapRequest = createAsyncThunk(
  "swaps/create",
  async (params) => {
    const response = await createSwapRequest(params);
    return response.data;
  }
);

export const fetchSwapById = createAsyncThunk(
  "swaps/getById",
  async (params) => {
    const response = await getSwapRequestById(params);
    return response.data;
  }
);

export const fetchSwapApprove = createAsyncThunk(
  "swaps/approve",
  async (params, { rejectWithValue }) => {
    try {
      const response = await approveSwapById(params);
      return response.data;
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const fetchSwapReject = createAsyncThunk(
  "swaps/reject",
  async (params) => {
    const response = await rejectSwapById(params);
    return response.data;
  }
);

export const fetchSwapCancel = createAsyncThunk(
  "swaps/cancel",
  async (params) => {
    const response = await cancelSwapById(params);
    return response.data;
  }
);
export const fetchSwaps = createAsyncThunk("swaps/get", async (params) => {
  const response = await getSwaps(params);
  return response.data;
});

const initialState = {
  loading: false,
  swapsList: {
    entities: [],
    loading: false,
    isLastPage: false,
  },
};

const swapSlice = createSlice({
  name: "swaps",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreateSwapRequest.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCreateSwapRequest.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchCreateSwapRequest.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchSwaps.pending, (state, action) => {
        state.swapsList.loading = true;
      })
      .addCase(fetchSwaps.rejected, (state, action) => {
        state.swapsList.loading = false;
        state.swapsList.isLastPage = true;
      })
      .addCase(fetchSwaps.fulfilled, (state, action) => {
        state.swapsList.loading = false;
        state.swapsList.entities = action.payload;

        const swaps = action.payload;

        state.swapsList.isLastPage = swaps?.length < action?.meta?.arg?.limit;
      });
  },
});

export default swapSlice.reducer;
