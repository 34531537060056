import { useTheme } from "@emotion/react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { ReactComponent as EditIcon } from "assets/svg/edit-icon.svg";
import { ReactComponent as RemoveIcon } from "assets/svg/icon-delete.svg";
import { ReactComponent as ReportIcon } from "assets/svg/report-icon.svg";
import { ReactComponent as TranslationIcon } from "assets/svg/translation-icon.svg";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "services/localizationContext";

import { ActionTooltip } from "../../../ActionTooltip/ActionTooltip";
import styles from "./ActionCommentTooltip.module.scss";

export const ActionCommentTooltip = ({
  reportHandler,
  deleteHandler,
  editHandler,
  isLoading,
  isMyComment,
  translationHandler,
  isTranslated,
  backToOriginal,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);

  const Loader = () =>
    isLoading ? (
      <Box className={styles.loader}>
        <CircularProgress color="inherit" size={16} />
      </Box>
    ) : null;

  const userCommentTooltip = (
    <Box
      className={styles.tooltipRow}
      onClick={() => {
        reportHandler();
        setOpen(false);
      }}
    >
      <ReportIcon />
      <Typography variant="body2">{t("discover.reportComment")}</Typography>
    </Box>
  );

  const ownCommentTooltip = (
    <>
      <Box
        className={`${styles.tooltipRow} ${styles.tooltipRowEdit}`}
        onClick={editHandler}
      >
        <EditIcon />
        <Typography variant="body2">{t("discover.editComment")}</Typography>
      </Box>
      <Box className={styles.tooltipRow} onClick={deleteHandler}>
        <RemoveIcon />
        <Typography
          variant="body2"
          style={{
            color: theme.palette.error.main,
          }}
        >
          {t("discover.deleteComment")}
        </Typography>
      </Box>
    </>
  );

  return (
    <ActionTooltip
      isLoading={isLoading}
      open={open}
      setOpen={setOpen}
      disabled={disabled}
    >
      <Box className={styles.tooltip}>
        <Loader />
        <Box
          className={styles.tooltipRow}
          onClick={!isTranslated ? translationHandler : backToOriginal}
        >
          <TranslationIcon />
          <Typography variant="body2">
            {!isTranslated
              ? t("discover.seeTranslation")
              : t("discover.seeOriginal")}
          </Typography>
        </Box>
        {isMyComment ? userCommentTooltip : ownCommentTooltip}
      </Box>
    </ActionTooltip>
  );
};
