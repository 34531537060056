import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, FormControl, TextField, Typography } from "@mui/material";
import Countdown from "components/Countdown";
import React, { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { LocalizationContext } from "services/localizationContext";
import roundedInputStyles from "styles/input/rounded.module.scss";
import * as Yup from "yup";

import styles from "./MFA.module.scss";

const VerificationCode = ({
  phone,
  onSubmit,
  onResend,
  resendCountdown,
  setResendCountdown,
  title,
  setCurrentCodeValue,
  noSubmit,
  autoFocus,
  isPhoneChanging,
  codeSentTo,
  phoneError,
}) => {
  const { t } = useContext(LocalizationContext);

  const loading = useSelector((state) => state.app.loader);
  const [currentCode, setCurrentCode] = useState("");
  const inputRef = useRef(null);

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(t("error.required")),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const resend = () => {
    onResend({ phone, isResend: true });
  };

  const onChange = () => {
    setCurrentCode(inputRef.current?.value);
    if (!!setCurrentCodeValue) {
      setCurrentCodeValue(inputRef.current?.value);
    }
  };

  const handleFormSubmit = async (e) => {
    if (noSubmit && !onSubmit) {
      e.preventDefault();
    } else {
      handleSubmit(onSubmit)(e).then(() => setValue("code", ""));
    }
  };

  return (
    <form
      onSubmit={handleFormSubmit}
      autoComplete="off"
      className={styles.form}
    >
      {title ? (
        <Typography variant="body1" component="h4" className={styles.VCTitle}>
          {title}
        </Typography>
      ) : null}
      <FormControl className={styles.inputWrap}>
        <TextField
          label={t("kyc.verificationCode")}
          error={!!errors.code}
          className={`${roundedInputStyles.input} ${roundedInputStyles.cyan} ${
            styles.codeInput
          } ${!!currentCode.length ? roundedInputStyles.filled : null}`}
          inputProps={{
            ref: inputRef,
            onWheel: () => inputRef.current?.blur(),
            onScroll: () => inputRef.current?.blur(),
            onChange: onChange,
            pattern: "[0-9]*",
          }}
          autoComplete="off"
          type="number"
          autoFocus={autoFocus}
          {...register("code")}
        />
        {!!errors.code ? (
          <Typography variant="error" sx={{ mt: 1 }}>
            {errors.code?.message}
          </Typography>
        ) : null}
      </FormControl>

      <FormControl
        className={`${styles.resendBlock} ${!!title ? styles.noBg : ""}`}
      >
        {!title ? (
          <Typography variant="body2" className={styles.sendCode}>
            {isPhoneChanging && codeSentTo
              ? t("settings.enterCodeWeSent", { phone: codeSentTo })
              : t("settings.enterAuthenticationCode")}
          </Typography>
        ) : null}
        {!resendCountdown > 0 ? (
          <Typography variant="body2">
            {t("auth.didntReceiveCode")}&nbsp;&nbsp;
            <Button
              disabled={loading}
              onClick={resend}
              variant="text"
              className={styles.sendCodeBtn}
            >
              {t("auth.resend")}
            </Button>
          </Typography>
        ) : (
          <Countdown value={resendCountdown} onSetValue={setResendCountdown}>
            <Typography variant="body2" component="span">
              {t("auth.codeResent")}
            </Typography>
          </Countdown>
        )}
      </FormControl>

      {!noSubmit ? (
        <FormControl className={styles.inputWrap}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading}
            className={styles.submitBtn}
            disabled={!currentCode.length || phoneError}
          >
            {t("settings.confirmCode")}
          </LoadingButton>
        </FormControl>
      ) : null}
    </form>
  );
};

export default VerificationCode;
