import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Container, FormControl, Input, Typography } from "@mui/material";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import { WITHDRAW_TYPE } from "constants/";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { nameValidation } from "utils/validators";
import styles from "views/Payments/AddCard/AddCard.module.scss";
import stylesMain from "views/Wallet/Funds.module.scss";
import * as Yup from "yup";

import { PageLayout } from "../../PageLayout/PageLayout";
Yup.addMethod(Yup.string, "validateLatinLetter", nameValidation);

const AddBankAccount = () => {
  const { t } = useContext(LocalizationContext);
  const location = useLocation();
  const { bankType, amount } = location?.state;
  const navigate = useNavigate();
  let validationSchema = null;

  useEffect(() => {
    if (
      ![
        WITHDRAW_TYPE.US,
        WITHDRAW_TYPE.IBAN_NOT_SUPPORTED,
        WITHDRAW_TYPE.IBAN_SUPPORTED,
      ].includes(bankType)
    ) {
      console.error("Incorrect bank type passed to the location state");
      navigate("/", { replace: true });
    }
  }, [bankType]);

  const cardHolderNameValidation = Yup.string()
    .trim()
    .test("cardName", t("payments.cardNameError"), (value) => {
      if (value && value.trim().split(" ").length === 2) {
        return true;
      }
      return false;
    })
    .required(t("error.required"))
    .validateLatinLetter(t("error.onlyEnglishLetter"));

  if (bankType === WITHDRAW_TYPE.US) {
    validationSchema = Yup.object().shape({
      bankName: Yup.string().required(t("error.required")),
      cardHolderName: cardHolderNameValidation,
      accountNumber: Yup.string().required(t("error.required")),
      email: Yup.string().notRequired(),
      routingNumber: Yup.string().required(t("error.required")),
    });
  } else if (bankType === WITHDRAW_TYPE.IBAN_NOT_SUPPORTED) {
    validationSchema = Yup.object().shape({
      bankName: Yup.string().required(t("error.required")),
      cardHolderName: cardHolderNameValidation,
      accountNumber: Yup.string().required(t("error.required")),
      email: Yup.string().notRequired(),
      routingNumber: Yup.string().notRequired(),
    });
  } else if (bankType === WITHDRAW_TYPE.IBAN_SUPPORTED) {
    validationSchema = Yup.object().shape({
      cardHolderName: cardHolderNameValidation,
      bankName: Yup.string().required(t("error.required")),
      iban: Yup.string().required(t("error.required")),
    });
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const handleFormSubmit = (data) => {
    navigate("/add-bank-account/bank-address", {
      state: {
        type: "WIRE",
        subtype: bankType,
        ...data,
        amount,
      },
    });
  };

  const renderFormByType = (bankType) => {
    switch (bankType) {
      case WITHDRAW_TYPE.US: {
        return (
          <Box className={`${styles.formContainer}`}>
            <Box className={styles.formWrapper}>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>{t("payments.bankName")}</label>
                <Input
                  placeholder={t("payments.bankName")}
                  {...register("bankName")}
                  error={errors.bankName ? true : false}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error" className={styles.inputError}>
                  {errors.bankName?.message}
                </Typography>
              </FormControl>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("payments.routingNumber")}
                </label>
                <Input
                  {...register("routingNumber")}
                  error={errors.routingNumber ? true : false}
                  placeholder={t("payments.routingNumber")}
                  autoComplete="off"
                  className={styles.input}
                />

                <Typography variant="error" className={styles.inputError}>
                  {errors.routingNumber?.message}
                </Typography>
              </FormControl>
            </Box>
            <Box className={styles.formWrapper}>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("payments.holderName")}{" "}
                </label>
                <Input
                  {...register("cardHolderName")}
                  error={errors.cardHolderName ? true : false}
                  placeholder={t("payments.holderName")}
                  className={styles.input}
                />
                <Typography variant="error" className={styles.inputError}>
                  {errors.cardHolderName?.message}
                </Typography>
              </FormControl>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("payments.bankNumber")}
                </label>
                <Input
                  {...register("accountNumber")}
                  error={errors.accountNumber ? true : false}
                  placeholder={t("payments.bankNumber")}
                  className={styles.input}
                />
                <Typography variant="error" className={styles.inputError}>
                  {errors.accountNumber?.message}
                </Typography>
              </FormControl>
            </Box>
          </Box>
        );
      }
      case WITHDRAW_TYPE.IBAN_NOT_SUPPORTED: {
        return (
          <Box className={`${styles.formContainer}`}>
            <Box className={styles.formWrapper}>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>{t("payments.bankName")}</label>
                <Input
                  placeholder={t("payments.bankName")}
                  {...register("bankName")}
                  error={errors.bankName ? true : false}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error" className={styles.inputError}>
                  {errors.bankName?.message}
                </Typography>
              </FormControl>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("payments.routingNumber")}
                </label>
                <Input
                  {...register("routingNumber")}
                  error={errors.routingNumber ? true : false}
                  placeholder={t("payments.routingNumber")}
                  className={styles.input}
                />

                <Typography variant="error" className={styles.inputError}>
                  {errors.routingNumber?.message}
                </Typography>
              </FormControl>
            </Box>
            <Box className={styles.formWrapper}>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("payments.holderName")}
                </label>

                <Input
                  {...register("cardHolderName")}
                  error={errors.cardHolderName ? true : false}
                  placeholder={t("payments.holderName")}
                  className={styles.input}
                />
                <Typography variant="error" className={styles.inputError}>
                  {errors.cardHolderName?.message}
                </Typography>
              </FormControl>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("payments.bankNumber")}
                </label>
                <Input
                  {...register("accountNumber")}
                  error={errors.accountNumber ? true : false}
                  placeholder={t("payments.bankNumber")}
                  className={styles.input}
                />
                <Typography variant="error" className={styles.inputError}>
                  {errors.accountNumber?.message}
                </Typography>
              </FormControl>
            </Box>
          </Box>
        );
      }
      case WITHDRAW_TYPE.IBAN_SUPPORTED: {
        return (
          <Box className={styles.ibanNotSupportedWrapper}>
            <FormControl className={styles.formControlInput}>
              <label className={styles.label}>{t("payments.bankName")}</label>
              <Input
                placeholder={t("payments.bankName")}
                {...register("bankName")}
                error={errors.bankName ? true : false}
                autoComplete="off"
                className={styles.input}
              />
              <Typography variant="error" className={styles.inputError}>
                {errors.bankName?.message}
              </Typography>
            </FormControl>
            <FormControl className={styles.formControlInput}>
              <label className={styles.label}>{t("payments.iban")}</label>
              <Input
                placeholder={t("payments.iban")}
                {...register("iban")}
                error={errors.iban ? true : false}
                autoComplete="off"
                className={styles.input}
              />
              <Typography variant="error" className={styles.inputError}>
                {errors.iban?.message}
              </Typography>
            </FormControl>
            <FormControl className={styles.formControlInput}>
              <label className={styles.label}>{t("payments.holderName")}</label>
              <Input
                error={errors.cardHolderName ? true : false}
                {...register("cardHolderName")}
                placeholder={t("payments.holderName")}
                className={styles.input}
                autoComplete="off"
              />
              <Typography variant="error" className={styles.inputError}>
                {errors.cardHolderName?.message}
              </Typography>
            </FormControl>
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };
  const title = t("payments.bankDetailsTitle");
  return (
    <PageLayout title={title} isBack={true} backBtnProps={{ isPlain: true }}>
      <Container className={stylesMain.container}>
        <Box className={`${styles.detailsContainer}`}>
          <form
            onSubmit={handleSubmit(handleFormSubmit)}
            autoComplete="off"
            className={styles.paymentsForm}
          >
            {renderFormByType(bankType)}

            <FormControl>
              <LoadingButton
                type="submit"
                variant="outlined"
                className={styles.btnContinue}
              >
                {t("kyc.continueButton")}
              </LoadingButton>
            </FormControl>
          </form>
        </Box>
      </Container>
    </PageLayout>
  );
};
export default AddBankAccount;
