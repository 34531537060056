import { Box, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SCROLL_THRESHOLD } from "constants/index";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import PhaseListItem from "./components/PhaseListItem";
import styles from "./PhaseBody.module.scss";

export const PhaseBody = ({ cards, isLoading, next, isLastPage }) => {
  const theme = useTheme();

  return (
    <Box>
      <InfiniteScroll
        className={styles.viewPhaseScroll}
        dataLength={cards.length}
        next={next}
        hasMore={!isLastPage}
        scrollThreshold={SCROLL_THRESHOLD}
        loader={
          <CircularProgress
            className={styles.dropsLoader}
            sx={{
              color: theme.palette.surface.main,
              display: "block",
            }}
            size={20}
          />
        }
        endMessage=""
        scrollableTarget="containerEl"
      >
        <Box className={styles.wrapperPhaseList}>
          <PhaseListItem
            cards={cards}
            loading={!cards.length && isLoading}
            classNameGrid={styles.classNameGrid}
          />
        </Box>
      </InfiniteScroll>
    </Box>
  );
};
