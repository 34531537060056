import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  ANALYTICS_EVENTS,
  DROP_INITIAL_PRICE,
  EDITION_STATUSES,
  ORDER_TYPES,
  PATHS,
} from "constants/index";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setError, setLoader, setOnfidoToken } from "redux/appSlice";
import { fetchCard, resetCard } from "redux/cardSlice";
import { fetchDrop, isDropFinished, resetDrop } from "redux/dropsSlice";
import { fetchEditionMarketStatus } from "redux/editionSlice";
import { getDropCard } from "redux/selectors/dropsSelectors";
import Analytics from "services/Analytics.service";
import { getCardMetadata } from "services/api/metatagsApi";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import { CheckKycStatus } from "utils/hooks/kycCheck";
import DropDetail from "views/Drops/DropDetail/DropDetail";
import Edition from "views/Edition";

import { PageLayout } from "../PageLayout/PageLayout";
import styles from "./Card.module.scss";

const Card = () => {
  const theme = useTheme();
  const isAuth = isAuthenticated();
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useContext(LocalizationContext);
  const isFinished = useSelector((state) => isDropFinished(state));
  const currentDrop = useSelector(getDropCard);

  const isMobile = useMediaQuery(theme.breakpoints.down("lg_small"));

  const currentDropIsFinished = currentDrop?.id === id && isFinished;

  const [status, setStatus] = useState();
  const [loading, setLoading] = useState();
  const [checking, setChecking] = useState(false);
  const [titleTag, setTitleTag] = useState(null);

  const checkStatus = CheckKycStatus();

  const backRoute = location.state?.backRoute || PATHS.DROPS;

  //ToDo: need refactor (please use pure function)
  const handleBuyBtnClick = (token) => {
    const {
      currentSalePrice,
      id,
      influencer: { name },
      series,
    } = currentDrop || {};

    if (isAuthenticated()) {
      Analytics.track(ANALYTICS_EVENTS.ADD_TO_CART, {
        value: currentSalePrice,
        item: [
          {
            name: t("default.editionNumber", { number: series }),
            id,
            price: currentSalePrice,
            celebrity: name,
            initial_price: DROP_INITIAL_PRICE,
            quantity: 1,
          },
        ],
      });
      if (token?.id && token?.price && token?.tokenSerial) {
        navigate(`/discover/pass/${id}/buy`, {
          state: {
            order: {
              ...token,
              price: token?.price,
              tokenSerial: token?.tokenSerial,
            },
            edition: status,
          },
        });
      } else {
        navigate(PATHS.DROPS_ID_BUY.replace(":id", id));
      }
    } else {
      navigate(PATHS.SIGN_UP);
    }
  };

  const onSell = async (token) => {
    setChecking(true);
    const kycStatus = await checkStatus();
    setChecking(false);
    if (kycStatus?.sdkToken) {
      return dispatch(setOnfidoToken({ sdkToken: kycStatus.sdkToken }));
    }
    if (kycStatus !== 1) return;

    try {
      if (token?.id && token?.price && token?.tokenSerial) {
        navigate(`/discover/pass/${id}/sell`, {
          state: {
            order: {
              ...token,
              price: token.price,
              tokenSerial: token.tokenSerial,
            },
            edition: status,
          },
        });
      } else {
        navigate(`/discover/pass/${id}/sell`, id);
      }
    } catch (e) {
      dispatch(setError({ open: true }));
    }
  };

  useEffect(() => {
    const getMetaData = async () => {
      try {
        const res = await getCardMetadata(id);
        setTitleTag(res?.title);
      } catch (e) {}
    };
    if (id) getMetaData();
    dispatch(fetchDrop(id, isAuth));
    dispatch(fetchCard({ id }));
    return () => {
      dispatch(resetDrop());
      dispatch(resetCard());
    };
  }, [id]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      setLoader({
        title: t("default.loading"),
        subtitle: t("default.itMayTakeAFewSeconds"),
      })
    );
    dispatch(fetchEditionMarketStatus({ id, type: ORDER_TYPES.sell }))
      .unwrap()
      .then((res) => {
        setStatus(res);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        dispatch(setLoader(false));
        setLoading(false);
      });
  }, [id, currentDropIsFinished]);

  const alertHandler = () =>
    dispatch(
      setError({
        open: true,
        classNameContainer: styles.errorModalContainer,
        isNonMobileStyles: true,
        isAlertIcon: true,
        cardId: id,
        actions: "BuyCardActions",
        title: t("drops.buyCardAlertTitle"),
        subtitle: t("drops.buyCardAlertSubTitle", {
          creator: currentDrop.influencer?.name,
        }),
      })
    );

  if (loading) return null;

  return (
    <PageLayout isBack={isMobile} backRoute={backRoute} titleTag={titleTag}>
      {status?.card?.status === EDITION_STATUSES.complete ||
      currentDropIsFinished ? (
        <Edition
          handleBuyBtnClick={handleBuyBtnClick}
          onSell={onSell}
          checking={checking}
          status={status}
          setBuyAlert={alertHandler}
        />
      ) : status ? (
        <DropDetail
          status={status}
          handleBuyBtnClick={handleBuyBtnClick}
          onSell={onSell}
          checking={checking}
          setBuyAlert={alertHandler}
        />
      ) : null}
    </PageLayout>
  );
};
export default Card;
