import { Box, Button, Typography } from "@mui/material";
import React from "react";

import styles from "./EmailBanner.module.scss";

export const EmailBanner = ({
  condition,
  title,
  buttonText,
  onClick,
  loading,
}) => {
  if (!condition || loading) {
    return null;
  }
  return (
    <Box className={styles.kyc}>
      <Typography className={styles.kycTitle} variant="body1">
        {title}
        <Button size="small" onClick={onClick} className={styles.kycButton}>
          {buttonText}
        </Button>
      </Typography>
    </Box>
  );
};
