import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { FormControl, TextField, Box } from "@mui/material";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import Alert from "components/ErrorAlert";

import styles from "./ResetPassword.module.scss";
import roundedInputStyles from "styles/input/rounded.module.scss";

const Email = ({ email, onSubmit, mainServerError }) => {
  const { t } = useContext(LocalizationContext);

  const loading = useSelector((state) => state.app.loader);
  const { handleSubmit } = useForm();
  const refEr = React.createRef();

  const onPhoneSubmit = () => {
    onSubmit();
  };

  return (
    <Box className={styles.container}>
      <form onSubmit={handleSubmit(onPhoneSubmit)} className={styles.form}>
        <FormControl className={`${styles.phoneInputWrap} ${styles.inputWrap}`}>
          <TextField
            label={t("settings.email")}
            className={`${roundedInputStyles.input} ${roundedInputStyles.filled}`}
            type="text"
            value={email}
            disabled
          />
          {mainServerError ? (
            <Alert ref={refEr} sx={{ mt: 1 }}>
              {mainServerError}
            </Alert>
          ) : null}
        </FormControl>
        <FormControl className={styles.inputWrap}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={loading}
            className={styles.submitBtn}
          >
            {t("auth.sendCode")}
          </LoadingButton>
        </FormControl>
      </form>
    </Box>
  );
};

export default Email;
