import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import EditionCard from "components/Card/Edition/EditionCard";
import CollectionsEmptyPlacaholder from "components/CollectionsEmptyPlacaholder";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchPublicUser, searchUser } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";
import { getUsernameFromPathname } from "utils/usernameHelper";

import styles from "./public-collections.module.scss";

const PublicEditions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const { t } = useContext(LocalizationContext);
  const publicUser = useSelector((state) => state.users.publicUser.entities);
  const userMe = useSelector((state) => state.users.me.entities);
  const editions = publicUser?.cards;
  const loading = useSelector((state) => state.users.publicUser.loading);
  const username =
    params?.username || getUsernameFromPathname(location.pathname);

  useEffect(() => {
    if (params?.id) {
      dispatch(fetchPublicUser(params?.id));
      return;
    }
    if (!Object.keys(publicUser).length) {
      dispatch(searchUser(username))
        .unwrap()
        .then((user) => {
          dispatch(fetchPublicUser(user?.id));
        })
        .catch((err) => {
          console.log("Cannot get public user", err);
        });
    }
  }, [params?.id, publicUser, username]);

  const wrapClassName = editions?.length ? styles.cardsWrap : styles.wrapper;

  const swapEdition = (cardId) => {
    navigate("/swaps/create", {
      state: { cardId: cardId, userId: publicUser.id },
    });
  };

  const isMyProfile = publicUser?.id === userMe?.id;

  return (
    <Box
      className={[wrapClassName, editions?.length ? styles.editionsWrap : ""]}
    >
      {loading ? (
        <Stack spacing={3} direction="row">
          <Stack spacing={1}>
            <Skeleton width={153} height={215} variant="rectangular" />
            <Skeleton variant="rectangular" />
          </Stack>
          <Stack spacing={1}>
            <Skeleton width={153} height={215} variant="rectangular" />
            <Skeleton variant="rectangular" />
          </Stack>
          <Stack spacing={1}>
            <Skeleton width={153} height={215} variant="rectangular" />
            <Skeleton variant="rectangular" />
          </Stack>
        </Stack>
      ) : editions?.length ? (
        editions.map((card) => (
          <EditionCard
            key={`edition-${card.id}`}
            className={styles.card}
            wrapClassName={styles.editionCardWrap}
            {...card}
            editionsDropped={card.series}
            cardImage={card.imageUrl}
            onClick={() => navigate(`/pass/${card.id}`)}
            isSwappable={card?.isSwappable}
            swapEditionCb={() => swapEdition(card.id)}
            showOptsBtn
            isPublicUser
            barterDisabled={
              isMyProfile || !card?.isSwappable || !card?.availableForBarter
            }
            inBarter={false}
          />
        ))
      ) : (
        <CollectionsEmptyPlacaholder title={t("account.noCardsToDisplay")} />
      )}
    </Box>
  );
};
export default PublicEditions;
