import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoader } from "redux/appSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "./CheckoutForm.module.scss";

const StripeCheckoutForm = ({ returnUrl }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useContext(LocalizationContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  const handleSubmit = async (event) => {
    try {
      dispatch(
        setLoader({
          title: t("trading.submittingOrder"),
          subtitle: t("default.itMayTakeAFewSeconds"),
        })
      );
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
      });
      dispatch(setLoader(false));

      if (error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(null);
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } catch (e) {
      console.error(e);
      dispatch(setLoader(false));
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <PaymentElement />
      <Button
        variant="contained"
        type="submit"
        disabled={!stripe}
        className={styles.submitBtn}
      >
        {t("default.submit")}
      </Button>
      {errorMessage && (
        <Box className={styles.errors}>
          <Typography variant="body2">{errorMessage}</Typography>
        </Box>
      )}
    </form>
  );
};
export default StripeCheckoutForm;
