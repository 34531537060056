import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TutorialTooltip } from "components/TutorialTooltip/TutorialTooltip";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserSeenTutorials } from "redux/selectors/usersSelectors";
import { updateUserEntities, updateUserProfile } from "redux/usersSlice";

export const HeaderTooltip = ({
  title,
  subtitle,
  header,
  seenTutorialsValue,
  isHidden,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const seenTutorials = useSelector(getUserSeenTutorials);

  const closeTutorialTooltip = () => {
    const params = {
      seenTutorials: {
        ...(seenTutorials && seenTutorials),
        [seenTutorialsValue]: true,
      },
    };
    dispatch(updateUserEntities(params));
    dispatch(updateUserProfile(params));
  };

  return (
    <TutorialTooltip
      isOpen={!isHidden && !seenTutorials?.[seenTutorialsValue]}
      setOpen={closeTutorialTooltip}
      placement={isMd ? "bottom" : "right"}
      title={title}
      subTitle={subtitle}
    >
      {header}
    </TutorialTooltip>
  );
};
