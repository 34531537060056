import { Box, Button, IconButton } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ReactComponent as CopyIcon } from "assets/svg/copy-icon.svg";
import { ReactComponent as ShareIcon } from "assets/svg/share-icon.svg";
import { ReactComponent as ShareIconMobile } from "assets/svg/share-icon-white.svg";
import classnames from "classnames/bind";
import { forwardRef, useContext, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { setSnackbar } from "redux/appSlice";
import { LocalizationContext } from "services/localizationContext";

import { ANALYTICS_EVENTS } from "../../../constants";
import Analytics from "../../../services/Analytics.service";
import styles from "./ShareBtn.module.scss";

const cx = classnames.bind(styles);
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    placement="top-start"
    PopperProps={{
      disablePortal: true,
    }}
    disableFocusListener
    disableHoverListener
    disableTouchListener
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.dark.main,
    color: "white",
    border: `1px solid white`,
    maxWidth: "300px",
  },
  ".MuiTooltip-arrow": {
    color: "white",
  },
}));
const ShareBtn = forwardRef(
  (
    {
      variant,
      children,
      shareLink,
      btnClassName,
      iconClassName,
      className,
      ...rest
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const { t } = useContext(LocalizationContext);
    const dispatch = useDispatch();
    const classNames = cx(styles.styled, className);
    const handleTooltipClose = () => {
      setOpen(false);
    };

    const handleTooltipOpen = (e) => {
      Analytics.track(ANALYTICS_EVENTS.SHARE);
      e.stopPropagation();
      setOpen(true);
    };

    const onCopied = () => {
      handleTooltipClose();
      dispatch(
        setSnackbar({
          open: true,
          message: t("drops.copied"),
        })
      );
    };
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Box className={classNames} {...rest}>
          <HtmlTooltip
            onClose={handleTooltipClose}
            open={open}
            ref={ref}
            arrow
            onClick={(e) => e.stopPropagation()}
            title={
              <CopyToClipboard
                text={shareLink}
                onCopy={onCopied}
                onClick={(e) => e.stopPropagation()}
              >
                <Box
                  className={cx(
                    "App-flexCenter",
                    "App-flexCenter-between",
                    styles.tooltipContent
                  )}
                  minHeight="40px"
                >
                  <Box className={styles.shareLink}>{shareLink}</Box>
                  <IconButton className={styles.copyBtn}>
                    <CopyIcon />
                  </IconButton>
                </Box>
              </CopyToClipboard>
            }
          >
            {variant === "icon" ? (
              <IconButton
                className={`${btnClassName} ${styles.button}`}
                onClick={handleTooltipOpen}
              >
                <ShareIconMobile className={iconClassName} />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                endIcon={<ShareIcon />}
                className={`${btnClassName} ${styles.button}`}
                onClick={handleTooltipOpen}
              >
                {t("drops.shareBtn")}
              </Button>
            )}
          </HtmlTooltip>
        </Box>
      </ClickAwayListener>
    );
  }
);
export default ShareBtn;
