import { Box } from "@mui/material";
import { PATHS } from "constants/index";
import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./challenge-item.module.scss";
import { ChallengeCarousel } from "./components/ChallengeCarousel/ChallengeCarousel";

const ChallengeItem = ({ challenge }) => {
  const { imageUrl, code } = challenge;

  const navigate = useNavigate();

  const goToChallenge = (e) => {
    if (!e.target.closest(".nav-challenge-carousel")) {
      navigate(PATHS.CHALLENGES_ID.replace(":id", challenge.id));
    }
  };

  return (
    <Box className={styles.challenge} onClick={goToChallenge}>
      <ChallengeCarousel
        // stages={stages}
        stages={[challenge?.appUserTrack?.challengeStage]}
        challengeStage={challenge?.appUserTrack?.challengeStage}
        code={code}
        progress={challenge?.appUserTrack?.progress || 0}
        imageUrl={imageUrl}
        metadata={challenge?.metadata}
      />
    </Box>
  );
};

export default ChallengeItem;
