import { Box, Skeleton, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ChallengeItem from "components/ChallengeItem";
import { MultipleTutorialTooltip } from "components/MultipleTutorialTooltip/MultipleTutorialTooltip";
import { ViewAll } from "components/ViewAll/ViewAll";
import { PATHS, TUTORIALS_PROPS } from "constants/index";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchChallenges } from "redux/challengesSlice";
import {
  clubChallengesById,
  clubChallengesByIdLoader,
} from "redux/selectors/challengesSelector";
import { getUserSeenTutorials } from "redux/selectors/usersSelectors";
import { LocalizationContext } from "services/localizationContext";

import { ChallengeItemMobile } from "../../../../components/ChallengeItem/ChallengeItemMobile/ChallengeItemMobile";
import {
  updateUserEntities,
  updateUserProfile,
} from "../../../../redux/usersSlice";
import styles from "./ClubChallenges.module.scss";

export const ClubChallenges = ({ influencerId }) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width:840px)");

  const loader = useSelector(clubChallengesByIdLoader);
  const challenges = useSelector(clubChallengesById(influencerId));
  const seenTutorials = useSelector(getUserSeenTutorials);

  const tutorialUpdate = (params) => () => {
    dispatch(updateUserEntities(params));
    dispatch(updateUserProfile(params));
  };

  const tutorialSkip = () => {
    const params = {
      seenTutorials: {
        ...(seenTutorials && seenTutorials),
        [TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_1]: true,
        [TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_2]: true,
      },
    };
    dispatch(updateUserEntities(params));
    dispatch(updateUserProfile(params));
  };

  useEffect(() => {
    dispatch(fetchChallenges({ influencerId }));
  }, []);

  if (
    influencerId === null ||
    influencerId === undefined ||
    !challenges?.length
  ) {
    return null;
  }

  const trimArrayValue = isMobile ? 4 : 2;

  if (loader) {
    return <Skeleton variant="rectangular" className={styles.skeleton} />;
  }

  return (
    <Box className={styles.challenges}>
      <Box className={styles.challengesHeader}>
        <Typography className={`${styles.challengesHeaderTitle} opacity_07`}>
          {t("challenges.clubChallengesTitle")}
        </Typography>
        {challenges.length > trimArrayValue ? (
          <ViewAll
            path={PATHS.CLUB_CHALLENGES_ID.replace(":id", influencerId)}
            text={t("default.viewAllWithCount", { count: challenges.length })}
          />
        ) : null}
      </Box>
      <Box className={styles.challengesList}>
        {challenges?.slice(0, trimArrayValue).map((item, index) => (
          <React.Fragment key={item.id}>
            {isMobile ? (
              <ChallengeItemMobile challenge={item} />
            ) : index === 0 ? (
              <MultipleTutorialTooltip
                isOpen={
                  seenTutorials?.[
                    TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_1
                  ] &&
                  !seenTutorials?.[TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_2]
                }
                title={t("default.tutorialCreatorTitle2")}
                subTitle={t("default.tutorialCreatorSubTitle2")}
                skipButtonText={t("default.skip")}
                nextButtonText={t("default.gotIt")}
                prevButtonText={t("default.back")}
                isArrow={true}
                placement={"right"}
                next={tutorialUpdate({
                  seenTutorials: {
                    ...(seenTutorials && seenTutorials),
                    [TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_2]: true,
                  },
                })}
                prev={tutorialUpdate({
                  seenTutorials: {
                    ...(seenTutorials && seenTutorials),
                    [TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_1]: false,
                  },
                })}
                skip={tutorialSkip}
              >
                <ChallengeItem challenge={item} isClub={true} />
              </MultipleTutorialTooltip>
            ) : (
              <ChallengeItem challenge={item} isClub={true} />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};
