import {
  Box,
  Button,
  FormControl,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import { ReactComponent as UnlockedIcon } from "assets/svg/unlocked-icon.svg";
import classnames from "classnames/bind";
import { MIN_BUY_CARD, PAYMENT_TYPE, REWARD_STATUS } from "constants/index";
import React, { useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";
import { roundToTwo } from "utils/math";

import styles from "./buy-edition.module.scss";
import { orderSteps } from "./index";

const BuyCard = ({
  setOrderStep,
  paymentMethod,
  ordersCount,
  edition,
  order: currentOrder,
  setPlatformFee,
  setPriceValue,
  priceValue,
  platformFee,
}) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const numberInputRef = useRef(null);
  const minPrice = edition?.minOrder || MIN_BUY_CARD;
  const cx = classnames.bind(styles);
  const order = location?.state?.order || currentOrder;

  const handleChange = (event) => {
    setPriceValue(event.target.value);
  };

  const handleBlur = (event) => {
    if (event.target.value < minPrice) {
      setPriceValue(minPrice);
    }
  };

  const goToSelectPayment = () => {
    navigate("/payment/accounts", {
      state: {
        prevRoute: location.pathname,
        paymentType: PAYMENT_TYPE.MARKET,
        order,
        edition,
      },
    });
  };

  useEffect(() => {
    if (!edition) {
      return;
    }

    setPlatformFee(+priceValue * +edition.feePercentage);
  }, [priceValue]);

  useEffect(() => {
    if (!order || !edition) {
      return;
    }

    setPlatformFee(+order.price * +edition.feePercentage);
    setPriceValue(+order.price);
  }, [edition?.feePercentage, order]);

  const isPriceNumberValid = priceValue !== "" && !Number.isNaN(priceValue);
  const isPriceMinValid = isPriceNumberValid ? priceValue >= minPrice : true;
  const isPriceValid = isPriceNumberValid && isPriceMinValid;
  const isDisableReview =
    !isPriceValid ||
    !paymentMethod?.id ||
    (paymentMethod?.type === "card" && !order?.id);

  const platformFeeRounded = roundToTwo(+platformFee);
  const estimatedTotalAmount = (() => {
    if (isPriceNumberValid) {
      return roundToTwo(+priceValue + platformFeeRounded);
    }
  })();

  const tokenNumber = currentOrder?.tokenSerial?.split("-")[1];

  if (!edition) {
    return null;
  }

  const priceInputBlock = (
    <Box className={styles.buyCardPrice}>
      <Typography variant="h6" className={styles.buyCardLeftTitle}>
        {t("payments.enterOfferPrice")}
      </Typography>
      <FormControl variant="standard" className={styles.priceField}>
        <Input
          id="price-input"
          autoFocus
          value={priceValue}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={order}
          inputProps={{
            ref: numberInputRef,
            onWheel: () => numberInputRef.current?.blur(),
            step: 1,
            min: minPrice,
            type: "number",
          }}
          startAdornment={
            <InputAdornment position="start">
              <Typography
                variant="h1"
                component="p"
                className={styles.priceInputAdornment}
              >
                {edition.currencySymbol}
              </Typography>
            </InputAdornment>
          }
          className={styles.priceInput}
        />
        <Box className={styles.cardMinPrice}>
          <Typography
            variant="h6"
            className={cx(
              styles.cardMinPriceText,
              !isPriceMinValid && styles.cardMinPriceTextError
            )}
          >
            {t("error.minCardPayment", {
              currency: edition.currencySymbol,
              count: minPrice,
            })}
          </Typography>
        </Box>
      </FormControl>
    </Box>
  );

  return (
    <Box className={styles.buyCardContainer}>
      <Box className={styles.buyCardRight}>
        {!order ? priceInputBlock : null}
        {edition && (
          <>
            {isMobile ? (
              <img
                className={styles.editionInfoImage}
                src={edition.card.imageUrl}
                alt={edition.card.influencer.name}
              />
            ) : null}
            <Box
              className={`${styles.editionInfo} ${
                order?.rewardStatus === REWARD_STATUS.UNLOCKED
                  ? styles.editionInfoUnlocked
                  : ""
              }`}
            >
              <Box className={styles.row}>
                {!isMobile ? (
                  <img
                    className={styles.editionInfoImage}
                    src={edition.card.imageUrl}
                    alt={edition.card.influencer.name}
                  />
                ) : null}
                <Box className={styles.column}>
                  <Typography className={styles.editionInfoName} variant="h6">
                    {edition.card.influencer.name}
                  </Typography>
                  {tokenNumber ? (
                    <Typography
                      variant="textNormal_14"
                      className={styles.editionInfoSeries}
                    >
                      {t("default.editionNumber", { number: tokenNumber })}
                    </Typography>
                  ) : null}
                </Box>
              </Box>
              <Box
                className={`${styles.column} ${styles.editionInfoPriceBlock}`}
              >
                {order ? (
                  <Button
                    variant="text"
                    className={styles.infoBtn}
                    onClick={() =>
                      navigate(`/pass/${edition.card?.id}/pass-for-sale`, {
                        state: {
                          backRoute: location.pathname,
                          order,
                          paymentMethod,
                        },
                      })
                    }
                  >
                    {t("default.changeCard")}
                  </Button>
                ) : null}
                <Typography variant="h6" className={styles.editionInfoPrice}>
                  {`${t("default.price")} ${edition.currencySymbol}${
                    order?.price || edition.card.latestPrice
                  }`}
                </Typography>
              </Box>
            </Box>
            {order?.rewardStatus === REWARD_STATUS.UNLOCKED ? (
              <Typography className={styles.rewardStatus} variant="subtitle1">
                <UnlockedIcon />
                {t("default.recivedCardStatusTitle")}
              </Typography>
            ) : null}
          </>
        )}
        <Box
          className={`${styles.paymentBlock} ${
            paymentMethod?.type === "card" && !order?.id
              ? styles.paymentBlockError
              : ""
          }`}
          sx={{
            justifyContent: paymentMethod?.id ? "space-between" : "center",
          }}
        >
          {paymentMethod?.id && (
            <Typography variant="h6">
              {`${t("payments.payingWith")} ${paymentMethod?.name}`}
            </Typography>
          )}
          <Button
            variant="contained"
            className={styles.paymentBlockBtn}
            onClick={goToSelectPayment}
          >
            {paymentMethod?.id
              ? t("payments.change")
              : t("payments.selectPaymentMethod")}
          </Button>
        </Box>
        {paymentMethod?.type === "card" && !order?.id ? (
          <Typography variant="textNormal_14" className={styles.errorParagraph}>
            {t("discover.errorNotSupportedPayment3ds")}
          </Typography>
        ) : null}
        <Box className={styles.priceBlock}>
          <Box className={`${styles.row} ${styles.priceBlockRow}`}>
            <Typography variant="h6">{t("discover.platformFee")}</Typography>
            <Typography
              variant="h6"
              component="span"
              className={styles.priceBlockValue}
            >
              {edition.currencySymbol}
              {platformFeeRounded}
            </Typography>
          </Box>
          <Box className={`${styles.row} ${styles.priceBlockRow}`}>
            <Typography variant="h6">
              {t("trading.estimatedTotalAmount")}
            </Typography>
            <Typography
              variant="h6"
              component="span"
              className={styles.priceBlockValue}
            >
              {edition.currencySymbol}
              {estimatedTotalAmount}
            </Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          className={styles.reviewOrderBtn}
          disabled={isDisableReview}
          onClick={() => setOrderStep(orderSteps.reviewDetails)}
        >
          {t("trading.reviewOrder")}
        </Button>
      </Box>
    </Box>
  );
};

export default BuyCard;
