import { Box } from "@mui/material";
import Popover from "@mui/material/Popover";
import { ReactComponent as IconProfile } from "assets/svg/icon-profile.svg";
import ActiveLink from "components/ActiveLink";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./ProfileNav.module.scss";
import { ProfileNavMenu } from "./ProfileNavMenu";

export const ProfileNavPopover = ({ onClose, ...rest }) => {
  const { t } = useContext(LocalizationContext);
  return (
    <Popover
      {...rest}
      onClose={onClose}
      classes={{ paper: "MuiPopover-paper App-ProfileManuPaper" }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box className={styles.popoverMenu}>
        <ActiveLink
          to="/profile"
          className={styles.popoverMenuItem}
          onClick={onClose}
          activeClass={styles.menuItemActive}
        >
          <IconProfile />
          {t("account.profile")}
        </ActiveLink>
        <ProfileNavMenu handleClose={onClose} />
        {/*<ActiveLink*/}
        {/*  to="/watch-list"*/}
        {/*  onClick={handleClose}*/}
        {/*  className={styles.popoverMenuItem}*/}
        {/*  activeClass={styles.menuItemActive}*/}
        {/*>*/}
        {/*  <IconWatchlist /> {t("home.watchlist")}*/}
        {/*</ActiveLink>*/}
      </Box>
    </Popover>
  );
};
