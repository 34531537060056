import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Snackbar,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as CheckedIcon } from "assets/svg/checked-icon.svg";
import { FULL_COMMENT_TEXT_LIMIT } from "constants/index";
import React, { useContext, useRef, useState } from "react";
import { updateComment } from "services/api/feedApi";
import { LocalizationContext } from "services/localizationContext";
import useDelayedExecution from "utils/hooks/useDelayedExecution";

import styles from "./CommentEditBlock.module.scss";

const AUTO_HIDE_TIMEOUT = 2000;

export const CommentEditBlock = ({
  message,
  oldMessageVersion,
  setEditMode,
  setActionLoading,
  postId,
  commentId,
  setMessageValue,
  isEditMode,
  setCommentEdited,
}) => {
  const [isSnackBarEdit, setSnackBarEdit] = useState(false);

  const textareaRef = useRef(null);

  const { stopExecute, startExecute } = useDelayedExecution(AUTO_HIDE_TIMEOUT);

  const theme = useTheme();
  const { t } = useContext(LocalizationContext);

  const editHandler = ({ message }) => {
    setActionLoading(true);
    setSnackBarEdit(true);
    setMessageValue(message);
    setEditMode(false);
    setCommentEdited(true);
    startExecute(() => {
      updateComment({
        id: postId,
        commentId,
        message: message,
      }).finally(() => {
        setActionLoading(false);
      });
    });
  };

  const undoEdit = () => {
    stopExecute();
    setActionLoading(false);
    setSnackBarEdit(false);
    setMessageValue(oldMessageVersion);
    setCommentEdited(false);
  };

  return (
    <>
      {isEditMode ? (
        <Box className={styles.editBlock}>
          <TextareaAutosize
            minRows={1}
            maxRows={9}
            defaultValue={message}
            ref={textareaRef}
            maxLength={FULL_COMMENT_TEXT_LIMIT}
          />
          <Box className={styles.editBlockActions}>
            <Button onClick={() => setEditMode(false)} variant="outlined">
              <Typography component="span" variant="subtitle1">
                {t("default.cancel")}
              </Typography>
            </Button>
            <LoadingButton
              variant="contained"
              style={{ background: theme.palette.tertiary.main }}
              startIcon={<CheckedIcon />}
              onClick={() =>
                editHandler({ message: textareaRef.current.value })
              }
            >
              <Typography
                component="span"
                variant="subtitle1"
                color={(theme) => theme.palette.common.black}
              >
                {t("default.save")}
              </Typography>
            </LoadingButton>
          </Box>
        </Box>
      ) : null}
      <Snackbar
        open={isSnackBarEdit}
        message={t("discover.editCommentSuccess")}
        autoHideDuration={AUTO_HIDE_TIMEOUT}
        severity={"success"}
        onClose={() => setSnackBarEdit(false)}
        action={
          <Button className="snackbar-undo-button" onClick={undoEdit}>
            {t("discover.undo")}
            <svg
              width="28"
              height="12"
              viewBox="0 0 28 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27 10.75V7.25C27 3.93629 24.3137 1.25 21 1.25H1.5"
                stroke="#111322"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </Button>
        }
      />
    </>
  );
};
