import "./Snackbar.scss";

import { Box, Snackbar } from "@mui/material";
import { SNACKBAR_TYPE } from "constants/index";
import React, { Suspense, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSnackbar } from "redux/appSlice";
import { isAuthenticated } from "services/auth.service";

const SNACKBARS = {
  [SNACKBAR_TYPE.COOKIE]: React.lazy(() =>
    import("components/Snakbar/CookieSnakbar.jsx")
  ),
};
const AppSnackbar = () => {
  const snackbar = useSelector((state) => state.app.snackbar);
  const {
    type,
    message,
    open,
    autoHideDuration,
    severity,
    width,
    left,
    right,
    unclosable,
    className,
  } = snackbar || {};
  const dispatch = useDispatch();
  const renderContent = useMemo(() => {
    switch (type) {
      case SNACKBAR_TYPE.COOKIE: {
        return <SNACKBARS.COOKIE />;
      }
      default: {
        return message;
      }
    }
  }, [type, message]);

  // className="meta-snackbar" to remove this element for meta image generation on be
  return (
    <Snackbar
      className={`${
        !isAuthenticated() ? "public" : ""
      } meta-snackbar ${className}`}
      open={!!renderContent && open}
      autoHideDuration={autoHideDuration}
      message={<Suspense fallback={<Box></Box>}>{renderContent}</Suspense>}
      severity={severity}
      sx={{
        width,
        left,
        right,
      }}
      onClose={() => {
        if (!unclosable) {
          dispatch(setSnackbar({ open: false, severity }));
        }
      }}
    ></Snackbar>
  );
};

export default AppSnackbar;
