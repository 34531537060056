import { Box } from "@mui/material";
import { FileThumb } from "components/FileThumb/FileThumb";
import { MEDIA_TYPES, PATHS, REWARDS_ACTION_TYPE } from "constants/index";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import { MediaModal } from "../../../Modal/MediaModal/MediaModal";
import { RewardHeader } from "../../../RewardHeader/RewardHeader";
import { AudioIcon, FileIcon, ImageIcon, VideoIcon } from "../icons";
import { MediaAmountBox } from "../MediaAmountBox/MediaAmountBox";
import styles from "./HiddenContent.module.scss";

export const HiddenContent = ({
  media,
  mediaSummary,
  holdings,
  unlockModalHandler,
  selectedToken,
  cardId,
  marketTokens,
}) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);
  const [mediaIndex, setMediaIndex] = useState(0);

  const setMediaHandler = (index) => {
    setMediaIndex(index);
    setOpen(true);
  };

  const parsedMedia = media.map((item, index) => ({
    ...item,
    nativeIndex: index,
  }));

  const filteredMedia = parsedMedia.filter(
    (item) => item.type !== MEDIA_TYPES.FILE
  );

  const index = filteredMedia.findIndex(
    (item) => +item.nativeIndex === +mediaIndex
  );

  const allAmount =
    (+mediaSummary?.image || 0) +
    (+mediaSummary?.video || 0) +
    (+mediaSummary?.audio || 0) +
    (+mediaSummary?.files || 0);

  return (
    <Box>
      <RewardHeader
        holdings={holdings}
        unlockModalHandler={unlockModalHandler}
        buyAlertHandler={() =>
          navigate(PATHS.DROPS_ID_BUY.replace(":id", cardId))
        }
        title={t("default.hiddenContent")}
        type={REWARDS_ACTION_TYPE.HIDDEN_CONTENT}
        marketTokens={marketTokens}
        selectedToken={selectedToken}
      >
        <MediaAmountBox count={mediaSummary?.image} icon={<ImageIcon />} />
        <MediaAmountBox count={mediaSummary?.video} icon={<VideoIcon />} />
        <MediaAmountBox count={mediaSummary?.audio} icon={<AudioIcon />} />
        <MediaAmountBox count={mediaSummary?.files} icon={<FileIcon />} />
      </RewardHeader>
      <Box
        className={`${styles.list} global-list-thumb-${
          parsedMedia.length || allAmount
        }`}
      >
        {parsedMedia.map((item, index) => (
          <FileThumb
            key={index}
            file={{ type: item.type, preview: item.url }}
            className={`${styles.thumb} ${
              item.type === MEDIA_TYPES.AUDIO ? styles.thumbAudio : ""
            }`}
            clickHandler={(e) => {
              e.stopPropagation();
              setMediaHandler(`${index}`);
            }}
            thumbClassName={`${styles.thumbPreview} ${
              item.type === MEDIA_TYPES.VIDEO ? styles.thumbPreviewVideo : ""
            } ${
              item.type === MEDIA_TYPES.IMAGE ? styles.thumbPreviewImage : ""
            }`}
            stylesWrapper={
              item.type === MEDIA_TYPES.IMAGE &&
              parsedMedia.length === 1 && {
                background: `url(${item.url}) no-repeat center center / cover`,
                backdropFilter: "blur(10px)",
              }
            }
            isBlurred={
              item.type === MEDIA_TYPES.IMAGE && parsedMedia.length === 1
            }
          />
        ))}
        {!media.length
          ? Array(allAmount)
              .fill(null)
              .map((_, index) => (
                <FileThumb
                  key={index}
                  isFake={true}
                  className={`${styles.thumb} ${styles.fakePreview}`}
                  thumbClassName={styles.thumbPreview}
                  index={index}
                />
              ))
          : null}
      </Box>
      <MediaModal
        isOpen={isOpen}
        setOpen={setOpen}
        media={filteredMedia}
        index={+index}
      />
    </Box>
  );
};
