import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getProfileIsCompleted,
  getUserWallet,
} from "./redux/selectors/usersSelectors";
import { fetchUserWallet } from "./redux/usersSlice";

export const AppUserWallet = () => {
  const dispatch = useDispatch();

  const userWallet = useSelector(getUserWallet);
  const isCompleted = useSelector(getProfileIsCompleted);

  // useEffect(() => {
  //   if (isEmpty(userWallet) && isCompleted) {
  //     dispatch(fetchUserWallet());
  //   }
  // }, [isCompleted]);

  return <></>;
};
