export const RewardModalIcon = () => (
  <svg
    width="235"
    height="172"
    viewBox="0 0 235 172"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_31519_133689)">
      <path
        d="M199.647 74.9772L198.619 76.7572L196.61 76.3303C196.157 76.2341 195.848 76.7741 196.155 77.1179L197.529 78.6433L196.501 80.4234C196.269 80.8252 196.685 81.2848 197.109 81.0965L198.984 80.2614L200.358 81.7868C200.665 82.1306 201.237 81.8762 201.188 81.4192L200.972 79.375L202.852 78.5407C203.272 78.3515 203.209 77.7324 202.757 77.6362L200.747 77.2093L200.532 75.165C200.487 74.709 199.878 74.5796 199.647 74.9772Z"
        fill="#F1FC70"
      />
    </g>
    <g filter="url(#filter1_d_31519_133689)">
      <path
        d="M33.851 97.4095L36.4198 98.8886L38.6195 96.9045C39.115 96.4576 39.891 96.9082 39.7559 97.5592L39.143 100.455L41.7118 101.934C42.2906 102.269 42.1043 103.142 41.4383 103.214L38.4946 103.526L37.8818 106.422C37.7467 107.073 36.8498 107.168 36.5808 106.562L35.3735 103.855L32.4252 104.171C31.7638 104.239 31.3953 103.421 31.8908 102.974L34.0905 100.99L32.8832 98.2825C32.6095 97.6811 33.2764 97.0796 33.851 97.4095Z"
        fill="#F1FC70"
      />
    </g>
    <path
      d="M174.817 76.4582L120.175 44.917L65.5342 76.4582V139.541L120.175 171.082L174.817 139.541V76.4582Z"
      fill="#F1FC70"
    />
    <path
      d="M171.77 78.4758L120.628 48.9111L69.446 78.4758V137.523L120.628 167.088L171.77 137.523V78.4758Z"
      fill="url(#paint0_linear_31519_133689)"
    />
    <g filter="url(#filter2_d_31519_133689)">
      <path
        d="M211.83 11.0218L205.75 17.7537L197.473 14.0563C195.609 13.2234 193.776 15.2681 194.784 17.0354L199.303 24.8861L193.223 31.6181C191.854 33.1386 193.222 35.5052 195.228 35.0847L204.091 33.2121L208.611 41.0628C209.619 42.83 212.319 42.2596 212.531 40.2428L213.488 31.2223L222.368 29.3576C224.357 28.9292 224.657 26.1994 222.792 25.3665L214.515 21.6691L215.472 12.6486C215.701 10.6396 213.191 9.51868 211.83 11.0218Z"
        fill="#F1FC70"
      />
    </g>
    <g filter="url(#filter3_d_31519_133689)">
      <path
        d="M18.5414 30.2619L20.9187 34.3777L25.5649 33.3905C26.6116 33.1681 27.3267 34.4168 26.6164 35.2118L23.4401 38.7389L25.8174 42.8547C26.3521 43.7838 25.392 44.8466 24.4101 44.4111L20.0741 42.4801L16.8978 46.0072C16.1875 46.8022 14.8664 46.2139 14.9793 45.1574L15.4778 40.4306L11.132 38.5016C10.1599 38.0641 10.3056 36.6326 11.3522 36.4102L15.9985 35.423L16.497 30.6962C16.6001 29.6418 18.0087 29.3425 18.5414 30.2619Z"
        fill="#F1FC70"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M101.577 102C100.984 102 100.503 102.488 100.503 103.091L100.503 126.845C100.503 129.917 102.961 132.416 105.983 132.416H135.023C138.045 132.416 140.503 129.917 140.503 126.845L140.503 103.091C140.503 102.488 140.023 102 139.43 102H135.572H132.731H126.968H124.701H116.305H114.038H108.275H105.434H101.577ZM117.126 125.405V130.233H123.88V103.882H117.126V121.186C117.126 121.789 116.645 122.277 116.052 122.277C115.459 122.277 114.978 121.789 114.978 121.186V103.882H102.65V126.845C102.65 128.714 104.145 130.233 105.983 130.233H114.978V125.405C114.978 124.802 115.459 124.314 116.052 124.314C116.645 124.314 117.126 124.802 117.126 125.405ZM135.023 130.233C136.861 130.233 138.356 128.714 138.356 126.845V103.882H126.027V130.233H135.023Z"
      fill="#F1FC70"
    />
    <path
      d="M111.552 116.562H106.737C106.138 116.562 105.653 117.048 105.653 117.646C105.653 118.245 106.138 118.73 106.737 118.73H111.552C112.15 118.73 112.636 118.245 112.636 117.646C112.636 117.048 112.15 116.562 111.552 116.562Z"
      fill="#F1FC70"
    />
    <path
      d="M129.472 118.73H134.286C134.885 118.73 135.37 118.245 135.37 117.646C135.37 117.048 134.885 116.562 134.286 116.562H129.472C128.873 116.562 128.388 117.048 128.388 117.646C128.388 118.245 128.873 118.73 129.472 118.73Z"
      fill="#F1FC70"
    />
    <path
      d="M97.6134 103.888H101.223C101.827 103.888 102.317 103.526 102.317 102.942C102.317 102.474 101.827 102 101.223 102H97.6903V95.5637H114.876V102H105.452C104.848 102 104.359 102.474 104.359 102.942C104.359 103.526 104.848 103.888 105.452 103.888H143.393C144.556 103.888 145.503 103.2 145.503 102.074V95.4895C145.503 94.3635 144.556 93.4473 143.393 93.4473H135.19C136.038 92.4489 136.548 91.172 136.548 89.7818C136.548 86.5937 133.867 84 130.572 84H128.779C125.198 84 122.079 85.9544 120.503 88.8187C118.926 85.9544 115.808 84 112.227 84H110.434C107.139 84 104.458 86.5937 104.458 89.7819C104.458 91.172 104.968 92.449 105.816 93.4474H97.6134C96.4497 93.4474 95.5029 94.3635 95.5029 95.4896V102.074C95.5031 103.2 96.4497 103.888 97.6134 103.888ZM117.063 102V95.5637H123.943V102H117.063ZM143.316 102H126.13V95.5637H143.316V102ZM121.597 93.0667C121.597 89.2343 124.819 86.1164 128.779 86.1164H130.572C132.661 86.1164 134.361 87.7607 134.361 89.7819C134.361 91.8031 132.661 93.4474 130.572 93.4474H121.597V93.0667ZM106.645 89.7819C106.645 87.7607 108.345 86.1164 110.433 86.1164H112.226C116.187 86.1164 119.409 89.2343 119.409 93.0667V93.4473H110.433C108.345 93.4473 106.645 91.8029 106.645 89.7819Z"
      fill="#F1FC70"
    />
    <defs>
      <filter
        id="filter0_d_31519_133689"
        x="186.031"
        y="64.7402"
        width="27.1028"
        height="27.2056"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.945098 0 0 0 0 0.988235 0 0 0 0 0.439216 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_31519_133689"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_31519_133689"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_31519_133689"
        x="21.6619"
        y="86.7251"
        width="30.3958"
        height="30.2422"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.945098 0 0 0 0 0.988235 0 0 0 0 0.439216 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_31519_133689"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_31519_133689"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_31519_133689"
        x="182.671"
        y="0.328125"
        width="51.3667"
        height="51.791"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.945098 0 0 0 0 0.988235 0 0 0 0 0.439216 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_31519_133689"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_31519_133689"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_31519_133689"
        x="0.47876"
        y="19.7139"
        width="36.4231"
        height="36.6616"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.945098 0 0 0 0 0.988235 0 0 0 0 0.439216 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_31519_133689"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_31519_133689"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_31519_133689"
        x1="69.465"
        y1="107.999"
        x2="171.778"
        y2="107.999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#21264E" />
        <stop offset="1" stopColor="#111322" />
      </linearGradient>
    </defs>
  </svg>
);
