import { Box } from "@mui/material";
import React from "react";

import styles from "./AudioBlock.module.scss";
import { AudioWrapper } from "./AudioWrapper";

export const AudioBlock = ({
  audios,
  postIndex,
  accessGranted = true,
  handle,
}) => {
  if (!audios?.length) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      {audios.map((src, index) => (
        <AudioWrapper
          src={src}
          postIndex={postIndex}
          mediaIndex={index}
          accessGranted={accessGranted}
          handle={handle}
        />
      ))}
    </Box>
  );
};
