import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as IconClose } from "assets/svg/icon-close.svg";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { getInfluencerCalendar } from "services/influencerApi";
import { LocalizationContext } from "services/localizationContext";
import { getConvertedTimeZoneDate } from "utils/index";

import { convertTime } from "../../utils";
import styles from "./ScheduledModal.module.scss";

export const ScheduledModal = ({
  isShow = false,
  setShow,
  influencerId,
  time,
  setTime,
  startDate,
  setStartDate,
  submit,
}) => {
  const { t } = useContext(LocalizationContext);
  const isMobile = useMediaQuery("(max-width:768px)");

  const [timeSlots, setTimeSlots] = useState({
    availableSlots: [],
    prevDaySlots: [],
    nextDaySlots: [],
  });
  const [timeLoader, setTimeLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const minDate = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000);
  const maxDate = new Date(minDate.getTime() + 11 * 24 * 60 * 60 * 1000); // Adding 14 days in milliseconds

  const close = () => setShow(false);

  const setTimeHandler =
    ({ value, serverStartDate }) =>
    () => {
      const currentValue = value === time?.value ? null : value;
      setTime({ value: currentValue, serverStartDate });
    };

  const submitHandler = () => {
    if (time.serverStartDate) {
      setSubmitLoader(true);
      submit(time.serverStartDate)
        .then(() => close())
        .finally(() => setSubmitLoader(false));
    }
  };

  const convertedTimes = convertTime({
    times: timeSlots.availableSlots,
    from: "Etc/UTC",
    startDate,
  });

  const prevDate = new Date(startDate);
  prevDate.setDate(prevDate.getDate() - 1);

  const convertedPrevDayTimes = convertTime({
    times: timeSlots.prevDaySlots,
    from: "Etc/UTC",
    startDate,
    comparableDate: prevDate,
  });

  const nextDate = new Date(startDate);
  nextDate.setDate(nextDate.getDate() + 1);
  const convertedNextDayTimes = convertTime({
    times: timeSlots.nextDaySlots,
    from: "Etc/UTC",
    startDate,
    comparableDate: nextDate,
  });

  const allSlots = [
    ...convertedPrevDayTimes,
    ...convertedTimes,
    ...convertedNextDayTimes,
  ];

  useEffect(() => {
    if (startDate) {
      setTimeLoader(true);
      getInfluencerCalendar({
        date: format(startDate, "MM-dd-yyyy"),
        influencerId,
      })
        .then((res) => {
          if (res?.availableSlots) {
            setTimeSlots(res);
          }
        })
        .finally(() => setTimeLoader(false));
    }
  }, [startDate]);

  if (!isShow) {
    return <></>;
  }

  return (
    <Box className={styles.modalWrapper}>
      <Box onClick={close} className={styles.overlay} />
      <Box className={styles.modal}>
        {!isMobile || (isMobile && startDate) ? (
          <IconButton className={styles.modalClose} onClick={close}>
            <IconClose />
          </IconButton>
        ) : null}
        <Box className={styles.modalBody}>
          <Box className={styles.modalBodyLeft}>
            <Box className={styles.modalHeader}>
              <Typography className={styles.modalTitle} variant="h4">
                {t("default.premiumChatScheduledTitle")}
              </Typography>
              <Typography className={styles.modalSubTitle} variant="body2">
                {t("default.premiumChatScheduledSubTitle")}
              </Typography>
            </Box>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              calendarClassName={`scheduled-calendar ${styles.calendar}`}
              minDate={minDate}
              maxDate={maxDate}
              inline
            />
          </Box>
          {startDate ? (
            <Box className={styles.modalBodyRight}>
              {isMobile ? (
                <IconButton
                  className={`${styles.modalClose} ${styles.modalTimeClose}`}
                  onClick={() => {
                    setTime(null);
                    setStartDate(null);
                  }}
                >
                  <IconClose />
                </IconButton>
              ) : null}
              {!isMobile ? (
                <Typography variant="body1">
                  {getConvertedTimeZoneDate(startDate)}
                </Typography>
              ) : (
                <Box className={styles.modalBodyRightHeader}>
                  <Typography variant="h4">
                    {t("default.premiumChatScheduledTitleTime")}
                  </Typography>
                  <Typography variant="body2" className="opacity_07">
                    {t("default.justFor")} {getConvertedTimeZoneDate(startDate)}
                  </Typography>
                </Box>
              )}
              <Box className={styles.timeList}>
                {timeLoader ? (
                  <Box className={styles.timeListLoader}>
                    <CircularProgress />
                  </Box>
                ) : null}
                {!allSlots.length && !timeLoader ? (
                  <Typography
                    variant="body5"
                    className={styles.timeListEmptyTitle}
                  >
                    {t("messages.noTimeSlotsTitle")}
                  </Typography>
                ) : null}
                {allSlots.map(({ start, serverStartDate }) => (
                  <Box className={styles.timeItem} key={start}>
                    <Button
                      onClick={setTimeHandler({
                        value: start,
                        serverStartDate,
                      })}
                      variant="outlined"
                      className={
                        start === time?.value ? styles.timeItemActive : ""
                      }
                      disabled={start !== time?.value && submitLoader}
                    >
                      {start}
                    </Button>
                    {start === time?.value ? (
                      <LoadingButton
                        variant="contained"
                        className={styles.timeItemNext}
                        onClick={submitHandler}
                        loading={submitLoader}
                        disabled={submitLoader}
                      >
                        {t("default.next")}
                      </LoadingButton>
                    ) : null}
                  </Box>
                ))}
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
