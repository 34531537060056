import { Box } from "@mui/material";
import { PATHS } from "constants/index";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import { SubHeader } from "../SubHeader/SubHeader";
import { Card } from "./components/Card/Card";
import styles from "./Groups.module.scss";
import { GroupsSkeleton } from "./GroupsSkeleton";

export const Groups = ({ data, loading, isHideHeader, isHideViewAll }) => {
  const { t } = useContext(LocalizationContext);

  if (!loading && !data.length) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      {loading ? (
        <GroupsSkeleton isHideHeader={isHideHeader} />
      ) : (
        <>
          {!isHideHeader ? (
            <SubHeader
              path={PATHS.DISCOVER_GROUPS}
              title={t("discover.groups")}
              isHideViewAll={isHideViewAll}
            />
          ) : null}
          <Box className={styles.grid}>
            {data.map((card) => (
              <Card card={card} key={card.id} />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};
