import "react-image-crop/dist/ReactCrop.css";

import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { ReactComponent as IconClose } from "assets/svg/icon-close.svg";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { LocalizationContext } from "services/localizationContext";

import styles from "./ModalUploadPhoto.module.scss";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 40,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ModalUploadPhoto = ({ open, handleClick, handleRemove, handleClose }) => {
  const { t } = useContext(LocalizationContext);
  const [step, setStep] = useState(0);
  const [crop, setCrop] = useState(undefined);
  const [imgSrc, setImgSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const imgRef = useRef(null);

  const [completedCrop, setCompletedCrop] = useState();

  const scale = 1;
  const rotate = 0;
  const aspect = 1;

  useEffect(() => {
    setStep(0);
    setLoading(false);
  }, [open]);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result.toString() || "");
        setStep(1);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const makeClientCrop = async (crop) => {
    if ((imgSrc, crop.width && crop.height)) {
      const croppedImg = await getCroppedImg(imgSrc, crop, "croppedImage.jpeg");
      setCompletedCrop(croppedImg);
    }
  };

  const getCroppedImg = (sourceImage, crop, fileName) => {
    const canvas = document.createElement("canvas");

    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;

    const ctx = canvas.getContext("2d");

    const image = new Image();
    image.src = sourceImage;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    try {
      return new Promise((resolve) => {
        canvas.toBlob((file) => {
          resolve(file);
        }, "image/jpeg");
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleUpload = () => {
    setLoading(true);
    handleClick({ image: completedCrop });
  };

  const renderSteps = () => {
    switch (step) {
      case 0: {
        return (
          <Box className={styles.buttonContainer}>
            <input
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              style={{ display: "none" }}
              id="contained-button-file"
            />
            <label htmlFor="contained-button-file">
              <Button
                className={styles.btn}
                variant="contained"
                component="span"
              >
                {t("account.uploadFromDevice")}
              </Button>
            </label>
            <LoadingButton
              className={styles.btn}
              variant="outlined"
              onClick={handleRemove}
              loading={loading}
            >
              {t("account.removeCurrentPhoto")}
            </LoadingButton>
          </Box>
        );
      }
      case 1: {
        return (
          <Box className={styles.cropContainer}>
            {Boolean(imgSrc) && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => makeClientCrop(c)}
                aspect={aspect}
                circularCrop
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
            <Box className={styles.buttonContainer}>
              <LoadingButton
                className={styles.btn}
                variant="contained"
                onClick={handleUpload}
                loading={loading}
              >
                {t("account.upload")}
              </LoadingButton>
            </Box>
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          height: "auto",
          maxWidth: "695px",
          width: "calc(100% - 32px)",
          transform: "translate(-50%, -50%)",
          background: "#111322",
          outline: "none",
          borderRadius: "12px",
        }}
      >
        <Typography
          className={styles.modalTitle}
          id="modal-modal-title"
          variant="textMediumBold"
        >
          {t("account.changeProfilePhoto")}
          <IconButton className={styles.iconClose} onClick={handleClose}>
            <IconClose />
          </IconButton>
        </Typography>
        {renderSteps()}
      </Box>
    </Modal>
  );
};

export default ModalUploadPhoto;
