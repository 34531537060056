import { Box, Typography } from "@mui/material";
import { ReactComponent as IconMail } from "assets/svg/icon-mail.svg";
import { dateLocales } from "constants/";
import {
  NOTIFICATION_REDIRECT,
  NOTIFICATION_STATUS,
  NOTIFICATION_TYPE,
} from "constants/notifications";
import { format, isToday } from "date-fns";
import _ from "lodash";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchMarkAsReadById } from "redux/notificationsSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "./NotificationCard.module.scss";

const NotificationCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { payload, type, status, date, id, locale } = props;

  const { t } = useContext(LocalizationContext);
  const isUnread = status === NOTIFICATION_STATUS.NEW;

  const isChallengeNotification =
    type === NOTIFICATION_TYPE.CHALLENGE_COMPLETED;

  const pyld = !!payload?.nPayload ? payload.nPayload : payload;

  const getTranslationTitle = (key, params) => {
    return t(`notifications.${_.camelCase(key)}Title`, params);
  };

  const getTranslationBody = (key, params) => {
    if (
      isChallengeNotification &&
      pyld.hasOwnProperty("isGraded") &&
      !pyld.isGraded
    ) {
      return t(`notifications.${_.camelCase(key)}BodyNonGraded`, params);
    }
    return t(`notifications.${_.camelCase(key)}Body`, params);
  };

  const handleRedirect = () => {
    if (isUnread) dispatch(fetchMarkAsReadById(id));
    const url = NOTIFICATION_REDIRECT[type].redirect(pyld);
    if (!url) return;

    navigate(url, {
      state: { activeBadge: pyld?.grade || 0, backRoute: "/notifications" },
    });
  };

  return (
    <Box
      className={`${styles.notificationCardContainer} ${
        isUnread ? styles.unread : ""
      }`}
      onClick={() => handleRedirect()}
    >
      <Box className={styles.mailIconWrap}>
        {isUnread && <Box className={styles.dot} />}
        <IconMail />
      </Box>
      <Box
        className={`${styles.titleWrap} ${
          isChallengeNotification ? styles.isChallange : null
        }`}
      >
        <Typography variant="h6" className={styles.title}>
          {getTranslationTitle(type, pyld)}
        </Typography>
        <Typography variant="body1" className={styles.description}>
          {getTranslationBody(type, {
            ...pyld,
            ...(isChallengeNotification
              ? { challengeName: t(`challenges.${pyld.code}-name`) }
              : null),
          })}
        </Typography>
      </Box>
      <Box className={styles.dateWrap}>
        <Typography variant="body3" className={styles.date}>
          {isToday(new Date(date))
            ? format(new Date(date), "hh:mm aaa")
            : format(new Date(date), "MMM dd yyyy hh:mm aaa", {
                locale: !!locale ? dateLocales[locale] : null,
              })}
        </Typography>
      </Box>
    </Box>
  );
};

export default NotificationCard;
