import { Box, Typography } from "@mui/material";

import styles from "./StreakDay.module.scss";

const NonActiveIcon = () => (
  <svg
    width="28"
    height="27"
    viewBox="0 0 28 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 26C20.9036 26 26.5 20.4036 26.5 13.5C26.5 6.59644 20.9036 1 14 1C7.09644 1 1.5 6.59644 1.5 13.5C1.5 20.4036 7.09644 26 14 26Z"
      stroke="#B8B9CC"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

const ActiveIcon = () => (
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.166 25C20.0696 25 25.666 19.4036 25.666 12.5C25.666 5.59644 20.0696 0 13.166 0C6.26246 0 0.666016 5.59644 0.666016 12.5C0.666016 19.4036 6.26246 25 13.166 25Z"
      fill="#EE5261"
    />
    <path
      d="M5.26953 12.4956L9.26806 16.9629L19.1935 9.30469"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const StreakDay = ({ isActive, title }) => {
  return (
    <Box className={styles.box}>
      {isActive ? <ActiveIcon /> : <NonActiveIcon />}
      <Typography variant="body1" className="opacity_07">
        {title}
      </Typography>
    </Box>
  );
};
