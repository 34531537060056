import { Box, CircularProgress, Typography } from "@mui/material";
import { COMMENT_TEXT_LIMIT } from "constants/index";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { LocalizationContext } from "services/localizationContext";
import { truncateText } from "utils/stringHelper";

import styles from "./CommentTextBlock.module.scss";

export const CommentTextBlock = ({
  message,
  isEditMode,
  isCommentEdited,
  tipAmount,
  isMessageLoading,
  replyToUser,
  replyToId,
}) => {
  const { t } = useContext(LocalizationContext);
  const post = useSelector((state) => state.feed.post.entities);
  const [showMore, setShowMore] = useState(false);

  const showHandler = () => setShowMore(!showMore);

  const hideHandler = () => setShowMore((prevState) => !prevState);

  const EditedText = () =>
    isCommentEdited ? (
      <Typography
        component="span"
        variant="body2"
        className={styles.editedText}
      >
        {t("discover.edited")}
      </Typography>
    ) : null;

  const TipAmount = () => {
    if (!tipAmount) {
      return null;
    }
    const txt =
      tipAmount === "hidden"
        ? `$${t("discover.tipHidden")}`
        : `$${t("discover.tip", { value: tipAmount.toFixed(2) })}`;
    return (
      <Typography
        variant="body6_medium_dark"
        className={styles.tipBox}
        component="span"
      >
        {txt}
      </Typography>
    );
  };

  if (isEditMode) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      <TipAmount />
      <Box className={styles.message}>
        {isMessageLoading ? (
          <CircularProgress className={styles.loader} size={24} />
        ) : null}
        <Typography
          className={`${!post.accessGranted ? styles.blured : ""} ${
            styles.messageValue
          }`}
        >
          {replyToUser && replyToId ? (
            <a href={`#${replyToId}`} className={styles.replyUsername}>
              @{replyToUser}
            </a>
          ) : null}
          {showMore ? message : truncateText(message, COMMENT_TEXT_LIMIT)}
          <EditedText />
        </Typography>
        {!showMore && message.length > COMMENT_TEXT_LIMIT ? (
          <Typography
            valiant="body3"
            className={styles.commentItemMoreBtn}
            onClick={showHandler}
          >
            {t("discover.seeMore")}
          </Typography>
        ) : null}
        {showMore && message.length > COMMENT_TEXT_LIMIT ? (
          <Typography
            valiant="body3"
            className={styles.commentItemMoreBtn}
            onClick={hideHandler}
          >
            {t("discover.seeLess")}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};
