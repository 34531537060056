import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SOCIAL_LINKS } from "constants";
import { getCategories } from "constants/index";
import Proptypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./about.module.scss";

export const AboutInfluencer = ({ about, socialMediaLinks, category }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:840px)");
  const { t } = useContext(LocalizationContext);
  const [isExpandable, setIsExpandable] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const categories = getCategories({ t });

  useEffect(() => {
    if (isMobile) {
      setIsExpanded(true);
      setIsExpandable(false);
    }
  }, [isMobile]);

  useEffect(() => {
    const isFull = about && socialMediaLinks.length > 0;
    setIsExpandable(isFull);
    setIsExpanded(!isFull);
  }, [about, socialMediaLinks]);

  if (!about && socialMediaLinks.length === 0) {
    return null;
  }

  const toggleExpansion = () => {
    if (isExpandable) {
      setIsExpanded(!isExpanded);
    }
  };

  const getLink = ({ socialPlatformId, link }) => {
    let prependLink = "";
    const found = SOCIAL_LINKS.find(
      (item) => item.socialPlatformId === socialPlatformId
    );
    if (found) prependLink = found.prependLink;
    return prependLink + link;
  };

  return (
    <Box className={styles.accordion}>
      <Box
        className={`${styles.block} ${isExpandable ? styles.expandable : ""} ${
          isMobile ? styles.mobileBlock : ""
        }`}
        onClick={toggleExpansion}
      >
        {!isMobile ? (
          <Box aria-controls="about-content" id="about-header">
            <Typography variant="body2" className={styles.aboutTitle}>
              {t("discover.about")}
            </Typography>
          </Box>
        ) : null}
        <Typography
          variant="body1"
          className={`${styles.aboutText} ${
            isExpanded ? "" : styles.collapsedContent
          }`}
        >
          {about || ""}
        </Typography>
        {!isExpanded && (
          <Button className={styles.expandBtn}>
            <Typography variant="body2" className={styles.expandBtnTxt}>
              {t("discover.seeMore")}
            </Typography>
          </Button>
        )}
        {isMobile && isExpanded && category ? (
          <Box className={styles.categoryBlock}>
            <Typography variant="body2" className={styles.categoryTitle}>
              {t("default.category")}
            </Typography>
            <Typography className={styles.category} variant="body2">
              {categories[category] || category}
            </Typography>
          </Box>
        ) : null}
        {isExpanded && (
          <Box className={styles.smBlock}>
            {socialMediaLinks?.length > 0 &&
              socialMediaLinks.map((s) => {
                return (
                  <a
                    key={s.socialPlatformId}
                    className={styles.smItem}
                    href={getLink(s)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Box sx={{ bgcolor: theme.palette.surface.main }}>
                      <img
                        className={styles.socialIcon}
                        src={s.iconURL}
                        alt={s.name}
                      />
                    </Box>
                    <Typography variant="body2" className={styles.socialName}>
                      {s.name}
                    </Typography>
                  </a>
                );
              })}
          </Box>
        )}
      </Box>
    </Box>
  );
};

AboutInfluencer.defaultProps = {
  about: "",
  socialMediaLinks: [],
  category: "",
};

AboutInfluencer.propTypes = {
  about: Proptypes.string,
  socialMediaLinks: Proptypes.array,
  category: Proptypes.string,
};
