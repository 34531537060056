import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { PATHS } from "constants/index";
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUserProfile } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

import { PageLayout } from "../../PageLayout/PageLayout";
import { OverlayImages } from "../components/OverlayImages/OverlayImages";
import { TelegramPageWrapper } from "../TelegramPageWrapper";
import { images } from "./data";
import styles from "./TelegramSplash.module.scss";

export const TelegramSplash = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);

  useEffect(() => {
    const referralCode = window.Telegram.WebApp.initDataUnsafe.start_param;

    if (referralCode) {
      dispatch(updateUserProfile({ referralCode }));
    }
  }, []);

  return (
    <TelegramPageWrapper>
      <PageLayout
        isShowHeader={false}
        isBottomMenuHide={true}
        pageWrapperClassName={styles.pageWrapper}
        pageClassName={styles.page}
      >
        <OverlayImages images={images} />
        <Box className={styles.pageContent}>
          <Box className={styles.pageHeader}>
            <Typography variant="h2">{t("default.welcomeTo")}</Typography>
            <Logo />
          </Box>
          <Typography variant="h6" className={styles.pageSlogan}>
            {t("default.telegramSplashPageSlogan")}
          </Typography>
          <Box className={styles.pageFooter}>
            <Button
              variant="contained"
              onClick={() => navigate(PATHS.TELEGRAM_SLIDE_EARN)}
            >
              {t("default.continueBtn")}
            </Button>
          </Box>
        </Box>
      </PageLayout>
    </TelegramPageWrapper>
  );
};
