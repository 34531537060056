import { Box, Container } from "@mui/material";
import { RisingStars } from "components/InfluencersRows/RisingStars/RisingStars";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import { PATHS, SCROLL_THRESHOLD } from "constants/index";
import React, { useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { LEVEL_SMALLER } from "utils/getFilteredInfluencers";

import { PageLayout } from "../../PageLayout/PageLayout";
import styles from "./styles.module.scss";
import { useData } from "./useData";

export const DiscoverRisingStars = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const { data, loadMore, scrollLoader, loader, hasMore } = useData({
    level: LEVEL_SMALLER,
    featured: 0,
  });

  return (
    <PageLayout>
      <Container className={styles.container}>
        <Box className={styles.backHeadline}>
          <BackBtn
            onClick={() => navigate(PATHS.DISCOVER)}
            title={t("discover.risingStars")}
          />
        </Box>
        <InfiniteScroll
          dataLength={data.length}
          next={loadMore}
          hasMore={hasMore}
          loader={scrollLoader}
          scrollThreshold={SCROLL_THRESHOLD}
          scrollableTarget="scrollableEl"
        >
          <RisingStars data={data} loading={loader} isHideHeader={true} />
        </InfiniteScroll>
      </Container>
    </PageLayout>
  );
};
