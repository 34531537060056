const FAQ_CONTENT = [
  {
    title: "What is Zoop?",
    desc: "Zoop is a digital trading card platform that is changing the way fans connect to creators by allowing them to collect trading cards in the form of NFTs, with unique rewards attached. Zoop users are known as “Zoops”",
  },
  {
    title: "What is an NFT?",
    desc: "NFT stands for “non-fungible token”. An NFT is essentially a digital record of ownership for a unique item that is recorded on a blockchain for everyone to see, meaning it can't be forged or manipulated by anyone. NFTs provide value through proof of ownership. An NFT can be anything but is normally a jpg, video or a gif.",
  },
  {
    title: "Are all Zoop cards NFTs?",
    desc: "Yes, Zoop cards are minted in the blockchain.",
  },
  {
    title: "What is the Zoop app for?",
    desc: "Zoops can bid, buy, sell, and trade creator cards as well as compete in games and challenges, earn points, and rank up. Users can earn special reward cards which have extra perks attached to them. Reward points can also be exchanged for Zoop tokens in the future.",
  },
  {
    title: "Where can I get Zoop creator cards?",
    desc: "Base cards are released to the market on the Zoop app via an auction-style process that we call a “drop”. This is the earliest opportunity to get your hands on a new release of a card. After a card has dropped, users will be able to buy and sell them in the marketplace. Additional special reward cards are available for unlocking through challenges, competitions and reward packs.",
  },
  {
    title: "Do I need cryptocurrency?",
    desc: "Zoop makes NFTs really simple for anyone unfamiliar with cryptocurrencies. We display everything in dollars. Cryptocurrencies can be used to fund your in-app wallet but will be converted into dollars for everyone’s ease of use.",
  },
];

export default FAQ_CONTENT;
