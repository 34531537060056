import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import { Box, IconButton } from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { setError } from "redux/appSlice";
import {
  stripeSavePaymentMethod,
  stripeSetupPaymentMethod,
} from "services/api/paymentsApi";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";

import { CloseIcon } from "./icons";
import styles from "./StripeCard.module.scss";

export const StripeCard = ({ callback, name }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setLoading] = useState(false);
  const [isShow, setShow] = useState(true);

  const showErrorModal = (e) => {
    dispatch(
      setError({
        open: true,
        title: "Error",
        subtitle: errorResponseMessages(e.data || e, t),
      })
    );
  };

  const saveCard = ({ clientSecret }) => {
    const cardElement = elements.getElement(CardElement);

    stripe
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name,
          },
        },
      })
      .then(function (result) {
        if (result.error) {
          setLoading(false);
          dispatch(
            setError({
              open: true,
              title: t("error.error"),
              subtitle: result.error?.message,
            })
          );
        } else {
          stripeSavePaymentMethod(result.setupIntent.payment_method)
            .then((data) => {
              if (data) {
                callback({ id: data.id, name: data.name });
              }
              setShow(false);
            })
            .finally(() => setLoading(false));
        }
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    stripeSetupPaymentMethod()
      .then((res) => {
        if (res?.clientSecret) {
          saveCard({ clientSecret: res.clientSecret });
        }
      })
      .catch((e) => {
        showErrorModal(e);
        setLoading(false);
      });
  };

  const closeHandler = () => {
    setShow(false);
    if (callback) {
      callback();
    }
  };

  if (!isShow) {
    return <></>;
  }

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.stripeCardWrapper}>
        <Box className={styles.stripeCard}>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: "14px",
                  color: theme.palette.secondary.main,
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
              },
            }}
          />
        </Box>
        <IconButton onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </Box>
      <LoadingButton
        disabled={!stripe}
        loading={isLoading}
        onClick={handleSubmit}
        variant="contained"
        size="small"
        className={styles.submitButton}
      >
        {t("default.save")}
      </LoadingButton>
    </Box>
  );
};
