import React from "react";

export const EmptyStateIcon = () => (
  <svg
    width="101"
    height="100"
    viewBox="0 0 101 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.9728 67.6876L43.7246 45.9296L60.6916 31.8015"
      stroke="white"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
    <path
      d="M65.1577 83.5404C54.8584 88.0195 42.6583 87.7381 32.1978 81.6987C14.6842 71.5873 8.68884 49.2123 18.8003 31.6987C28.9117 14.1852 51.2867 8.18982 68.8003 18.3013C86.3138 28.4127 92.3092 50.7877 82.1978 68.3013"
      stroke="white"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
