import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { POST_STATUS } from "constants/index";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { usePolicyHostUrl } from "utils/hooks";
import { getStatus } from "utils/post";

import { InfoIcon } from "./icons";
import styles from "./StatusSection.module.scss";

export const StatusSection = ({ status, reason }) => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const getDynamicUrl = usePolicyHostUrl();

  const callSupportHandler = (e) => {
    e.preventDefault();
    if (window.zE) {
      // window.zE("messenger:set", "zIndex", 99999);
      // window.zE("messenger", "open");
      window.zE("messenger", "show");
    }
  };

  const data = getStatus({
    value: status,
    t,
    theme,
    reason,
    getDynamicUrl,
    callSupport: callSupportHandler,
  });

  if (status === POST_STATUS.PUBLISHED) {
    return null;
  }

  return (
    <Box className={styles.statusSection}>
      <InfoIcon />
      <Box>
        <Box className={styles.row}>
          <Typography variant="body1" className={styles.label}>
            {t("settings.status")}
          </Typography>
          <Button
            style={{ background: data.background, color: data.statusColor }}
          >
            {data.title}
          </Button>
        </Box>
        <Typography variant="body1" className={styles.description}>
          {data.description}
        </Typography>
        {data.value ? (
          <ul>
            <li>
              <Typography variant="body1" className={styles.value}>
                {data.value}
              </Typography>
            </li>
          </ul>
        ) : null}
        {data.bottomText ? (
          <Typography variant="body1" className={styles.bottomText}>
            {data.bottomText}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};
