import "./App.scss";

import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import { DEFAULT_TITLE_TAG } from "constants";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import LangService from "services/LangService";
import ResponseInterceptor from "services/ResponseInterceptor";
import { ViewportService } from "services/ViewportService";
import store from "store";
import theme from "theme";

import { AppUserWallet } from "./AppUserWallet";
import { Telegram } from "./components/Telegram/Telegram";
import Pusher from "./modules/Pusher.module";
import { Pixel } from "./Pixel";
import { Prompt } from "./Prompt";
import Router from "./Router";
import { storageCleaner } from "./utils";
import { Verification3ds } from "./Verification3ds";
import { YupCustomMethods } from "./YupCustomMethods";

function App() {
  storageCleaner();

  return (
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <Helmet>
              <title>{DEFAULT_TITLE_TAG}</title>
            </Helmet>
            <Telegram />
            <YupCustomMethods />
            <ViewportService />
            <LangService />
            <ResponseInterceptor />
            <Pusher />
            <Router />
            <AppUserWallet />
            <Pixel />
            <Prompt />
            <Verification3ds />
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  );
}

export default App;
