import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "services/dropsApi";

import * as USER_API from "../services/userApi";
import { formatEditionOrders } from "../utils";

const initialState = {
  loading: false,
  card: [],
  cardTokens: {
    tokens: [],
    amountCards: 0,
  },
};

export const fetchCard = createAsyncThunk(
  "card",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await API.getCard(id);
      return response;
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const fetchCardTokens = createAsyncThunk(
  "get/card-tokens",
  async (params, { rejectWithValue }) => {
    try {
      const response = await USER_API.getUserCard(params);
      return response?.data;
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    resetCard: (state) => {
      state.card = [];
      state.loading = false;
    },
    updateCardTokens: (state, action) => {
      state.cardTokens = action.payload;
    },
    updateRewardOrderStatus: (state, action) => {
      const { rewardOrderId, status } = action.payload || {};
      const index = state.cardTokens.tokens.findIndex(
        (item) => item.rewardOrder?.id === rewardOrderId
      );
      if (state.cardTokens.tokens[index]?.rewardOrder) {
        state.cardTokens.tokens[index].rewardOrder.status = status;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCard.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCard.fulfilled, (state, action) => {
        state.loading = false;
        state.card = action.payload;
      })
      .addCase(fetchCard.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchCardTokens.fulfilled, (state, action) => {
        const data = formatEditionOrders(action.payload);
        state.cardTokens.tokens = data.tokens;
      });
  },
});
export const { resetCard, updateCardTokens, updateRewardOrderStatus } =
  cardSlice.actions;
export default cardSlice.reducer;
