import { Box, Skeleton } from "@mui/material";
import React from "react";

import styles from "./AllStars.module.scss";

export const AllStarsSkeleton = ({ isHideHeader }) => {
  return (
    <Box className={styles.skeleton}>
      {!isHideHeader ? (
        <Skeleton variant="rectangular" className={styles.skeletonHeader} />
      ) : null}
      <Box className={styles.grid}>
        <Skeleton variant="rectangular" className={styles.card} />
        <Skeleton variant="rectangular" className={styles.card} />
        <Skeleton variant="rectangular" className={styles.card} />
        <Skeleton variant="rectangular" className={styles.card} />
        <Skeleton variant="rectangular" className={styles.card} />
      </Box>
    </Box>
  );
};
