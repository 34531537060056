import { CONNECTION_REQUEST_TYPE } from "constants/index";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConnectionsRequests } from "redux/selectors/usersSelectors";
import { fetchUserFriendRequests } from "redux/usersSlice";

import { ConnectionsRequests } from "./ConnectionsRequests";

export const ConnectionsRequestsWrapper = () => {
  const dispatch = useDispatch();
  const data = useSelector(getConnectionsRequests);

  useEffect(() => {
    dispatch(
      fetchUserFriendRequests({ type: CONNECTION_REQUEST_TYPE.incoming })
    );
  }, []);

  return <ConnectionsRequests data={data.entities} loading={data.loading} />;
};
