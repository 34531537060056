import { LoadingButton } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { ReactComponent as ReplyArrow } from "assets/svg/feed/reply.svg";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./Reply.module.scss";

const ReplyLoad = ({
  isHidden = false,
  handleReply,
  comment,
  isNested,
  repliesCount,
  activeHandler,
  isReplyLoading,
}) => {
  const { t } = useContext(LocalizationContext);
  const [areRepliesOpen, setAreRepliesOpen] = useState(false);

  const handleButtonClick = () => {
    setAreRepliesOpen(!areRepliesOpen);
    activeHandler();
    handleReply(comment, false);
  };

  return (
    <Box
      className={`${styles.replyLoad} ${isHidden ? styles.replyHidden : ""}`}
    >
      {repliesCount && !isNested ? (
        <LoadingButton
          onClick={handleButtonClick}
          disabled={isReplyLoading}
          loadingPosition="start"
          loading={isReplyLoading}
        >
          <Typography variant="body2" className={styles.replyCount}>
            <ReplyArrow
              className={`${styles.icon} ${areRepliesOpen ? styles.open : ""}`}
            />
            {t(
              areRepliesOpen ? "discover.hideReplies" : "discover.viewReplies",
              {
                count: repliesCount,
                useSingular: true,
              }
            )}
          </Typography>
        </LoadingButton>
      ) : null}
    </Box>
  );
};

export default ReplyLoad;
