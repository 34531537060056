import { Box, Typography } from "@mui/material";
import { ViewAll } from "components/ViewAll/ViewAll";
import React from "react";

import styles from "./SubHeader.module.scss";

export const SubHeader = ({ title, path, isHideViewAll }) => {
  return (
    <Box className={styles.wrapper}>
      <Typography variant="h4" className={styles.title}>
        {title}
      </Typography>
      {!isHideViewAll ? <ViewAll path={path} /> : null}
    </Box>
  );
};
