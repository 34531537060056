import { Box } from "@mui/material";
import classNames from "classnames/bind";
import React from "react";

import styles from "./Badge.module.scss";

export const BadgeImageBox = ({
  imageUrl,
  goalCount,
  imgClass,
  badgeGoalClass,
}) => {
  const imgClassName = classNames(styles.badgeImg, {
    [imgClass]: imgClass,
  });
  const badgeGoalClassName = classNames(styles.badgeGoal, {
    [badgeGoalClass]: badgeGoalClass,
  });

  return (
    <Box className={styles.badgeImgWrapper}>
      <img src={imageUrl} alt="badge" className={imgClassName} />
      {goalCount ? <Box className={badgeGoalClassName}>{goalCount}</Box> : null}
    </Box>
  );
};
