import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { ReactComponent as TranslationIcon } from "assets/svg/translation-icon.svg";
import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "redux/appSlice";
import { postTranslate } from "services/api/feedApi";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";
import { formatPostMessage } from "utils";

import TimeAgo from "../../TimeAgo";
import styles from "./Post.module.scss";

export const PostText = ({
  message,
  question,
  postId,
  originalMessage,
  createdAt,
  locale,
  shouldShowTime,
}) => {
  const dispatch = useDispatch();
  const textRef = useRef();
  const { t } = useContext(LocalizationContext);
  const language =
    useSelector((state) => state.users.entities?.language) ||
    navigator.language;

  const [isTruncated, setIsTruncated] = useState(false);
  const [lineHeight, setLineHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(message);
  const [isTranslated, setIsTranslated] = useState(false);

  const MAX_LINES = 3;

  const showHandler = (e) => {
    e.stopPropagation();
    textRef.current.style.display = "block";
    setIsTruncated(false);
  };

  const backToOriginal = () => {
    setCurrentMessage(originalMessage);
    setIsTranslated(false);
  };

  const translationHandler = () => {
    setLoading(true);
    postTranslate({
      type: "post",
      id: postId,
      destinationLanguage: language,
    })
      .then((data) => {
        setIsTranslated(true);
        setCurrentMessage(data.message);
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              subtitle: errorResponseMessages(err.data || err, t),
            })
          );
        }
        console.log(err?.data || err);
      })
      .finally(() => setLoading(false));
  };

  useLayoutEffect(() => {
    const textBlock = textRef.current;
    if (textBlock) {
      const computedStyle = window.getComputedStyle(textBlock);
      const lineHeight = parseFloat(computedStyle.lineHeight);
      setLineHeight(lineHeight);

      const lines = Math.floor(textBlock.offsetHeight / lineHeight);
      if (lines > MAX_LINES) {
        setIsTruncated(true);
      }
    }
  }, []);

  if (!currentMessage) return "";

  return (
    <Box className={styles.postsItemBody} onClick={showHandler}>
      {loading ? (
        <CircularProgress className={styles.loader} size={34} />
      ) : null}
      {question ? (
        <Typography
          variant="body4_groteskMedium"
          className={styles.pollQuestion}
        >
          {question}
        </Typography>
      ) : null}
      <Typography
        variant="subtitle1"
        ref={textRef}
        className={`${styles.postsItemMessage} ql-editor ${
          isTruncated ? styles.isTruncated : ""
        }`}
        style={{
          maxHeight: isTruncated ? `${MAX_LINES * lineHeight}px` : "none",
        }}
      >
        {formatPostMessage(currentMessage)}
      </Typography>
      <Box className={styles.postsItemBodyActionWrapper}>
        <Box className={styles.postsItemBodyAction}>
          {isTruncated && (
            <Button variant="text" className={styles.postsItemBodyActionBtn}>
              <Typography valiant="body6">{t("discover.seeMore")}</Typography>
            </Button>
          )}
          {!isTranslated ? (
            <Button
              variant="text"
              className={styles.postsItemBodyActionBtn}
              onClick={translationHandler}
            >
              <TranslationIcon />
              <Typography valiant="body6">
                {t("discover.seeTranslation")}
              </Typography>
            </Button>
          ) : (
            <Button
              variant="text"
              className={styles.postsItemBodyActionBtn}
              onClick={backToOriginal}
            >
              <TranslationIcon />
              <Typography valiant="body6">
                {t("discover.seeOriginal")}
              </Typography>
            </Button>
          )}
        </Box>
        {shouldShowTime ? (
          <Typography variant="body3" className={styles.postsItemTimestamp}>
            <TimeAgo value={createdAt} locale={locale} />
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};
