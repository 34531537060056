import { LoadingButton } from "@mui/lab";
import { Box, Input, Typography } from "@mui/material";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { EMULATE } from "constants/index";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "redux/modalSlice";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { isEmulationMode } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import UserPool from "services/userPool";

import DeleteAccountBirth from "./DeleteAccountBirth";
import styles from "./Modal.module.scss";

const DeleteAccountModal = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.entities);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleContinue = () => {
    const cognitoUser = new CognitoUser({
      Username: user.email,
      Pool: UserPool(),
    });

    if (isEmulationMode()) {
      console.error(EMULATE.ACCESS_ERROR);
    } else {
      setLoading(true);
      const authDetails = new AuthenticationDetails({
        Username: user.email,
        Password: value,
      });
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (data) => {
          setLoading(false);
          dispatch(openModal({ children: <DeleteAccountBirth /> }));
        },
        mfaRequired: (codeDeliveryDetails, params) => {
          setLoading(false);
          dispatch(openModal({ children: <DeleteAccountBirth /> }));
        },
        onFailure: (error) => {
          setLoading(false);
          setError(errorResponseMessages(error, t));
        },
      });
    }
  };

  return (
    <Box>
      <Typography variant="textNormal" className={styles.passwordTitle}>
        {t("settings.enterYourPassword")}
      </Typography>
      <Typography variant="textNormal" className={styles.passwordLabel}>
        {t("auth.password")}
      </Typography>
      <Input
        onChange={(e) => setValue(e.target.value)}
        className={styles.input}
        type="password"
      />

      <LoadingButton
        loading={loading}
        variant="contained"
        color="primary"
        onClick={handleContinue}
        className={styles.continueButton}
      >
        {t("auth.contunue")}
      </LoadingButton>
      {error && (
        <Typography variant="error" className={styles.passwordLabel}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default DeleteAccountModal;
