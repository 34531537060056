import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { CONNECTIONS_LOAD_LIMIT } from "constants/index";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { acceptFriendRequest, rejectFriendRequest } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

import { Level } from "../Level/Level";
import styles from "./FriendRequestItem.module.scss";

const FriendRequestItem = ({
  request,
  handleRedirect,
  setFriendRequests,
  getFriendsData,
  friendsOffset,
}) => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const [loader, setLoader] = useState(false);

  const handleRejectRequest = () => {
    setLoader(true);
    dispatch(rejectFriendRequest(request.id))
      .then(() => {
        if (setFriendRequests) {
          setFriendRequests((prevState) =>
            prevState.filter((c) => c.id !== request.id)
          );
        }
        if (getFriendsData) {
          getFriendsData(friendsOffset + CONNECTIONS_LOAD_LIMIT);
        }
      })
      .finally(() => setLoader(false));
  };

  const handleAcceptRequest = () => {
    setLoader(true);
    dispatch(acceptFriendRequest(request.id)).then(() => {
      if (setFriendRequests) {
        setFriendRequests((preState) =>
          preState.filter((c) => c.id !== request.id)
        );
      }
      if (getFriendsData) {
        getFriendsData(friendsOffset + CONNECTIONS_LOAD_LIMIT);
      }
      setLoader(false);
    });
  };

  return (
    <Box className={styles.requestItem}>
      {loader ? (
        <Box className={styles.loader}>
          <CircularProgress />
        </Box>
      ) : null}
      <Box className={styles.requestItemImageBox}>
        <img
          className={styles.requestItemImage}
          src={request.friend.userPicCroppedUrl}
          alt={request.friend.username}
          onClick={handleRedirect}
        />
        <Typography
          variant="body1"
          className={styles.requestItemName}
          onClick={handleRedirect}
        >
          <Box className={styles.requestItemNameValue}>
            {request.friend.username}
          </Box>
          <Box>
            <Level level={request.friend?.level} />
          </Box>
        </Typography>
      </Box>
      <Box className={styles.requestItemButtons}>
        <Button
          variant="contained"
          className={styles.requestItemBtn}
          onClick={handleAcceptRequest}
        >
          {t("account.addFriend")}
        </Button>
        <Button
          variant="contained"
          className={`${styles.requestItemBtn} ${styles.requestItemRejectBtn}`}
          onClick={handleRejectRequest}
        >
          {t("account.remove")}
        </Button>
      </Box>
    </Box>
  );
};

export default FriendRequestItem;
