import { Box, Typography } from "@mui/material";
import cardBg from "assets/img/card-bg.png";
import cardEpicBg from "assets/img/telegram/tg-gift-card-epic.png";
import cardLegendaryBg from "assets/img/telegram/tg-gift-card-legendary.png";
import cardMythicBg from "assets/img/telegram/tg-gift-card-mythic.png";
import cardRareBg from "assets/img/telegram/tg-gift-card-rare.png";
import classNames from "classnames/bind";

import styles from "./Card.module.scss";
export const Card = ({
  isActive,
  title,
  description,
  activeTitle,
  activeDescription,
  stylesCard,
  onClick,
  cardClassName,
  activeImgIndex = 0,
}) => {
  const cardClass = classNames(styles.card, {
    [cardClassName]: cardClassName,
  });

  const cardsBg = [cardLegendaryBg, cardEpicBg, cardRareBg, cardMythicBg];

  return (
    <Box className={cardClass} style={stylesCard} onClick={onClick}>
      <img alt="card" src={isActive ? cardsBg[activeImgIndex] : cardBg} />
      <Box className={styles.cardFooter}>
        <Typography className={styles.cardTitle} variant="textMediumBold2">
          {isActive ? activeTitle : title}
        </Typography>
        <Typography
          className={`${styles.cardDescription} opacity_07`}
          variant="textNormal"
        >
          {isActive ? activeDescription : description}
        </Typography>
      </Box>
    </Box>
  );
};
