import { Box, Typography } from "@mui/material";
import { ReactComponent as ArrowRight } from "assets/svg/arrow-right.svg";
import { PATHS } from "constants/index";
import { debounce } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import styles from "./CategoryRow.module.scss";

export const CategoryRow = ({
  title,
  category,
  isMore,
  children,
  restCount,
  rowContentClassName,
}) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const rowRef = useRef(null);

  const [rowHeight, setRowHeight] = useState(100);

  const resizeHandler = debounce(() => {
    setRowHeight(rowRef.current?.clientHeight);
  }, 200);

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    setRowHeight(rowRef.current?.clientHeight);

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  return (
    <Box className={styles.row} ref={rowRef}>
      <Box className={styles.rowTitleBox} style={{ width: `${rowHeight}px` }}>
        <Typography variant="textMediumBold2">{title}</Typography>
      </Box>
      <Box
        className={`${styles.rowContent} ${
          rowContentClassName ? rowContentClassName : ""
        }`}
      >
        {children}
        {isMore ? (
          <Box
            className={`${styles.rowContentItem} ${styles.rowContentItemEmpty}`}
            onClick={() =>
              navigate(PATHS.CHALLENGES_CATEGORY.replace(":category", category))
            }
          >
            {restCount >= 1 ? (
              <>
                {restCount > 3 ? (
                  <Box
                    className={`${styles.rowContentItemEmpty} ${styles.rowContentItemEmptyBox}`}
                  />
                ) : null}
                {restCount > 2 ? (
                  <Box
                    className={`${styles.rowContentItemEmpty} ${styles.rowContentItemEmptyBox}`}
                  />
                ) : null}
                <Box
                  className={`${styles.rowContentItemEmpty} ${styles.rowContentItemEmptyBox}`}
                />
              </>
            ) : null}
            <Typography
              variant="body2"
              className={styles.rowContentItemEmptyBoxText}
            >
              {t("challenges.viewMore", {
                count: restCount,
              })}
              <ArrowRight />
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
