import { api } from "./api.service";
const API_URL = process.env.REACT_APP_API_HOST;

export const sendFCMToken = async (params) => {
  try {
    return api.put(`${API_URL}/app-users/devices/${params.uuid}`, params);
  } catch (error) {
    console.error(error);
  }
};
