import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { dateLocales, FLOWS, TRANSFER_STATUSES } from "constants/";

import styles from "./TransfersCard.module.scss";

const TransfersCard = (props) => {
  const { status, description, createdAt, amount, paymentMethod, locale } =
    props;
  const { t } = useContext(LocalizationContext);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const translatedStatus = () => {
    switch (status) {
      case TRANSFER_STATUSES.approved:
        return t("orderdetails.approved");
      case TRANSFER_STATUSES.cancelled:
        return t("orderdetails.cancelled");
      case TRANSFER_STATUSES.pending:
        return t("orderdetails.pending");
      case TRANSFER_STATUSES.placed:
        return t("orderdetails.placed");
      case TRANSFER_STATUSES.processed:
        return t("orderdetails.processed");
      case TRANSFER_STATUSES.processing:
        return t("orderdetails.processing");
      case TRANSFER_STATUSES.rejected:
        return t("orderdetails.rejected");
      case TRANSFER_STATUSES.returned:
        return t("orderdetails.returned");
      case TRANSFER_STATUSES.reversed:
        return t("orderdetails.reversed");
      case TRANSFER_STATUSES.succeeded:
        return t("orderdetails.succeeded");
      case TRANSFER_STATUSES.success:
        return t("orderdetails.success");
      default:
        return status;
    }
  };

  return (
    <Box className={styles.transfersCardContainer}>
      <Box className={styles.titleContainer}>
        <Typography
          variant="textNormal"
          component={"p"}
          className={styles.paymentMethod}
        >
          {paymentMethod?.name}
        </Typography>
        <Typography
          variant="textNormal"
          component={"p"}
          className={styles.title}
        >
          {t("settings.status")}: {translatedStatus()}
        </Typography>
      </Box>
      <Box className={styles.descriptionContainer}>
        <Typography
          variant="textNormal"
          component={"p"}
          className={styles.description}
        >
          {description}
        </Typography>
        <Typography
          variant="textNormal"
          component={"p"}
          className={styles.amount}
        >
          {formatter.format(amount)}
        </Typography>
      </Box>

      <Typography variant="textNormal" component={"p"} className={styles.date}>
        {format(new Date(createdAt), "MM/dd/yyyy hh:mm", {
          locale: !!locale ? dateLocales[locale] : null,
        })}
      </Typography>
    </Box>
  );
};

export default TransfersCard;
