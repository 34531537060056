import { Box, Typography } from "@mui/material";
import { ReactComponent as Icon } from "assets/svg/icon-transactions.svg";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./EmptyOrders.module.scss";

export const EmptyOrders = () => {
  const { t } = useContext(LocalizationContext);

  return (
    <Box className={styles.noOrdersBlock}>
      <Icon className={styles.noOrdersIcon} />
      <Typography variant="h5" className={styles.emptyBlockText}>
        {t("drops.noOrders")}
      </Typography>
    </Box>
  );
};
