import { useWindowSize } from "utils/hooks";
const { useEffect } = require("react");

export const ViewportService = () => {
  const { height } = useWindowSize();
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, [height]);
  return null;
};
