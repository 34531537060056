import { api } from "./api.service";
import { getAccessToken } from "./auth.service";

const API_URL = process.env.REACT_APP_API_HOST;

export const getUser = () => {
  return api.get(`${API_URL}/app-users/me`).then((response) => response.data);
};

export const getUserZendeskJWT = () => {
  return api
    .post(`${API_URL}/app-users/me/zendesk-jwt`)
    .then((response) => response.data);
};

export const getUserProfile = () => {
  return api
    .get(`${API_URL}/app-users/profile`)
    .then((response) => response.data);
};

export const getNotifications = () => {
  return api
    .get(`${API_URL}/app-users/notifications/status`)
    .then((response) => response.data);
};

export const getWatchList = async () => {
  try {
    return api.get(`${API_URL}/app-users/me/watchlist`);
  } catch (error) {
    console.error(error);
  }
};

export const addToWatchList = async (cardId) => {
  try {
    await api.post(`${API_URL}/app-users/me/watchlist`, { cardId });
  } catch (error) {
    console.error("Cannot add the item to watch list", error);
  }
};

export const removeFromWatchList = async (cardId) => {
  try {
    await api.delete(`${API_URL}/app-users/me/watchlist/${cardId}`);
  } catch (error) {
    console.error("Cannot remove the item from watch list", error);
  }
};

export const getUserCard = async (params) => {
  try {
    return api.get(`${API_URL}/app-users/me/cards`, params);
  } catch (error) {
    console.error(error);
  }
};

export const getUserWallet = async () => {
  try {
    return api.get(`${API_URL}/app-users/me/wallet`);
  } catch (error) {
    console.error(error);
  }
};

export const updateUserTrophyCard = async (params) => {
  try {
    return api.put(`${API_URL}/app-users/me/trophy`, params);
  } catch (error) {
    console.error(error);
  }
};
export const generateS3Url = async (data) => {
  const params = {
    ...data,
    uploadType: "userpicOriginal",
  };
  try {
    return api.post(`${API_URL}/app-users/generate-upload-s3-url`, params);
  } catch (error) {
    console.error(error);
  }
};

export const updateUser = async (params) => {
  const result = await api.patch(`${API_URL}/app-users/profile`, params);
  return result;
};

export const getKycStatus = async () => {
  try {
    return api.get(`${API_URL}/kyc/status`);
  } catch (error) {
    console.error(error);
  }
};

export const checkEidCheck = async () => {
  try {
    return api.post(`${API_URL}/kyc/eidvcheck`);
  } catch (error) {
    console.error(error);
  }
};

export const sendVerifyEmail = async (params) => {
  const result = await api.post(`${API_URL}/app-users/me/email`, params);
  return result;
};

export const sendVerifyCode = async (params) => {
  try {
    const result = await api.post(
      `${API_URL}/app-users/me/verify-email`,
      params
    );
    return result;
  } catch (error) {
    console.error("sendVerifyCode", error);
    return error;
  }
};

export const getTransfers = async (params) => {
  try {
    const formattedParams = (() => {
      if (typeof params === "object" && params !== null) {
        const paramsArray = Object.entries(params).map(([k, v]) => `${k}=${v}`);
        return `?${paramsArray.join("&")}`;
      }
      return "";
    })();

    const result = await api.get(
      `${API_URL}/app-users/me/transfers${formattedParams}`
    );

    return result;
  } catch (error) {
    console.error(error);
  }
};

export const getEmailNotificationSettings = async () => {
  try {
    const response = await api.get(
      `${API_URL}/app-users/me/notifications-settings`
    );
    return response;
  } catch (error) {
    console.error("Error fetching email notification settings:", error);
    return error;
  }
};

export const updateEmailNotificationSettings = async (params) => {
  try {
    const response = await api.patch(
      `${API_URL}/app-users/me/notifications-settings`,
      params
    );
    return response;
  } catch (error) {
    console.error("Error updating email notification settings:", error);
    return error;
  }
};

export const getNotificationsData = (offset = 0, limit = 1) => {
  return api
    .get(`${API_URL}/app-users/notifications?offset=${offset}&limit=${limit}`)
    .then((response) => response.data);
};

export const markAsRead = () => {
  return api
    .post(`${API_URL}/app-users/notifications/read-all`)
    .then((response) => response.data);
};

export const markAsReadById = (notificationId) => {
  return api
    .post(`${API_URL}/app-users/notifications/${notificationId}/mark-as-read`)
    .then((response) => response.data);
};

export const getUserReferrals = () => {
  const params = {
    limit: 1000,
  };

  return api
    .get(`${API_URL}/app-users/me/referrals`, { params })
    .then((response) => response);
};

export const getUserReferralsInfluencers = () => {
  return api
    .get(`${API_URL}/app-users/me/referrals/influencers`)
    .then((response) => response);
};

export const searchUserBy = async (params) => {
  const formattedParams = (() => {
    const paramsArray = Object.entries(params).map(([k, v], i) => {
      return `${k}=${v}`;
    });

    const str = paramsArray.join("&");
    return str;
  })();

  return api
    .get(`${API_URL}/public/users/search?${formattedParams}`)
    .then((response) => response);
};

export const searchUserByUserName = async (username) => {
  try {
    return api
      .get(`${API_URL}/public/discover/user-id/${username}`)
      .then((response) => response);
  } catch (error) {
    console.error(error);
  }
};

export const getPublicUser = async (id) => {
  try {
    return api.get(`${API_URL}/public/users/${id}`);
  } catch (error) {
    console.error(error);
  }
};

export const updateCardProps = async (cardId, props) => {
  try {
    await api.put(`${API_URL}/app-users/me/cards/${cardId}/settings`, {
      ...props,
    });
  } catch (error) {
    console.error("Cannot toggle barter for this item", error);
  }
};

export const getUserConnections = async (params) => {
  try {
    return api.get(`${API_URL}/app-users/me/connections`, { params });
  } catch (error) {
    console.error(error);
  }
};

export const sendFriendRequest = async (userId) => {
  try {
    const res = await api.post(`${API_URL}/app-users/me/connections`, {
      userId,
    });
    return res;
  } catch (error) {
    console.error("Cannot add user to the connection list", error);
  }
};

export const removeUserConnection = async (connectionId) => {
  try {
    return await api.delete(`${API_URL}/connections/${connectionId}/delete`);
  } catch (error) {
    console.error("Cannot remove user from the connection list", error);
  }
};

export const getFriendRequests = async (params) => {
  try {
    return api.get(
      `${API_URL}/connections/requests?type=${params?.type}`,
      params
    );
  } catch (error) {
    console.error("Cannot get connections requests", error);
  }
};

export const getFriendRequestsIndicator = async () => {
  try {
    return api.get(`${API_URL}/app-users/me/connections/indicator`);
  } catch (error) {
    console.error("Cannot get friend requests indicator", error);
  }
};

export const setFriendRequestsIndicator = async () => {
  try {
    await api.post(`${API_URL}/app-users/me/connections/indicator`);
  } catch (error) {
    console.error("Cannot hide friend requests indicator", error);
  }
};

export const acceptUserFriendRequest = async (connectionId) => {
  try {
    await api.post(`${API_URL}/connections/${connectionId}/accept`);
  } catch (error) {
    console.error(
      "Cannot accept user request to add to the connection list",
      error
    );
  }
};

export const rejectUserFriendRequest = async (connectionId) => {
  try {
    await api.post(`${API_URL}/connections/${connectionId}/reject`);
  } catch (error) {
    console.error(
      "Cannot reject user request to add to the connection list",
      error
    );
  }
};

export const followInfluencer = async ({ influencerId }) => {
  try {
    const result = await api.post(
      `${API_URL}/influencer/${influencerId}/followers`
    );

    return result.data;
  } catch (error) {
    console.error("Follow influencer:", error);
  }
};

export const unFollowInfluencer = async ({ influencerId }) => {
  try {
    await api.delete(`${API_URL}/influencer/${influencerId}/followers`);
  } catch (error) {
    console.error("Unfollow influencer error:", error);
  }
};

export const getInfluencerFollowers = async ({
  influencerId,
  page = 1,
  limit = 100,
}) => {
  try {
    const result = await api.get(
      `${API_URL}/influencer/${influencerId}/followers?limit=${limit}&page=${page}`
    );

    return result.data;
  } catch (error) {
    console.error("Vip pass transaction error:", error);
  }
};

export const getUsersCommunities = async () => {
  try {
    const result = await api.get(`${API_URL}/me/communities`);

    return result.data;
  } catch (error) {
    console.error("Fetch user follows error:", error);
  }
};

export const deleteYourAccount = async () => {
  try {
    const result = await api.delete(`${API_URL}/app-users/me`);

    return result.data;
  } catch (error) {
    console.error("Delete user error:", error);
  }
};
export const rejectDeleteYourAccount = async () => {
  try {
    const result = await api.delete(`${API_URL}/app-users/me-delete`);

    return result.data;
  } catch (error) {
    console.error("Delete user error:", error);
  }
};

export const getUserFriendsById = async ({ id }) => {
  try {
    const result = await api.get(`${API_URL}/public/users/${id}/connections`);

    return result.data;
  } catch (error) {
    console.error("Fetch user friends error:", error);
  }
};

export const getFollowingByUserId = async ({ id }) => {
  try {
    const result = await api.get(`${API_URL}/public/users/${id}/following`);

    return result.data;
  } catch (error) {
    console.error("Fetch user friends error:", error);
  }
};

export const sendExternalWallet = async (params) => {
  try {
    const result = await api.post(
      `${API_URL}/app-users/me/external-wallet`,
      params
    );

    return result.data;
  } catch (error) {
    console.error("Fetch user friends error:", error);
  }
};

export const getBlacklist = async () => {
  try {
    const result = await api.get(`${API_URL}/blacklist`);

    return result.data;
  } catch (error) {
    console.error("Cannot get blacklist", error);
  }
};

export const blockUserById = async (userId) => {
  try {
    await api.post(`${API_URL}/blacklist`, { userId: userId });
  } catch (error) {
    console.error("Cannot block user", error);
  }
};

export const unblockUserById = async (userId) => {
  try {
    await api.delete(`${API_URL}/blacklist/${userId}`);
  } catch (error) {
    console.error("Cannot unblock user", error);
  }
};

export const withdrawCards = async (params) => {
  try {
    const result = await api.post(`${API_URL}/cards/withdraw-request`, params);

    return result.data;
  } catch (error) {
    console.error("Withdraw cards error:", error);
  }
};

export const updateZendeskUserInfo = async (params) => {
  try {
    const result = await api.put(`${API_URL}/app-users/me/zendesk`, params);

    return result.data;
  } catch (error) {
    console.error(error);
  }
};

export const getUserDevices = async () => {
  try {
    const result = await api.get(`${API_URL}/app-users/me/devices`);

    return result.data;
  } catch (error) {
    console.error("Cannot get user devices:", error);
  }
};

export const logoutAllUserDevices = async () => {
  const res = await api.post(`${API_URL}/app-users/me/devices`, {
    token: getAccessToken(),
  });
  return res.data;
};

export const getRewards = (params) => {
  return api
    .get(`${API_URL}/engagement/my-rewardpoints-history`, { params })
    .then((response) => response.data);
};

export const getBadges = async ({ offset = 0, limit = 100 }) => {
  try {
    const result = await api.get(
      `${API_URL}/engagement/my-badges?offset=${offset}&limit=${limit}`
    );
    return result.data;
  } catch (error) {
    console.error("Get badges error:", error);
  }
};

export const verifyPhone = async (params) => {
  const { data } = await api.post(
    `${API_URL}/app-users/me/verify-phone`,
    params
  );
  return data;
};

export const generateMFACode = (params) => {
  return api
    .post(`${API_URL}/app-users/me/2fa`, params)
    .then((response) => response?.data);
};
