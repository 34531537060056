import { Box, Button, Typography } from "@mui/material";
import AuthLeftSide from "components/AuthLeftSide";
import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import styles from "styles/auth/Auth.module.scss";
import { useIsMobile } from "utils/hooks";

const Success = () => {
  const { t } = useContext(LocalizationContext);

  const isMobile = useIsMobile();
  const location = useLocation();
  const { email } = location?.state || {};

  return (
    <Box className={styles.container}>
      {!isMobile && <AuthLeftSide step={0} />}
      <Box className={styles.rightSide}>
        <Box
          className={`${styles.rightSideContent} ${styles.forgotPasswordSuccess}`}
        >
          <Typography variant="h3" component="h1" className={styles.title}>
            {t("auth.passwordChanged")}
          </Typography>
          <Typography variant="body1">{t("auth.youCanLogin")}</Typography>
          <Button
            component={Link}
            to="/login"
            state={{ email }}
            variant="contained"
            className={styles.submitBtn}
          >
            {t("auth.logIn")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Success;
