import coin from "assets/img/telegram/airdrop-coin.png";
import coin1 from "assets/img/telegram/airdrop-coin-1.png";
import coin2 from "assets/img/telegram/airdrop-coin-2.png";
import coin3 from "assets/img/telegram/airdrop-coin-3.png";
import coin4 from "assets/img/telegram/airdrop-coin-4.png";
import coin5 from "assets/img/telegram/airdrop-coin-5.png";
import coin6 from "assets/img/telegram/airdrop-coin-6.png";
import coin7 from "assets/img/telegram/airdrop-coin-7.png";
import coin8 from "assets/img/telegram/airdrop-coin-8.png";
import coin9 from "assets/img/telegram/airdrop-coin-9.png";

export const images = [
  {
    src: coin,
    style: { top: "32%", left: "64%", width: "100px", height: "auto" },
  },
  {
    src: coin1,
    style: { top: "62%", left: "21%", width: "56px", height: "auto" },
  },
  {
    src: coin2,
    style: {
      top: "5%",
      left: "5.3%",
      width: "193px",
      height: "auto",
    },
  },
  {
    src: coin3,
    style: {
      top: "31.4%",
      left: "34%",
      width: "82px",
      height: "auto",
    },
  },
  {
    src: coin4,
    style: { top: 0, left: "auto", width: "161px", height: "auto", right: 0 },
  },
  {
    src: coin5,
    style: { top: "60.7%", left: "76%", width: "74px", height: "auto" },
  },
  {
    src: coin6,
    style: { top: "67%", left: "30%", width: "122px", height: "auto" },
  },
  {
    src: coin7,
    style: {
      top: "auto",
      left: "auto",
      width: "97px",
      height: "auto",
      right: 0,
      bottom: "5%",
    },
  },
  {
    src: coin8,
    style: {
      top: "19.4%",
      left: "71%",
      width: "73px",
      height: "auto",
    },
  },
  {
    src: coin9,
    style: {
      top: "81.5%",
      left: "56%",
      width: "59px",
      height: "auto",
    },
  },
];
