import { Button } from "@mui/material";
import React from "react";
import { v4 as uuidv4 } from "uuid";

import styles from "../OnboardingModal.module.scss";

export const Indicators = ({ data, activeSlide, setActiveSlideHandler }) => {
  return data.map((_, index) => (
    <Button
      className={`${styles.modalIndicatorButton} 
                ${
                  index === activeSlide ? styles.modalIndicatorButtonActive : ""
                }`}
      onClick={setActiveSlideHandler(index)}
      key={uuidv4()}
    />
  ));
};
