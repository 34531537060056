import { Box, Modal, Typography, IconButton } from "@mui/material";
import { ReactComponent as IconRedirect } from "assets/svg/icon-link.svg";
import { ReactComponent as IconClose } from "assets/svg/icon-close.svg";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import styles from "./PriacyPolicyModal.module.scss";

const AGREEMENTS_TYPE = {
  CIRCLE: "https://www.circle.com/en/legal",
  OPENNODE: "https://www.opennode.com/terms-and-conditions/",
};
const PrivacyPolicyModal = ({ open, onClose, privacyPolicyLink }) => {
  const { t } = useContext(LocalizationContext);
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.modal}>
        <Typography
          id="modal-modal-title"
          variant="h3"
          component="h2"
          className={styles.modalTitle}
        >
          {t("auth.modalThirdPartyTitle")}

          <IconButton className={styles.btnClose} onClick={onClose}>
            <IconClose />
          </IconButton>
        </Typography>
        <Typography
          variant="h6"
          component="a"
          href={AGREEMENTS_TYPE.CIRCLE}
          className={styles.modalLink}
          target="_blank"
        >
          {t("auth.modalCircleTC")}
          <IconRedirect />
        </Typography>
        <Typography
          variant="h6"
          component="a"
          href={AGREEMENTS_TYPE.OPENNODE}
          className={styles.modalLink}
          target="_blank"
        >
          {t("auth.modalOpennodeTC")}
          <IconRedirect />
        </Typography>
        <Typography
          variant="h6"
          component="a"
          href={privacyPolicyLink}
          className={styles.modalLink}
          target="_blank"
        >
          {t("auth.modalCompanyTC")}
          <IconRedirect />
        </Typography>
      </Box>
    </Modal>
  );
};
export default PrivacyPolicyModal;
