import { useEffect } from "react";
import { useExternalScript } from "utils/hooks/useExternalScript";
import { setError } from "redux/appSlice";
import { useDispatch } from "react-redux";
import { paypalCapture } from "services/api/paymentsApi";

const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const PaypalButton = ({ trackingId, onSuccess, onReady, onCancel }) => {
  const paypalScript = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&currency=USD&disable-funding=card`;
  const paypalState = useExternalScript(paypalScript);
  const dispatch = useDispatch();

  useEffect(() => {
    if (paypalState === "ready") {
      onReady && onReady();
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return trackingId;
          },
          onApprove: async (data, actions) => {
            try {
              const orderData = await paypalCapture(data?.orderID);
              const errorDetail =
                Array.isArray(orderData.details) && orderData.details[0];

              if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
                return actions.restart();
              }

              if (errorDetail) {
                if (orderData.debug_id) console.log(orderData.debug_id);
                return dispatch(
                  setError({
                    open: true,
                    subtitle: errorDetail.description,
                  })
                );
              }

              onSuccess(orderData);
            } catch (e) {
              dispatch(
                setError({
                  open: true,
                })
              );
              console.error(e);
              onCancel && onCancel();
            }
          },
          onError: (err) => {
            console.error("paypal error ", err);
          },
          onCancel: () => {
            onCancel && onCancel();
          },
        })
        .render("#paypal-button-container");
    }
  }, [paypalState]);

  return paypalState === "ready" ? (
    <div id="paypal-button-container"></div>
  ) : null;
};
export default PaypalButton;
