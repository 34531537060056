import * as Yup from "yup";

import { isAlphanumeric } from "./utils/validators";

Yup.addMethod(Yup.string, "validateLatinLetter", function (errorMessage) {
  return this.test(`is-valid-string`, errorMessage, function (value) {
    const result = /^[aA-zZ-\s]+$/i.test(value);
    return result;
  });
});

Yup.addMethod(Yup.string, "isAlphanumeric", isAlphanumeric);

export const YupCustomMethods = () => {
  return <></>;
};
