import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SearchBar } from "components/SearchBar/SearchBar";
import { CHATS_TYPE } from "constants/index";
import React, { useContext, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setActiveChat, setSelectedUser } from "redux/messengerSlice";
import { getSearchUser } from "services/api/messangerApi";
import { LocalizationContext } from "services/localizationContext";

import { ArrowLeftIcon } from "../../../../icons";
import { ResultItem } from "./components/ResultItem";
import styles from "./SearchContainer.module.scss";

export const SearchContainer = ({ classNameWrapper, setChatsType }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:840px)");
  const { t } = useContext(LocalizationContext);
  const refSearchWrapper = useRef();

  const [isResultOpened, setResultOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoader, setLoader] = useState(false);

  const handleSearch = (searchTerm) => {
    if (searchTerm?.trim()?.length) {
      getSearchUser({ limit: 30, searchTerm }).then((res) => setData(res));
    } else {
      setData([]);
    }
  };

  const setChatHandler = (data) => {
    setLoader(true);
    dispatch(setSelectedUser(data));
    setChatsType(CHATS_TYPE.MY);
  };

  const clickAwayBySearchResult = (e) => {
    if (
      refSearchWrapper.current &&
      refSearchWrapper.current.contains(e.target)
    ) {
      return;
    }
    setResultOpen(false);
  };

  return (
    <Box className={`${classNameWrapper} ${styles.container}`}>
      {isMobile ? (
        <Box className={styles.header}>
          <Button onClick={() => dispatch(setActiveChat(null))}>
            <ArrowLeftIcon />
          </Button>
          <Typography variant="h4_24">{t("messages.newMessage")}</Typography>
        </Box>
      ) : null}
      <Box className={styles.searchBox}>
        {isMobile ? (
          <Typography variant="body4_groteskMedium" style={{ lineHeight: 1 }}>
            {t("messages.to")}:
          </Typography>
        ) : (
          <Typography variant="h6">{t("messages.newMessageTo")}:</Typography>
        )}
        <Box className={styles.searchWrapper} ref={refSearchWrapper}>
          <SearchBar
            onSeacrhChanged={handleSearch}
            classNameWrapper={styles.search}
            isResultOpened={isResultOpened}
            setResultOpen={setResultOpen}
            classNameResult={styles.searchResult}
            isClearDataByOutClick={false}
            placeholder={t("default.searchPeople")}
            isFocus={true}
            onFocus={() => setResultOpen(true)}
          />
        </Box>
      </Box>
      {data?.length && isResultOpened ? (
        <Box className={styles.containerResult}>
          {isLoader ? (
            <Box className={styles.containerResultLoader}>
              <CircularProgress />
            </Box>
          ) : null}
          <ClickAwayListener onClickAway={clickAwayBySearchResult}>
            <Box>
              {data.map((item) => (
                <ResultItem
                  {...item}
                  setChatHandler={setChatHandler}
                  key={item.id}
                />
              ))}
            </Box>
          </ClickAwayListener>
        </Box>
      ) : null}
    </Box>
  );
};
