import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ReactComponent as ClearIcon } from "assets/svg/close-mobile-menu.svg";
import { ReactComponent as SearchIcon } from "assets/svg/discover/search-icon.svg";
import classNames from "classnames/bind";
import _ from "lodash";
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import roundedInputStyles from "styles/input/rounded.module.scss";

import styles from "./SearchBar.module.scss";

export const SearchBar = ({
  onSeacrhChanged,
  className,
  classNameWrapper,
  classNameInputAnimatedActive,
  isAnimate,
  isShowMainSearchIcon,
  children,
  classNameResult,
  isResultOpened,
  setResultOpen,
  isClearDataByOutClick = true,
  isShow = true,
  placeholder,
  isFocus,
  onFocus = () => {},
  setActiveCallback,
}) => {
  const location = useLocation();
  const { t } = useContext(LocalizationContext);
  const inputRef = useRef();
  const resultRef = useRef();

  const [searchTerm, setSearchTerm] = useState("");
  const [active, setActive] = useState(false);

  const cx = classNames.bind(styles);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedResults(value);
  };

  const debouncedResults = useMemo(
    () =>
      _.debounce((inputVal) => {
        onSeacrhChanged(inputVal);
      }, 500),
    []
  );

  const cancelActiveSearch = () => {
    if (isClearDataByOutClick) {
      setActive(false);
      if (setActiveCallback) {
        setActiveCallback(false);
      }
      setResultOpen(false);
      setSearchTerm("");
    }
  };

  const clearInput = () => {
    setSearchTerm("");
    onSeacrhChanged("");
  };

  const activeHandler = () => {
    setActive(true);
    if (setActiveCallback) {
      setActiveCallback(true);
    }
    if (inputRef.current) {
      const input = inputRef.current.querySelector("input");
      input.focus();
    }
  };

  useEffect(() => {
    cancelActiveSearch();
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, []);

  useEffect(() => {
    if (active) {
      document.body.classList.add("header-search-active");
    } else {
      document.body.classList.remove("header-search-active");
    }
  }, [active]);

  useLayoutEffect(() => {
    if (isResultOpened) {
      const { top, height } = inputRef.current.getBoundingClientRect();
      resultRef.current.style.top = `${top + height}px`;
    }
  }, [isResultOpened]);

  if (!isShow) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={cancelActiveSearch}>
      <Box>
        <Box className={cx(styles.search, classNameWrapper)}>
          <TextField
            className={cx(
              className,
              roundedInputStyles.input,
              styles.input,
              searchTerm.length > 0 && roundedInputStyles.filled,
              isAnimate ? styles.inputAnimated : "",
              isAnimate && active
                ? `${styles.inputAnimatedActive} ${
                    classNameInputAnimatedActive
                      ? classNameInputAnimatedActive
                      : ""
                  }`
                : ""
            )}
            type="search"
            id="search_bar"
            name="search_bar"
            value={searchTerm}
            onChange={handleChange}
            placeholder={placeholder || t("discover.searchLabel")}
            ref={inputRef}
            autoFocus={isFocus}
            onFocus={onFocus}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={styles.searchIcon} />
                </InputAdornment>
              ),
              endAdornment:
                searchTerm.length > 0 ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={clearInput}
                      className={styles.clearBtn}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
          />
          {(isAnimate && !active) || isShowMainSearchIcon ? (
            <IconButton onClick={activeHandler} className={styles.turnBarBtn}>
              <SearchIcon className={styles.searchIcon} />
            </IconButton>
          ) : null}
        </Box>
        {isResultOpened ? (
          <Box
            className={cx(styles.searchResult, classNameResult)}
            ref={resultRef}
          >
            {children}
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};
