import { Box, Button } from "@mui/material";
import { PATHS } from "constants";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import styles from "styles/auth/Auth.module.scss";

const AuthTabPanel = () => {
  const { t } = useContext(LocalizationContext);
  const location = useLocation();
  const routePath = location.pathname;

  return (
    <Box>
      <Box className={styles.tabPanel}>
        <Button
          component={Link}
          to={PATHS.SIGN_UP}
          variant={routePath === PATHS.SIGN_UP ? "contained" : "outlined"}
          className={styles.tabPanelBtn}
        >
          {t("auth.signUp")}
        </Button>
        <Button
          to={PATHS.LOGIN}
          component={Link}
          className={styles.tabPanelBtn}
          variant={routePath === PATHS.LOGIN ? "contained" : "outlined"}
        >
          {t("default.login")}
        </Button>
      </Box>
    </Box>
  );
};
export default AuthTabPanel;
