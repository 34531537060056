import React from "react";

export const SubscribeIcon = () => (
  <svg
    width="19"
    height="23"
    viewBox="0 0 19 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5919 11.3614C10.01 11.9433 9.20417 12.3078 8.31297 12.3078V13.6078C9.5661 13.6078 10.6979 13.0939 11.5111 12.2807L10.5919 11.3614ZM8.31297 12.3078C6.52762 12.3078 5.08767 10.8678 5.08767 9.08249H3.78766C3.78766 11.5858 5.80965 13.6078 8.31297 13.6078V12.3078ZM5.08767 9.08249C5.08767 7.29714 6.52762 5.85719 8.31297 5.85719V4.55719C5.80965 4.55719 3.78766 6.57917 3.78766 9.08249H5.08767ZM8.31297 5.85719C10.0983 5.85719 11.5383 7.29714 11.5383 9.08249H12.8383C12.8383 6.57917 10.8163 4.55719 8.31297 4.55719V5.85719ZM2.46627 21.7814C1.66258 19.5311 1.49809 18.2236 1.63773 17.5013C1.70231 17.1673 1.82475 16.9964 1.94857 16.8957C2.08198 16.7873 2.28606 16.7046 2.60193 16.6722C3.26517 16.6041 4.15558 16.776 5.20793 17.004C6.20272 17.2195 7.33519 17.4829 8.31298 17.4829V16.1829C7.50813 16.1829 6.53825 15.962 5.48316 15.7334C4.48564 15.5174 3.37865 15.2856 2.46916 15.379C1.99867 15.4273 1.52255 15.5666 1.12845 15.8871C0.724771 16.2153 0.472392 16.6803 0.361369 17.2545C0.149818 18.3487 0.430984 19.9478 1.242 22.2186L2.46627 21.7814ZM8.31298 17.4829C9.29076 17.4829 10.4232 17.2195 11.418 17.004C12.4704 16.776 13.3608 16.6041 14.024 16.6722C14.3399 16.7046 14.544 16.7873 14.6774 16.8957C14.8012 16.9964 14.9236 17.1673 14.9882 17.5013C15.1279 18.2236 14.9634 19.5311 14.1597 21.7814L15.384 22.2186C16.195 19.9478 16.4761 18.3487 16.2646 17.2545C16.1536 16.6803 15.9012 16.2153 15.4975 15.8871C15.1034 15.5666 14.6273 15.4273 14.1568 15.379C13.2473 15.2856 12.1403 15.5174 11.1428 15.7334C10.0877 15.962 9.11783 16.1829 8.31298 16.1829V17.4829ZM15.6066 0V2.74242H16.9066V0H15.6066ZM15.6066 2.74242V5.48483H16.9066V2.74242H15.6066ZM16.2566 2.09242H13.5142V3.39242H16.2566V2.09242ZM16.2566 3.39242H18.9991V2.09242H16.2566V3.39242Z"
      fill="white"
    />
  </svg>
);

export const SubscribedIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
      stroke="white"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M5.68359 10.9902L8.88242 14.564L16.8228 8.4375"
      stroke="white"
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
);

export const FireIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5332 15.0508C11.5965 14.7972 11.63 14.5329 11.63 14.2613C11.63 12.7951 10.4531 11.6128 9.37511 10.5297C9.33928 10.4938 9.30356 10.4579 9.268 10.4221L7.92003 9.06731L6.57205 10.4221C6.53797 10.4564 6.50373 10.4907 6.4694 10.5252C5.39018 11.6085 4.21001 12.7931 4.21001 14.2613C4.21001 14.895 4.39244 15.489 4.71096 16C2.21993 15.1756 0.5 13.3385 0.5 10.7178V10.6453C0.5 8.89909 1.60311 7.50247 3.28251 6.59558C4.88936 5.72826 5.83912 4.13048 5.60168 2.47334L5.13752 0L6.85731 0.655436C9.95476 1.8344 12.5345 3.88067 14.2064 6.48098C14.9416 7.61054 15.3352 8.92821 15.3401 10.2759V10.7178C15.3401 12.0056 14.9245 13.1046 14.2064 13.9868L11.5332 15.0508Z"
      fill="#F1FC70"
    />
  </svg>
);
