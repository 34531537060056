import { api } from "./api.service";
const API_URL = process.env.REACT_APP_API_HOST;

export const getSwipeItem = async (params) => {
  try {
    const formattedParams = (() => {
      const paramsArray = Object.entries(params)
        .filter(([k, v]) => k !== "barterCardId" && !!v?.length)
        .map(([k, v]) => {
          return `${k}=${v}`;
        });

      const str = paramsArray.join("&");
      return str;
    })();

    return api
      .post(
        `${API_URL}/barter/start${
          formattedParams ? "?" : ""
        }${formattedParams}`,
        params
      )
      .then((response) => response.data);
  } catch (e) {
    console.error("Cannot start barter", e);
  }
};

export const getMatches = async () => {
  try {
    const result = await api.get(`${API_URL}/barter/matches`);

    return result.data;
  } catch (error) {
    console.error("Cannot get matches", error);
  }
};

export const getMatch = async (id) => {
  try {
    const result = await api.get(`${API_URL}/barter/matches/${id}`);

    return result.data;
  } catch (error) {
    console.error("Cannot get match", error);
  }
};

export const approve = async (params) => {
  try {
    return api.post(`${API_URL}/barter/approve`, params);
  } catch (error) {
    console.error("Cannot approve match", error);
  }
};

export const reaction = async (params) => {
  try {
    const result = await api.post(
      `${API_URL}/barter/${params.cardId}/reaction`,
      params
    );

    return result.data;
  } catch (error) {
    console.error("Cannot make reaction", error);
  }
};

export const undo = async (params) => {
  try {
    const result = await api.post(
      `${API_URL}/barter/${params.cardId}/reaction-undo`,
      params
    );

    return result.data;
  } catch (error) {
    console.error("Cannot undo reaction", error);
  }
};

export const getInfluencersNames = async () => {
  try {
    const response = await api.get(`${API_URL}/barter/influencers-names`);
    return response.data;
  } catch (error) {
    console.error("Cannot get influencers names", error);
  }
};
