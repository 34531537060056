import { Box } from "@mui/material";
import { PATHS } from "constants/index";
import React from "react";

import BackBtn from "../Layout/BackBtn/BackBtn";
import ToggleButton from "../Layout/ToggleBtnAccent";
import styles from "./PhaseHeader.module.scss";

export const PhaseHeader = ({
  title,
  orderByList,
  orderBy,
  isLoading,
  setOrderBy,
}) => {
  return (
    <Box>
      <Box className={styles.header}>
        <BackBtn link={PATHS.DROPS} title={title} />
      </Box>
      <Box>
        <Box className={styles.sortBtnsWrap}>
          {orderByList.map((opt) => (
            <ToggleButton
              key={opt.value}
              value={opt.value}
              selected={orderBy === opt.value}
              disabled={isLoading}
              className={styles.sortBtn}
              onChange={() => {
                setOrderBy(opt.value);
              }}
            >
              {opt.text}
            </ToggleButton>
          ))}
          {/*TEMPORARY COMMENT*/}
          {/*{params.id === "0" && (*/}
          {/*  <ToggleButton*/}
          {/*    disabled={dropsStatus === "loading"}*/}
          {/*    value={reminderSet}*/}
          {/*    selected={!!reminderSet}*/}
          {/*    onChange={() => {*/}
          {/*      setReminderSet((prev) => (prev ? 0 : 1));*/}
          {/*    }}*/}
          {/*    className={styles.sortBtn}*/}
          {/*  >*/}
          {/*    {t("drops.reminderSetSelection")}*/}
          {/*  </ToggleButton>*/}
          {/*)}*/}
        </Box>
      </Box>
    </Box>
  );
};
