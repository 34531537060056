import { Box, Typography } from "@mui/material";
import { ReactComponent as GradeIcon } from "assets/svg/grade-icon.svg";
import { PATHS } from "constants/index";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import styles from "./Badge.module.scss";
import { BadgeImageBox } from "./BadgeImageBox";
export const Badge = ({
  imageUrl,
  stageNum,
  title,
  goalCount,
  wrapperClassName,
  challengeId,
}) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const clickHandler = () =>
    navigate(PATHS.CHALLENGES_ID.replace(":id", challengeId));

  return (
    <Box
      className={`${styles.badge} ${wrapperClassName ? wrapperClassName : ""}`}
      onClick={clickHandler}
    >
      <BadgeImageBox imageUrl={imageUrl} goalCount={goalCount} />
      <Typography variant="body2" className={styles.badgeStage}>
        <GradeIcon />
        {t("challenges.stage", { rank: stageNum })}
      </Typography>
      <Typography className={styles.badgeTitle} variant="body2">
        {title}
      </Typography>
    </Box>
  );
};
