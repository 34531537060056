import { Skeleton, Box } from "@mui/material";
import styles from "./Skeletons.module.scss";
import stylesMain from "views/Drops/DropDetail/DropDetail.module.scss";

const DropDetailSkeleton = ({ className }) => {
  return (
    <Box className={[styles.container, className]}>
      <Skeleton
        variant="rectangular"
        className={`${stylesMain.card} ${styles.card}`}
      />
      <Box className={`${stylesMain.details} ${styles.details}`}>
        <Skeleton variant="rectangular" className={styles.progress} />
        <Box className={styles.actions}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </Box>
      </Box>
    </Box>
  );
};
export default DropDetailSkeleton;
