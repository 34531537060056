import { Box, Skeleton, Typography } from "@mui/material";
import classNames from "classnames/bind";
import { ViewAll } from "components/ViewAll/ViewAll";

import styles from "./SlideBox.module.scss";
const cx = classNames.bind(styles);
export const SlideBox = ({
  title,
  children,
  isLoading,
  isShow,
  path,
  buttonText,
  className,
  subtitle,
}) => {
  if (isLoading) {
    return (
      <Skeleton variant="rectangular" className={styles.containerSkeleton} />
    );
  }

  if (!isShow && !isLoading) {
    return null;
  }

  return (
    <Box className={cx(styles.container, className)}>
      <Typography variant="h4" className={styles.containerTitle}>
        {title}
      </Typography>
      {subtitle ? (
        <Typography variant="body2" className={styles.containerSubtitle}>
          {subtitle}
        </Typography>
      ) : null}
      {children}
      {path ? (
        <Box className={styles.containerFooter}>
          <ViewAll text={buttonText} path={path} isArrow={true} />
        </Box>
      ) : null}
    </Box>
  );
};
