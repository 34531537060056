import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getNotifications,
  getNotificationsData,
  markAsRead,
  markAsReadById,
} from "services/userApi";

import { sendFCMToken } from "services/notificationsApi";
import { NOTIFICATION_STATUS } from "constants/notifications";

export const fetchNotificationsStatus = createAsyncThunk(
  "notifications/status",
  async () => {
    const response = await getNotifications();
    return response.numberUnreadNotifications;
  }
);
export const sentToken = createAsyncThunk(
  "device/send/token",
  async (params) => {
    const response = await sendFCMToken(params);
    return response.data;
  }
);

export const fetchNotificationsData = createAsyncThunk(
  "notifications/data",
  async ({ offset, limit }) => {
    const response = await getNotificationsData(offset, limit);
    return response;
  }
);

export const fetchMarkAsRead = createAsyncThunk(
  "notifications/markAsRead",
  async () => {
    const response = await markAsRead();
    return response.numberUnreadNotifications;
  }
);

export const fetchMarkAsReadById = createAsyncThunk(
  "notifications/markAsReadById",
  async (id) => {
    const response = await markAsReadById(id);
    return response.numberUnreadNotifications;
  }
);

const initialState = {
  loading: false,
  numberUnreadNotifications: 0,
  entities: [],
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    removeNotifications: (state, action) => {
      state.entities = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationsStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchNotificationsStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.numberUnreadNotifications = action.payload;
      })
      .addCase(fetchMarkAsRead.fulfilled, (state, action) => {
        state.numberUnreadNotifications = action.payload;
      })
      .addCase(fetchMarkAsReadById.fulfilled, (state, action) => {
        let found = state.entities.find((item) => item.id === action.meta.arg);
        if (found) found.status = NOTIFICATION_STATUS.SEEN;
        state.numberUnreadNotifications = action.payload;
      })
      .addCase(fetchNotificationsStatus.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchNotificationsData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchNotificationsData.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = [...state.entities, ...action.payload];
      })
      .addCase(fetchNotificationsData.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { removeNotifications } = notificationsSlice.actions;
export default notificationsSlice.reducer;
