import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as Icon } from "assets/svg/notifications.svg";
import { PATHS } from "constants";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    fontFamily: `"Clash Display", "Noto Sans JP", "Noto Sans KR", "Noto Sans SC", sans-serif`,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: 1,
    height: "23px",
    padding: "0px 7px",
    minWidth: "23px",
    borderRadius: "23px",
    top: "-2px",
    left: "7px",
  },
}));
export const NotificationsIcon = ({ className, text, ...rest }) => {
  const notifications = useSelector(
    (state) => state.notifications.numberUnreadNotifications
  );
  return (
    <Link to={PATHS.NOTIFICATIONS} className={className} {...rest}>
      <StyledBadge
        badgeContent={notifications}
        color="coral"
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Icon />
        {text}
      </StyledBadge>
    </Link>
  );
};
