import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";
import { Card } from "components/InfluencersRows/Groups/components/Card/Card";
import { SCROLL_THRESHOLD } from "constants/index";
import React, { useCallback, useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import { getInfluencers } from "services/discoverApi";
import { LocalizationContext } from "services/localizationContext";
import {
  initialDataState,
  initialLoaders,
  LEVEL_GROUP,
} from "utils/getFilteredInfluencers";

// import { handleSort, initialFilterState } from "../utils";
// import { Filter } from "../components/Filter/Filter";
import styles from "../../discover.module.scss";
import { EmptyStateIcon } from "../../icons";
import { SEARCH_PARAM } from "../../utils";
import { CategoriesList } from "../CategoriesList/CategoriesList";

export const DiscoverGroupsBody = () => {
  const { t } = useContext(LocalizationContext);
  const location = useLocation();

  const [data, setData] = useState(initialDataState);
  const [loaders, setLoaders] = useState(initialLoaders);
  const [offset, setOffset] = useState(0);
  const [category, setCategory] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  // const [showFilters, setShowFilters] = useState(false);
  // const [filter, setFilter] = useState({
  //   ...initialFilterState,
  //   orderBy: "createdAt",
  // });

  const isEmpty = !data.groups.length;

  const setCategoryHandler = (value) => {
    setCategory(value);
    setOffset(0);
  };

  const loadMore = () => setOffset((prevState) => prevState + 1);

  // const sort = () => {
  //   handleSort({
  //     filter,
  //     setLoaders,
  //     setData,
  //     setFilter,
  //     params: { limit: 25 },
  //     rest: { preventBigger: true, preventSmaller: true },
  //   });
  // };

  // useEffect(() => {
  //   getFilteredInfluencers({
  //     params: { ...filter, limit: 25 },
  //     setLoaders,
  //     setData,
  //     preventBigger: true,
  //     preventSmaller: true,
  //   });
  // }, []);

  const fetchInfluencers = useCallback(
    (value = null) => {
      setLoaders((prevState) => ({ ...prevState, groupsLoader: true }));
      getInfluencers({
        level: LEVEL_GROUP,
        limit: 25,
        category: value || category,
        ...(offset && { offset: 25 * offset }),
      })
        .then((res) => {
          if (res?.data) {
            setData((prevState) => ({
              ...prevState,
              groups: offset ? prevState.groups.concat(res.data) : res.data,
            }));
          }
          if (!res.data?.length) {
            setHasMore(false);
          } else {
            setHasMore(res.data.length % 25 === 0);
          }
        })
        .finally(() =>
          setLoaders((prevState) => ({ ...prevState, groupsLoader: false }))
        );
    },
    [category, offset]
  );

  useEffect(() => {
    if (offset > 0) {
      fetchInfluencers();
    }
  }, [fetchInfluencers, offset]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const value = searchParams.get(SEARCH_PARAM);
    fetchInfluencers(value);
    setCategory(value);
  }, [location.search]);

  return (
    <>
      {" "}
      {/*{showFilters ? (*/}
      {/*  <Filter*/}
      {/*    setFilter={setFilter}*/}
      {/*    data={filter}*/}
      {/*    getFilteredInfluencers={getFilteredInfluencers}*/}
      {/*    setShowFilters={setShowFilters}*/}
      {/*    setLoaders={setLoaders}*/}
      {/*    setData={setData}*/}
      {/*    preventBigger={true}*/}
      {/*    preventSmaller={true}*/}
      {/*  />*/}
      {/*) : null}*/}
      <CategoriesList
        setCategory={setCategoryHandler}
        category={category}
        loader={loaders.groupsLoader}
      />
      {loaders.groupsLoader && isEmpty ? (
        <Box className={styles.gridGroup}>
          <Skeleton
            variant="rectangular"
            className={styles.skeletonCardGroup}
          />
          <Skeleton
            variant="rectangular"
            className={styles.skeletonCardGroup}
          />
        </Box>
      ) : null}
      {isEmpty && !loaders.groupsLoader ? (
        <Box className={styles.emptyState}>
          <EmptyStateIcon />
          <Typography>{t("discover.discoverEmptyState")}</Typography>
        </Box>
      ) : (
        <InfiniteScroll
          dataLength={data.groups?.length}
          next={loadMore}
          hasMore={hasMore}
          scrollThreshold={SCROLL_THRESHOLD}
          scrollableTarget="scrollableEl"
          className={styles.gridGroup}
          loader={
            <Box className={styles.gridGroupLoader}>
              <CircularProgress />
            </Box>
          }
        >
          {data.groups.map((card) => (
            <Card card={card} key={card.id} />
          ))}
        </InfiniteScroll>
      )}
    </>
  );
};
