import { Box, Typography } from "@mui/material";
import { ReactComponent as GoalIcon } from "assets/svg/goal-icon.svg";
import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { LocalizationContext } from "services/localizationContext";

import { NextArrow, PrevArrow } from "../../../Slider/Arrow";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import styles from "./ChallengeCarousel.module.scss";

export const ChallengeCarousel = ({
  stages,
  challengeStage,
  code,
  progress,
  imageUrl,
  metadata,
}) => {
  const sliderRef = useRef(null);

  const { t } = useContext(LocalizationContext);

  const slideIndex = challengeStage?.stageNum ? challengeStage.stageNum - 1 : 0;

  const [sliderIndex, setSlideIndex] = useState(slideIndex);

  const allStages = stages
    .map((item) =>
      item?.stageNum === challengeStage?.stageNum ? challengeStage : item
    )
    .filter((item) => item?.id);

  const sliderSettings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    prevArrow:
      sliderIndex !== 0 ? (
        <PrevArrow
          onClick={() => sliderRef?.current.slickPrev()}
          arrowClassName={`${styles.arrow} ${styles.arrowPrev} nav-challenge-carousel`}
        />
      ) : (
        <></>
      ),
    nextArrow:
      sliderIndex < allStages.length - 1 ? (
        <NextArrow
          onClick={() => sliderRef?.current.slickNext()}
          arrowClassName={`${styles.arrow} ${styles.arrowNext} nav-challenge-carousel`}
        />
      ) : (
        <></>
      ),
    beforeChange: (_current, next) => setSlideIndex(next),
  };

  const getProgress = ({ currentStageNum, stageNum }) => {
    if (currentStageNum === stageNum) {
      return progress;
    }

    if (currentStageNum > stageNum) {
      return 100;
    }

    return 0;
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(slideIndex);
    }
  }, [sliderRef.current]);

  const Grade = ({ stageNum }) => (
    <Box className={`${styles.challengeSubtitleItem} ${styles.grade}`}>
      <Typography
        variant={"body3"}
        className={styles.challengeSubtitleItemValue}
      >
        {t("challenges.stage", { rank: stageNum })}
      </Typography>
    </Box>
  );

  if (!allStages?.length) {
    return null;
  }

  return (
    <Slider ref={sliderRef} {...sliderSettings} className={styles.slider}>
      {allStages.map(
        ({
          rewardPoints,
          metadata: { startCount, goalCount },
          stageNum,
          badge,
          id,
        }) => {
          return (
            <Box key={id}>
              <Box className={styles.slideWrapper}>
                <Box className={styles.challengeInfoWrap}>
                  <Box className={styles.challengeIconWrap}>
                    <img
                      className={styles.challengeIcon}
                      src={imageUrl}
                      alt={"img"}
                    />
                  </Box>
                  <Box className={styles.challengeInfo}>
                    <Typography
                      variant="body4"
                      className={styles.challengeName}
                    >
                      {t(`challenges.${code}-title`)}
                    </Typography>
                    <Box className={styles.challengeSubtitle}>
                      <Box className={styles.challengeSubtitleItem}>
                        <GoalIcon
                          className={styles.challengeSubtitleItemIcon}
                        />
                        <Typography
                          variant="body2"
                          className={styles.challengeSubtitleItemValue}
                        >
                          {t(`challenges.${code}-stage-goal`, {
                            count: goalCount,
                            useSingular: true,
                            ...(metadata?.name && {
                              influencerName: metadata.name,
                            }),
                          })}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className={`${styles.slide} ${styles.slideClub}`}>
                  <ProgressBar
                    startCount={startCount}
                    progress={getProgress({
                      currentStageNum: challengeStage?.stageNum,
                      stageNum,
                    })}
                    code={code}
                    goalCount={goalCount}
                    grade={<Grade stageNum={stageNum} />}
                    stageNum={stageNum}
                  />
                </Box>
              </Box>
            </Box>
          );
        }
      )}
    </Slider>
  );
};
