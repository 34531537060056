import { Box, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import { urlify } from "utils/index";

import styles from "./MessageItem.module.scss";

export const MessageItem = ({ text, incoming, className }) => {
  return (
    <Box
      className={`${className} ${styles.messageWrapper} ${
        !incoming ? styles.messageWrapperOutcoming : ""
      }`}
    >
      <Typography
        className={` ${styles.message} ${
          !incoming ? styles.messageOutcoming : ""
        }`}
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(urlify(text), {
            USE_PROFILES: { html: true },
            ADD_ATTR: ["target"],
          }),
        }}
      />
    </Box>
  );
};
