import { Avatar, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as Crown } from "assets/svg/feed/crown.svg";
import { ReactComponent as Pin } from "assets/svg/pin-icon.svg";
import { ActionTooltipPost } from "components/ActionTooltipPost/ActionTooltipPost";
import { Level } from "components/Level/Level";
import { ModalPostCreator } from "components/ModalPostCreator/ModalPostCreator";
import Tooltip from "components/Tooltip";
import { POST_STATUS } from "constants/index";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError } from "redux/appSlice";
import { deletePostInReview, updatePostSlice } from "redux/feedSlice";
import { deletePost } from "services/api/feedApi";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";
import { LEVEL_GROUP } from "utils/getFilteredInfluencers";
import { getInfluencerProfileLink } from "utils/index";

import styles from "./post-header.module.scss";

export const PostHeader = ({ post, language, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const pinnedPost = useSelector((state) => state.feed.pinnedPost);
  const level = useSelector((state) => state.influencer.entities?.level);
  const user = useSelector((state) => state.users.entities);
  const postsPendingReview = useSelector(
    (state) => state.influencer.entities?.community?.postsPendingReview
  );
  const [isLoading, setLoading] = useState(false);
  const [isOpenPostTooltip, setOpenPostTooltip] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);

  const influencerProfileLink = ({ id, handle }) => {
    return getInfluencerProfileLink({
      id,
      handle,
    });
  };

  const deletePostHandler = () => {
    setLoading(true);
    deletePost({ postId: id })
      .then(() => {
        dispatch(deletePostInReview({ postId: id }));
        navigate(
          influencerProfileLink({
            id: post?.influencer?.id,
            handle: post?.influencer?.handle,
          })
        );
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              subtitle: errorResponseMessages(err.data || err, t),
            })
          );
        }
        console.log(err?.data || err);
      })
      .finally(() => setLoading(false));
  };

  const updatePostHandler = (payload) => {
    return dispatch(
      updatePostSlice({
        ...payload,
        inReview: post?.status !== POST_STATUS.PUBLISHED,
      })
    )
      .then(() => {
        setOpenModal(false);
        navigate(
          influencerProfileLink({
            id: post?.influencer?.id,
            handle: post?.influencer?.handle,
          })
        );
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              subtitle: errorResponseMessages(err.data || err, t),
            })
          );
        }
        console.log(err?.data || err);
      });
  };

  const avatarSrc = post.isGroupOwner
    ? post?.influencer?.profileImageUrl
    : post?.appUser?.profileImageUrl;

  const name = post.isGroupOwner
    ? post?.influencer?.name
    : post?.appUser?.username;

  const isShowActionTooltip = postsPendingReview
    ? post?.status !== POST_STATUS.PUBLISHED
    : user?.appUserID === post?.appUserId && level === LEVEL_GROUP;

  const goToInfluencer = () =>
    navigate(
      influencerProfileLink({
        id: post?.influencer?.id,
        handle: post?.influencer?.handle,
      })
    );

  return (
    <>
      <Box className={styles.headerWrapper}>
        <Box className={styles.header}>
          <Avatar
            variant="square"
            src={avatarSrc}
            className={`${styles.avatar} ${
              post?.isGroupOwner ? styles.avatarCeleb : ""
            }`}
            sx={{
              bgcolor: theme.palette.surface.main,
            }}
            onClick={goToInfluencer}
          />
          <Box>
            <Typography variant="h6" className={styles.name}>
              <Box
                component="span"
                onClick={goToInfluencer}
                style={{
                  cursor: "pointer",
                }}
              >
                {name}
              </Box>
              {post?.isGroupOwner && level === LEVEL_GROUP ? (
                <Box className={styles.crownIcon} component="span">
                  <Tooltip title={t("default.groupOwner")}>
                    <Crown />
                  </Tooltip>
                </Box>
              ) : null}
              {!post?.isGroupOwner && level === LEVEL_GROUP ? (
                <Level level={post?.appUser?.level} />
              ) : null}
            </Typography>
            {/*<Typography variant="body3" className={styles.date}>*/}
            {/*  <TimeAgo value={post?.createdAt} locale={language} />*/}
            {/*</Typography>*/}
          </Box>
        </Box>
        <Box>
          {pinnedPost?.id === Number(id) ? (
            <Box>
              <Box className={styles.titlePinnedWrapper}>
                <Pin />
                <span className={styles.titlePinned}>
                  {t("discover.pinned")}
                </span>
              </Box>
            </Box>
          ) : null}
          {isShowActionTooltip ? (
            <ActionTooltipPost
              isLoading={isLoading}
              deleteHandler={deletePostHandler}
              open={isOpenPostTooltip}
              setOpen={setOpenPostTooltip}
              status={post?.status}
              editHandler={() => {
                setOpenModal(true);
                setOpenPostTooltip(false);
              }}
            />
          ) : null}
        </Box>
      </Box>
      <ModalPostCreator
        isOpen={isOpenModal}
        setOpenModal={setOpenModal}
        title={t("default.editPost")}
        onSubmitHandler={(payload) => updatePostHandler(payload)}
        influencerId={post?.influencer?.id}
        post={post}
        isUpdate={true}
      />
    </>
  );
};
