import { Box, Button } from "@mui/material";
import { ReactComponent as MessageIcon } from "assets/svg/message-icon.svg";
import { PATHS } from "constants/index";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedUser } from "redux/messengerSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "./CommentMessageTooltip.module.scss";

export const CommentMessageTooltip = (data) => {
  const { profileImageUrl, id } = data;
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const goToMessenger = () => {
    dispatch(setSelectedUser(data));
    navigate(PATHS.CHAT_DIRECT.replace(":userId", id));
  };

  return (
    <Box>
      <Box className={styles.containerTop}>
        <img src={profileImageUrl} alt="avatar" className={styles.avatar} />
        {/*{trophyCardURL ? (*/}
        {/*  <img*/}
        {/*    src={profileImageUrl}*/}
        {/*    alt="trophy-card"*/}
        {/*    className={styles.trophy}*/}
        {/*  />*/}
        {/*) : null}*/}
      </Box>
      <Button
        onClick={goToMessenger}
        className={styles.button}
        variant="outlined"
      >
        <MessageIcon />
        {t("messages.sendMessage")}
      </Button>
    </Box>
  );
};
