import { Box } from "@mui/material";
import React from "react";
import { v4 as uuidv4 } from "uuid";

import styles from "../OnboardingModal.module.scss";

export const Slides = ({ data, activeSlide }) => {
  return data.map((item, index) => (
    <Box
      key={uuidv4()}
      className={`${styles.modalSlide} ${
        index === activeSlide ? styles.modalSlideActive : ""
      }`}
    >
      {item}
    </Box>
  ));
};
