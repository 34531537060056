import { useStripe } from "@stripe/react-stripe-js";
import { MODAL_TYPE } from "constants";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setError, setLoader } from "redux/appSlice";
import { openModal } from "redux/modalSlice";
import { LocalizationContext } from "services/localizationContext";

export const PAYMENT_INTENT_STATUSES = {
  SUCCEEDED: "succeeded",
  PROCESSING: "processing",
  REQUIRES_PAYMENT_METHOD: "requires_payment_method",
};
const PaymentStatus = ({
  onClose,
  clientSecret,
  modalType = MODAL_TYPE.STRIPE_SUCCESS_MODAL,
  modalData,
  statusesTexts,
  onPaymentSuccess,
}) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const [paymentIntent, setPaymentIntent] = useState(null);

  const onSuccess = (sucessModalDesc) => {
    if (onPaymentSuccess) {
      onPaymentSuccess();
    } else {
      showSuccesModal(sucessModalDesc);
      onClose();
    }
  };

  const showSuccesModal = (description) => {
    dispatch(
      openModal({
        type: modalType,
        payload: {
          description,
          ...modalData,
        },
      })
    );
  };
  const handleIntent = () => {
    let sucessModalDesc = null;

    switch (paymentIntent.status) {
      case PAYMENT_INTENT_STATUSES.SUCCEEDED:
        sucessModalDesc =
          statusesTexts?.[PAYMENT_INTENT_STATUSES.SUCCEEDED] ||
          t("default.success");
        break;

      case PAYMENT_INTENT_STATUSES.PROCESSING:
        sucessModalDesc =
          statusesTexts?.[PAYMENT_INTENT_STATUSES.PROCESSING] ||
          t("subscription.subscribeProcessingDesc");
        break;

      case PAYMENT_INTENT_STATUSES.REQUIRES_PAYMENT_METHOD:
        dispatch(
          setError({
            open: true,
            subtitle: t("subscription.paymentFailed"),
          })
        );
        break;

      default:
        dispatch(setError({ open: true }));
        break;
    }
    if (sucessModalDesc) onSuccess(sucessModalDesc);
  };

  const getIntentStatus = () => {
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        setPaymentIntent(paymentIntent);
        dispatch(setLoader(false));
      })
      .catch((e) => {
        dispatch(setLoader(false));
      });
  };
  useEffect(() => {
    if (paymentIntent && (modalData || onPaymentSuccess)) handleIntent();
  }, [paymentIntent, modalData, onPaymentSuccess]);

  useEffect(() => {
    if (stripe && clientSecret) getIntentStatus();
  }, [clientSecret, stripe]);

  return null;
};

export default PaymentStatus;
