import { Box, Skeleton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ChallengeItem from "components/ChallengeItem";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchChallenges } from "redux/challengesSlice";
import {
  getChallengesCategorySelector,
  getChallengesLoadingSelector,
} from "redux/selectors/challengesSelector";
import { LocalizationContext } from "services/localizationContext";

import { ChallengeItemMobile } from "../../components/ChallengeItem/ChallengeItemMobile/ChallengeItemMobile";
import { PageLayout } from "../PageLayout/PageLayout";
import styles from "./ChallengeCategory.module.scss";

export const ChallengeCategory = () => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const params = useParams();
  const isMobile = useMediaQuery("(max-width:1024px)");

  const challenges = useSelector(
    getChallengesCategorySelector(params.category)
  );
  const loading = useSelector(getChallengesLoadingSelector);

  useEffect(() => {
    if (!challenges?.length && params.category) {
    }
    dispatch(fetchChallenges({ category: params.category }));
  }, []);

  return (
    <PageLayout title={t(`challenges.${params.category}`)} isBack={true}>
      <Box className={styles.list}>
        {loading && !challenges?.length ? (
          <>
            <Skeleton
              variant="rectangular"
              className={`${styles.item} ${styles.itemSkeleton}`}
            />
            <Skeleton
              variant="rectangular"
              className={`${styles.item} ${styles.itemSkeleton}`}
            />
            <Skeleton
              variant="rectangular"
              className={`${styles.item} ${styles.itemSkeleton}`}
            />
          </>
        ) : (
          challenges?.map((item) => (
            <Box key={item.id} className={styles.item}>
              {isMobile ? (
                <ChallengeItemMobile challenge={item} key={item.id} />
              ) : (
                <ChallengeItem challenge={item} key={item.id} />
              )}
            </Box>
          ))
        )}
      </Box>
    </PageLayout>
  );
};
