import { Box, Typography } from "@mui/material";
import styles from "./EmptyBlock.module.scss";
const EmptyBlock = ({ text, icon, bg }) => {
  return (
    <Box className={styles.placeholder} sx={{ background: bg || "none" }}>
      {icon && icon}
      <Typography variant="body2" className={styles.placeholderText}>
        {text}
      </Typography>
    </Box>
  );
};
export default EmptyBlock;
