import { Box, Typography } from "@mui/material";
import classNames from "classnames/bind";
import { PriceMovementIndicator } from "components/PriceTitle/PriceMovementIndicator";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import stylesGeneral from "./Price.module.scss";

export const Price = ({
  className,
  currencySymbol,
  priceMovement,
  price,
  overrideStyles,
}) => {
  const styles = overrideStyles || stylesGeneral;

  const cx = classNames.bind(styles);
  const { t } = useContext(LocalizationContext);
  return (
    <Box className={cx(className, styles.price)}>
      <Typography className={styles.priceTitle} variant="textNormal_14">
        {t("edition.price")}
      </Typography>
      <Box className={styles.priceContent}>
        <Typography
          variant="textMediumBold"
          className={styles.priceContentValue}
        >
          {currencySymbol}
          {price}
        </Typography>
        {/*<PriceMovementIndicator*/}
        {/*  priceMovement={priceMovement}*/}
        {/*  width={6}*/}
        {/*  height={12}*/}
        {/*  className={styles.priceContentIndicator}*/}
        {/*/>*/}
      </Box>
    </Box>
  );
};
