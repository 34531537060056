import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getInfluencers,
  getSeasons,
  getTrendingEditions,
} from "services/discoverApi";

export const fetchInfluencers = createAsyncThunk(
  "discover/feed",
  async (params) => {
    const response = await getInfluencers(params);
    return response.data;
  }
);

export const fetchTrendingEditions = createAsyncThunk(
  "discover/trending-cards",
  async (params) => {
    const response = await getTrendingEditions(params);
    return response.data;
  }
);

export const fetchSeasons = createAsyncThunk(
  "discover/get-seasons",
  async () => {
    const response = await getSeasons();
    return response.data;
  }
);

const initialState = {
  influencers: {
    loading: false,
    entities: [],
    isLastPage: false,
  },
  trendingEditions: {
    loading: false,
    entities: [],
  },
  seasons: [],
};

const discoverSlice = createSlice({
  name: "discover",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfluencers.pending, (state, action) => {
        state.influencers.loading = true;
      })
      .addCase(fetchInfluencers.rejected, (state, action) => {
        state.influencers.loading = false;
        state.influencers.isLastPage = true;
      })
      .addCase(fetchInfluencers.fulfilled, (state, action) => {
        state.influencers.loading = false;
        state.influencers.entities = action.payload;

        state.influencers.isLastPage =
          action.payload.allInfluencers.length < action?.meta?.arg?.limit;
      })

      .addCase(fetchTrendingEditions.pending, (state, action) => {
        state.trendingEditions.loading = true;
      })
      .addCase(fetchTrendingEditions.rejected, (state, action) => {
        state.trendingEditions.loading = false;
      })
      .addCase(fetchTrendingEditions.fulfilled, (state, action) => {
        state.trendingEditions.loading = false;
        state.trendingEditions.entities = action.payload;
      })
      .addCase(fetchSeasons.fulfilled, (state, action) => {
        state.seasons = action.payload;
      });
  },
});

export default discoverSlice.reducer;
