import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Typography } from "@mui/material";
import { ReactComponent as AddFriendIcon } from "assets/svg/add-friend-icon.svg";
import { ReactComponent as CheckedIcon } from "assets/svg/checked-icon.svg";
import { ReactComponent as FriendIcon } from "assets/svg/friend-icon.svg";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./UserListItem.module.scss";

const UserListItem = ({
  user,
  onClick,
  handleAddFriend,
  handleRemoveFriend,
  isMe,
}) => {
  const { t } = useContext(LocalizationContext);

  const [loading, setLoading] = useState(false);

  const isFriends = user.connectionData?.connection?.status === "accepted";
  const pendingConnection = user.connectionData?.pendingConnection;

  const addHandler = (e) => {
    e.stopPropagation();
    setLoading(true);
    handleAddFriend(user.id).finally(() => setLoading(false));
  };

  const removeHandler = (e) => {
    e.stopPropagation();
    setLoading(true);
    if (user.connectionData?.connection) {
      handleRemoveFriend({
        connectionId: user.connectionData.connection.id,
        id: user.id,
      }).finally(() => setLoading(false));
    }
  };

  const FriendButton = () => {
    if (isFriends) {
      return (
        <LoadingButton
          className={styles.userItemButton}
          variant="outlined"
          onClick={removeHandler}
          loading={loading}
          disabled={loading}
        >
          <FriendIcon className={styles.userItemButtonIcon} />
          {t("challenges.friends")}
        </LoadingButton>
      );
    }
    if (pendingConnection) {
      return (
        <LoadingButton
          className={styles.userItemButton}
          variant="outlined"
          onClick={removeHandler}
          loading={loading}
          disabled={loading}
        >
          <CheckedIcon
            className={`${styles.userItemButtonIcon} ${styles.userItemButtonIconRequested}`}
          />
          {t("challenges.requested")}
        </LoadingButton>
      );
    }

    return (
      <LoadingButton
        className={styles.userItemButton}
        variant="contained"
        onClick={addHandler}
        loading={loading}
        disabled={loading}
      >
        <AddFriendIcon className={styles.userItemButtonIcon} />
        {t("challenges.addFriend")}
      </LoadingButton>
    );
  };

  if (isMe) {
    return null;
  }

  return (
    <Box className={styles.userItem} onClick={onClick}>
      <Box className={styles.userItemInfo}>
        <img
          className={styles.userItemAvatar}
          src={user.userPicCroppedUrl}
          alt={user.username}
        />
        <Box className={styles.userItemText}>
          <Typography variant="body5">{user.username}</Typography>
          {user.stageNum ? (
            <Typography variant="body2" className={styles.userItemTextStage}>
              {t("challenges.stage", { rank: user.stageNum })}
            </Typography>
          ) : null}
        </Box>
      </Box>
      <FriendButton />
    </Box>
  );
};

export default UserListItem;
