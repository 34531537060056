import { CardsSlider } from "components/CardsSlider/CardsSlider";
import { Card } from "components/NewCard";
import { PATHS } from "constants";
import { EDITION_STATUSES } from "constants";
import { CARD_VARIANTS } from "constants/card";
import React from "react";

export const SeasonSlider = ({ seasonCards, cardProps = {} }) => {
  return (
    <CardsSlider>
      {seasonCards.map((data, index) => {
        const card = {
          ...data,
          ...data?.cardMetadata,
          createdAt: data?.createdAt,
          ...cardProps,
        };
        const isReleased = [
          EDITION_STATUSES.complete,
          EDITION_STATUSES.phase2,
        ].includes(data?.status);
        return (
          <Card
            variant={CARD_VARIANTS.SMALL}
            data={data && card}
            link={data?.id ? PATHS.CARD_ID.replace(":id", data.id) : null}
            key={index}
            isGrayedOut={isReleased && !data?.holdings}
          />
        );
      })}
    </CardsSlider>
  );
};
