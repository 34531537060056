import classnames from "classnames/bind";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

const ActiveLink = ({
  children,
  className,
  activeClass,
  to,
  matchEnd = true,
  isExternal = false,
  ...props
}) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: matchEnd });
  const classNames = classnames(match && !isExternal && activeClass, className);

  return (
    <Link className={classNames} to={to} {...props}>
      {children}
    </Link>
  );
};
export default ActiveLink;
