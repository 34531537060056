import { Box, Typography } from "@mui/material";
import classnames from "classnames/bind";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { getInfluencerProfileLink } from "utils";

import styles from "./InfluencerLink.module.scss";

export const InfluencerLink = ({ influencer, className }) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const cx = classnames.bind(styles);
  const location = useLocation();

  if (!influencer) {
    return null;
  }
  const influencerProfileLink = getInfluencerProfileLink({
    id: influencer?.id,
    handle: influencer?.handle,
  });
  const toInfluencerId = () =>
    navigate(influencerProfileLink, {
      state: { backRoute: location.pathname },
    });
  return (
    <Box className={cx(styles.creator, className)} onClick={toInfluencerId}>
      <Typography variant="body2" className={styles.creatorLabel}>
        {t("default.from")}
      </Typography>
      <Box className={styles.creatorImg}>
        <img
          src={influencer.profileImageUrl}
          alt={t("default.influencerAvatar")}
        />
      </Box>
      <Typography className={styles.creatorName} variant="body5">
        {influencer.name}
      </Typography>
    </Box>
  );
};
