import React, { useState, useContext, useEffect } from "react";
import { LocalizationContext } from "services/localizationContext";
import { Box, Typography } from "@mui/material";
import SeparateDateInput from "components/SeparateDateInput";
import { openModal } from "redux/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { isValid } from "date-fns";
import DeleteAccountWallet from "./DeleteAccountWallet";

import styles from "./Modal.module.scss";

const transformDate = (date) => {
  if (!isValid(new Date(date)) || !date) return null;

  const newBirthday = date.split("/");

  return `${newBirthday[2]}-${newBirthday[0]}-${newBirthday[1]}`;
};

const DeleteAccountBirth = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.entities);
  const me = useSelector((state) => state.users.me.entities);
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!user.dateOfBirth) {
      dispatch(
        openModal({
          styles: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 350,
            bgcolor: "#111322",
            border: "2px solid #21264E",
            boxSizing: "border-box",
            borderRadius: "10px",
            padding: "16px",
          },
          children: <DeleteAccountWallet />,
        })
      );
    }
  }, []);

  const handleContinue = () => {
    setLoading(true);
    if (!isValid(new Date(value)) || !value) {
      setError(t("error.invalidDate"));
      setLoading(false);
      return false;
    }

    const birth = transformDate(value);

    if (birth == user.dateOfBirth) {
      setLoading(false);
      dispatch(
        openModal({
          styles: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 350,
            bgcolor: "#111322",
            border: "2px solid #21264E",
            boxSizing: "border-box",
            borderRadius: "10px",
            padding: "16px",
          },
          children: <DeleteAccountWallet />,
        })
      );
    } else {
      setError(t("error.invalidBirthday"));
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="textNormal" className={styles.passwordTitle}>
        {t("settings.enterDateOfBirth")}
      </Typography>
      <Box className={styles.birthWrapper}>
        <SeparateDateInput onChange={(value) => setValue(value)} />
      </Box>

      <LoadingButton
        loading={loading}
        variant="contained"
        color="primary"
        onClick={handleContinue}
        className={styles.continueButton}
      >
        {t("auth.contunue")}
      </LoadingButton>
      {error && (
        <Typography variant="error" className={styles.passwordLabel}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default DeleteAccountBirth;
