import { Box, Button, Skeleton, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames/bind";
import { CategoryRow } from "components/CategoryRow/CategoryRow";
import ChallengeItem from "components/ChallengeItem";
import { ChallengeItemMobile } from "components/ChallengeItem/ChallengeItemMobile/ChallengeItemMobile";
import { ChallengeItemMobileTg } from "components/ChallengeItem/ChallengeItemMobileTg/ChallengeItemMobileTg";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchChallenges } from "redux/challengesSlice";
import {
  getChallengesLoadingSelector,
  getChallengesSelector,
} from "redux/selectors/challengesSelector";
import { LocalizationContext } from "services/localizationContext";
import { useTelegram } from "utils/hooks/useTelegram";

import styles from "./Challenges.module.scss";

export const Challenges = () => {
  const isMobile = useMediaQuery("(max-width:1024px)");
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const { user: tgUser } = useTelegram();

  const challenges = useSelector(getChallengesSelector);
  const loading = useSelector(getChallengesLoadingSelector);
  const challengesArray = Object.entries(challenges);

  const [activeCategory, setActiveCategory] = useState("all");

  const setCategoryHandler = (value) => () => setActiveCategory(value);

  const listMobileClass = classNames(styles.listMobile, {
    [styles.listMobileTg]: tgUser,
  });

  useEffect(() => {
    dispatch(fetchChallenges());
  }, []);

  return (
    <Box className={styles.list}>
      {loading && !challengesArray.length ? (
        <>
          <Skeleton variant="rectangular" className={styles.rowSkeleton} />
          <Skeleton variant="rectangular" className={styles.rowSkeleton} />
        </>
      ) : isMobile ? (
        <>
          {!tgUser ? (
            <Typography variant="h5_medium" className={styles.listTitle}>
              {t("challenges.challengesHub")}
            </Typography>
          ) : null}
          <Box className={`${styles.filter} ${tgUser ? styles.filterTg : ""}`}>
            <Button
              variant="outlined"
              key="all"
              className={`${styles.filterBtn} ${
                activeCategory === "all" ? styles.filterBtnActive : ""
              }`}
              onClick={setCategoryHandler("all")}
            >
              {t("default.allStr")}
            </Button>
            {challengesArray.map((item) => (
              <Button
                variant="outlined"
                key={item[0]}
                className={`${styles.filterBtn} ${
                  activeCategory === item[0] ? styles.filterBtnActive : ""
                }`}
                onClick={setCategoryHandler(item[0])}
              >
                {t(`challenges.${item[0]}`)}
              </Button>
            ))}
          </Box>
          <Box className={listMobileClass}>
            {activeCategory === "all"
              ? challengesArray.map((item) =>
                  item[1].map((challenge) =>
                    tgUser ? (
                      <ChallengeItemMobileTg
                        challenge={challenge}
                        key={challenge.id}
                      />
                    ) : (
                      <ChallengeItemMobile
                        challenge={challenge}
                        key={challenge.id}
                      />
                    )
                  )
                )
              : challenges[activeCategory]?.map((challenge) =>
                  tgUser ? (
                    <ChallengeItemMobileTg
                      challenge={challenge}
                      key={challenge.id}
                    />
                  ) : (
                    <ChallengeItemMobile
                      challenge={challenge}
                      key={challenge.id}
                    />
                  )
                )}
          </Box>
        </>
      ) : (
        <>
          {challengesArray.map((item) => {
            const sliceData =
              item[1].length > 6 ? item[1].slice(0, 5) : item[1];
            const isMore = item[1].length > 6;
            const restCount = item[1]?.length - sliceData?.length;

            return (
              <CategoryRow
                title={t(`challenges.${item[0]}`)}
                key={item[0]}
                category={item[0]}
                isMore={isMore}
                restCount={restCount}
              >
                {sliceData.map((item) => (
                  <Box key={item.id} className={styles.rowContentItem}>
                    <ChallengeItem challenge={item} key={item.id} />
                  </Box>
                ))}
              </CategoryRow>
            );
          })}
        </>
      )}
    </Box>
  );
};
