import { Box, Typography } from "@mui/material";
import AuthLeftSide from "components/AuthLeftSide";
import AuthTabPanel from "components/AuthTabPanel";
import { LanguageSelect } from "components/LanguageSelect/LanguageSelect";
import LoginForm from "components/LoginForm";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import styles from "styles/auth/Auth.module.scss";
import { useIsMobile } from "utils/hooks";

// import { googleAuth } from "../../utils/auth";

const Login = () => {
  const { t } = useContext(LocalizationContext);
  const isMobile = useIsMobile();

  return (
    <>
      <Box className={`${styles.container} ${styles.signContainer}`}>
        {!isMobile && <AuthLeftSide className={styles.authLeftSide} />}
        <Box className={`${styles.rightSide} ${styles.signRightSide}`}>
          {!isMobile ? <LanguageSelect className={styles.langSelect} /> : null}
          <Box
            className={`${styles.rightSideContent} ${styles.signRightSideContent}`}
          >
            <Box className={styles.signRightSideContentBox}>
              <Typography variant="h3" component="h1" className={styles.title}>
                {t("auth.loginTitle")}
              </Typography>
              <Typography variant="subtitle1" className={styles.subtitle}>
                {t("auth.loginDescription")}
              </Typography>
              <AuthTabPanel />
              <LoginForm />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Login;
