import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as BARTER_API from "services/barterApi";

export const fetchSwipeItem = createAsyncThunk(
  "barter/start",
  async (params) => {
    const response = await BARTER_API.getSwipeItem(params);
    return response;
  }
);

export const fetchMatches = createAsyncThunk("barter/matches", async () => {
  const response = await BARTER_API.getMatches();
  return response;
});

export const fetchMatch = createAsyncThunk("barter/match", async (id) => {
  const response = await BARTER_API.getMatch(id);
  return response;
});

export const approveMatch = createAsyncThunk(
  "barter/approve",
  async (params) => {
    const response = await BARTER_API.approve(params);
    return response;
  }
);

export const reaction = createAsyncThunk("barter/reaction", async (params) => {
  const response = await BARTER_API.reaction(params);
  return response;
});

export const undoReaction = createAsyncThunk("barter/undo", async (params) => {
  const response = await BARTER_API.undo(params);
  return response;
});

export const fetchInfluencersNames = createAsyncThunk(
  "barter/influencers-names",
  async () => {
    const response = await BARTER_API.getInfluencersNames();
    return response;
  }
);

const initialState = {
  loading: false,
  influencersNames: {
    entities: [],
    loading: false,
  },
  matches: {
    entities: [],
    loading: false,
  },
  swipedCardIds: [],
};

const barterSlice = createSlice({
  name: "barter",
  initialState,
  reducers: {
    setSwipedCardIds: (state, action) => {
      state.swipedCardIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfluencersNames.pending, (state, action) => {
        state.influencersNames.loading = true;
      })
      .addCase(fetchInfluencersNames.rejected, (state, action) => {
        state.influencersNames.loading = false;
      })
      .addCase(fetchInfluencersNames.fulfilled, (state, action) => {
        state.influencersNames.loading = false;
        state.influencersNames.entities = action.payload;
      })
      .addCase(fetchMatches.pending, (state, action) => {
        state.matches.loading = true;
      })
      .addCase(fetchMatches.rejected, (state, action) => {
        state.matches.loading = false;
      })
      .addCase(fetchMatches.fulfilled, (state, action) => {
        state.matches.loading = false;
        state.matches.entities = action.payload;
      });
  },
});

export const { setSwipedCardIds } = barterSlice.actions;
export default barterSlice.reducer;
