import "styles/slider/index.scss";

import { useTheme } from "@emotion/react";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ActivityStatistics from "components/ActivityStatistics";
import Tokens from "components/Card/Tokens";
import { DescriptionCardHeader } from "components/DescriptionCard/components/DescriptionCardHeader";
import { DescriptionCard } from "components/DescriptionCard/DescriptionCard";
import { SeasonSlider } from "components/SeasonSlider/SeasonSlider";
import { CHANNELS, EVENTS, ORDER_TYPES } from "constants/index";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateCardTokens } from "redux/cardSlice";
import {
  fetchEditionActivity,
  fetchEditionData,
  fetchEditionOrders,
} from "redux/editionSlice";
import { getCardSelector, getCardTokens } from "redux/selectors/cardSelectors";
import { fetchUserEditions } from "redux/usersSlice";
//TODO::commented code related to Barter
// import { getEditionOwners } from "services/api/publicApi";
import { LocalizationContext } from "services/localizationContext";
import PusherService from "services/Pusher.service";
import { getUserCard } from "services/userApi";
import { formatEditionOrders } from "utils";

import { CARD_TYPES } from "../../constants/card";
// import BarterEdition from "./BarterEdition";
import styles from "./edition.module.scss";
import CardSeason from "./Season";
import { SellCards } from "./SellCards";

const Edition = ({
  handleBuyBtnClick,
  onSell,
  checking,
  status,
  setBuyAlert,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const [editionOrders, setEditionOrders] = useState({
    tokens: [],
    amountCards: 0,
  });
  const [sellCardsLoader, setSellCardsLoader] = useState(false);
  const influencer = status?.card?.influencer;
  const [currencySymbol, setCurrensySymbol] = useState(null);
  const [seasonNum, setSeasonNum] = useState(null);
  const [season, setSeason] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [isOwnersLoading, setOwnersLoading] = useState(false);
  const [activity, setActivity] = useState(null);
  const editionId = +params.id;
  // const [editionOwners, setEditionOwners] = useState([]);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("editionMobile"));
  const isInCollection = status?.assetHoldings && status?.assetHoldings > 0;

  const userMe = useSelector((state) => state.users.me.entities);
  const card = useSelector(getCardSelector);
  const inCollectionCard = useSelector(getCardTokens);

  const unmounted = useRef(false);
  useEffect(() => {
    if (status) {
      const { card } = status;
      setSellCardsLoader(true);
      dispatch(fetchEditionOrders({ id: editionId, type: ORDER_TYPES.sell }))
        .then((res) => {
          if (res?.payload) {
            const amount = card?.quantityAllocated || 0;
            const formatRes = formatEditionOrders(res.payload, amount);
            setEditionOrders({
              amountCards: formatRes.amountCards,
              tokens: formatRes.tokens,
            });
          }
        })
        .finally(() => setSellCardsLoader(false));
    }
  }, [editionId, status]);

  useEffect(() => {
    const channelCards = PusherService.subscribe(CHANNELS.CARDS);
    channelCards.bind(EVENTS.PRICE_CHANGED, (data) => {
      dispatch(fetchUserEditions());
    });
    channelCards.bind(EVENTS.BUY, (data) => {
      dispatch(fetchUserEditions());
    });
  }, []);

  useEffect(() => {
    if (!editionId) return;
    setIsLoading(true);
    dispatch(fetchEditionData(editionId))
      .unwrap()
      .then((d) => {
        if (unmounted.current) return;
        setSeasonNum(d.seasonNum);
        setCurrensySymbol(d?.influencer?.currencySymbol);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        !unmounted.current && setIsLoading(false);
      });

    return () => {
      unmounted.current = true;
    };
  }, [editionId]);

  useEffect(() => {
    const getMyCardData = async () => {
      try {
        const res = await getUserCard({ params: { cardId: editionId } });
        const formatRes = formatEditionOrders(
          res?.data,
          status?.card?.quantityAllocated
        );
        dispatch(updateCardTokens(formatRes));
      } catch (e) {
        console.error(e);
      }
    };
    isInCollection ? getMyCardData() : dispatch(updateCardTokens(null));
  }, [isInCollection, editionId]);

  useEffect(() => {
    dispatch(fetchEditionActivity({ id: editionId }))
      .unwrap()
      .then((d) => {
        !unmounted.current && setActivity(d);
      });
    // getOwners(editionId);
  }, [editionId, userMe?.id]);

  const cardIsNotComplete = status?.card?.status !== "complete";
  // const isSellBtnDisabled = !isInCollection || !status?.assetHoldings;
  // const totalHoldings =
  //   currencySymbol && inCollectionCard
  //     ? `${currencySymbol}${inCollectionCard?.overallValue}`
  //     : "";

  const onBuy = () => {
    if (editionOrders && editionOrders.tokens && editionOrders.tokens[0]) {
      const token = editionOrders.tokens[0];
      handleBuyBtnClick({
        ...token,
        price: token.purchasePrice || token.price,
        total: editionOrders?.amountCards,
        currencySymbol,
        allTokens: editionOrders.tokens,
      });
    } else {
      handleBuyBtnClick();
    }
  };

  // const getOwners = async (cardId) => {
  //   try {
  //     setOwnersLoading(true);
  //     const res = await getEditionOwners({ cardId, userId: userMe?.id });
  //     if (!unmounted.current) {
  //       const owners = !!userMe?.id
  //         ? res.filter(
  //             (owner) => owner.id !== userMe?.id && owner.availableForBarter
  //           )
  //         : [];
  //       setEditionOwners(owners);
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   } finally {
  //     !unmounted.current && setOwnersLoading(false);
  //   }
  // };

  const MainInfo = () => {
    return !status?.card ? (
      <Skeleton
        className={`${styles.mainInfo} ${styles.skeletonBg}`}
        variant="rectangular"
      />
    ) : (
      <Box className={styles.mainInfo}>
        <img
          className={styles.mainImage}
          src={status?.card?.imageUrl}
          alt={influencer?.name}
        />
      </Box>
    );
  };

  const MyCards = () =>
    inCollectionCard && isInCollection ? (
      <Box className={styles.myCards}>
        <Tokens
          data={inCollectionCard}
          currencySymbol={currencySymbol}
          onSubmit={onSell}
          loading={checking}
          subTitle={
            card?.type === CARD_TYPES.CUSTOM
              ? t("discover.editionNumber")
              : null
          }
        />
      </Box>
    ) : null;

  const descriptionEl = (
    <DescriptionCard
      className={styles.seasonDescription}
      buyAlertHandler={() => setBuyAlert(true)}
      title={season?.title || card?.name}
      {...card}
      currencySymbol={currencySymbol}
      latestPrice={status?.card?.latestPrice}
      marketTokens={editionOrders}
      isMobile={isMobile}
    />
  );
  return (
    <>
      <Box className={styles.container}>
        <>
          <Box className={styles.content}>
            {isMobile ? (
              <DescriptionCardHeader
                title={card.name}
                seasonNum={seasonNum}
                influencer={influencer}
                saleTokensLeft={card?.saleTokensLeft}
                className={styles.cardHeader}
                isMobile={true}
              />
            ) : null}
            <Box className={styles.leftSide}>
              {<MainInfo />}
              {!status?.card || isLoading ? (
                <Skeleton
                  className={`${styles.buttonsBlock} ${styles.skeletonBg}`}
                  variant="rectangular"
                  sx={{
                    height: 54,
                  }}
                />
              ) : (
                <Box className={styles.buttonsBlock}>
                  {editionOrders?.tokens?.length ? (
                    <Button
                      variant="contained"
                      disabled={cardIsNotComplete}
                      className={styles.buttonsBlockBtn}
                      onClick={onBuy}
                    >
                      {t("discover.buy")}
                    </Button>
                  ) : null}
                </Box>
              )}
              {!isMobile ? <MyCards /> : null}
              {!isMobile ? (
                <SellCards
                  id={editionId}
                  handleBuyBtnClick={handleBuyBtnClick}
                  data={editionOrders}
                  loading={sellCardsLoader}
                  currencySymbol={currencySymbol}
                  defaultExpanded={inCollectionCard?.tokens?.length}
                />
              ) : null}
            </Box>
            <Box className={styles.rightSide}>
              {isMobile ? <MyCards /> : null}
              {card?.type === CARD_TYPES.CUSTOM ? (
                <>
                  {descriptionEl}
                  {card.otherCards?.length ? (
                    <Box className={styles.usersSwapBlock}>
                      <Typography
                        variant="h5_medium"
                        className={styles.title}
                        component="h2"
                      >
                        {`${t("drops.otherCardsFrom")} ${card.influencer.name}`}
                      </Typography>
                      <SeasonSlider
                        seasonCards={card.otherCards}
                        cardProps={{ description: null }}
                      />
                    </Box>
                  ) : null}
                </>
              ) : null}
              {card?.type !== CARD_TYPES.CUSTOM ? (
                <>
                  <CardSeason
                    seasonNum={seasonNum}
                    editionId={editionId}
                    influencer={influencer}
                    currencySymbol={currencySymbol}
                    latestPrice={status?.card?.latestPrice}
                    marketTokens={editionOrders}
                    saleTokensLeft={card?.saleTokensLeft}
                    isMobile={isMobile}
                    onGetSeason={(val) => setSeason(val)}
                  />
                </>
              ) : null}
              {/*{!isInCollection ? (*/}
              {/*  <BarterEdition*/}
              {/*    editionId={editionId}*/}
              {/*    holders={editionOwners}*/}
              {/*    isLoading={isOwnersLoading}*/}
              {/*  />*/}
              {/*) : null}*/}

              {!activity ? (
                <Skeleton
                  className={`${styles.accordionWrap} ${styles.skeletonBg}`}
                  variant="rectangular"
                  sx={{
                    height: 83,
                  }}
                />
              ) : (
                <ActivityStatistics
                  isEditionColumnEnabled={false}
                  data={activity}
                />
              )}
            </Box>
          </Box>
        </>
      </Box>
    </>
  );
};

export default Edition;
