export const InfoIcon = ({ svgStrokeColor }) => (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_30360_20118)">
      <path
        d="M6.5 12.5C9.53757 12.5 12 10.0376 12 7C12 3.96243 9.53757 1.5 6.5 1.5C3.46243 1.5 1 3.96243 1 7C1 10.0376 3.46243 12.5 6.5 12.5Z"
        stroke={svgStrokeColor || "#111322"}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 3.94189V8.58023"
        stroke={svgStrokeColor || "#111322"}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 9.70646V9.57812"
        stroke={svgStrokeColor || "#111322"}
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_30360_20118">
        <rect width="13" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
