import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./ShowMoreRepliesButton.module.scss";

const ArrowIcon = () => (
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 4.5H4C2.5 4.5 1 4.7 1 7.5V9M11 4.5L7.5 1M11 4.5L7.5 8"
      stroke="#70E8FC"
      strokeWidth="1.3"
    />
  </svg>
);

export const ShowMoreRepliesButton = ({
  isNested,
  commentsLengts,
  parentRepliesCount,
  loadingReplyIds,
  setReplyPage,
  selectedReply,
}) => {
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);

  if (isNested) {
    return parentRepliesCount > commentsLengts ? (
      <LoadingButton
        variant="text"
        loading={loadingReplyIds.includes(selectedReply?.id)}
        onClick={() => setReplyPage((prevState) => prevState + 1)}
        className={styles.btn}
      >
        <Typography
          variant="body2"
          style={{ color: theme.palette.accent.secondary }}
        >
          <ArrowIcon />
          {t("discover.showMoreReplies")}
        </Typography>
      </LoadingButton>
    ) : null;
  }
  return null;
};
