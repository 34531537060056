import { Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MultipleTutorialTooltip } from "components/MultipleTutorialTooltip/MultipleTutorialTooltip";
import { TUTORIALS_PROPS } from "constants/index";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserSeenTutorials } from "redux/selectors/usersSelectors";
import { updateUserEntities, updateUserProfile } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";
import { LEVEL_GROUP } from "utils/getFilteredInfluencers";

import styles from "./CommunityInfo.module.scss";

export const CommunityInfo = ({ community, isFollowing, level }) => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const isMd = useMediaQuery("(max-width:1024px)");

  const seenTutorials = useSelector(getUserSeenTutorials);

  const tutorialUpdate = (params) => () => {
    dispatch(updateUserEntities(params));
    dispatch(updateUserProfile(params));
  };

  const tutorialSkip = () => {
    const params = {
      seenTutorials: {
        ...(seenTutorials && seenTutorials),
        [TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_1]: true,
        [TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_2]: true,
      },
    };
    dispatch(updateUserEntities(params));
    dispatch(updateUserProfile(params));
  };

  if (!community || isFollowing) {
    return null;
  }

  return (
    <Box
      className={`${styles.info} ${
        level === LEVEL_GROUP ? styles.infoGroup : ""
      }`}
    >
      <Box>
        <MultipleTutorialTooltip
          isOpen={!seenTutorials?.[TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_1]}
          title={t("default.tutorialCreatorTitle1")}
          subTitle={t("default.tutorialCreatorSubTitle1")}
          skipButtonText={t("default.skip")}
          nextButtonText={`${t("default.start")} (1/2)`}
          isArrow={true}
          placement={isMd ? "bottom" : "right"}
          next={tutorialUpdate({
            seenTutorials: {
              ...(seenTutorials && seenTutorials),
              [TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_1]: true,
            },
          })}
          skip={tutorialSkip}
          tooltipClassName={styles.imgTooltip}
        >
          <Typography variant="body1Display" className={styles.infoTitle}>
            {isFollowing
              ? t("discover.communityInfoTitle")
              : t("discover.joinCommunityTitle")}
          </Typography>
        </MultipleTutorialTooltip>
        <Box className={styles.infoStats}>
          <Box className={styles.infoStatsItem}>
            <Typography variant="h5" className={styles.infoStatsNumber}>
              {community?.numFollowers}
            </Typography>
            <Typography variant="body2">{t("discover.fans")}</Typography>
          </Box>
          <Box className={styles.infoStatsItem}>
            <Typography variant="h5" className={styles.infoStatsNumber}>
              {community?.numPosts}
            </Typography>
            <Typography variant="body2">
              {community?.numPosts > 1
                ? t("discover.posts")
                : t("discover.post")}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Typography variant="body1" className={styles.infoDesc}>
        {community?.description}
      </Typography>
      {!!community?.perks?.length && (
        <Box className={styles.infoPerks}>
          <Typography variant="body1Display" className={styles.infoPerksTitle}>
            {t("discover.perksTitle")}
          </Typography>
          <ul className={styles.infoPerksList}>
            {community?.perks.map((item, index) => (
              <li className={styles.infoPerksListItem} key={index}>
                <Typography variant="body1">{item}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
};
