import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as HederaIcon } from "assets/svg/hedera-icon.svg";
import { ReactComponent as LogoIcon } from "assets/svg/logo.svg";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import styles from "./AuthLeftSide.module.scss";

const AuthLeftSide = ({ className }) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Box className={`${styles.leftSide} ${className}`}>
      <Box className={styles.leftSideContent}>
        <IconButton component={Link} to="/" className={styles.logoWrap}>
          <LogoIcon className={styles.logo} />
        </IconButton>
        <Box className={`${styles.bottomBLock} auth-left-side-bottom-block`}>
          <Typography variant="body3" className={styles.bottomBLockText}>
            {t("auth.poweredBy")}
          </Typography>
          <HederaIcon className={styles.bottomBLockIcon} />
        </Box>
      </Box>
    </Box>
  );
};

export default AuthLeftSide;
