import React, { useContext, useRef, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LocalizationContext } from "services/localizationContext";

import styles from "styles/auth/Auth.module.scss";
import roundedInputStyles from "styles/input/rounded.module.scss";

import { ReactComponent as VisibilityOff } from "assets/svg/visibility-off.svg";
import { ReactComponent as Visibility } from "assets/svg/visibility.svg";

const NewPassword = ({ onSubmit }) => {
  const { t } = useContext(LocalizationContext);
  const ref = useRef(null);
  const refConfirm = useRef(null);

  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("error.required"))
      .min(8, t("error.shortText"))
      .matches(/\d/, t("error.shouldContainNumbers"))
      .matches(/^(?=(.*[a-z]){1})/, t("error.shouldContainLowercaseLetter"))
      .matches(/^(?=(.*[A-Z]){1})/, t("error.shouldContainUppercaseLetter"))
      .matches(
        /^(?=(.*[!@#$%^&*()_+\-=\[\]{};~`':"\\|,.<>\/?]){1})/,
        t("error.shouldContainSpecialCharacter")
      ),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("auth.passwordsDontMatch")
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onPasswordSubmit = (data) => {
    onSubmit(data.password);
  };

  return (
    <Box>
      <Typography variant="h3" component="h1" className={styles.title}>
        {t("auth.createNewPassword")}
      </Typography>
      <form
        onSubmit={handleSubmit(onPasswordSubmit)}
        autoComplete="off"
        className={styles.form}
      >
        <FormControl>
          <TextField
            label={t("auth.nwPassword")}
            error={!!errors.password}
            className={`${roundedInputStyles.input} ${
              roundedInputStyles.cyan
            } ${!!passwordValue.length ? roundedInputStyles.filled : ""}`}
            inputProps={{
              ref: ref,
              onChange: () => setPasswordValue(ref.current?.value || ""),
            }}
            autoComplete="off"
            type={showPassword ? "text" : "password"}
            autoFocus
            {...register("password")}
          />
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowPassword(!showPassword)}
            className={roundedInputStyles.showPasswordToggle}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
          {!!errors.password ? (
            <Typography variant="error" sx={{ mt: 1 }}>
              {errors.password?.message}
            </Typography>
          ) : null}
        </FormControl>

        <FormControl>
          <TextField
            label={t("auth.confirmNewPassword")}
            error={!!errors.confirm_password}
            className={`${roundedInputStyles.input} ${
              roundedInputStyles.cyan
            } ${
              !!confirmPasswordValue.length ? roundedInputStyles.filled : null
            }`}
            inputProps={{
              ref: refConfirm,
              onChange: () =>
                setConfirmPasswordValue(refConfirm.current?.value || ""),
            }}
            autoComplete="off"
            type={showPassword ? "text" : "password"}
            {...register("confirm_password")}
          />
          {!!errors.confirm_password ? (
            <Typography variant="error" sx={{ mt: 1 }}>
              {errors.confirm_password?.message}
            </Typography>
          ) : null}
        </FormControl>
        <FormControl>
          <Button
            type="submit"
            variant="contained"
            className={styles.submitBtn}
            disabled={!!errors.password || !!errors.confirm_password}
          >
            {t("auth.createNewPassword")}
          </Button>
        </FormControl>
      </form>
    </Box>
  );
};
export default NewPassword;
