import { Box, CircularProgress } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Card } from "components/NewCard";
import { CARD_VARIANTS } from "constants/card";
import { EDITION_STATUSES, PATHS } from "constants/index";
import { SCROLL_THRESHOLD } from "constants/index";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import styles from "./LimitedEdition.module.scss";

export const LimitedEdition = ({ cards, loadMore, hasMore }) => {
  const isSm = useMediaQuery("(max-width:460px)");

  const [isFull, setFull] = useState(true);

  const isReleased = (status) => {
    return [EDITION_STATUSES.complete, EDITION_STATUSES.phase2].includes(
      status
    );
  };

  return (
    <>
      <Box className={styles.box}>
        {/*<Box className={styles.header}>*/}
        {/*  <Typography variant="h5_medium" className={styles.title}>*/}
        {/*    {t("drops.limitedEditionCardsTitle")}*/}
        {/*  </Typography>*/}
        {/*  {isFull ? (*/}
        {/*    <ViewAll*/}
        {/*      handler={() => setFull(false)}*/}
        {/*      text={t("default.viewLess")}*/}
        {/*    />*/}
        {/*  ) : (*/}
        {/*    <ViewAll handler={() => setFull(true)} />*/}
        {/*  )}*/}
        {/*</Box>*/}
        {/*{!isFull ? <SeasonSlider seasonCards={cards} /> : null}*/}
        {isFull ? (
          <InfiniteScroll
            dataLength={cards.length}
            next={loadMore}
            hasMore={hasMore}
            loader={
              <Box className={styles.loader}>
                <CircularProgress size={24} />
              </Box>
            }
            scrollThreshold={SCROLL_THRESHOLD}
            scrollableTarget="scrollableEl"
          >
            <Box className={styles.wrapper}>
              {cards.map((item) => {
                return (
                  <Card
                    data={{
                      ...item,
                      description: null,
                      dropStartDateFormat: isSm ? "dd MMM" : null,
                    }}
                    variant={CARD_VARIANTS.SMALL}
                    key={item.id}
                    overrideStyles={{ root: styles.card, img: styles.img }}
                    isImg={true}
                    link={
                      item?.id ? PATHS.CARD_ID.replace(":id", item.id) : null
                    }
                    isGrayedOut={isReleased(item.status) && !item?.holdings}
                  />
                );
              })}
            </Box>
          </InfiniteScroll>
        ) : null}
      </Box>
    </>
  );
};
