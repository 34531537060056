import { format, isThisYear, isToday } from "date-fns";

export function getFormattedDate(dateData) {
  if (!dateData) return "---";
  const date = new Date(dateData);

  const year = date.getFullYear();
  const monthNumber = date.getMonth() + 1;
  const day = date.getDate();

  return `${monthNumber}/${day}/${year}`;
}

export function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;

  return strTime;
}

export function convertSecondsToDDHHMMSS(seconds) {
  if (!seconds) return "---";
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const days = d > 0 ? d + " d " : "";
  const hours = h > 0 ? h + " h " : "";
  const minutes = m > 0 ? m + " min " : "";
  const sec = s > 0 ? s + " sec" : "";
  return days + hours + minutes + sec;
}

export const formatDate = (d) => new Date(d).setHours(0, 0, 0, 0);

export function isDateInArray(date, arr) {
  const d = new Date(date);
  for (var i = 0; i < arr.length; i++) {
    if (formatDate(d) === formatDate(arr[i])) {
      return true;
    }
  }
  return false;
}

export const walletFormatDate = (date, t) => {
  if (isToday(date)) {
    return `${t("default.today")} ${format(date, "HH:mm")}`;
  } else if (isThisYear(date)) {
    return `${format(date, "MMM d")}`;
  } else {
    return `${format(date, "MMM d yyyy")}`;
  }
};
