import { Box, Typography } from "@mui/material";
import IconAvatar from "assets/svg/avatar-icon.svg";
import { ReactComponent as IconPoints } from "assets/svg/points-icon.svg";
import { PATHS } from "constants/index";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getProfile,
  getUsersMeLevel,
  getUsersMeRewardPoints,
} from "redux/selectors/usersSelectors";
import { isAuthenticated } from "services/auth.service";

import { Level } from "../../../Level/Level";
import styles from "./ProfileBox.module.scss";
export const ProfileBox = () => {
  const navigate = useNavigate();
  const rewardPoints = useSelector(getUsersMeRewardPoints);
  const level = useSelector(getUsersMeLevel);
  const profile = useSelector(getProfile);

  const isLoggedIn = isAuthenticated();
  const avatar = (isLoggedIn && profile.userPicCroppedUrl) || IconAvatar;

  const goTo = (path) => () => navigate(path);

  return (
    <Box className={styles.profile} onClick={goTo(PATHS.PROFILE)}>
      <img alt="avatar" src={avatar} className={styles.profileAvatar} />
      <Box className={styles.profileInfo}>
        <Typography variant="body3" className={styles.profileInfoTitle}>
          <IconPoints className={styles.profileInfoIcon} />
          {rewardPoints}
        </Typography>
        <Level level={level} isLine={true} />
      </Box>
    </Box>
  );
};
