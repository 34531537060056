import { Box, Skeleton, Typography } from "@mui/material";
import { ReactComponent as FlameIcon } from "assets/svg/flame-icon.svg";
import { ReactComponent as IconQuestionMark } from "assets/svg/question-icon.svg";
import Tooltip from "components/Tooltip";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchChallengeDetails } from "redux/challengesSlice";
import { getChallengeDetails } from "redux/selectors/challengesSelector";
import {
  getUsersActiveDaysStreak,
  getUsersLoading,
} from "redux/selectors/usersSelectors";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";

import { StreakDay } from "./components/StreakDay/StreakDay";
import styles from "./LoginStreak.module.scss";

export const LoginStreak = ({ wrapperClassName }) => {
  const { t } = useContext(LocalizationContext);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const activeDaysStreak = useSelector(getUsersActiveDaysStreak);
  const isLoading = useSelector(getUsersLoading);
  const data = useSelector(getChallengeDetails(7));

  const [dataLoading, setDataLoading] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const goalCount =
    data?.challenge?.appUserTrack?.challengeStage?.metadata?.goalCount || 0;

  const days = [
    t("default.sundayAbbr"),
    t("default.mondayAbbr"),
    t("default.tuesdayAbbr"),
    t("default.wednesdayAbbr"),
    t("default.thursdayAbbr"),
    t("default.fridayAbbr"),
    t("default.saturdayAbbr"),
  ];

  const getDay = ({ arr, index, currentIndex }) => {
    const today = new Date();
    const startDayIndex = new Date(
      today.setDate(today.getDate() - currentIndex)
    ).getDay();

    return arr[(startDayIndex + index) % arr.length];
  };

  useEffect(() => {
    if (!data?.challenge?.id) {
      setDataLoading(true);
      dispatch(fetchChallengeDetails({ id: 7 })).finally(() =>
        setDataLoading(false)
      );
    }
  }, []);

  return (
    <Box className={wrapperClassName ? wrapperClassName : ""}>
      {(isLoading || dataLoading) && !data?.challenge?.id ? (
        <Skeleton variant="rectangular" className={styles.skeletonRect} />
      ) : (
        <Box className={styles.stats}>
          <FlameIcon className={styles.statsIcon} />
          <Box className={styles.statsTextBlock}>
            <Typography variant="h5_medium" className={styles.statsTitle}>
              {activeDaysStreak || 0}
              <Typography variant="body2" className={` opacity_07`}>
                {t("barter.totalAssets", { count: goalCount })}
              </Typography>
            </Typography>
            <Typography variant="body1" className={styles.statsSubtitle}>
              {t("challenges.loginStreak")}
            </Typography>
          </Box>
          <Tooltip
            title={
              <>
                <Typography
                  variant="body3_grotesk_medium"
                  className={styles.tooltipTitle}
                >
                  {t("challenges.loginStreakTooltipTitle")}
                </Typography>
                <Typography variant="body3" className={styles.tooltipSubTitle}>
                  {t("challenges.loginStreakTooltipSubTitle")}
                </Typography>
              </>
            }
            isArrow={true}
            isClick={isMobile}
            open={openTooltip}
            setOpen={setOpenTooltip}
            tooltipClassName={styles.tooltip}
          >
            <Box
              component="span"
              className={styles.tooltipContent}
              onClick={() => {
                if (isMobile) {
                  setOpenTooltip(true);
                }
              }}
            >
              <IconQuestionMark />
            </Box>
          </Tooltip>
          <Box className={styles.days}>
            {days.map((title, index) => (
              <StreakDay
                title={getDay({
                  arr: days,
                  index,
                  currentIndex: activeDaysStreak - 1,
                })}
                isActive={index < activeDaysStreak}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
