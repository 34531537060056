import React, { useState, useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { Box, Typography, Input } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import styles from "./Modal.module.scss";
import { LoadingButton } from "@mui/lab";
import {
  fetchUser,
  withdrawCards,
  deleteYourAccount,
  externalWallet,
} from "redux/usersSlice";
import DeleteAccountSuccess from "./DeleteAccountSuccess";
import { openModal } from "redux/modalSlice";

const DeleteAccountWallet = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.users.me.entities);
  const [walletValue, setWalletValue] = useState("");
  const [repeatWalletValue, setWalletReapeatValue] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleContinue = () => {
    setLoading(true);
    if (walletValue !== repeatWalletValue) {
      setError(t("error.walletDoNotMatch"));
      setLoading(false);
      return false;
    }
    dispatch(externalWallet({ externalWalletAddress: walletValue }));
    dispatch(deleteYourAccount()).then(() => {
      dispatch(withdrawCards({ walletAddress: walletValue }));
      dispatch(fetchUser()).then(() => {
        setLoading(false);
        dispatch(openModal({ children: <DeleteAccountSuccess /> }));
      });
    });
  };

  return (
    <Box className={styles.modalWalletConfirm}>
      <Typography variant="textNormal" className={styles.walletTitle}>
        {t("settings.enterYourWalletAddress")}
      </Typography>
      <Typography variant="textNormal" className={styles.walletDescription}>
        {t("settings.enterYourWalletAddressDescription")}
      </Typography>
      <Box>
        <Typography variant="textNormal" className={styles.passwordLabel}>
          {t("settings.nftWalletAddress")}
        </Typography>
        <Input
          onChange={(e) => setWalletValue(e.target.value)}
          className={styles.input}
        />
      </Box>
      <Box>
        <Typography variant="textNormal" className={styles.passwordLabel}>
          {t("settings.repeatNftWalletAddress")}
        </Typography>
        <Input
          onChange={(e) => setWalletReapeatValue(e.target.value)}
          className={styles.input}
        />
      </Box>

      <LoadingButton
        loading={loading}
        variant="contained"
        color="primary"
        onClick={handleContinue}
        className={styles.continueButton}
      >
        {t("auth.contunue")}
      </LoadingButton>
      {error && (
        <Typography variant="error" className={styles.errorLabel}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default DeleteAccountWallet;
