import { IconButton } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "redux/modalSlice";

import { MODAL_TYPE } from "../../../../constants";
import { DollarIcon } from "../../icons";

export const Donation = ({
  buttonClass,
  getValues,
  isDisabled,
  controlName,
  name,
  onSendMessage,
}) => {
  const dispatch = useDispatch();

  const openModalHandler = () =>
    dispatch(
      openModal({
        type: MODAL_TYPE.DONATE_MODAL,
        payload: {
          message: getValues(controlName),
          name,
          onSubmit: onSendMessage,
          isMessageRequired: false,
        },
      })
    );

  return (
    <IconButton
      className={buttonClass}
      disabled={isDisabled}
      onClick={openModalHandler}
    >
      <DollarIcon />
    </IconButton>
  );
};
