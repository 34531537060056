import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getEditionActivity,
  getEditionData,
  getEditionStats,
} from "services/editionApi";
import {
  createEditionOrderBuy,
  createEditionOrderSell,
  getEditionMarketStatus,
  getEditionOrders,
} from "services/tradingApi";

export const fetchEditionData = createAsyncThunk("edition/data", async (id) => {
  const response = await getEditionData(id);
  return response;
});

export const fetchEditionStats = createAsyncThunk(
  "edition/stats",
  async (id) => {
    const response = await getEditionStats(id);
    return response.data;
  }
);

export const fetchEditionActivity = createAsyncThunk(
  "edition/activity",
  async (id) => {
    const response = await getEditionActivity(id);
    return response.data;
  }
);

export const fetchEditionOrders = createAsyncThunk(
  "edition/orders",
  async (id) => {
    const response = await getEditionOrders(id);
    return response.data;
  }
);

export const fetchEditionMarketStatus = createAsyncThunk(
  "edition/market-status",
  async (id) => {
    const response = await getEditionMarketStatus(id);
    return response.data;
  }
);

export const createBuyEditionOrder = createAsyncThunk(
  "edition/create-buy-order",
  async (params, { rejectWithValue }) => {
    try {
      const response = await createEditionOrderBuy(params);
      return response.data;
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

export const createSellEditionOrder = createAsyncThunk(
  "edition/create-sell-order",
  async (params, { rejectWithValue }) => {
    try {
      const response = await createEditionOrderSell(params);
      return response.data;
    } catch (err) {
      if (!err.data) {
        throw err;
      }
      return rejectWithValue(err.data);
    }
  }
);

const initialState = {
  editionData: {
    loading: false,
    data: {},
    version: 0,
  },
  marketStatus: { isWatchlisted: false, loading: false },
  editionOrders: { loading: false },
};

const editionSlice = createSlice({
  name: "edition",
  initialState,
  reducers: {
    refetchEdition: (state) => {
      state.version = state.version++;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEditionData.pending, (state, action) => {
        state.editionData.loading = true;
      })
      .addCase(fetchEditionData.rejected, (state, action) => {
        state.editionData.loading = false;
      })
      .addCase(fetchEditionData.fulfilled, (state, action) => {
        state.editionData.loading = false;
        state.editionData.data = action.payload;
      })

      .addCase(fetchEditionMarketStatus.pending, (state, action) => {
        state.marketStatus.loading = true;
      })
      .addCase(fetchEditionMarketStatus.rejected, (state, action) => {
        state.marketStatus.loading = false;
      })
      .addCase(fetchEditionMarketStatus.fulfilled, (state, action) => {
        state.marketStatus.isWatchlisted = action.payload.isWatchlisted;
        state.marketStatus.loading = false;
      })

      .addCase(fetchEditionOrders.pending, (state, action) => {
        state.editionOrders.loading = true;
      })
      .addCase(fetchEditionOrders.rejected, (state, action) => {
        state.editionOrders.loading = false;
      })
      .addCase(fetchEditionOrders.fulfilled, (state, action) => {
        state.editionOrders.loading = false;
      });
  },
});

export const { refetchEdition, setWatchlisted } = editionSlice.actions;

export default editionSlice.reducer;
