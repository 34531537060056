import { Box, Tooltip, Typography } from "@mui/material";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./TooltipTypes.module.scss";

function FileFormats() {
  const formats = [".jpg", ".png", ".mp3", ".mp4", ".avi"];

  return (
    <Typography className={styles.value} variant="body4_groteskMedium">
      {formats.map((format, index) => (
        <span key={index} className={styles.formatItem}>
          {format}
        </span>
      ))}
    </Typography>
  );
}

export const TooltipTypes = ({ children }) => {
  const { t } = useContext(LocalizationContext);
  return (
    <Tooltip
      placement="top"
      arrow={true}
      classes={{
        popper: styles.popper,
        tooltip: styles.tooltip,
        arrow: styles.customTooltipArrow,
      }}
      enterTouchDelay={0}
      title={
        <Box className={styles.tooltipWrapper}>
          <Typography variant="body2">
            {t("default.acceptFollowingTypes")}:
          </Typography>
          <FileFormats />
          <Typography variant="body2">
            {t("default.maximumUploadFileSize")}:
          </Typography>
          <Typography variant="body4_groteskMedium" className={styles.value}>
            500 mb
          </Typography>
          <Typography variant="body2">
            {t("default.acceptFollowingTypesDesc")}
          </Typography>
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
};
