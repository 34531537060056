import { Box, Button, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CardsSlider } from "components/CardsSlider/CardsSlider";
import { CHAT_CATEGORIES, PATHS } from "constants/index";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveChat } from "redux/messengerSlice";
import { LocalizationContext } from "services/localizationContext";

import { UserProfileBox } from "../UserProfileBox/UserProfileBox";
import styles from "./EmptyMessengerContent.module.scss";

export const EmptyMessengerContent = ({ data, chatCategory }) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:768px)");

  const goToGroups = () => {
    navigate(PATHS.DISCOVER_GROUPS);
  };

  return (
    <Box className={styles.wrapper}>
      {chatCategory === CHAT_CATEGORIES.GENERAL ? (
        <>
          <Box className={styles.sliderWrapper} key={isMobile}>
            {data?.length ? (
              <CardsSlider
                spaceBetweenSlides={0}
                spaceBetweenSlidesMobile={0}
                cardWidth={isMobile ? 156 : 172}
              >
                {data.map((item) => (
                  <UserProfileBox {...item} key={item.id} />
                ))}
              </CardsSlider>
            ) : null}
          </Box>
          <Typography variant="h5" className={styles.emptyTitle}>
            {t("messages.emptyTitle")}
          </Typography>
          <Typography variant="body2" className={styles.emptyDescription}>
            {t("messages.emptyDescriptionBeforeLink")}{" "}
            <Button variant="text" onClick={goToGroups}>
              {t("messages.emptyDescriptionLink")}
            </Button>{" "}
            {t("messages.emptyDescriptionAfterLink")}
          </Typography>
          <Button
            variant="contained"
            className={styles.emptyButton}
            onClick={() => dispatch(setActiveChat({ isNewMessageTo: true }))}
          >
            {t("messages.newMessage")}
          </Button>
        </>
      ) : null}
      {chatCategory === CHAT_CATEGORIES.PREMIUM ? (
        <>
          <Typography variant="h5" className={styles.emptyTitle}>
            {t("rewards.ctaEmptyTitle")}
          </Typography>
          <Typography variant="body2" className={styles.emptyDescription}>
            {t("rewards.ctaEmptyDescriptions1")}
          </Typography>
          <Button
            variant="contained"
            className={styles.emptyButton}
            onClick={() => navigate(PATHS.LIMITED_EDITION_CARDS)}
          >
            {t("rewards.ctaEmptyPremium")}
          </Button>
        </>
      ) : null}
    </Box>
  );
};
