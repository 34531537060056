import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { LocalizationContext } from "services/localizationContext";

import { PageLayout } from "../PageLayout/PageLayout";
import styles from "./Rewards.module.scss";
import RewardsList from "./RewardsList";

const Rewards = () => {
  const user = useSelector((state) => state.users.me.entities);
  const loading = useSelector((state) => state.users.me.loading);
  const { t } = useContext(LocalizationContext);

  return (
    <PageLayout
      title={t("rewards.title")}
      isBack={true}
      isBottomMenuHide={true}
    >
      <Box className={styles.content}>
        <Box className={styles.leftBlock}>
          <Box className={styles.balance}>
            <Typography variant="body1" className={styles.balanceTitle}>
              {t("rewards.balanceTitle")}
            </Typography>
            <Typography variant="balance" className={styles.balanceScore}>
              {loading ? (
                <Skeleton
                  sx={{
                    height: "70px",
                    width: "178px",
                    transform: "none",
                    background: "#21264e",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                />
              ) : (
                <NumberFormat
                  value={user.rewardPoints || 0}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              )}
            </Typography>
          </Box>
          {/* TODO::uncomment when the zoop pro is ready */}
          {/* <Box className={styles.zoopPro}>
            <Typography variant="subtitle1" className={styles.zoopProTitle}>
              <Box className={styles.strong}>ZoopPro: &nbsp;</Box> inactive
              <Box
                className={`${styles.zoopProStatus} ${styles.zoopProStatusInactive}`}
              ></Box>
            </Typography>
            <Typography variant="body1" my={2}>
              Double your points by subscribing to ZoopPro
            </Typography>
            <Button variant="contained" className={styles.zoopProBtn}>
              Subscribe now
            </Button>
          </Box> */}
        </Box>

        <RewardsList />
      </Box>
    </PageLayout>
  );
};
export default Rewards;
