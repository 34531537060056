import { api } from "services/api.service";

const API_URL = process.env.REACT_APP_API_HOST;

export const getInfluencerMetadata = (id) => {
  return api
    .get(`${API_URL}/public/discover/metatags/influencer/${id}`)
    .then((response) => response.data);
};

export const getCardMetadata = (id) => {
  return api
    .get(`${API_URL}/public/discover/metatags/card/${id}`)
    .then((response) => response.data);
};

export const getFanMetadata = (username) => {
  return api
    .get(`${API_URL}/public/discover/metatags/fan/${username}`)
    .then((response) => response.data);
};
