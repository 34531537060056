import { Box, IconButton } from "@mui/material";
import { ReactComponent as ReferralsIcon } from "assets/svg/referrals-icon.svg";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import { ProfileBox } from "../../components/Telegram/components/ProfileBox/ProfileBox";
import { PATHS } from "../../constants";
import { useGoTo } from "../../utils/hooks/useGoTo";
import { useTelegram } from "../../utils/hooks/useTelegram";
import { PageLayout } from "../PageLayout/PageLayout";
import styles from "./ChallengesHub.module.scss";
import { Challenges } from "./components/Challenges/Challenges";
import { LoginStreak } from "./components/LoginStreak/LoginStreak";

export const ChallengesHub = () => {
  const { t } = useContext(LocalizationContext);

  const { goTo } = useGoTo();
  const { user: tgUser } = useTelegram();

  return (
    <PageLayout
      title={tgUser ? t("home.mine") : t("challenges.challengesHub")}
      customMobileHeaderMenu={
        tgUser ? (
          <Box className={styles.headerMenu}>
            <ProfileBox />
            <IconButton
              onClick={() => goTo(PATHS.REFERRALS)}
              className={styles.headerButton}
            >
              <ReferralsIcon />
            </IconButton>
          </Box>
        ) : (
          <></>
        )
      }
    >
      <Box className={styles.content}>
        <Box className={styles.top}>
          <Box className={styles.topRight}>
            <LoginStreak wrapperClassName={styles.loginStreak} />
          </Box>
        </Box>
        <Challenges />
      </Box>
    </PageLayout>
  );
};
