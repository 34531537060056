import { Box, Typography } from "@mui/material";
import { ReactComponent as IconRedirect } from "assets/svg/icon-redirect.svg";
import MenuTitle from "components/MenuTitle";
import { POLICIES_STATES } from "constants/index";
import React from "react";
import { useNavigate } from "react-router-dom";

import styles from "./Settings.module.scss";
import { usePolicies } from "./usePolicies";

const Policies = () => {
  const navigate = useNavigate();

  const handleRedirect = (link) => {
    window.open(link, "_blank");
  };

  const POLICIES = usePolicies();

  return (
    <Box className={styles.tabContainer}>
      <Box className={styles.policiesContent}>
        <MenuTitle
          states={POLICIES_STATES}
          currentState={POLICIES_STATES.default}
          onBack={() => navigate("/settings")}
        />
        <Box className={styles.policiesList}>
          {POLICIES.map((item, index) => {
            return (
              <Box
                className={styles.policiesItem}
                key={index}
                onClick={() => handleRedirect(item.link)}
              >
                <Typography variant="h6">{item.title}</Typography>
                <IconRedirect />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Policies;
