import { Box, LinearProgress, Typography } from "@mui/material";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { roundToTwo } from "utils/math";

import { BadgeImageBox } from "../../../../components/Badge/BadgeImageBox";
import styles from "./SimpleChallenge.module.scss";

export const SimpleChallenge = ({
  code,
  appUserTrack,
  imageUrl,
  currentBadge,
  ...rest
}) => {
  const { t } = useContext(LocalizationContext);
  const metadata = appUserTrack?.challengeStage?.metadata || {};
  const progress = appUserTrack?.progress || 0;

  return (
    <Box className={styles.challenge}>
      <BadgeImageBox
        imageUrl={imageUrl}
        goalCount={currentBadge?.goalCount}
        imgClass={styles.challengeImg}
        badgeGoalClass={styles.challengeGoal}
      />
      <Box className={styles.challengeContent}>
        <Typography variant="textMediumBold2">
          {t(`challenges.${code}-title`)}
        </Typography>
        <Typography variant="body2" className={styles.challengeSubtitle}>
          {t(`challenges.${code}-stage-goal`, {
            count: metadata.goalCount,
            useSingular: true,
            ...(metadata.name && {
              influencerName: metadata.name,
            }),
          })}
        </Typography>
        <Box>
          <LinearProgress
            variant="determinate"
            value={Math.min(roundToTwo(progress), 100) || 0}
            classes={{
              root: styles.progressBarRoot,
              bar:
                progress >= 100
                  ? styles.progressBarCompleted
                  : styles.progressBarInProgress,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
