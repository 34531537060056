import UnfriendModal from "components/Modal/UnfriendModal";
import React, { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader, setSnackbar } from "redux/appSlice";
import { closeModal, openModal } from "redux/modalSlice";
import {
  getPublicUser,
  getUserBlacklist,
} from "redux/selectors/usersSelectors";
import { blockUser, fetchBlacklist, unblockUser } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

export const usePublicUserConnect = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const publicUser = useSelector(getPublicUser);

  const blacklist = useSelector(getUserBlacklist);

  const isBlocked = useMemo(
    () => blacklist?.findIndex((user) => user.id === publicUser.id) >= 0,
    [blacklist, publicUser.id]
  );

  const handleBlock = () => {
    dispatch(setLoader(true));

    dispatch(closeModal());

    dispatch(blockUser(publicUser.id))
      .unwrap()
      .then(() =>
        dispatch(fetchBlacklist())
          .unwrap()
          .then(() => {
            dispatch(
              setSnackbar({
                open: true,
                autoHideDuration: 3000,
                message: t("account.youBlocked", {
                  username: publicUser.username,
                }),
              })
            );
          })
          .finally(() => dispatch(setLoader(false)))
      );
  };

  const showBlockModal = () => {
    dispatch(
      openModal({
        children: (
          <UnfriendModal
            title={t("account.blockModalTitle")}
            description={t("account.blockModalText")}
            onConfirm={handleBlock}
            onCancel={() => dispatch(closeModal())}
            confirmBtnText={t("account.blockUser")}
            cancelBtnText={t("default.goBack")}
          />
        ),
      })
    );
  };

  const handleUnblock = () => {
    dispatch(setLoader(true));
    dispatch(unblockUser(publicUser.id))
      .unwrap()
      .then(() =>
        dispatch(fetchBlacklist())
          .unwrap()
          .then(() => {
            dispatch(setLoader(false));
          })
      );
  };

  return {
    isBlocked,
    handleUnblock,
    showBlockModal,
  };
};
