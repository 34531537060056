import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as DotsEditIcon } from "assets/svg/dots-edit-icon.svg";
import CustomTooltip from "components/Tooltip";
import { PATHS } from "constants/index";
import { differenceInDays, getYear, isToday } from "date-fns";
import DOMPurify from "dompurify";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setSnackbar } from "redux/appSlice";
import {
  blockChatToggle,
  deleteChatSlice,
  setActiveChat,
} from "redux/messengerSlice";
import { getActiveChat } from "redux/selectors/messengerSelectors";
import { LocalizationContext } from "services/localizationContext";
import { blockUserById, unblockUserById } from "services/userApi";
import {
  getConvertedTimeZoneDate,
  goToInfluencer,
  isMobileDevice,
} from "utils/index";

import {
  BlockProfileIcon,
  DeleteIcon,
  ProfileIcon,
} from "../../../Messenger/icons";
import stylesMain from "../../../Messenger/Messenger.module.scss";
import styles from "./ChatItem.module.scss";

export const ChatItem = (data) => {
  const { id, otherUser, lastMessage, newMessages, chatBlocked, chatCategory } =
    data;

  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const isPhone = isMobileDevice();
  const dispatch = useDispatch();
  const params = useParams();
  const activeChat = useSelector(getActiveChat);

  const [loader, setLoader] = useState(false);

  const goToProfile = (e) => {
    e.stopPropagation();
    if (otherUser?.isInfluencer) {
      goToInfluencer({
        id: otherUser.id,
        handle: otherUser.username,
        navigate,
        location,
      })();
    } else {
      navigate(`/public/user/${otherUser.username}`);
    }
  };

  const blockToggle = (e) => {
    e.stopPropagation();
    setLoader(true);
    if (otherUser?.id) {
      const handler = !chatBlocked ? blockUserById : unblockUserById;
      handler(otherUser.id)
        .then(() => {
          dispatch(
            blockChatToggle({
              chatId: id,
              chatBlocked: !chatBlocked,
            })
          );
          dispatch(
            setSnackbar({
              open: true,
              message: !chatBlocked
                ? t("messages.chatBlocked")
                : t("messages.chatUnblocked"),
            })
          );
        })
        .finally(() => setLoader(false));
    }
  };

  const convertData = (value) => {
    const dateValue = new Date(value);
    const today = new Date();
    const currentYear = getYear(today);
    const dayDifference = differenceInDays(today, dateValue);

    if (isToday(dateValue) || dayDifference === 0) {
      return getConvertedTimeZoneDate(value, "HH:mm");
    } else {
      if (dayDifference <= 28) {
        return dayDifference === 1
          ? `1 ${t("default.dayAgo")}`
          : `${dayDifference} ${t("default.daysAgo")}`;
      } else {
        return getYear(dateValue) === currentYear
          ? getConvertedTimeZoneDate(value, "MMM dd")
          : getConvertedTimeZoneDate(value, "MMM dd yyyy");
      }
    }
  };

  const clearChatHandler = (e) => {
    e.stopPropagation();
    setLoader(true);
    dispatch(deleteChatSlice({ chatId: id, type: chatCategory }))
      .then(() => {
        if (+params.userId === +otherUser.id) {
          navigate(PATHS.CHAT);
          setTimeout(() => {
            dispatch(setActiveChat(null));
          }, 0);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  return (
    <Box
      className={`${styles.chat} ${
        id === activeChat?.id ? styles.chatActive : ""
      }`}
      onClick={() => dispatch(setActiveChat(data))}
    >
      {loader ? (
        <Box className={styles.loaderBox}>
          <CircularProgress size={24} />
        </Box>
      ) : null}
      <img
        src={otherUser.profileImageUrl}
        alt="avatar"
        className={otherUser?.isInfluencer ? styles.influencerAvatar : ""}
      />
      <Box className={styles.chatBody}>
        <Box className={`${styles.chatTop} ${styles.chatRow}`}>
          <Typography variant="textMediumBold2" className={styles.chatName}>
            {otherUser.username}
          </Typography>
          {lastMessage?.timestamp || lastMessage?.createdAt ? (
            <Typography variant="body3" className={styles.chatTime}>
              {convertData(lastMessage.timestamp || lastMessage.createdAt)}
            </Typography>
          ) : null}
        </Box>
        <Box className={`${styles.chatRow} ${styles.chatRowText}`}>
          <Typography
            className={`${styles.opacity} ${styles.message}`}
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                lastMessage?.messageContent ? lastMessage.messageContent : "",
                {
                  USE_PROFILES: { html: true },
                }
              ),
            }}
          />
          {newMessages && id !== activeChat?.id ? (
            <Typography
              variant="clash_display_12_500"
              className={styles.newMessages}
            >
              {newMessages}
            </Typography>
          ) : null}
        </Box>
      </Box>
      <Box className={styles.boxShadow} />
      {!isPhone ? (
        <CustomTooltip
          isArrow={false}
          placement="bottom"
          title={
            <Box>
              <Box className={stylesMain.tooltipRow} onClick={goToProfile}>
                <ProfileIcon />
                <Typography variant="body2">
                  {t("tradehistory.viewProfile")}
                </Typography>
              </Box>
              {otherUser?.id ? (
                <Box className={stylesMain.tooltipRow} onClick={blockToggle}>
                  <BlockProfileIcon />
                  <Typography variant="body2">
                    {!chatBlocked ? t("account.block") : t("account.unblock")}
                  </Typography>
                </Box>
              ) : null}
              <Box className={stylesMain.tooltipRow} onClick={clearChatHandler}>
                <DeleteIcon />
                <Typography
                  variant="body5"
                  style={{ color: theme.palette.error.main }}
                >
                  {t("messages.deleteChat")}
                </Typography>
              </Box>
            </Box>
          }
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 0 }}
        >
          <Button className={styles.tooltipButton}>
            <DotsEditIcon />
          </Button>
        </CustomTooltip>
      ) : null}
    </Box>
  );
};
