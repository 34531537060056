import { Box, Typography } from "@mui/material";
import { ReactComponent as ArrowRight } from "assets/svg/arrow-right.svg";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { getInfluencerProfileLink } from "utils";

import styles from "./FollowersBlock.module.scss";

export const FollowersBlock = ({
  influencerData,
  className,
  followersClassNameBlock,
  followers,
}) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const handleRedirectToFollowers = (e) => {
    e.stopPropagation();
    const url = getInfluencerProfileLink({
      id: influencerData?.id,
      handle: influencerData?.handle,
    });
    navigate(`${url}/followers`);
  };

  if (!followers?.rows?.length) {
    return null;
  }

  const RenderFollowersText = () => {
    if (!followers?.rows.length) {
      return null;
    }

    if (followers?.rows.length > 2) {
      return (
        <Typography
          style={{ wordBreak: "break-word", lineHeight: "1.3" }}
          variant="body2"
        >
          {t("discover.followText", {
            firstUser: followers?.rows[0].appUser.username,
            secondUser: followers?.rows[1].appUser.username,
            count: followers?.total - 2,
            useSingular: true,
          })}
        </Typography>
      );
    }
    return (
      <Typography variant="body2" mt={1}>
        {followers?.rows
          .map((follower) => follower.appUser.username)
          .join(", ")}{" "}
        {followers?.rows.length > 1
          ? t("discover.followThisUserMultiple")
          : t("discover.followThisUserSingle")}
      </Typography>
    );
  };

  return (
    <Box className={`${styles.top} ${className}`}>
      <Box
        className={`${styles.followersWrapper} ${followersClassNameBlock}`}
        onClick={handleRedirectToFollowers}
      >
        <Box className={styles.followers}>
          <Box className={styles.followersAvatarWrapper}>
            {followers?.rows &&
              followers?.rows.slice(0, 3).map((follower) => {
                return (
                  <img
                    key={follower.appUser.id}
                    src={follower.appUser.profileImageUrl}
                    className={styles.followerAvatar}
                    alt={follower.appUser.name}
                  />
                );
              })}
          </Box>
          <RenderFollowersText />
        </Box>
        <ArrowRight className={styles.arrowLink} />
      </Box>
    </Box>
  );
};
