import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar, Box, IconButton, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as Send } from "assets/svg/feed/send.svg";
import SelectedReply from "components/Feed/SelectedReply/SelectedReply";
import { FULL_COMMENT_TEXT_LIMIT } from "constants/index";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "redux/appSlice";
import { fetchCommentReplies, onPostComment } from "redux/feedSlice";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import * as Yup from "yup";

import styles from "../../post.module.scss";
import { Donation } from "../Donation/Donation";

const scrollToElement = (id) => {
  if (!id) return;

  const element = document.getElementById(id);
  if (element) {
    const bounding = element.getBoundingClientRect();
    if (
      bounding.top < 0 ||
      bounding.left < 0 ||
      bounding.right > window.innerWidth ||
      bounding.bottom > window.innerHeight
    ) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }
};

export const PostForm = ({
  isReply,
  loading,
  postId,
  selectedReply,
  setIsReply,
  setLoadingReply,
  deleteLoadingReply,
  isSubscriptionReady,
  isDonationEnabled,
  influencerName,
  accessGranted,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const { t } = useContext(LocalizationContext);
  const posting = useSelector((state) => state.feed.post.posting);
  const profile = useSelector((state) => state.users.entities);
  const isAuth = isAuthenticated();

  const [messageValue, setMessageValue] = useState("");

  const validationSchema = Yup.object().shape({
    message: Yup.string().trim().required(t("error.required")),
  });

  const { register, handleSubmit, reset, getValues } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSendMessage = async ({
    message,
    paymentMethodId,
    tipAmount,
    tipIsHidden,
  }) => {
    return await dispatch(
      onPostComment({
        id: postId,
        params: {
          message,
          ...(isReply && { replyToId: selectedReply.id }),
          ...(paymentMethodId && { paymentMethodId }),
          ...(tipAmount && { tipAmount }),
          ...(tipIsHidden && { tipIsHidden }),
        },
      })
    )
      .unwrap()
      .then((data) => {
        if (data?.error) {
          dispatch(
            setError({
              open: true,
              title: "Error",
              subtitle: data?.message,
            })
          );
          return { status: "ignore" };
        }
        // if (isReply) {
        //   setLoadingReply(selectedReply.id);
        //   dispatch(
        //     fetchCommentReplies({
        //       id: postId,
        //       params: {
        //         limit: 1,
        //         page: 1,
        //         commentId: selectedReply.id,
        //       },
        //       isAuth,
        //     })
        //   ).finally(() => {
        //     deleteLoadingReply(selectedReply.id);
        //     scrollToElement(data?.data?.id);
        //   });
        // } else {
        //   scrollToElement(data?.data?.id);
        // }
        scrollToElement(data?.data?.id);
        reset();
        setMessageValue("");
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              title: "Error",
              subtitle: errorResponseMessages(err.data || err, t),
            })
          );
        }
      })
      .finally(() => {
        setIsReply(false);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const isActionClass = messageValue.length > (isXs ? 35 : isSm ? 75 : 120);

  const isDonationEnable =
    isSubscriptionReady && isDonationEnabled && isAuth && accessGranted;

  return (
    <form
      onSubmit={handleSubmit(onSendMessage)}
      autoComplete="off"
      className={`${styles.sendMsg} ${styles.container}`}
    >
      <SelectedReply
        selectedReply={selectedReply}
        isReply={isReply}
        onClick={() => setIsReply(false)}
      />
      <Box className={styles.sendMsgContainer}>
        <Avatar
          src={profile?.userPicCroppedUrl}
          className={styles.sendMsgAvatar}
          sx={{
            bgcolor: theme.palette.surface.main,
          }}
        />
        <TextField
          {...register("message")}
          name="message"
          variant="outlined"
          multiline
          minRows={1}
          maxRows={5}
          inputProps={{ maxLength: FULL_COMMENT_TEXT_LIMIT }}
          className={`${styles.sendMsgInput} ${
            isActionClass ? styles.sendMsgInputAction : ""
          }`}
          placeholder={t("discover.writeMessagePrompt")}
          autoComplete="off"
          disabled={loading || posting || !isAuth || !accessGranted}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              ev.preventDefault();
              handleSubmit(onSendMessage)();
            }
          }}
          onKeyUp={(e) => setMessageValue(e.target.value)}
        />
        <Box
          className={`${styles.actionWrap} ${
            isActionClass ? styles.actionWrapVertical : ""
          }`}
        >
          {isDonationEnable ? (
            <Donation
              buttonClass={styles.sendMsgBtn}
              isDisabled={posting}
              getValues={getValues}
              controlName="message"
              name={influencerName}
              onSendMessage={onSendMessage}
            />
          ) : null}
          <IconButton
            className={styles.sendMsgBtn}
            type="submit"
            disabled={posting}
          >
            <Send />
          </IconButton>
        </Box>
      </Box>
    </form>
  );
};
