import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Container,
  FormControl,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import CountriesJson from "constants/Countries.json";
import { STATE_KEYS } from "constants/states";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import styles from "views/Payments/AddCard/AddCard.module.scss";
import stylesMain from "views/Wallet/Funds.module.scss";
import * as Yup from "yup";

import PlaceAutocomplete from "../../KYC/PlaceAutocomplete";
import { PageLayout } from "../../PageLayout/PageLayout";

const BankAddress = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const cardData = location.state;
  const countries = CountriesJson.Countries;

  useEffect(() => {
    if (!cardData) {
      console.error("Incorrect data  passed to the location state", cardData);
      navigate("/", { replace: true });
    }
  }, [cardData]);

  const validationSchema = Yup.object().shape({
    addressLine1: Yup.string()
      .trim()
      .required(t("error.required"))
      .isAlphanumeric(t("error.isAlphanumeric")),
    city: Yup.string()
      .trim()
      .required(t("error.required"))
      .isAlphanumeric(t("error.isAlphanumeric")),
    country: Yup.string()
      .trim()
      .required(t("error.required"))
      .isAlphanumeric(t("error.isAlphanumeric")),
    county: Yup.string()
      .trim()
      .when(["country"], {
        is: (country) => {
          return country === "US" || country === "United States";
        },
        then: (schema) => {
          return Yup.string().oneOf(STATE_KEYS, t("payments.stateError"));
        },
        otherwise: Yup.string().required(t("error.required")),
      }),
    zipPostcode: Yup.string()
      .trim()
      .required(t("error.required"))
      .isAlphanumeric(t("error.isAlphanumeric")),
  });
  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleFormSubmit = (bankAddress) => {
    setLoading(true);

    const country = CountriesJson.Countries.find(
      (c) => c.name == bankAddress.country || c.code == bankAddress.country
    );

    navigate("/add-card/billing-address", {
      state: { ...cardData, bankAddress: { ...bankAddress, country } },
    });
  };

  const onAdressChange = (adressDetails) => {
    clearErrors();
    if (!adressDetails) return;
    const { city, streetNumber, streetAddress, region } = adressDetails;
    setValue("city", city);
    setValue("county", region);
    if (streetNumber || streetAddress) {
      setValue("addressLine1", `${streetNumber || ""} ${streetAddress || ""}`);
    } else {
      setValue("addressLine1", " ");
    }
  };
  const handleChangeCountry = (value) => {
    if (!value) {
      setValue("country", "");
      return;
    }
    setValue("country", value.name);
  };
  const title = t("payments.bankAddress");
  return (
    <PageLayout title={title} isBack={true} backBtnProps={{ isPlain: true }}>
      <Container className={stylesMain.container}>
        <Box className={styles.detailsContainer}>
          <form
            onSubmit={handleSubmit(handleFormSubmit)}
            autoComplete="off"
            className={`${styles.paymentsForm}`}
          >
            <Box className={`${styles.formContainer}`}>
              <Box className={styles.formWrapper}>
                <FormControl className={styles.formControlInput}>
                  <label className={styles.label}>
                    {t("kyc.streetAddressField")}
                  </label>
                  <Controller
                    control={control}
                    name="addressLine1"
                    rules={{ required: t("error.required") }}
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                    }) => (
                      <Box className={styles.placeInput}>
                        <PlaceAutocomplete
                          placeholder={t("kyc.streetAddressField")}
                          onChange={onChange}
                          onOptionPress={onAdressChange}
                          error={errors.addressLine1 ? true : false}
                          errorText={errors.addressLine1?.message}
                          defaultValue={getValues("addressLine1")}
                        />
                      </Box>
                    )}
                  />
                </FormControl>
                <FormControl className={styles.formControlInput}>
                  <label className={styles.label}>
                    {t("kyc.streetAddress2Field")}
                  </label>
                  <Input
                    placeholder={t("kyc.streetAddress2Field")}
                    {...register("addressLine2")}
                    error={errors.addressLine2 ? true : false}
                    autoComplete="off"
                    className={styles.input}
                  />
                  <Typography variant="error" className={styles.inputError}>
                    {errors.addressLine2?.message}
                  </Typography>
                </FormControl>

                <FormControl className={styles.formControlInput}>
                  <label className={styles.label}>{t("kyc.cityField")}</label>
                  <Input
                    placeholder={t("kyc.cityField")}
                    {...register("city")}
                    error={errors.city ? true : false}
                    autoComplete="off"
                    className={styles.input}
                  />
                  <Typography variant="error" className={styles.inputError}>
                    {errors.city?.message}
                  </Typography>
                </FormControl>
              </Box>

              <Box className={styles.formWrapper}>
                <FormControl className={styles.formControlInput}>
                  <label className={styles.label}>
                    {t("kyc.stateRegionField")}
                  </label>
                  <Input
                    placeholder={t("kyc.stateRegionField")}
                    {...register("county")}
                    error={errors.county ? true : false}
                    autoComplete="off"
                    className={styles.input}
                  />
                  <Typography variant="error" className={styles.inputError}>
                    {errors.county?.message}
                  </Typography>
                </FormControl>

                <FormControl className={styles.formControlInput}>
                  <label className={styles.label}>
                    {t("kyc.postalCodeField")}
                  </label>
                  <Input
                    placeholder={t("kyc.postalCodeField")}
                    {...register("zipPostcode")}
                    error={errors.zipPostcode ? true : false}
                    autoComplete="off"
                    className={styles.input}
                  />
                  <Typography variant="error" className={styles.inputError}>
                    {errors.zipPostcode?.message}
                  </Typography>
                </FormControl>
                <FormControl className={styles.formControlInput}>
                  <label className={styles.label}>
                    {t("kyc.countryField")}
                  </label>
                  <Box className={styles.countrySelect}>
                    <Autocomplete
                      id="countries-input"
                      className={styles.inputWrap}
                      options={countries}
                      getOptionLabel={(option) => option.name}
                      onChange={(_ev, option) => {
                        handleChangeCountry(option);
                      }}
                      popupIcon={<></>}
                      componentsProps={{
                        paper: {
                          sx: {
                            backgroundColor: "#21264e",
                            border: "none",
                            padding: "15px",
                          },
                          variant: "outlined",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={""}
                          placeholder={t("kyc.countryField")}
                          variant="standard"
                          autoComplete="off"
                          className={styles.input}
                          error={errors.country ? true : false}
                          {...register("country")}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          {...props}
                          className={styles.inputOption}
                        >
                          {option.name}
                        </Box>
                      )}
                    />
                  </Box>
                  <Typography variant="error" className={styles.inputError}>
                    {errors.country?.message}
                  </Typography>
                </FormControl>
              </Box>
            </Box>
            <FormControl>
              <LoadingButton
                type="submit"
                variant="outlined"
                className={styles.btnContinue}
                loading={loading}
              >
                {t("kyc.continueButton")}
              </LoadingButton>
            </FormControl>
          </form>
        </Box>
      </Container>
    </PageLayout>
  );
};
export default BankAddress;
