import { TUTORIALS_PROPS } from "constants/index";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import { HeaderTooltip } from "../../../components/HeaderTooltip/HeaderTooltip";
import { PageLayout } from "../../PageLayout/PageLayout";
import { DiscoverGroupsBody } from "../components/DiscoverGroupsBody/DiscoverGroupsBody";

const DiscoverGroups = () => {
  const { t } = useContext(LocalizationContext);

  return (
    <PageLayout
      title={
        <HeaderTooltip
          title={t("default.groupsTutorialTitle")}
          subtitle={t("default.groupsSubTutorialTitle")}
          header={t("discover.groups")}
          seenTutorialsValue={TUTORIALS_PROPS.GROUPS_TUTORIAL_1}
        />
      }
      isSearch={true}
    >
      <DiscoverGroupsBody />
    </PageLayout>
  );
};

export default DiscoverGroups;
