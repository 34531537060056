import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { ReactComponent as IconClose } from "assets/svg/icon-close.svg";
import Editor from "components/QuillEditor/Editor";
import { parseLinks } from "components/QuillEditor/utils";
import CustomTooltip from "components/Tooltip";
import { MEDIA_TYPES, YOUTUBE_REGEX } from "constants/index";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";
import * as yup from "yup";

import {
  AddMediaButton,
  TooltipTypes,
  TypesErrorBox,
  YoutubeButton,
  YoutubeSection,
} from "./components";
import {
  IconCamera,
  IconChecked,
  IconMedia,
  IconMusic,
  IconPlay,
  IconQuestionMark,
  IconUnchecked,
} from "./icons";
import styles from "./PostCreator.module.scss";
import { EDITOR_TOOLBAR_ITEMS, getMediaType, toggleActiveVideo } from "./utils";

export const PostCreator = ({
  sendButtonTitle,
  formClassname,
  turnOffClass,
  onSubmitHandler,
  post,
  influencerId,
}) => {
  const { t } = useContext(LocalizationContext);
  const videoContainerRef = useRef(null);
  const reactQuillRef = useRef(null);

  const isMobile = useIsMobile();

  const [isYoutubeSection, setYoutubeSection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState({ value: "", isValid: false });
  const [isErrorType, setErrorType] = useState("");
  const [shouldHideTooltips, setShouldHideTooltips] = useState(false);

  const postId = post?.id;

  const schema = yup.object({
    allowComments: yup.boolean().notRequired(),
    ...(isYoutubeSection && {
      youtubeLink: yup
        .string()
        .trim()
        .notRequired()
        .matches(YOUTUBE_REGEX, t("default.invalidLink")),
    }),
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const updateMediaFiles = () => {
    if (post?.images?.length) {
      const images = post.images.map((imageUrl) => ({
        imageUrl,
        type: MEDIA_TYPES.IMAGE,
      }));
      setFiles(images);
    } else if (post?.videos?.length) {
      setFiles([{ videoUrl: post.videos[0], type: MEDIA_TYPES.VIDEO }]);
    } else if (post?.audios?.length) {
      const audios = post.audios.map((audioUrl) => ({
        audioUrl,
        type: MEDIA_TYPES.AUDIO,
      }));
      setFiles(audios);
    }
  };

  const messageValidation = (value, _, __, editor) => {
    const messageValue = editor ? editor.getText() : value;
    if (messageValue?.trim()?.length) {
      setMessage({ value, isValid: true });
    } else {
      setMessage({ value, isValid: false });
    }
  };

  const onSubmit = (data) => {
    setLoading(true);
    const mediaType = files.length ? getMediaType(files[0]) : null;

    const payload = {
      message: parseLinks(message.value),
      ...(postId && { postId }),
      allowComments: !data.allowComments,
      ...(files.length && { mediaType }),
      files: files,
      youtubeUrl: data.youtubeLink,
      influencerId: influencerId,
    };
    onSubmitHandler(payload)?.finally(() => setLoading(false));
  };

  const handleRemoveItem = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  useEffect(() => {
    if (post?.data?.youtubeUrl) {
      setYoutubeSection(true);
      setValue("youtubeLink", post?.data?.youtubeUrl);
    }
  }, [post?.data?.youtubeUrl]);

  useEffect(() => {
    if (post) {
      setValue("allowComments", !post?.allowComments);
      messageValidation(post?.message);
      updateMediaFiles();
    }
  }, [post]);

  return (
    <Box className={`${styles.newPostForm} ${formClassname}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          {loading ? (
            <Box className={styles.newPostFormLoader}>
              <CircularProgress />
            </Box>
          ) : null}
          <Editor
            data={message.value}
            reactQuillRef={reactQuillRef}
            editorToolbarId={"toolbar"}
            customFormats={EDITOR_TOOLBAR_ITEMS}
            onChange={messageValidation}
          />
        </FormControl>
        <FormControl>
          <Box className={styles.newPostFormEmptyActions}>
            {isMobile ? (
              <Box className={styles.attachBox}>
                <Typography variant="body2" className={styles.attach}>
                  {t("default.attach")}:
                </Typography>
                <Box className={styles.typesBox}>
                  <TooltipTypes>
                    <Box
                      component="span"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <IconQuestionMark />
                    </Box>
                  </TooltipTypes>
                  <Typography component="p" variant="body1">
                    {t("default.fileTypesAccept")}
                  </Typography>
                </Box>
              </Box>
            ) : null}
            <Box className={styles.buttonsContainer}>
              <CustomTooltip
                tooltipClassName={styles.mediaChangeTooltip}
                isArrow={true}
                shouldHideTooltip={shouldHideTooltips}
                isCustomTitleLayout={true}
                title={
                  <Box>
                    <Typography
                      variant="body3_grotesk_medium"
                      className={styles.mediaChangeTooltipContent}
                    >
                      {t("discover.replaceYtbVideoTooltipTop")}
                      <br />
                      {t("discover.replaceYtbVideoTooltip")}
                    </Typography>
                  </Box>
                }
                isDisabled={
                  files.length > 0 || (files.length === 0 && !isYoutubeSection)
                }
              >
                <Box>
                  <AddMediaButton
                    files={files}
                    setFiles={setFiles}
                    setErrorType={setErrorType}
                    isActive={files.length > 0}
                    icon={<IconCamera />}
                    isAnotherMediaType={isYoutubeSection}
                    handleMediaChange={() =>
                      isYoutubeSection && setYoutubeSection(false)
                    }
                    setShouldHideTooltips={setShouldHideTooltips}
                  />
                </Box>
              </CustomTooltip>
              <CustomTooltip
                tooltipClassName={styles.mediaChangeTooltip}
                isArrow={true}
                shouldHideTooltip={shouldHideTooltips}
                isCustomTitleLayout={true}
                title={
                  <Box>
                    <Typography
                      variant="body3_grotesk_medium"
                      className={styles.mediaChangeTooltipContent}
                    >
                      {t("discover.replaceMediaTooltipTop")}
                      <br />
                      {t("discover.replaceMediaTooltip")}
                    </Typography>
                  </Box>
                }
                isDisabled={
                  isYoutubeSection || (files.length === 0 && !isYoutubeSection)
                }
              >
                <Box>
                  <YoutubeButton
                    isAnotherMediaType={files.length > 0}
                    isYoutubeSection={isYoutubeSection}
                    setYoutubeSection={setYoutubeSection}
                    handleMediaChange={() =>
                      files.length > 0 &&
                      setFiles([]) &&
                      setYoutubeSection(true)
                    }
                    setShouldHideTooltips={setShouldHideTooltips}
                  />
                </Box>
              </CustomTooltip>
            </Box>
            {!isMobile ? (
              <Box className={styles.typesBox}>
                <TooltipTypes>
                  <Box
                    component="span"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <IconQuestionMark />
                  </Box>
                </TooltipTypes>
                <Typography component="p" variant="body1">
                  {t("default.fileTypesAccept")}
                </Typography>
              </Box>
            ) : null}
          </Box>
          {!!files && files.length ? (
            <Box className={styles.galleryWrapper}>
              <Box className={styles.galleryWrapperContent}>
                {files.map((file, index) => {
                  return (
                    <Box className={styles.galleryItem} key={`item-${index}`}>
                      <IconButton
                        className={styles.galleryRemoveItemBtn}
                        onClick={() => handleRemoveItem(index)}
                      >
                        <IconClose />
                      </IconButton>
                      {file?.imageUrl ? (
                        <img src={file?.imageUrl} alt="" />
                      ) : file?.videoUrl ? (
                        <Box
                          className={styles.newPostFormVideoWrapper}
                          data-active
                          ref={videoContainerRef}
                          onClick={toggleActiveVideo(videoContainerRef)}
                        >
                          <IconPlay />
                          <video src={file.videoUrl} width={175} height={100} />
                        </Box>
                      ) : file?.audioUrl ? (
                        <Box className={styles.audioBox}>
                          <IconMusic />
                        </Box>
                      ) : null}
                    </Box>
                  );
                })}
                <Box className={styles.galleryItem}>
                  <AddMediaButton
                    files={files}
                    setFiles={setFiles}
                    setErrorType={setErrorType}
                    className={styles.addPhotoBtn}
                    icon={<IconMedia />}
                  />
                </Box>
              </Box>
            </Box>
          ) : null}
        </FormControl>
        <YoutubeSection
          isActive={isYoutubeSection}
          control={control}
          errors={errors}
          setValue={setValue}
        />
        <TypesErrorBox
          message={t("error.errorMessageDifferentFiles")}
          isError={isErrorType}
          setErrorType={setErrorType}
        />
        <FormControl className={`${styles.checkboxWrapper} ${turnOffClass}`}>
          <Typography variant="subtitle1" className={styles.checkboxLabel}>
            {t("default.turnOffComment")}
          </Typography>
          <Controller
            render={() => {
              return (
                <Checkbox
                  checked={getValues("allowComments") ? "checked" : false}
                  onChange={(e) => setValue("allowComments", e.target.checked)}
                  icon={<IconUnchecked />}
                  checkedIcon={<IconChecked />}
                  sx={{
                    ".MuiSvgIcon-root": {
                      path: {
                        fill: "#ABB3F3",
                      },
                    },
                  }}
                />
              );
            }}
            name="allowComments"
            control={control}
          />
        </FormControl>
        <Box className={styles.actions}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading}
            disabled={loading || !message.isValid}
          >
            {sendButtonTitle}
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};
