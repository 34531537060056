import { PATHS } from "constants/index";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const SYMBOLS = {
  id: ":id",
};

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const paths = Object.values(PATHS).filter(
      (item) => item !== PATHS.HOME && item !== PATHS.PROFILE
    );
    const regex = /\/\d+($|\/)/g;
    const pathScheme = pathname.replace(regex, `/${SYMBOLS.id}$1`);
    if (paths.includes(pathScheme)) {
      window.scrollTo({ top: 0 });
    }
  }, [pathname]);

  return null;
}
