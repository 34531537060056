export const handleSharingPostLink = (params = {}) => {
  const { postId, handle, url } = params;
  let res = url;
  if (postId && handle) {
    let domain = window.location.origin;
    let newUrl = `${domain}/${handle}/post/${postId}`;
    res = newUrl;
  }
  return res;
};
