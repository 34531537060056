import { Box, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getMyFollowersLoaderSelector,
  getMyFollowersSelector,
  getPublicUserCommunity,
  getPublicUserCommunityLoading,
} from "redux/selectors/usersSelectors";
import { fetchFollowingByUserId, fetchMyFollows } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

import { PageLayout } from "../PageLayout/PageLayout";
import styles from "./Clubs.module.scss";
import { ClubsList } from "./components/ClubsList/ClubsList";

export const Clubs = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);

  const clubs = useSelector(getMyFollowersSelector);
  const clubsLoading = useSelector(getMyFollowersLoaderSelector);
  const publicUserClubsCommunity = useSelector(getPublicUserCommunity);
  const publicUserClubsLoading = useSelector(getPublicUserCommunityLoading);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (id) {
      dispatch(fetchFollowingByUserId({ id }));
    } else {
      dispatch(fetchMyFollows());
    }
  }, [id]);

  const data = id ? publicUserClubsCommunity : clubs;
  const loading = id ? publicUserClubsLoading : clubsLoading;

  return (
    <PageLayout
      title={
        <Box className={styles.header}>
          {t("default.clubs")}
          <Typography className={`opacity_07 ${styles.headerSubTitle}`}>
            ({data.length})
          </Typography>
        </Box>
      }
      isBack={true}
    >
      <Box className={styles.main}>
        <ClubsList
          rows={data}
          loader={loading}
          total={data.length}
          setPage={setPage}
          isLastPage={true}
        />
      </Box>
    </PageLayout>
  );
};
