import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { closeModal, openModal } from "redux/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconError } from "assets/svg/icon-error.svg";
import styles from "./Modal.module.scss";
import { MODAL_TYPE } from "constants/";
import { LocalizationContext } from "services/localizationContext";
import DeleteAccountModal from "./DeleteAccountModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 290,
  bgcolor: "#111322",
  border: "2px solid #21264E",
  boxSizing: "border-box",
  borderRadius: "10px",
  padding: "16px",
};

const DeleteAccountInfo = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };
  const handleContinue = () => {
    dispatch(openModal({ children: <DeleteAccountModal /> }));
  };

  return (
    <Box sx={style}>
      <IconError />
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        className={styles.modalTitle}
      >
        {t("default.areYouSure")}
      </Typography>
      <Box className={styles.modalBody}>
        <Typography variant="body2" className={styles.modalDescription}>
          {t("settings.deleteAccountDescription")}
        </Typography>
      </Box>
      <Box className={styles.btnWrapper}>
        <Button
          variant="outlined"
          className={styles.buttonClose}
          onClick={handleClose}
        >
          {t("default.cancel")}
        </Button>
        <Button
          variant="verify"
          className={styles.buttonOkay}
          onClick={handleContinue}
        >
          {t("settings.deleteAccount")}
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteAccountInfo;
