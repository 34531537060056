import { Box } from "@mui/material";
import BackBtn from "components/Layout/BackBtn/BackBtn";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import { PageLayout } from "../PageLayout/PageLayout";
import styles from "./Collections.module.scss";

const Collections = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/profile");
  };

  return (
    <PageLayout>
      <Box className={styles.container}>
        <Box className={styles.headerContainer}>
          <BackBtn onClick={handleBack} title={t("account.myCollections")} />
        </Box>
        <Box className={styles.collectionContainer}>
          <Outlet />
        </Box>
      </Box>
    </PageLayout>
  );
};

export default Collections;
