import { formatDistanceToNow } from "date-fns";
import { dateLocales } from "constants/";

const TimeAgo = ({ value, children, className, locale, ...props }) => {
  const l = !!locale ? locale : "en-GB";
  return formatDistanceToNow(new Date(value), {
    addSuffix: true,
    locale: dateLocales[l],
  });
};

export default TimeAgo;
