import { Box, LinearProgress, Typography } from "@mui/material";
import { ReactComponent as GoalIcon } from "assets/svg/goal-icon.svg";
import { ReactComponent as IconPoints } from "assets/svg/points-icon.svg";
import { PATHS } from "constants/index";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { roundToTwo } from "utils/math";

import styles from "./ChallengeItemMobileTg.module.scss";

export const ChallengeItemMobileTg = ({ challenge }) => {
  const { appUserTrack } = challenge;
  const navigate = useNavigate();

  const { t } = useContext(LocalizationContext);

  const rewardPoints = appUserTrack?.challengeStage?.rewardPoints;

  return (
    <Box
      className={styles.challenge}
      onClick={() => navigate(PATHS.CHALLENGES_ID.replace(":id", challenge.id))}
    >
      {challenge.imageUrl ? (
        <img
          src={challenge.imageUrl}
          alt="challenge"
          className={styles.challengeImg}
        />
      ) : null}
      <Box className={styles.challengeMain}>
        <Typography className={styles.challengeTitle} variant="textMediumBold2">
          {t(`challenges.${challenge.code}-title`)}
        </Typography>
        <Box className={styles.challengeSubtitle}>
          <Box className={styles.challengeSubtitleItem}>
            <GoalIcon className={styles.challengeSubtitleItemIcon} />
            <Typography
              variant="body3"
              className={styles.challengeSubtitleItemValue}
            >
              {t(`challenges.${challenge.code}-stage-goal`, {
                count: appUserTrack?.challengeStage?.metadata?.goalCount,
                useSingular: true,
                ...(appUserTrack?.challengeStage?.metadata?.name && {
                  influencerName: appUserTrack?.challengeStage?.metadata.name,
                }),
              })}
            </Typography>
          </Box>
          <Typography
            variant="body3"
            className={styles.challengeSubtitlePoints}
          >
            <IconPoints />
            {t("default.points", { points: rewardPoints })}
          </Typography>
        </Box>
        <Box className={styles.challengeBar}>
          <LinearProgress
            variant="determinate"
            value={Math.min(roundToTwo(+appUserTrack.progress), 100) || 0}
            classes={{
              root: styles.progressBarRoot,
              bar: styles.progressBar,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
