export const getLimitedEditionCards = (state) =>
  state.drops.limitedEditionCards;
export const getLimitedEditionCardsTotal = (state) =>
  state.drops.limitedEditionCardsTotal;
export const getCheckoutUrl = (state) => state.drops.checkoutUrl;
export const getDropCard = (state) => state.drops.drop;
export const getDropsByPhase = (id) => (state) => state.drops.drops[id];
export const getRewardMessagesLoader = (state) =>
  state.drops.currentLimitedEditionReward.loading;
export const getRewardMessages = (state) =>
  state.drops.currentLimitedEditionReward.messages;
