export const AddFriendIcon = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.35375 8.14143C8.91652 8.57866 8.31187 8.85183 7.64345 8.85183V10.3518C8.72948 10.3518 9.71019 9.90631 10.4144 9.20209L9.35375 8.14143ZM7.64345 8.85183C6.30321 8.85183 5.22275 7.77137 5.22275 6.43113H3.72275C3.72275 8.5998 5.47478 10.3518 7.64345 10.3518V8.85183ZM5.22275 6.43113C5.22275 5.09089 6.30321 4.01042 7.64345 4.01042V2.51042C5.47478 2.51042 3.72275 4.26246 3.72275 6.43113H5.22275ZM7.64345 4.01042C8.98369 4.01042 10.0642 5.09089 10.0642 6.43113H11.5642C11.5642 4.26246 9.81212 2.51042 7.64345 2.51042V4.01042ZM3.06526 16.7477C2.40892 14.91 2.29392 13.8892 2.39611 13.3606C2.44115 13.1277 2.51952 13.0373 2.57386 12.9931C2.63925 12.9399 2.76057 12.8818 2.99307 12.8579C3.49439 12.8064 4.18655 12.9371 5.05677 13.1256C5.86057 13.2997 6.81442 13.5224 7.64346 13.5224V12.0224C7.01396 12.0224 6.24772 11.8488 5.37434 11.6596C4.56739 11.4848 3.62532 11.2851 2.83987 11.3658C2.42897 11.4079 1.99377 11.5315 1.62756 11.8292C1.25031 12.136 1.02201 12.5657 0.923381 13.0759C0.738226 14.0336 0.987852 15.3908 1.65264 17.2523L3.06526 16.7477ZM7.64346 13.5224C8.47249 13.5224 9.42635 13.2997 10.2301 13.1256C11.1004 12.9371 11.7925 12.8064 12.2938 12.8579C12.5263 12.8818 12.6477 12.9399 12.7131 12.9931C12.7674 13.0373 12.8458 13.1277 12.8908 13.3606C12.993 13.8892 12.878 14.91 12.2217 16.7477L13.6343 17.2523C14.2991 15.3908 14.5487 14.0336 14.3635 13.0759C14.2649 12.5657 14.0366 12.136 13.6594 11.8292C13.2931 11.5315 12.8579 11.4079 12.447 11.3658C11.6616 11.2851 10.7195 11.4848 9.91257 11.6596C9.0392 11.8488 8.27295 12.0224 7.64346 12.0224V13.5224ZM13.3928 0V2.2438H14.8928V0H13.3928ZM13.3928 2.2438V4.48759H14.8928V2.2438H13.3928ZM14.1428 1.4938H11.899V2.9938H14.1428V1.4938ZM14.1428 2.9938H16.3866V1.4938H14.1428V2.9938Z"
      fill="#111322"
    />
  </svg>
);
