import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { PARTICIPATING_USERS_TYPES } from "constants/";
import {
  getChallengeDetails,
  getChallenges,
  getChallengeUsers,
  getCompletedChallenges,
  markChallengeAsSeen,
} from "services/challengesApi";

export const fetchChallenges = createAsyncThunk(
  "challenges/get",
  async (params) => {
    const response = await getChallenges(params);
    return response.data;
  }
);

export const fetchChallengeDetails = createAsyncThunk(
  "challenge/get",
  async (params) => {
    const response = await getChallengeDetails(params);
    return response.data;
  }
);

export const fetchChallengeUsers = createAsyncThunk(
  "challenge/users/get",
  async (params) => {
    const response = await getChallengeUsers(params);
    return response.data;
  }
);

export const fetchCompletedChallenges = createAsyncThunk(
  "challenges/get/completed",
  async () => {
    const response = await getCompletedChallenges();
    return response.data;
  }
);

export const markAsSeen = createAsyncThunk("challenge/seen", async (params) => {
  const response = await markChallengeAsSeen(params);
  return response.data;
});

const initialState = {
  loading: false,
  challengesList: {
    loading: false,
    entities: {},
    clubLoading: false,
    club: {},
  },
  users: {
    loading: false,
    entities: [],
    isLastPage: false,
    inProgress: {
      entities: [],
      isLastPage: false,
    },
    completed: {
      entities: [],
      isLastPage: false,
    },
  },
  challengeDetails: {},
};

export const initialChallengeDetailsSubState = {
  challenge: {},
  users: {
    rows: [],
    total: 0,
  },
};

const challengesSlice = createSlice({
  name: "challenges",
  initialState,
  reducers: {
    updateChallenge: (state, action) => {
      const { progress, challengeId, influencerId } = action.payload || {};
      if (state.challengesList.club[influencerId]) {
        const challengeIndex = state.challengesList.club[
          influencerId
        ].findIndex((item) => {
          return item.appUserTrack?.challengeStage?.challengeId === challengeId;
        });
        if (
          state.challengesList.club[influencerId][challengeIndex]?.appUserTrack
        ) {
          state.challengesList.club[influencerId][
            challengeIndex
          ].appUserTrack.progress = progress;
        }
      } else {
        let category = null;
        for (const [key, challenges] of Object.entries(
          state.challengesList.entities
        )) {
          for (const challenge of challenges) {
            if (challenge.id === challengeId) {
              category = key;
              break;
            }
          }
          if (category !== null) {
            break;
          }
        }
        if (state.challengesList.entities[category]) {
          const index = state.challengesList.entities[category].findIndex(
            (item) => item.id === challengeId
          );
          if (index !== -1) {
            if (state.challengesList.entities[category][index]?.appUserTrack) {
              state.challengesList.entities[category][
                index
              ].appUserTrack.progress = progress;
            }
          }
        }
      }
    },
    updateChallengeDetailsUser: (state, action) => {
      const { id, pendingConnection, connection } = action.payload || {};
      if (state.challengeDetails[id]) {
        const row = state.challengeDetails[id].users.rows.find(
          (item) => item.appUserId === id
        );
        if (row?.connectionData) {
          row.connectionData.connection = connection;
          row.connectionData.pendingConnection = pendingConnection;
        }
      }
    },
    updateChallengeDetails: (state, action) => {
      const { id, data } = action.payload || {};
      state.challengeDetails[id] = data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChallenges.pending, (state, action) => {
        const { influencerId } = action?.meta?.arg || {};
        if (influencerId !== undefined && influencerId !== null) {
          state.challengesList.clubLoading = true;
        } else {
          state.challengesList.loading = true;
        }
      })
      .addCase(fetchChallenges.rejected, (state, action) => {
        const { influencerId } = action?.meta?.arg || {};
        if (influencerId !== undefined && influencerId !== null) {
          state.challengesList.clubLoading = false;
        } else {
          state.challengesList.loading = false;
        }
      })
      .addCase(fetchChallenges.fulfilled, (state, action) => {
        const { influencerId, category } = action?.meta?.arg || {};
        if (influencerId !== undefined && influencerId !== null) {
          state.challengesList.clubLoading = false;
          state.challengesList.club[influencerId] = action.payload;
        } else if (category) {
          state.challengesList.loading = false;
          state.challengesList.entities[category] = action.payload;
        } else {
          state.challengesList.loading = false;
          state.challengesList.entities = action.payload;
        }
      })
      .addCase(fetchChallengeUsers.pending, (state, action) => {
        state.users.loading = true;
      })
      .addCase(fetchChallengeUsers.rejected, (state, action) => {
        state.users.loading = false;
        state.users.isLastPage = true;
      })
      .addCase(fetchChallengeUsers.fulfilled, (state, action) => {
        state.users.loading = false;

        if (action?.meta?.arg?.type === PARTICIPATING_USERS_TYPES.All) {
          state.users.entities = action.payload;

          state.users.isLastPage =
            action.payload?.usersInProgress?.length +
              action.payload?.usersCompleted?.length <
            action?.meta?.arg?.limit;

          state.users.inProgress.isLastPage =
            action.payload?.usersInProgress?.length < action?.meta?.arg?.limit;

          state.users.completed.isLastPage =
            action.payload?.usersCompleted?.length < action?.meta?.arg?.limit;
        }
        if (action?.meta?.arg?.type === PARTICIPATING_USERS_TYPES.inProgress) {
          state.users.inProgress.entities = action.payload;

          state.users.inProgress.isLastPage =
            action.payload?.usersInProgress?.length < action?.meta?.arg?.limit;
        }
        if (action?.meta?.arg?.type === PARTICIPATING_USERS_TYPES.completed) {
          state.users.completed.entities = action.payload;

          state.users.completed.isLastPage =
            action.payload?.usersCompleted?.length < action?.meta?.arg?.limit;
        }
      })
      .addCase(fetchChallengeDetails.fulfilled, (state, action) => {
        const { page } = action?.meta?.arg || {};
        const id = action.payload.challenge?.id || 0;
        state.challengeDetails[id] = JSON.parse(
          JSON.stringify(initialChallengeDetailsSubState)
        );
        state.challengeDetails[id].challenge = action.payload.challenge;
        if (page === 1) {
          state.challengeDetails[id].users = action.payload.users;
        } else {
          if (action.payload?.users?.rows) {
            state.challengeDetails[id].users.rows = state.challengeDetails[
              id
            ].users.rows.concat(action.payload.users.rows);
          }
        }
      });
  },
});
export const {
  updateChallenge,
  updateChallengeDetailsUser,
  updateChallengeDetails,
} = challengesSlice.actions;
export default challengesSlice.reducer;
