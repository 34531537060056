import { Box, Button, Typography } from "@mui/material";
import React from "react";

import styles from "./Placeholder.module.scss";

export const Placeholder = ({ text, buttonText, onBtnClick, icon }) => {
  return (
    <Box className={styles.placeholder}>
      {icon}
      <Typography
        variant="h5"
        component="h5"
        className={styles.placeholderText}
      >
        {text}
      </Typography>
      <Button variant="contained" onClick={onBtnClick}>
        {buttonText}
      </Button>
    </Box>
  );
};
