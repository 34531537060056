import { Box, Typography } from "@mui/material";
import classNames from "classnames/bind";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import stylesGeneral from "./SellFooter.module.scss";

export const SellFooter = ({
  orderMinPrice,
  ordersCount,
  className,
  overrideStyles,
  currencySymbol,
}) => {
  const styles = overrideStyles || stylesGeneral;

  const cx = classNames.bind(styles);
  const { t } = useContext(LocalizationContext);
  return (
    <Box className={cx(className)}>
      <Box className={styles.row}>
        <Typography className={styles.rowTitle} variant="textNormal_14">
          {t("edition.lowestPrice")}
        </Typography>
        <Box className={styles.rowContent}>
          <Typography
            variant="textMediumBold"
            className={styles.rowContentValue}
          >
            {currencySymbol}
            {orderMinPrice}
          </Typography>
        </Box>
      </Box>
      <Box className={styles.row}>
        <Typography className={styles.rowTitle} variant="textNormal_14">
          {t("edition.cardsForSale")}
        </Typography>
        <Box className={styles.rowContent}>
          <Typography
            variant="textMediumBold"
            className={styles.rowContentValue}
          >
            {ordersCount}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
