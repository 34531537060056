import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as IconLiked } from "assets/svg/feed/liked.svg";
import { ReactComponent as IconReaction } from "assets/svg/icon-reaction.svg";
import { PATHS } from "constants/index";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";

import styles from "./Likes.module.scss";

const Likes = ({ count, isLiked, onChange, accessGranted }) => {
  const { t } = useContext(LocalizationContext);
  const [isAnimating, setIsAnimating] = useState(false);
  const navigate = useNavigate();
  const isAuth = isAuthenticated();

  const handleClick = () => {
    if (!isAuth) {
      navigate(PATHS.SIGN_UP);
      return;
    } else if (!accessGranted) {
      return;
    }
    setIsAnimating(!isLiked);
    onChange(!isLiked);
  };
  return (
    <Box className={styles.like} onClick={handleClick}>
      <IconButton
        className={`${styles.btn} ${isAnimating ? styles.liked : ""}`}
      >
        {isLiked ? (
          <IconLiked className={styles.likeIcon} />
        ) : (
          <IconReaction className={styles.likeIcon} />
        )}
      </IconButton>

      <Typography variant="body2" className={styles.likeText}>
        {count > 0 && count}{" "}
        {t("discover.likes", {
          count: count > 0 ? count : 1,
          useSingular: true,
        })}
      </Typography>
    </Box>
  );
};
export default Likes;
