import { useEffect, useRef } from "react";
import Lottie from "lottie-react";
import { ANIMATION_DIRECTION } from "constants/";

const AnimatedCardIcon = ({
  animationData,
  play,
  handleComplete,
  direction,
  className,
  fallbackIcons,
  changeAnimationDirection,
}) => {
  const lottieRef = useRef();
  const numberOfFrames = animationData.op;

  const options = {
    animationData: animationData,
    loop: false,
    autoplay: false,
    initialSegment: [0, numberOfFrames],
    onComplete: () => handleComplete(),
  };

  useEffect(() => {
    if (
      direction === ANIMATION_DIRECTION.backward &&
      changeAnimationDirection
    ) {
      lottieRef.current?.goToAndStop(numberOfFrames, true);
    }
  }, []);

  useEffect(() => {
    changeAnimationDirection && lottieRef.current?.setDirection(direction);
    if (play) {
      lottieRef.current?.play();
    }
  }, [play]);

  if (
    !play &&
    !!fallbackIcons?.empty &&
    direction === ANIMATION_DIRECTION.forward
  ) {
    return fallbackIcons.empty;
  }

  if (
    !play &&
    !!fallbackIcons?.filled &&
    direction === ANIMATION_DIRECTION.backward
  ) {
    return fallbackIcons.filled;
  }

  return (
    <Lottie
      lottieRef={lottieRef}
      {...options}
      className={className ? className : ""}
    />
  );
};

export default AnimatedCardIcon;
