import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { useLocale } from "utils/hooks";
import styles from "./CommunityInfo.module.scss";
import DOMPurify from "dompurify";

const NoRenewalInfo = ({ influencerData }) => {
  const locale = useLocale();
  const { t } = useContext(LocalizationContext);
  const { subscriptionData, currencySymbol, community } = influencerData || {};
  const { currentPeriodEndAt } = subscriptionData || {};

  const endDateFormatted = currentPeriodEndAt
    ? format(new Date(currentPeriodEndAt), "dd MMM yyyy", {
        locale,
      })
    : null;
  return (
    <Box className={styles.info}>
      <Typography
        variant="body1Display"
        className={styles.infoHtmlText}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            t("subscription.noRenewalDesc", {
              price: `${currencySymbol}${community?.subscriptionPrice}`,
              endDate: endDateFormatted,
            })
          ),
        }}
      />
    </Box>
  );
};
export default NoRenewalInfo;
