import { INFLUENCERS_LOAD_LIMIT } from "constants/index";
import { useEffect, useState } from "react";
import { getInfluencers } from "services/discoverApi";

export const useData = ({ level, featured = 1 }) => {
  const [data, setData] = useState([]);
  const [loader, setLoading] = useState(true);
  const [scrollLoader, setScrollLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  const getFilteredInfluencers = (params, loaderFuncCb) => {
    loaderFuncCb(true);
    return getInfluencers({
      level,
      featured,
      limit: INFLUENCERS_LOAD_LIMIT,
      ...params,
    })
      .then((cards) => {
        if (cards?.data) {
          setData((prevState) => [...prevState, ...cards.data]);
        }
      })
      .finally(() => loaderFuncCb(false));
  };

  const loadMore = () =>
    setOffset((prevState) => prevState + INFLUENCERS_LOAD_LIMIT);

  useEffect(() => {
    getFilteredInfluencers({}, setLoading);
  }, []);

  useEffect(() => {
    if (offset > 0) {
      getFilteredInfluencers({ offset }, setScrollLoading);
    }
  }, [offset]);

  return {
    loader,
    loadMore,
    data,
    scrollLoader,
    hasMore: !(data.length < offset + INFLUENCERS_LOAD_LIMIT),
  };
};
