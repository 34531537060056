import mixpanel from "mixpanel-browser";

class Analytics {
  static identify({ userId }) {
    mixpanel.identify(userId);
    window.dataLayer.push({
      event: "identify",
      userId: userId,
    });
  }

  static track(trackName, params) {
    mixpanel.track(trackName, params);
    window.dataLayer.push({
      event: trackName,
      ...params,
    });
  }

  static page(pageName, params) {
    window.dataLayer.push({
      event: "page_view",
      pageName,
      ...params,
    });
    mixpanel.track("page_view", {
      pageName,
      ...params,
    });
  }

  static reset() {
    mixpanel.reset();
    window.dataLayer.push({
      event: "reset",
    });
  }
}

export default Analytics;
