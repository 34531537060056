import { Box } from "@mui/material";
import { PATHS } from "constants/index";
import React from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "services/auth.service";

import { Audio } from "../../../Audio/Audio";
import styles from "./AudioBlock.module.scss";

export const AudioWrapper = ({
  src,
  postIndex,
  mediaIndex,
  accessGranted = true,
  handle,
}) => {
  const navigate = useNavigate();
  const isAuth = isAuthenticated();

  const audioHandler = () => {
    let stopExecution = false;
    if (!accessGranted) {
      stopExecution = true;
      if (isAuth) {
        handle && navigate(`/${handle}`);
      } else {
        navigate(PATHS.SIGN_UP);
      }
    }

    return stopExecution;
  };

  return (
    <Box className={styles.audioBox}>
      <Audio
        src={src}
        callbackFunc={audioHandler}
        mediaIndex={mediaIndex}
        parentIndex={postIndex}
      />
    </Box>
  );
};
