import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { LocalizationContext } from "services/localizationContext";
import { dateLocales } from "constants/";
import { format } from "date-fns";

import styles from "./ReferralCard.module.scss";

export const ReferralCard = ({
  className,
  imageUrl,
  name,
  joinedAt,
  earnings,
  isLarge,
  locale,
  isInfluencer,
}) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Box
      className={`${styles.card} ${isLarge ? styles.large : ""} ${
        className || ""
      }`}
    >
      <img
        className={`${styles.cardImage} ${
          isInfluencer ? styles.cardImageInfluencer : ""
        }`}
        src={imageUrl}
        alt=""
      />
      <Box className={styles.cardNameWrap}>
        <Typography className={styles.cardName} variant="h6">
          {name}
        </Typography>
        <Typography className={styles.cardDate} variant="body1">
          {t("referral.joined")}:{" "}
          {format(new Date(joinedAt), "MMM dd", {
            locale: !!locale ? dateLocales[locale] : null,
          })}
        </Typography>
      </Box>
      <Typography variant="body2" className={styles.cardTotal}>
        {earnings > 0
          ? `${t("referral.earnings")}: $${earnings}`
          : t("referral.noEarnings")}
      </Typography>
    </Box>
  );
};

export default ReferralCard;
