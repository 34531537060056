import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ReactComponent as CloseIcon } from "assets/svg/close-icon.svg";
import StripeCheckoutForm from "components/Stripe/CheckoutForm";
import PaymentStatus from "components/SubscribeInfluencer/PaymentStatus";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./StripeCheckoutModal.module.scss";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const StripePayOrderModal = ({
  clientSecret,
  onCloseModal,
  title,
  paymentStatusOptions,
  onPaymentSuccess,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const clearPaymentStatusFromUrl = () => {
    navigate(location.pathname, { replace: true });
  };
  const clientSecretFromUrl = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );
  const secretKey = clientSecret || clientSecretFromUrl;
  if (!secretKey) return null;
  const showCheckoutModal = !!clientSecret;

  const stripeOptions = {
    clientSecret: secretKey,
    appearance: {
      theme: "night",
      variables: {
        colorPrimary: theme.palette.dark.main,
        colorBackground: theme.palette.surface.main,
        colorText: theme.palette.dark.main.contrastText,
        colorDanger: theme.palette.coral.main,
        fontFamily: theme.typography.fontFamily,
        spacingUnit: "4px",
        borderRadius: "8px",
      },
    },
  };

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <StripeCheckoutModal
        open={showCheckoutModal}
        onClose={onCloseModal}
        title={title}
      />
      {clientSecretFromUrl && (paymentStatusOptions || !!onPaymentSuccess) ? (
        <PaymentStatus
          onPaymentSuccess={onPaymentSuccess}
          onClose={clearPaymentStatusFromUrl}
          clientSecret={clientSecretFromUrl}
          {...paymentStatusOptions}
        />
      ) : null}
    </Elements>
  );
};
export const StripeCheckoutModal = ({ open, onClose, title }) => {
  const location = useLocation();
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        classes={{
          root: styles.modal,
        }}
      >
        <Box className={styles.modalBody}>
          <IconButton onClick={onClose} className={styles.close}>
            <CloseIcon />
          </IconButton>
          <Box className={styles.modalBodyContent}>
            <Typography variant="h4" className={styles.title}>
              {title}
            </Typography>

            <StripeCheckoutForm
              returnUrl={`${window.origin}${location.pathname}`}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};
