import Lottie from "lottie-react";
import { useEffect, useRef, useState } from "react";

export const LottieAnimation = ({ url, speed = 1 }) => {
  const lottieRef = useRef();
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    const fetchAnimationData = async () => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        setAnimationData(data);
      } catch (error) {
        console.error("Error fetching Lottie animation:", error);
      }
    };

    fetchAnimationData();
  }, [url]);

  return (
    <>
      {animationData && (
        <Lottie
          animationData={animationData}
          style={{ width: "100%", height: "100%" }}
          loop={true}
          autoplay={true}
          lottieRef={lottieRef}
          onDOMLoaded={() => {
            lottieRef.current.setSpeed(speed);
          }}
        />
      )}
    </>
  );
};
