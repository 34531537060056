import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import {
  deleteChat,
  getChat,
  getChats,
  getUnreadCountMessages,
  patchMessagesSeen,
  postNewChat,
  sendMessage,
} from "services/api/messangerApi";

import { CHAT_CATEGORIES, TMP_USER_CHAT_ID } from "../constants";
import { arrayToObjConverter } from "../utils";
import { getDeletedChatId } from "./selectors/messengerSelectors";

export const fetchChats = createAsyncThunk("get/chats", async (params) => {
  const response = await getChats(params);
  return response;
});

export const fetchChat = createAsyncThunk("get/chat", async (params) => {
  const response = await getChat(params);
  return response;
});

export const postMessage = createAsyncThunk(
  "post/message",
  async (params, { rejectWithValue }) => {
    try {
      const response = await sendMessage(params);
      return response;
    } catch (err) {
      return rejectWithValue(err?.data || err);
    }
  }
);

export const createChat = createAsyncThunk(
  "post/new-chat",
  async (params, { rejectWithValue }) => {
    try {
      const response = await postNewChat(params);
      return response;
    } catch (err) {
      return rejectWithValue(err?.data || err);
    }
  }
);

export const getGlobalUnreadCountMessages = createAsyncThunk(
  "get/unread-count-messages",
  async (params) => {
    const response = await getUnreadCountMessages(params);
    return response;
  }
);

export const patchMessagesSeenInChat = createAsyncThunk(
  "patch/seen-messages",
  async (params, { rejectWithValue }) => {
    try {
      const response = await patchMessagesSeen(params);
      return response;
    } catch (err) {
      return rejectWithValue(err?.data || err);
    }
  }
);

export const deleteChatSlice = createAsyncThunk(
  "delete/chat",
  async (params, { rejectWithValue }) => {
    try {
      const response = await deleteChat(params);
      return response;
    } catch (err) {
      return rejectWithValue(err?.data || err);
    }
  }
);

const deleteChatHandler = (state, chatId, type) => {
  const chatCategory = type || CHAT_CATEGORIES.GENERAL;

  if (state.singleChats[chatId]) {
    delete state.singleChats[chatId];
  }
  if (state.chats[chatCategory] && state.chats[chatCategory][chatId]) {
    delete state.chats[chatCategory][chatId];
    if (state.totalChats[chatCategory]) {
      state.totalChats[chatCategory] = state.totalChats[chatCategory] - 1;
    }
  }
  if (state.requestsChats[chatId]) {
    delete state.requestsChats[chatId];
    if (state.totalRequests) {
      state.totalRequests = state.totalRequests - 1;
    }
  }
};

const initialState = {
  selectedUser: null,
  chats: {
    premium: {},
    general: {},
  },
  requestsChats: {},
  totalChats: {
    general: 0,
    premium: 0,
  },
  totalRequests: 0,
  total: 0,
  chatsLoader: false,
  chatsRequestsLoader: false,
  hasMoreChats: {
    general: false,
    premium: false,
  },
  hasMoreRequestsChats: false,
  singleChats: {},
  singleChatsLoader: false,
  globalAmountUnreadMessages: 0,
  isLoadingByChatCreate: false,
  activeChat: null,
  deletedChatId: null,
};

export const messengerSlice = createSlice({
  name: "messenger",
  initialState,
  reducers: {
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
    },
    setActiveChat(state, action) {
      state.activeChat = action.payload;
    },
    setDeletedChatId(state, action) {
      state.deletedChatId = action.payload;
    },
    setNewChat(state, action) {
      const { id, type } = action.payload || {};
      const chatCategory = type || CHAT_CATEGORIES.GENERAL;
      if (id) {
        state.chats[chatCategory][id] = action.payload;
        state.totalChats[chatCategory] = state.totalChats[chatCategory] + 1;
      } else {
        delete state.chats[chatCategory][TMP_USER_CHAT_ID];
        if (state.totalChats[chatCategory]) {
          state.totalChats[chatCategory] = state.totalChats[chatCategory] - 1;
        }
      }
    },
    addNewMessage(state, action) {
      const { chatId, message, timestamp, id, chatAccepted, isPremium } =
        action.payload;
      const prop = !chatAccepted ? "requestsChats" : "chats";
      const type = isPremium
        ? CHAT_CATEGORIES.PREMIUM
        : CHAT_CATEGORIES.GENERAL;
      if (chatAccepted) {
        if (type && state[prop][type][chatId]) {
          state[prop][type][chatId].updatedAt = timestamp;
          state[prop][type][chatId].lastMessage = {
            id,
            messageContent: message,
            createdAt: timestamp,
            updatedAt: timestamp,
            timestamp,
          };
        }
      } else {
        if (state[prop][chatId]) {
          state[prop][chatId].updatedAt = timestamp;
          state[prop][chatId].lastMessage = {
            id,
            messageContent: message,
            createdAt: timestamp,
            updatedAt: timestamp,
            timestamp,
          };
        }
      }

      if (!state.singleChats[chatId]) {
        state.singleChats[chatId] = {};
      }
      if (!state.singleChats[chatId]?.messages) {
        state.singleChats[chatId].messages = {};
      }
      state.singleChats[chatId].messages[timestamp] = {
        ...action.payload,
        messageContent: message,
        createdAt: timestamp,
      };
    },
    updateGlobalCounterUnreadMessages(state, action) {
      const { count } = action.payload || { count: 0 };
      state.globalAmountUnreadMessages = count;
    },
    updateChatCounterUnreadMessages(state, action) {
      const { count, chatId, chatAccepted, isPremium } = action.payload || {
        count: 0,
      };
      const chatCategory = isPremium
        ? CHAT_CATEGORIES.PREMIUM
        : CHAT_CATEGORIES.GENERAL;
      if (state.chats[chatCategory][chatId] && chatAccepted) {
        state.chats[chatCategory][chatId].newMessages = count;
      }
      if (state.requestsChats[chatId] && !chatAccepted) {
        state.requestsChats[chatId].newMessages = count;
      }
    },
    markMessageAsSeen(state, action) {
      const { timestamp, chatId } = action.payload || {};
      if (state.singleChats[chatId]?.messages[timestamp]) {
        state.singleChats[chatId].messages[timestamp].seen = true;
      }
    },
    markAllUnseenMessagesAsSeen(state, action) {
      const { chatId, timestamp } = action.payload;
      const chat = state.singleChats[chatId];
      if (chat) {
        Object.entries(chat.messages).forEach(([_, message]) => {
          message.seen = message.timestamp <= timestamp;
        });
      }
    },
    blockChatToggle(state, action) {
      const { chatId, chatBlocked, type } = action.payload || {};
      const chatCategory = type || CHAT_CATEGORIES.GENERAL;

      if (state.chats[chatCategory][chatId]) {
        state.chats[chatCategory][chatId].chatBlocked = chatBlocked;
      }
      if (+state.activeChat?.id === +chatId) {
        state.activeChat.chatBlocked = chatBlocked;
      }
    },
    deleteMessage(state, action) {
      const { timestamp, chatId, prevMessage, type } = action.payload || {};
      const chatCategory = type || CHAT_CATEGORIES.GENERAL;

      if (state.singleChats[chatId]?.messages) {
        delete state.singleChats[chatId]?.messages[timestamp];
      }
      if (
        state.chats[chatCategory][chatId]?.lastMessage?.timestamp ===
          timestamp &&
        state.chats[chatCategory][chatId]?.lastMessage?.messageContent
      ) {
        if (prevMessage) {
          state.chats[chatCategory][chatId].lastMessage = prevMessage;
        } else {
          state.chats[chatCategory][chatId].lastMessage.messageContent = "";
        }
      }
    },
    deleteChatAction(state, action) {
      const { chatId, type } = action.payload || {};
      deleteChatHandler(state, chatId, type);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchChats.pending, (state, action) => {
        const { retrieveRequestsList } = action.meta?.arg || {};
        if (retrieveRequestsList) {
          state.chatsRequestsLoader = true;
        } else {
          state.chatsLoader = true;
        }
      })
      .addCase(fetchChats.fulfilled, (state, action) => {
        const { page, limit, retrieveRequestsList, type } =
          action.meta?.arg || {};
        const { chats, totalChats, totalRequests, total } =
          action.payload || {};
        const prop = retrieveRequestsList ? "requestsChats" : "chats";

        if (chats.length) {
          if (type) {
            state[prop][type] = {
              ...state[prop][type],
              ...arrayToObjConverter({
                key: "id",
                arr: chats,
              }),
            };
          } else {
            state[prop] = {
              ...state[prop],
              ...arrayToObjConverter({
                key: "id",
                arr: chats,
              }),
            };
          }
        }

        state.total = total || 0;
        if (retrieveRequestsList) {
          state.chatsRequestsLoader = false;
          state.hasMoreRequestsChats = page * limit < totalRequests;
          state.totalRequests = totalRequests || 0;
        } else {
          state.chatsLoader = false;
          state.hasMoreChats[type] = page * limit < totalChats;
          state.totalChats[type] = totalChats || 0;
        }
      })
      .addCase(fetchChats.rejected, (state, action) => {
        const { retrieveRequestsList } = action.meta?.arg || {};
        if (retrieveRequestsList) {
          state.chatsRequestsLoader = false;
        } else {
          state.chatsLoader = false;
        }
      })
      .addCase(fetchChat.pending, (state) => {
        state.singleChatsLoader = true;
      })
      .addCase(fetchChat.fulfilled, (state, action) => {
        const { chatId } = action.meta?.arg || {};
        state.singleChatsLoader = false;
        if (chatId && action.payload.messages?.length) {
          const messages = {
            ...(state.singleChats[chatId]?.messages || {}),
            ...arrayToObjConverter({
              key: "timestamp",
              arr: action.payload.messages,
            }),
          };

          state.singleChats[chatId] = {
            messages,
            total: action.payload.total,
          };
        }
      })
      .addCase(fetchChat.rejected, (state) => {
        state.singleChatsLoader = false;
      })
      .addCase(createChat.pending, (state) => {
        state.isLoadingByChatCreate = true;
      })
      .addCase(createChat.fulfilled, (state, action) => {
        const { id, type } = action.payload || {};
        const chatCategory = type || CHAT_CATEGORIES.GENERAL;
        state.isLoadingByChatCreate = false;

        if (id) {
          const clone = JSON.parse(
            JSON.stringify(state.singleChats[TMP_USER_CHAT_ID])
          );
          state.chats[chatCategory][id] = action.payload;
          state.singleChats[id] = clone;
          delete state.chats[chatCategory][TMP_USER_CHAT_ID];
          delete state.singleChats[TMP_USER_CHAT_ID];
        }
      })
      .addCase(postMessage.fulfilled, (state, action) => {
        const { chatId, chatAccepted, isMy, type } = action.meta?.arg || {};
        const chatCategory = type || CHAT_CATEGORIES.GENERAL;
        if (!chatAccepted && chatId && isMy) {
          state.totalRequests -= 1;
          if (state.requestsChats && state.requestsChats[chatId]) {
            if (!state.chats) {
              state.chats = {};
            }
            state.chats[chatCategory][chatId] = state.requestsChats[chatId];
            delete state.requestsChats[chatId];
          }
        }
      })
      .addCase(createChat.rejected, (state) => {
        state.isLoadingByChatCreate = false;
      })
      .addCase(getGlobalUnreadCountMessages.fulfilled, (state, action) => {
        state.globalAmountUnreadMessages = action.payload?.count || 0;
      })
      .addCase(patchMessagesSeenInChat.fulfilled, (state, action) => {
        const { chatId, type } = action.meta?.arg || {};
        const chatCategory = type || CHAT_CATEGORIES.GENERAL;
        if (state.chats[chatCategory] && state.chats[chatCategory][chatId]) {
          state.chats[chatCategory][chatId].newMessages = 0;
        }
      })
      .addCase(deleteChatSlice.fulfilled, (state, action) => {
        const { chatId, type } = action.meta?.arg || {};
        deleteChatHandler(state, chatId, type);
      });
  },
});

export const {
  setSelectedUser,
  addNewMessage,
  updateGlobalCounterUnreadMessages,
  updateChatCounterUnreadMessages,
  setNewChat,
  markMessageAsSeen,
  markAllUnseenMessagesAsSeen,
  blockChatToggle,
  deleteMessage,
  deleteChatAction,
  setActiveChat,
  setDeletedChatId,
} = messengerSlice.actions;

export default messengerSlice.reducer;
