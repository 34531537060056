import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as CloseIcon } from "assets/svg/close-icon.svg";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "redux/modalSlice";
import { LocalizationContext } from "services/localizationContext";

import { Indicators } from "./components/Indicators";
import { Slides } from "./components/Slides";
import styles from "./OnboardingModal.module.scss";

export const OnboardingModal = ({ children }) => {
  const childrenNodes = children.props.children;
  const theme = useTheme();
  const dispatch = useDispatch();

  const [activeSlide, setActiveSlide] = useState(0);

  const { t } = useContext(LocalizationContext);

  const handleClose = () => dispatch(closeModal());

  const isFinish = activeSlide === childrenNodes.length - 1;
  const isFirstSlide = activeSlide === 0;

  const setActiveSlideHandler = (index) => () => setActiveSlide(index);

  const prevSlideHandler = useCallback(() => {
    if (!isFirstSlide) {
      setActiveSlide((prevState) => prevState - 1);
    }
  }, [isFirstSlide]);

  const nextSlideHandler = useCallback(() => {
    if (!isFinish) {
      setActiveSlide((prevState) => prevState + 1);
    }
  }, [isFinish]);

  useEffect(() => {
    document.addEventListener("swipe-left", nextSlideHandler);
    document.addEventListener("swipe-right", prevSlideHandler);

    return () => {
      document.removeEventListener("swipe-left", nextSlideHandler);
      document.removeEventListener("swipe-right", prevSlideHandler);
    };
  }, [nextSlideHandler, prevSlideHandler]);

  const PrevButton = () =>
    isFirstSlide ? (
      <Button variant="outlined" onClick={handleClose}>
        <Typography variant="body1Display">{t("default.skip")}</Typography>
      </Button>
    ) : (
      <Button variant="outlined" onClick={prevSlideHandler}>
        <Typography variant="body1Display">{t("default.back")}</Typography>
      </Button>
    );

  const NextButton = () =>
    isFinish ? (
      <Button variant="contained" onClick={handleClose}>
        <Typography
          variant="textMediumBold2"
          style={{ color: theme.palette.dark.main }}
        >
          {t("default.finish")}
        </Typography>
      </Button>
    ) : (
      <Button variant="contained" onClick={nextSlideHandler}>
        <Typography
          variant="textMediumBold2"
          style={{ color: theme.palette.dark.main }}
        >
          {t("default.next")}
        </Typography>
      </Button>
    );

  return (
    <Box className={styles.modal}>
      <Button onClick={handleClose} className={styles.close} variant="text">
        <CloseIcon />
      </Button>
      <Box className={styles.modalContent}>
        {<Slides data={childrenNodes} activeSlide={activeSlide} />}
      </Box>
      <Box className={styles.modalIndicator}>
        <Indicators
          data={childrenNodes}
          activeSlide={activeSlide}
          setActiveSlideHandler={setActiveSlideHandler}
        />
      </Box>
      <Box className={styles.modalActions}>
        <PrevButton />
        <NextButton />
      </Box>
    </Box>
  );
};
