import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import EditionCard from "components/Card/Edition/EditionCard";
import CollectionsEmptyPlacaholder from "components/CollectionsEmptyPlacaholder";
import UnfriendModal from "components/Modal/UnfriendModal";
import { PATHS } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setError, setLoader, setOnfidoToken } from "redux/appSlice";
import { closeModal, openModal } from "redux/modalSlice";
import {
  fetchUser,
  fetchUserEditions,
  toggleCardBarter,
  updateCard,
  updateTrophyCard,
} from "redux/usersSlice";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";
import { CheckKycStatus } from "utils/hooks/kycCheck";

import styles from "./Collections.module.scss";

const Editions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const editions = useSelector((state) => state.users.editions.entities);
  const loading = useSelector((state) => state.users.editions.loading);
  const trophyCardId = useSelector(
    (state) => state.users.entities?.trophyCardId
  );
  const userIsCompleted = useSelector(
    (state) => state.users.entities?.isCompleted
  );

  const [withdrawDlgId, seWithdrawDlgId] = useState(null);

  const checkStatus = CheckKycStatus();

  useEffect(() => {
    dispatch(fetchUserEditions());
  }, []);

  const handleUpdateCard = (payload) => {
    const handleConfirm = () => {
      dispatch(setLoader(true));
      dispatch(updateCard(payload))
        .then(() => {
          dispatch(toggleCardBarter(payload));
          dispatch(closeModal());
        })
        .finally(() => dispatch(setLoader(false)));
    };

    if ("isSwappable" in payload.props && !payload.props?.isSwappable) {
      dispatch(
        openModal({
          children: (
            <UnfriendModal
              description={t("account.toggleSwappableModalText")}
              onConfirm={handleConfirm}
              onCancel={() => dispatch(closeModal())}
              cancelBtnText={t("default.cancel")}
              confirmBtnText={t("default.proceed")}
            />
          ),
        })
      );
    } else {
      handleConfirm();
    }
  };

  const wrapClassName = editions?.length ? styles.cardsWrap : styles.wrapper;

  const goToCompleteProfile = () => {
    localStorage.setItem("completeProfileBackRoute", location.pathname);
    navigate(PATHS.COMPLETE_PROFILE);
  };

  const handleWithdrawNft = (id) => {
    dispatch(setLoader(true));
    checkStatus()
      .then((status) => {
        if (status?.sdkToken) {
          dispatch(setLoader(true));
          return dispatch(
            setOnfidoToken({
              sdkToken: status?.sdkToken,
            })
          );
        }
        if (status !== 1) {
          return;
        }

        if (userIsCompleted) {
          seWithdrawDlgId(id);
        } else {
          goToCompleteProfile();
        }
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              subtitle: errorResponseMessages(err?.data || err, t),
            })
          );
        }
        console.log(err?.data || err);
      })
      .finally(() => dispatch(setLoader(false)));
  };

  const handleTrophyCard = (cardId) => {
    dispatch(setLoader(true));
    dispatch(updateTrophyCard({ cardId })).then(() => {
      dispatch(fetchUser());
      dispatch(setLoader(false));
    });
  };

  const sortedCards = (cards) => {
    const newArr = [];
    cards.forEach((item) => {
      if (item.id === trophyCardId) {
        newArr.unshift(item);
      } else {
        newArr.push(item);
      }
    });
    return newArr;
  };

  return (
    <Box
      className={[wrapClassName, editions?.length ? styles.editionsWrap : ""]}
    >
      {loading ? (
        <Stack spacing={3} direction="row">
          <Stack spacing={1}>
            <Skeleton width={153} height={215} variant="rectangular" />
            <Skeleton variant="rectangular" />
          </Stack>
          <Stack spacing={1}>
            <Skeleton width={153} height={215} variant="rectangular" />
            <Skeleton variant="rectangular" />
          </Stack>
          <Stack spacing={1}>
            <Skeleton width={153} height={215} variant="rectangular" />
            <Skeleton variant="rectangular" />
          </Stack>
        </Stack>
      ) : editions?.length ? (
        sortedCards(editions).map((card) => (
          <EditionCard
            key={`edition-${card.id}`}
            className={styles.card}
            wrapClassName={styles.editionCardWrap}
            {...card}
            isTrophy={card.id === trophyCardId}
            makeTrophyCard={() => handleTrophyCard(card.id)}
            editionsDropped={card.series}
            cardImage={card.imageUrl}
            onClick={() => navigate(`/pass/${card.id}`)}
            toggleBarterEnabled={() =>
              handleUpdateCard({
                cardId: card.id,
                props: { isSwappable: !card.isSwappable },
              })
            }
            handleWithdrawNft={handleWithdrawNft}
            seWithdrawDlgId={seWithdrawDlgId}
            withdrawDlgId={withdrawDlgId}
            showOptsBtn
            inBarter={false}
          />
        ))
      ) : (
        <CollectionsEmptyPlacaholder title={t("account.noCardsToDisplay")} />
      )}
    </Box>
  );
};
export default Editions;
