import { Box } from "@mui/material";
import { CognitoUser } from "amazon-cognito-identity-js";
import { EMULATE, errorResponseTypes, RESET_PASSWORD_STATES } from "constants/";
import React, { useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "redux/appSlice";
import Analytics from "services/Analytics.service";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { isEmulationMode } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import UserPool from "services/userPool";

import Email from "./Email";
import Password from "./Password";
import styles from "./ResetPassword.module.scss";
import Success from "./Success";
import VerificationCode from "./VerificationCode";

const ResetPassword = ({ onSuccessCb }) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.users.entities);

  const [state, setState] = useState(RESET_PASSWORD_STATES.email);
  const [serverError, setServerError] = useState(false);
  const [code, setCode] = useState("");
  const [resendCountdown, setResendCountdown] = useState(0);

  const email = user?.email;
  const cognitoUser = useMemo(() => {
    if (!email) {
      return null;
    }

    return new CognitoUser({
      Username: email,
      Pool: UserPool(),
    });
  }, [email]);

  const sendCode = (email, isResend) => {
    if (cognitoUser) {
      if (!isEmulationMode()) {
        dispatch(setLoader(true));
        setServerError(false);

        cognitoUser.forgotPassword({
          onSuccess: function (data) {
            dispatch(setLoader(false));
            if (!isResend) {
              setState(RESET_PASSWORD_STATES.code);
            } else {
              setResendCountdown(15);
            }
          },
          onFailure: function (err) {
            setServerError(errorResponseMessages(err, t));
            dispatch(setLoader(false));
          },
        });
      } else {
        console.error(EMULATE.ACCESS_ERROR);
      }
    }
  };

  const onSubmitCode = (code) => {
    setCode(code);
    setState(RESET_PASSWORD_STATES.password);
  };

  const onSubmit = (password) => {
    if (cognitoUser) {
      if (!isEmulationMode()) {
        dispatch(setLoader(true));
        setServerError(false);

        cognitoUser.confirmPassword(code, password, {
          onSuccess() {
            Analytics.track("Reset Password", { email });
            dispatch(setLoader(false));
            setState(RESET_PASSWORD_STATES.success);
          },
          onFailure(err) {
            dispatch(setLoader(false));
            setServerError(errorResponseMessages(err, t));
            if (
              err.code === errorResponseTypes.CodeMismatchException ||
              err.code === errorResponseTypes.CodeExpiredException
            ) {
              setState(RESET_PASSWORD_STATES.code);
            }
          },
        });
      } else {
        console.error(EMULATE.ACCESS_ERROR);
      }
    }
  };

  const getContent = () => {
    switch (state) {
      case RESET_PASSWORD_STATES.code:
        return (
          <VerificationCode
            email={email}
            onResend={sendCode}
            onSubmit={onSubmitCode}
            mainServerError={serverError}
            resendCountdown={resendCountdown}
            setResendCountdown={setResendCountdown}
          />
        );
      case RESET_PASSWORD_STATES.password:
        return <Password onSubmit={onSubmit} />;
      case RESET_PASSWORD_STATES.success:
        return <Success onContinue={onSuccessCb} />;
      default:
        return (
          <Email
            email={email}
            onSubmit={sendCode}
            mainServerError={serverError}
          />
        );
    }
  };

  return <Box className={styles.container}>{getContent()}</Box>;
};

export default ResetPassword;
