import { Box } from "@mui/material";
import Countdown from "components/Countdown";
import { differenceInSeconds, isValid } from "date-fns";
import Proptypes from "prop-types";
import * as React from "react";
import { useState } from "react";

import { LinearProgressStyled } from "./LinearProgress";

const CountdownProgress = (
  { endDate, startDate, color, children, className },
  ref
) => {
  const range = differenceInSeconds(new Date(endDate), new Date(startDate));

  const getValue = () => {
    const diff = Math.max(
      0,
      differenceInSeconds(new Date(endDate), new Date())
    );
    return 100 - (100 * diff) / range;
  };
  const [value, setValue] = useState(getValue);

  const onSetTimer = (val) => {
    setValue(getValue);
    setSecondsLeft(differenceInSeconds(new Date(endDate), new Date()));
  };
  const [secondsLeft, setSecondsLeft] = useState(
    differenceInSeconds(new Date(endDate), new Date())
  );

  return (
    <Box className={className}>
      <LinearProgressStyled color={color} value={value} />
      <Countdown value={secondsLeft} onSetValue={onSetTimer}></Countdown>
      {children}
    </Box>
  );
};
const validDate = (props, propName, componentName) => {
  if (!isValid(new Date(props[propName])))
    return new Error(
      `Invalid prop ${propName} passed to ${componentName}. Expected a valid Date.`
    );
};
CountdownProgress.propTypes = {
  endDate: validDate,
  startDate: validDate,
  color: Proptypes.shape({
    main: Proptypes.string,
  }).isRequired,
};
export default CountdownProgress;
