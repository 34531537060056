import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames/bind";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import BackBtn from "../../components/Layout/BackBtn/BackBtn";
import { BottomMenu } from "../../components/Layout/BottomMenu/BottomMenu";
import Header from "../../components/Layout/Header/Header";
import { PATHS } from "../../constants";
import { isAuthenticated } from "../../services/auth.service";
import { addBodyClass, BODY_CLASSES, removeBodyClass } from "../../utils";
import styles from "./PageLayout.module.scss";

const HIDE_HEADER_ROUTE = [
  PATHS.EMAIL_VERIFICATION,
  PATHS.COMPLETE_PROFILE,
  PATHS.BLOCKED,
];

const excludedPaths = [
  PATHS.LOGIN,
  PATHS.SIGN_UP,
  PATHS.FORGOT_PASSWORD,
  PATHS.PAGE_404,
  PATHS.PAGE_500,
  PATHS.PAGE_503,
  PATHS.TELEGRAM_ONBOARDING_SPLASH,
  PATHS.TELEGRAM_SLIDE_EARN,
  PATHS.TELEGRAM_SLIDE_POINTS,
  PATHS.TELEGRAM_SLIDE_FRIENDS,
  PATHS.TELEGRAM_SLIDE_AIRDROP,
  PATHS.TELEGRAM_ONBOARDING_GIFT,
  PATHS.REFERRALS,
];

export const PageLayout = ({
  title,
  subtitle,
  children,
  isBack,
  backRoute,
  pageContentClassName,
  pageWrapperClassName,
  pageClassName,
  headerClassName,
  state,
  isPlain,
  onBackBtnClick,
  isTitleUppercase = true,
  backBtnProps = {},
  isSearch,
  customMobileHeaderMenu,
  isLogo = false,
  isShowHeader = true,
  isBottomMenuHide = false,
  headerChildren,
  headerSubtitleClass,
  titleTag,
}) => {
  let location = useLocation();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("lg_small"));

  const isHideHeader =
    HIDE_HEADER_ROUTE.includes(location.pathname) || !isShowHeader;

  const isMainScreen =
    location.pathname.split("/").length === 2 ||
    location.pathname.includes(PATHS.CHAT);

  const isHistoryExist = location.key !== "default";

  const isExcludedPath = !excludedPaths.some((path) =>
    location.pathname.includes(path)
  );

  const isBottomMenu =
    (isMobile && isExcludedPath && isMainScreen && !isBottomMenuHide) ||
    (!isHistoryExist && isMobile && isExcludedPath);

  const pageLayoutWrapperClass = classNames(styles.pageLayoutWrapper, {
    [pageWrapperClassName]: pageWrapperClassName,
    [styles.pageLayoutMenu]: isBottomMenu,
  });

  const classSubtitle = classNames(styles.pageSubtitle, {
    [headerSubtitleClass]: headerSubtitleClass,
  });

  const cx = classNames.bind(styles);

  const pageClass = classNames(styles.page, {
    [pageClassName]: pageClassName,
  });
  const pageContentClass = classNames(styles.pageContent, {
    [pageContentClassName]: pageContentClassName,
  });

  useEffect(() => {
    if (isBottomMenu) {
      addBodyClass(BODY_CLASSES.bottomMenu);
    } else {
      removeBodyClass(BODY_CLASSES.bottomMenu);
    }
  }, [isBottomMenu]);

  const HeaderTitle = () => (
    <Box className={styles.pageTitleWrap}>
      <Typography
        variant="h1"
        className={cx(styles.pageTitle, headerClassName, {
          [styles.pageTitleInitial]: !isTitleUppercase,
        })}
      >
        {title}
      </Typography>
      {subtitle ? (
        <Typography variant="body1" className={classSubtitle}>
          {subtitle}
        </Typography>
      ) : null}
    </Box>
  );

  return (
    <Box className={pageLayoutWrapperClass}>
      {isAuthenticated() && !isHideHeader && (
        <Header
          backBtn={
            isMobile && isBack ? (
              <BackBtn
                title={title ? title : null}
                className={headerClassName ? headerClassName : ""}
                link={backRoute}
                state={state}
                isPlain={isPlain}
                onClick={onBackBtnClick}
                {...backBtnProps}
              />
            ) : null
          }
          isSearch={isSearch}
          customMobileHeaderMenu={customMobileHeaderMenu}
          isLogo={isLogo}
          title={<HeaderTitle />}
        >
          {headerChildren}
        </Header>
      )}
      <Box className={pageClass}>
        {isBack && !isMobile && isShowHeader ? (
          <BackBtn
            title={title ? title : null}
            className={headerClassName ? headerClassName : ""}
            link={backRoute}
            state={state}
            isPlain={isPlain}
            onClick={onBackBtnClick}
            {...backBtnProps}
          />
        ) : !isBack && title && !isMobile && isShowHeader ? (
          <HeaderTitle />
        ) : null}
        <Box className={pageContentClass}>{children}</Box>
      </Box>
      {isBottomMenu ? <BottomMenu /> : null}
      {titleTag ? (
        <Helmet>
          <title>{titleTag}</title>
        </Helmet>
      ) : null}
    </Box>
  );
};
