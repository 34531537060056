import React from "react";
import { useSelector } from "react-redux";

export const Pixel = () => {
  const isPixel = useSelector((state) => state.app.isPixel);

  if (!isPixel) {
    return <></>;
  }

  return (
    <img
      src="https://clickoz.o18.click/p?o=20538506&m=519&t=i&auth_token=cfb941cc05"
      width="0px"
      height="0px"
    />
  );
};
