import { Box, Skeleton } from "@mui/material";
import { DescriptionCard } from "components/DescriptionCard/DescriptionCard";
import OrderHistory from "components/Drops/OrderHistory";
import PriceChart from "components/PriceChart";
import { CARD_TYPES } from "constants/card";
import { CHANNELS, EVENTS } from "constants/index";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchDropHistory, updateDrop } from "redux/dropsSlice";
import { fetchUser } from "redux/usersSlice";
import { fetchTransfers } from "redux/usersSlice";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import PusherService from "services/Pusher.service";
import { useIsMobile } from "utils/hooks";

import { CardsList } from "./components/CardsLIst/CardsList";
import styles from "./DropDetail.module.scss";

//TODO:: do the Same for Season
const Phase2DropDetail = ({
  className,
  card,
  buyAlertHandler,
  seasonData,
  seasonLoader,
  seasonCards,
  descriptionProps,
}) => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const history = useSelector((state) => state.drops.history.entities);
  const isMobile = useIsMobile();
  const { id } = useParams();
  const [userLocale, setUserLocale] = useState(null);
  const phaseDetailsRef = useRef(null);

  const { lastOrders = [] } = card;

  const orders = lastOrders?.map((order) => ({
    ...order,
    date: order.createdAt,
  }));

  const descriptionEl = (
    <DescriptionCard
      className={styles.seasonDescription}
      buyAlertHandler={buyAlertHandler}
      isMobile={isMobile}
      {...descriptionProps}
    />
  );
  useEffect(() => {
    const channelDrop = PusherService.subscribe(CHANNELS.DROPS);
    channelDrop.bind(EVENTS.STATUS_CHANGED, (data) => {
      dispatch(updateDrop(data));
    });
    channelDrop.bind(EVENTS.PRICE_CHANGED, (data) => {
      dispatch(updateDrop(data));
    });
    channelDrop.bind(EVENTS.BUY, (data) => {
      dispatch(updateDrop(data));
    });
    return () => {
      PusherService.unsubscribe(CHANNELS.DROPS);
    };
  }, []);

  useEffect(() => {
    let unmounted = false;

    dispatch(fetchDropHistory({ cardId: id }));

    if (isAuthenticated()) {
      dispatch(fetchTransfers({ cardId: id }));
      dispatch(fetchUser())
        .unwrap()
        .then((d) => !unmounted && setUserLocale(d.language));
      return () => (unmounted = true);
    }
  }, [id]);

  return (
    <>
      <Box ref={phaseDetailsRef} className={className}>
        {history?.length && card.type !== CARD_TYPES.CUSTOM ? (
          <Box className={styles.chart}>
            <PriceChart
              className={styles.dropChart}
              data={[
                ...history,
                {
                  createdAt: card.createdAt,
                  price: card.currentSalePrice.toString(),
                },
              ]}
              currencySymbol={card.currencySymbol}
            />
          </Box>
        ) : null}
        <Box>
          {seasonLoader ? (
            <Skeleton
              className={styles.seasonDescriptionSkeleton}
              variant="rectangular"
            />
          ) : null}
          {card.type === CARD_TYPES.CUSTOM ? (
            <>
              {descriptionEl}
              <CardsList
                seasonCards={card.otherCards}
                title={`${t("drops.otherCardsFrom")} ${card.influencer.name}`}
              />
            </>
          ) : null}
          {card.type !== CARD_TYPES.CUSTOM ? (
            <>
              {descriptionEl}
              {seasonLoader ? (
                <Skeleton
                  className={styles.cardsListSkeleton}
                  variant="rectangular"
                />
              ) : null}
              <CardsList
                seasonCards={seasonCards}
                seasonNum={seasonData?.season.seasonNum}
              />
            </>
          ) : null}
        </Box>
        <OrderHistory data={orders} locale={userLocale} />
      </Box>
    </>
  );
};
export default Phase2DropDetail;
