import React, { useContext, useRef, useState } from "react";
import { LocalizationContext } from "services/localizationContext";
import { FormControl, Typography, TextField, Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import Countdown from "components/Countdown";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import styles from "./ResetPassword.module.scss";
import roundedInputStyles from "styles/input/rounded.module.scss";

const VerificationCode = ({
  email,
  onSubmit,
  onResend,
  mainServerError,
  resendCountdown,
  setResendCountdown,
}) => {
  const { t } = useContext(LocalizationContext);

  const loading = useSelector((state) => state.app.loader);
  const [currentCode, setCurrentCode] = useState("");
  const inputRef = useRef(null);

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(t("error.required")),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onCodeSubmit = (data) => {
    onSubmit(data.code);
  };

  const resend = () => {
    onResend(email, true);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.verificationCodeHeader}>
        <Typography variant="h3">{t("auth.enterVerificationCode")}</Typography>
        <Typography variant="body1">
          {t("auth.verificationPhoneNumberInfo")} {email}
        </Typography>
      </Box>
      <form
        onSubmit={handleSubmit(onCodeSubmit)}
        autoComplete="off"
        className={styles.form}
      >
        <FormControl className={styles.inputWrap}>
          {mainServerError && (
            <Typography variant="error" sx={{ mb: 1 }}>
              {mainServerError}
            </Typography>
          )}
          <TextField
            label={t("kyc.verificationCode")}
            error={!!errors.code}
            className={`${roundedInputStyles.input} ${styles.codeInput} ${
              !!currentCode.length ? roundedInputStyles.filled : null
            }`}
            inputProps={{
              ref: inputRef,
              onWheel: () => inputRef.current?.blur(),
              onScroll: () => inputRef.current?.blur(),
              onChange: () => setCurrentCode(inputRef.current?.value),
              pattern: "[0-9]*",
            }}
            autoComplete="off"
            type="number"
            autoFocus
            {...register("code")}
          />
          {!!errors.code ? (
            <Typography variant="error" sx={{ mt: 1 }}>
              {errors.code?.message}
            </Typography>
          ) : null}
        </FormControl>

        <FormControl className={styles.inputWrap}>
          {!resendCountdown > 0 ? (
            <Typography variant="body2" className={styles.sendCode}>
              {t("auth.didntReceiveCode")}&nbsp;&nbsp;
              <Button
                disabled={loading}
                onClick={resend}
                variant="text"
                className={styles.sendCodeBtn}
              >
                {t("auth.resend")}
              </Button>
            </Typography>
          ) : (
            <Box className={styles.sendCode}>
              <Countdown
                value={resendCountdown}
                onSetValue={setResendCountdown}
              >
                <Typography variant="body2" component="span">
                  {t("auth.codeResent")}
                </Typography>
              </Countdown>
            </Box>
          )}
        </FormControl>

        <FormControl className={styles.inputWrap}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading}
            className={styles.submitBtn}
          >
            {t("auth.verify")}
          </LoadingButton>
        </FormControl>
      </form>
    </Box>
  );
};

export default VerificationCode;
