import { Box, Button, Modal, Typography } from "@mui/material";
import { ReactComponent as IconWarning } from "assets/svg/icon-warning.svg";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./MediaResetPostModal.module.scss";

const MediaResetPostModal = ({
  title,
  description,
  handleModalConfirmation,
  isModalOpen,
  setIsModalOpen,
}) => {
  const { t } = useContext(LocalizationContext);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = () => {
    handleModalConfirmation();
    handleCloseModal();
  };

  return (
    <Modal open={isModalOpen}>
      <Box className={styles.modalBody}>
        <Box className={styles.modalTextBody}>
          <IconWarning width={50} height={50} className={styles.modalIcon} />
          <Box className={styles.modalText}>
            <Typography className={styles.modalTitle} variant="body4">
              {title}
            </Typography>
            <Typography className={styles.modalSubTitle} variant="subtitle1">
              {description}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.buttons}>
          <Button variant="outlined" onClick={() => handleCloseModal(false)}>
            <Typography variant="h6">{t("default.cancel")}</Typography>
          </Button>
          <Button className={styles.confirm} onClick={handleChange}>
            <Typography className={styles.buttonConfirmContent} variant="body4">
              {t("default.continueBtn")}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MediaResetPostModal;
