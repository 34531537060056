import { Box, LinearProgress, Typography } from "@mui/material";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { roundToTwo } from "utils/math";

import styles from "./ProgressBar.module.scss";

export const ProgressBar = ({
  goalCount,
  startCount,
  progress,
  code,
  grade,
  stageNum,
}) => {
  const { t } = useContext(LocalizationContext);

  const calculatedProgress =
    startCount + ((goalCount - startCount) / 100) * +progress;

  const status =
    !+progress && stageNum === 1
      ? t("challenges.notStarted")
      : +progress >= 100
      ? t("challenges.completed")
      : grade
      ? grade
      : t("challenges.inProgress");

  const progressCount = goalCount
    ? `${Math.round(calculatedProgress) || 0}/${goalCount}`
    : "";

  return (
    <Box className={styles.progressWrap}>
      <Box>
        <LinearProgress
          variant="determinate"
          value={Math.min(roundToTwo(+progress), 100) || 0}
          classes={{
            root: styles.progressBarRoot,
            bar:
              +progress >= 100
                ? styles.progressBarCompleted
                : styles.progressBarInProgress,
          }}
        />
      </Box>
      <Box className={styles.progressBarInfo}>
        <Typography variant="body3" className={styles.progressCount}>
          {`${progressCount} ${t(`challenges.${code}-progress-title`)}`}
        </Typography>
        <Typography variant="body3" className={styles.progressStatus}>
          {status}
        </Typography>
      </Box>
    </Box>
  );
};
