import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as ReplyArrow } from "assets/svg/feed/reply.svg";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./Reply.module.scss";

const Reply = ({ isHidden = false, handleReply, comment }) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Box className={`${styles.reply} ${isHidden ? styles.replyHidden : ""}`}>
      <Button
        onClick={() => handleReply(comment)}
        sx={{ visibility: isHidden ? "hidden" : "visible" }}
      >
        <Typography variant="body2">{t("discover.replyLabel")}</Typography>
      </Button>
    </Box>
  );
};

export default Reply;
