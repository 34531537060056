import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { getCategories } from "constants/index";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "services/localizationContext";
import { followInfluencer, unFollowInfluencer } from "services/userApi";
import { useIsMobile } from "utils/hooks";
import { useInfluencerSubscribe } from "utils/hooks/useInfluencerSubscribe";

import { FireIcon, SubscribedIcon, SubscribeIcon } from "../../../../../icons";
import styles from "./CardTopBlock.module.scss";

export const CardTopBlock = ({
  card,
  classNameBtn,
  setShowUnsubscribeConfirm,
  setClientSecret,
  isFollowing,
  setFollowing,
  isSubscribed,
  isSubscribedCancelled,
  setSubscribedCanceled,
}) => {
  const isMobile = useIsMobile();
  const { t } = useContext(LocalizationContext);

  const [loader, setLoader] = useState(false);

  const { onSubscribe, onResubscribe } = useInfluencerSubscribe({
    influencerData: card,
    influencerId: card?.id,
    setClientSecret,
    setLoader,
  });

  const isSubscribeHandler = card?.community?.subscriptionPrice;

  const followHandler = (e) => {
    e.stopPropagation();
    setLoader(true);
    let subscribeHandler = isFollowing ? unFollowInfluencer : followInfluencer;

    subscribeHandler({ influencerId: card.id })
      .then(() => setFollowing((prevState) => !prevState))
      .finally(() => setLoader(false));
  };

  const subscribe = (e) => {
    e.stopPropagation();

    if (isSubscribed) {
      if (isSubscribedCancelled) {
        onResubscribe(setSubscribedCanceled);
      } else {
        setShowUnsubscribeConfirm(true);
      }
    } else {
      onSubscribe(setSubscribedCanceled);
    }
  };
  const subscribeHandler = (e) => {
    if (isSubscribeHandler) {
      subscribe(e);
    } else {
      followHandler(e);
    }
  };

  const followText = isFollowing ? t("discover.joined") : t("discover.join");
  const followIcon = isFollowing ? <SubscribedIcon /> : <SubscribeIcon />;

  const subscribeText =
    isSubscribed && !isSubscribedCancelled
      ? t("discover.joined")
      : t("discover.join");

  const subscribeIcon =
    isSubscribed && !isSubscribedCancelled ? (
      <SubscribedIcon />
    ) : (
      <SubscribeIcon />
    );

  const categories = getCategories({ t });

  return (
    <Box className={styles.cardTop}>
      <Box>
        <Box className={styles.name}>
          <Typography variant="h5">{card.name}</Typography>
          {card.isHot ? <FireIcon /> : null}
        </Box>
        <Typography variant="body1Display" className={styles.cardCategory}>
          {categories[card.category] || card.category}
        </Typography>
      </Box>
      <Button
        onClick={subscribeHandler}
        className={`${styles.cardBtn} ${classNameBtn}`}
      >
        {loader ? (
          <Box className={styles.subscribeLoader}>
            <CircularProgress size={20} />
          </Box>
        ) : null}
        {!isMobile ? (
          <Typography variant="textNormal_14" className={styles.subscribeText}>
            {isSubscribeHandler ? subscribeText : followText}
          </Typography>
        ) : null}
        {isSubscribeHandler ? subscribeIcon : followIcon}
      </Button>
    </Box>
  );
};
