import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { ReactComponent as ExpandMoreIcon } from "assets/svg/arrow-down.svg";
import DOMPurify from "dompurify";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "services/localizationContext";

import { ReactComponent as LockedIcon } from "../../assets/svg/locked-icon.svg";
import { ReactComponent as UnlockedIcon } from "../../assets/svg/unlocked-icon.svg";
import { REWARD_STATUS } from "../../constants";
import styles from "./card.module.scss";

export default function Tokens({
  data,
  currencySymbol,
  onSubmit,
  loading,
  title,
  subTitle,
  expandButton,
  cardsCount,
  actionTitle,
  defaultExpanded = true,
}) {
  const { t } = useContext(LocalizationContext);
  actionTitle = actionTitle || t("edition.sellCard");

  const [isActive, setActive] = useState(false);

  const toggleHandler = () => setActive((prevState) => !prevState);

  const myTitle = title || t("default.myCards");
  const tokens = data?.tokens || [];
  const currentTokens = !isActive ? tokens.slice(0, 5) : tokens;

  return (
    <Box className={styles.accordionWrap}>
      <Accordion className={styles.accordion} defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="tokens"
          id="token"
          className={styles.accordionSummary}
        >
          <Typography variant="h5_medium">{myTitle}</Typography>
          {cardsCount ? (
            <Typography
              variant="body1"
              className={styles.accordionSummaryTitleCount}
            >
              ({cardsCount})
            </Typography>
          ) : null}
        </AccordionSummary>
        <AccordionDetails className={styles.tokenDetails}>
          <Box className={styles.collectionDetails}>
            <Box className={styles.collectionDetailsTitle}>
              {subTitle || t("edition.editionNumber")}
            </Box>
            <Box className={styles.collectionDetailsTitle}>
              {t("edition.priceLabel")}
            </Box>
          </Box>
          <Box className={styles.tokensWrapper}>
            {loading ? (
              <CircularProgress size={24} className={styles.loader} />
            ) : null}
            {currentTokens.map((token) => {
              return (
                <Box className={styles.tokenItem} key={token.id}>
                  <Box className={styles.tokenItemIcon}>
                    <Typography
                      variant="subtitle1"
                      component="span"
                      className={styles.tokenText}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          t("edition.numberOfTotal", {
                            number: token.id,
                            total: data?.amountCards,
                          }),
                          {
                            USE_PROFILES: { html: true },
                          }
                        ),
                      }}
                    />
                    {token?.rewardStatus ? (
                      <>
                        {token.rewardStatus === REWARD_STATUS.UNLOCKED ? (
                          <UnlockedIcon />
                        ) : token.rewardStatus === REWARD_STATUS.LOCKED ? (
                          <LockedIcon />
                        ) : null}
                      </>
                    ) : null}
                  </Box>
                  <Box>
                    {/*<Button*/}
                    {/*  className={styles.tokenButton}*/}
                    {/*  onClick={() => {*/}
                    {/*    onSubmit({*/}
                    {/*      ...token,*/}
                    {/*      price: token.purchasePrice || token.price,*/}
                    {/*      total: data?.amountCards,*/}
                    {/*      currencySymbol,*/}
                    {/*      allTokens: currentTokens,*/}
                    {/*    });*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  {actionTitle}*/}
                    {/*</Button>*/}
                    <Typography
                      variant="subtitle1"
                      component="span"
                      className={styles.tokenText}
                    >
                      {currencySymbol} {token.purchasePrice}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
          {tokens.length > 5 ? (
            <Box className={styles.collectionDetailsBottom}>
              {expandButton ? (
                expandButton
              ) : (
                <Typography
                  variant="body2"
                  className={`${styles.collectionDetailsBottomTitle} ${
                    isActive ? styles.collectionDetailsBottomTitleActive : ""
                  }`}
                  onClick={toggleHandler}
                >
                  {!isActive
                    ? t("edition.showMoreCards")
                    : t("edition.showLessCards")}
                  <ExpandMoreIcon />
                </Typography>
              )}
            </Box>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
