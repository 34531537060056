import { Box, Container, Typography } from "@mui/material";
import { ReactComponent as Icon503 } from "assets/svg/503.svg";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import { PageLayout } from "../PageLayout/PageLayout";
import styles from "./Error503.module.scss";

const Error503 = () => {
  const { t } = useContext(LocalizationContext);
  return (
    <PageLayout>
      <Container className={styles.container}>
        <Box className={styles.block}>
          <Icon503 className={styles.iconError} />
        </Box>
        <Box className={styles.block}>
          <Typography variant="h1" className={styles.title}>
            {t("error.maintenanceTitle")}
          </Typography>
          <Typography variant="textNormal" className={styles.description}>
            {t("error.maintenanceDesc")}
          </Typography>
        </Box>
      </Container>
    </PageLayout>
  );
};

export default Error503;
