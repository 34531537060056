import { Box, Typography } from "@mui/material";
import classNames from "classnames/bind";

import styles from "./Level.module.scss";

const IconArrows = () => (
  <svg
    width="7"
    height="8"
    viewBox="0 0 7 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 4.13158L3.7 1.5L6.4 4.13158M1 7.5L3.7 4.86842L6.4 7.5"
      stroke="white"
      strokeWidth="0.8"
      strokeLinecap="round"
    />
  </svg>
);

export const Level = ({
  level = 0,
  className,
  text,
  overrideStyles = {},
  isLine,
  levelLineWrapClassName,
  levelLineClassName,
}) => {
  const getClassName = (value) => {
    let name = "";
    if (value >= 6 && value <= 10) {
      name = styles.levelAqua;
    } else if (value >= 11 && value <= 20) {
      name = styles.levelGreen;
    } else if (value >= 21 && value <= 40) {
      name = styles.levelYellow;
    } else if (value > 40) {
      name = styles.levelRed;
    }
    return name;
  };

  const levelWrapperClass = classNames(styles.lineLevelWrapper, {
    [levelLineWrapClassName]: levelLineWrapClassName,
  });

  const levelLineClass = classNames(
    `${styles.lineLevel} ${getClassName(level)}`,
    {
      [levelLineClassName]: levelLineClassName,
    }
  );

  if (isLine) {
    const width = level > 100 ? "100%" : `${level}%`;

    return (
      <Box className={levelWrapperClass}>
        <Box className={levelLineClass} style={{ width }}></Box>
      </Box>
    );
  }

  return (
    <Typography
      variant="body3"
      className={`${styles.level} ${getClassName(level)} ${
        className ? className : ""
      }`}
      style={overrideStyles}
    >
      <IconArrows />
      {text ? <Box>{text}</Box> : null}
      {level}
    </Typography>
  );
};
