import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import appSlice from "redux/appSlice";
import bannersSlice from "redux/bannersSlice";
import barterSlice from "redux/barterSlice";
import cardSlice from "redux/cardSlice";
import challengesSlice from "redux/challengesSlice";
import discoverSlice from "redux/discoverSlice";
import dropsReducer from "redux/dropsSlice";
import editionSlice from "redux/editionSlice";
import feedSlice from "redux/feedSlice";
import influencerSlice from "redux/influencerSlice";
import mediaSlice from "redux/mediaSlice";
import meetingsSlice from "redux/meetingsSlice";
import messengerSlice from "redux/messengerSlice";
import modalSlice from "redux/modalSlice";
import notificationsSlice from "redux/notificationsSlice";
import rewardsSlice from "redux/rewardsSlice";
import swapsSlice from "redux/swapsSlice";
import usersSlice from "redux/usersSlice";

const reducers = combineReducers({
  drops: dropsReducer,
  users: usersSlice,
  discover: discoverSlice,
  modal: modalSlice,
  notifications: notificationsSlice,
  edition: editionSlice,
  app: appSlice,
  swaps: swapsSlice,
  banners: bannersSlice,
  barter: barterSlice,
  challenges: challengesSlice,
  influencer: influencerSlice,
  feed: feedSlice,
  rewards: rewardsSlice,
  media: mediaSlice,
  messenger: messengerSlice,
  card: cardSlice,
  meetings: meetingsSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "users/logout") {
    state = undefined;
  }
  return reducers(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export default store;
