import { getFilteredInfluencers } from "../../utils/getFilteredInfluencers";

export const initialFilterState = {
  orderBy: "random",
  countryId: null,
  category: [],
};

export const handleSort = ({
  filter,
  setData,
  setLoaders,
  setFilter,
  params,
  rest,
}) => {
  const sortAcsending = filter.orderBy.includes("-")
    ? filter.orderBy.replace("-", "")
    : filter.orderBy !== "random"
    ? `-${filter.orderBy}`
    : filter.orderBy;
  const categoryList = filter.category?.length
    ? filter.category.join(",")
    : null;
  getFilteredInfluencers({
    params: {
      ...filter,
      ...params,
      category: categoryList,
      orderBy: sortAcsending,
    },
    setData,
    setLoaders,
    ...rest,
  });
  setFilter((prevState) => ({
    ...prevState,
    orderBy: sortAcsending,
  }));
};

export const SEARCH_PARAM = "category";
