import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as MEETINGS_API from "services/meetingsApi";

export const createVideoMeeting = createAsyncThunk(
  "meetings/create-video",
  async (title) => {
    const response = await MEETINGS_API.createVideoMeeting(title);
    return response.data;
  }
);

export const joinVideoMeeting = createAsyncThunk(
  "meetings/join-video",
  async ({ meetingId, name }, { rejectWithValue }) => {
    try {
      const response = await MEETINGS_API.joinVideoMeeting(meetingId, name);
      return response.data;
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  creatingMeeting: false,
  joiningMeeting: false,
  currentMeetingId: null,
  currentAttendeeId: null,
  currentMeeting: {},
  currentAttendee: {},
  entities: [],
  meetingNotFound: false,
};

const meetingsSlice = createSlice({
  name: "meetings",
  initialState,
  reducers: {
    resetMeeting: (state) => {
      state.creatingMeeting = false;
      state.joiningMeeting = false;
      state.currentMeetingId = null;
      state.currentAttendeeId = null;
      state.currentMeeting = {};
      state.currentAttendee = {};
      state.entities = [];
      state.meetingNotFound = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createVideoMeeting.pending, (state, action) => {
        state.creatingMeeting = true;
      })
      .addCase(createVideoMeeting.fulfilled, (state, action) => {
        state.creatingMeeting = false;
        state.meetingNotFound = false;
        state.currentMeeting = action.payload.meetingInfo.Meeting;
        state.currentMeetingId = action.payload.meetingId;
      })
      .addCase(joinVideoMeeting.pending, (state, action) => {
        state.joiningMeeting = true;
      })
      .addCase(joinVideoMeeting.rejected, (state, action) => {
        state.joiningMeeting = false;
        state.meetingNotFound = true;
      })
      .addCase(joinVideoMeeting.fulfilled, (state, action) => {
        console.log({ state, action });
        state.meetingNotFound = false;
        state.joiningMeeting = false;
        state.currentAttendee = action.payload.attendeeInfo.Attendee;
        state.currentMeeting = action.payload.meetingInfo.Meeting;
        state.currentMeetingId = action.payload.meetingId;
        state.currentAttendeeId = action.payload.attendeeId;
        state.callData = action.payload.callData;
      });
  },
});
export const { resetMeeting } = meetingsSlice.actions;
export default meetingsSlice.reducer;
