import { Box, Skeleton, Typography } from "@mui/material";
import { CardsSlider } from "components/CardsSlider/CardsSlider";
import FriendRequestItem from "components/FriendRequestItem";
import { HeaderSection } from "components/HeaderSection/HeaderSection";
import { PATHS } from "constants/index";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";

import styles from "./ConnectionsRequests.module.scss";

export const ConnectionsRequests = ({ data, loading }) => {
  const { t } = useContext(LocalizationContext);
  const isMobile = useIsMobile();

  if (loading && !data.length) {
    return (
      <Box className={styles.requests}>
        <Skeleton
          variant="rectangular"
          className={`${styles.header} ${styles.headerSkeleton}`}
        />
        <Box className={styles.listSkeleton}>
          <Skeleton variant="rectangular" className={styles.itemSkeleton} />
          <Skeleton variant="rectangular" className={styles.itemSkeleton} />
          <Skeleton variant="rectangular" className={styles.itemSkeleton} />
          <Skeleton variant="rectangular" className={styles.itemSkeleton} />
          <Skeleton variant="rectangular" className={styles.itemSkeleton} />
        </Box>
      </Box>
    );
  }

  if (!loading && !data.length) {
    return null;
  }

  return (
    <Box className={styles.requests}>
      <HeaderSection
        path={PATHS.FRIEND_REQUESTS}
        title={t("account.friendRequests")}
        buttonText={t("drops.seeAll")}
        wrapperClass={styles.header}
        customTitle={
          <Typography variant="body4" className={styles.headerTitle}>
            {t("account.friendRequests")}
          </Typography>
        }
      />
      {data.length ? (
        <CardsSlider
          spaceBetweenSlides={13}
          spaceBetweenSlidesMobile={8}
          cardWidth={isMobile ? 150 : 180}
        >
          {data.map((item) => (
            <FriendRequestItem key={item.id} request={item} />
          ))}
        </CardsSlider>
      ) : null}
    </Box>
  );
};
