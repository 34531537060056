import { Box, Typography } from "@mui/material";
import * as Chime from "amazon-chime-sdk-js";
import CountdownDate from "components/CountdownDate/CountdownDate";
import { PATHS } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { joinVideoMeeting, resetMeeting } from "redux/meetingsSlice";
import { fetchUser } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";
import { useShowWithDelay } from "utils/hooks/useShowWithDelay";

import { AudioOutput } from "./components/AudioOutput/AudioOutput";
import { Controls } from "./components/Controls/Controls";
import { StreamingVideosSection } from "./components/StreamingVideosSection/StreamingVideosSection";
import styles from "./MeetingRoom.module.scss";

const MeetingRoom = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  let { meetingId } = useParams();
  const navigate = useNavigate();

  const user = useSelector((state) => state.users.entities);
  let currentMeetingId = useSelector(
    (state) => state.meetings.currentMeetingId
  );
  let currentAttendeeId = useSelector(
    (state) => state.meetings.currentAttendeeId
  );
  let currentMeeting = useSelector((state) => state.meetings.currentMeeting);
  let currentAttendee = useSelector((state) => state.meetings.currentAttendee);
  let joiningMeeting = useSelector((state) => state.meetings.joiningMeeting);
  // let creatingMeeting = useSelector((state) => state.meetings.creatingMeeting);
  let callData = useSelector((state) => state.meetings.callData);
  const meetingNotFound = useSelector(
    (state) => state.meetings.meetingNotFound
  );
  const [inMeeting, setInMeeting] = useState(false);
  const [callInitialized, setCallInitialized] = useState(false);
  const [meetingSession, setMeetingSession] = useState();
  const [hasInitiatedCall, setHasInitiatedCall] = useState(false);
  // const videoElement = useRef();

  const endDate = callData?.date
    ? new Date(callData.date).getTime() + 1000 * 60 * 15
    : null;

  const isAlert = useShowWithDelay({
    checkDelay: 1500,
    targetDate: new Date(endDate).getTime(), // 10min
    minutes: 10,
  });

  useEffect(() => {
    if (!joiningMeeting && meetingId && !currentAttendeeId && user?.username) {
      console.log(`joining meeting ${meetingId} as ${user.username}`);
      dispatch(joinVideoMeeting({ meetingId, name: user.username }));
    }
  }, [currentAttendeeId, joiningMeeting, meetingId, user.username]);

  useEffect(() => {
    console.log("useEffect (currentMeetingId)");
    if (meetingNotFound) {
      return navigate(PATHS.MEETING);
    }
    if (!meetingId && currentMeetingId) {
      return navigate(PATHS.MEETING_ID.replace(":meetingId", currentMeetingId));
    }
  }, [currentMeetingId, meetingId, meetingNotFound]);

  const initVideoCall = async () => {
    console.log("initVideoCall called", {
      currentMeetingId,
      currentAttendeeId,
    });
    if (!(currentMeetingId && currentAttendeeId)) {
      console.log("no meeting or attendee, exit");
      return;
    }
    const logger = new Chime.ConsoleLogger(
      "ChimeMeetingLogs",
      Chime.LogLevel.ERROR
    );
    console.log("initVideoCall:logger created", logger);
    const deviceController = new Chime.DefaultDeviceController(logger);
    console.log("initVideoCall:deviceController created", deviceController);
    const configuration = new Chime.MeetingSessionConfiguration(
      currentMeeting,
      currentAttendee
    );
    console.log("initVideoCall:configuration created", configuration);
    const meetingSession = new Chime.DefaultMeetingSession(
      configuration,
      logger,
      deviceController
    );
    console.log("initVideoCall:meetingSession created", meetingSession);

    setMeetingSession(meetingSession);
    setCallInitialized(true);
  };

  const joinVideoCall = async () => {
    const audioInputDevices =
      await meetingSession.audioVideo.listAudioInputDevices();
    const audioOutputDevices =
      await meetingSession.audioVideo.listAudioOutputDevices();
    const videoInputDevices =
      await meetingSession.audioVideo.listVideoInputDevices();
    await meetingSession.audioVideo.startAudioInput(
      audioInputDevices[0].deviceId
    );
    await meetingSession.audioVideo.chooseAudioOutput(
      audioOutputDevices.deviceId
    );
    await meetingSession.audioVideo.startVideoInput(
      videoInputDevices[0].deviceId
    );

    console.log("joinVideoCall:chooseVideoInputDevice ok");

    meetingSession.audioVideo.start();
    meetingSession.audioVideo.startLocalVideoTile();
    console.log("joinVideoCall:audioVideo.start ok");
    setInMeeting(true);
  };
  const pauseVideoCall = async () => {
    meetingSession.audioVideo.stop();
    meetingSession.audioVideo.stopLocalVideoTile();
    await meetingSession.audioVideo.stopAudioInput();
    await meetingSession.audioVideo.stopVideoInput();
    setInMeeting(false);
    navigate(PATHS.CARD_ID.replace(":id", callData?.order?.cardId));
  };

  useEffect(() => {
    dispatch(fetchUser());
    return () => {
      dispatch(resetMeeting());
    };
  }, []);

  useEffect(() => {
    return () => {
      if (meetingSession?.audioVideo) {
        meetingSession.audioVideo.stop();
        meetingSession.audioVideo.stopLocalVideoTile();
        meetingSession.audioVideo.stopAudioInput();
        meetingSession.audioVideo.stopVideoInput();
      }
    };
  }, [meetingSession]);

  useEffect(() => {
    if (callInitialized && meetingSession) {
      joinVideoCall();
    }
  }, [callInitialized, meetingSession]);

  useEffect(() => {
    console.log("useEffect on currentAttendeeId", { currentAttendeeId });
    if (!currentAttendeeId) {
      console.log("no currentAttendeeId, exit");
      return;
    }
    if (!hasInitiatedCall) {
      initVideoCall();
      setHasInitiatedCall(true);
    }
  }, [currentAttendeeId]);

  return (
    <Box className={styles.containerWrapper}>
      <Box className={styles.container}>
        <Box className={styles.containerHeader}>
          {callData?.influencer ? (
            <Box>
              <Box className={styles.celebBlock}>
                <img src={callData?.influencer?.profileImageUrl} alt="avatar" />
                <Typography variant="body5">
                  {callData.influencer?.name}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box />
          )}
          {isAlert && user ? (
            <Box className={`${styles.celebBlock} ${styles.celebBlockAlert}`}>
              <img src={user.userPicCroppedUrl} />
              <Box className={styles.celebBlockMain}>
                <Typography
                  variant="textMediumBold2"
                  className={styles.celebBlockAlertText}
                >
                  {t("default.celebBlockAlertText1")}
                  <CountdownDate
                    endDate={
                      callData?.date
                        ? new Date(callData.date).getTime() + 1000 * 60 * 15
                        : null
                    }
                    hoursStyling={{
                      variant: "body6_medium_dark",
                    }}
                    className={styles.celebBlockAlertTextCountDown}
                    isAbbr={false}
                  />
                  {t("default.celebBlockAlertText2")}
                </Typography>
                <Typography
                  variant="body2"
                  className={`${styles.celebBlockMainDescription} opacity_07`}
                >
                  {t("default.celebBlockMainDescription")}
                </Typography>
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box className={styles.containerMain}>
          <StreamingVideosSection
            meetingSession={meetingSession}
            user={user}
            callData={callData}
          />
          <AudioOutput meetingSession={meetingSession} />
          {/* <PinnedVideoSection /> */}
          <Controls
            meetingSession={meetingSession}
            leave={pauseVideoCall}
            callInitialized={callInitialized}
            inMeeting={inMeeting}
            endData={
              callData?.date
                ? new Date(callData.date).getTime() + 1000 * 60 * 15
                : null
            } // + 15min
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MeetingRoom;
