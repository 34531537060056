import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  modalBody: {
    title: null,
    children: null,
  },
  type: null,
  onClick: null,
  separateModals: {
    challengeSuccess: {
      isOpen: false,
    },
  },
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.modalBody = action.payload;
      state.type = action.payload.type;
      state.styles = action.payload.styles;
      state.onClick = action.payload.onClick;
      state.open = true;
    },
    closeModal: (state) => {
      state.open = false;
      state.type = initialState.modalBody;
      state.modalBody = null;
    },
    toggleSeparateModal: (state, action) => {
      const { type, params } = action.payload;
      state.separateModals[type] = params;
    },
  },
  extraReducers: () => {},
});

export const { toggleSeparateModal, openModal, closeModal } =
  modalsSlice.actions;
export default modalsSlice.reducer;
