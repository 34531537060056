import { Box } from "@mui/material";
import Tokens from "components/Card/Tokens";
import React from "react";

import styles from "./MyTokens.module.scss";

export const MyTokens = ({
  currencySymbol,
  assetHoldings,
  onSubmit,
  loading,
  collectionCard,
  subTitle,
}) => {
  if (!collectionCard || assetHoldings === 0) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      <Tokens
        data={collectionCard}
        currencySymbol={currencySymbol}
        onSubmit={onSubmit}
        loading={loading}
        subTitle={subTitle}
      />
    </Box>
  );
};
