import { CognitoUserPool } from "amazon-cognito-identity-js";

const userPoolId = process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID;
const clientId = process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID;

const poolData = {
  UserPoolId: userPoolId,
  ClientId: clientId,
};

const getCognitoUserPool = () => {
  return new CognitoUserPool(poolData);
};

export default getCognitoUserPool;
