import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useSetUrlParams = () => {
  const navigate = useNavigate();

  const setUrlParams = useCallback(({ params, isReset }) => {
    const url = new URL(window.location.href);

    if (isReset) {
      url.search = "";
    } else {
      params.forEach(({ name, value }) => {
        url.searchParams.set(name, value);
      });
    }

    let newUrl = url.pathname + url.search;
    navigate(newUrl);
  }, []);

  return { setUrlParams };
};
