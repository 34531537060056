import { Box, Paper } from "@mui/material";
import cardBg from "assets/img/card-bg.png";
import { Link, useLocation } from "react-router-dom";

import styles from "./Card.module.scss";

const Card = ({ card, className, link }) => {
  const location = useLocation();

  const pathTo = link || `/pass/${card.id}`;

  const backgroundImage =
    card.status === "droppingStage0"
      ? `url(${cardBg})`
      : `url(${card.imageUrl})`;

  return (
    <Paper
      className={className}
      elevation={0}
      component={Link}
      to={pathTo}
      state={{ backRoute: location.pathname }}
      sx={{
        backgroundImage,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <Box className={styles.dropBgShadow} />
    </Paper>
  );
};
export default Card;
