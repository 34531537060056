import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as UnfriendModalIcon } from "assets/svg/error-icon.svg";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./UnfriendModal.module.scss";

export const UnfriendModal = ({
  title,
  description,
  onConfirm,
  onCancel,
  confirmBtnText,
  cancelBtnText,
  isWarn,
  icon,
}) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Box className={styles.modal}>
      <Box className={styles.modalIconWrap}>
        {icon ? icon : <UnfriendModalIcon className={styles.modalIcon} />}
      </Box>
      <Typography className={styles.modalTitle} variant="h6">
        {title}
      </Typography>
      <Typography className={styles.modalDesc} variant="body1">
        {description}
      </Typography>
      <Box className={styles.modalButtons}>
        <Button
          className={styles.modalBtn}
          variant="outlined"
          onClick={onCancel}
        >
          {cancelBtnText || t("account.stayFriends")}
        </Button>
        <Button
          className={`${styles.modalBtn} ${styles.unfriendBtn} ${
            isWarn ? styles.warnBtn : ""
          }`}
          onClick={onConfirm}
        >
          {confirmBtnText || t("account.unfriend")}
        </Button>
      </Box>
    </Box>
  );
};

export default UnfriendModal;
