import { Box } from "@mui/material";
import React, { useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { getUsernameFromPathname } from "utils/usernameHelper";

import { PageLayout } from "../../PageLayout/PageLayout";
import styles from "./public-collections.module.scss";

const PublicCollections = () => {
  const { t } = useContext(LocalizationContext);
  const params = useParams();
  const location = useLocation();
  const username =
    params?.username || getUsernameFromPathname(location.pathname);

  const title = t("account.collections", { username });
  return (
    <PageLayout
      title={title}
      isBack={true}
      backRoute={`/public/user/${username}`}
    >
      <Box className={styles.container}>
        <Box className={styles.collectionContainer}>
          <Outlet />
        </Box>
      </Box>
    </PageLayout>
  );
};

export default PublicCollections;
