import { Box, Container, Typography } from "@mui/material";
import { ReactComponent as Icon404 } from "assets/svg/icon-404.svg";
import React from "react";

import { PageLayout } from "../PageLayout/PageLayout";
import styles from "./Error500.module.scss";

const NoMatch = () => {
  return (
    <PageLayout>
      <Container className={styles.container}>
        <Box className={styles.block}>
          <Icon404 />
        </Box>
        <Box className={styles.block}>
          <Typography variant="h1" className={styles.title}>
            Sorry, the Zoop server is down for maintenance
          </Typography>
          <Typography variant="textNormal" className={styles.description}>
            For help, contact support@zoop.com. Please try again later
          </Typography>
        </Box>
      </Container>
    </PageLayout>
  );
};

export default NoMatch;
