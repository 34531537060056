import { api } from "./api.service";
const API_URL = process.env.REACT_APP_API_HOST;

export const getEditionOrders = async (params) => {
  try {
    return api.get(`${API_URL}/trading/card/${params.id}/orders`, {
      params: { ...params },
    });
  } catch (error) {
    console.error(error);
  }
};

export const getEditionMarketStatus = async (params) => {
  try {
    return api.get(`${API_URL}/trading/card/${params.id}/market-status`, {
      params: { ...params },
    });
  } catch (error) {
    console.error(error);
  }
};

export const createEditionOrderBuy = async (data) => {
  try {
    return api.post(`${API_URL}/card/${data.id}/buy`, {
      ...data,
    });
  } catch (error) {
    console.error("createEditionOrderBuy", error);
  }
};

export const createEditionOrderSell = async (data) => {
  try {
    return api.post(`${API_URL}/card/${data.id}/sell`, {
      ...data,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getTransactions = async (params) => {
  try {
    return api.get(`${API_URL}/trading/transactions`, {
      params: { ...params },
    });
  } catch (error) {
    console.error(error);
  }
};

export const cancelPendingTransaction = async (orderId) => {
  try {
    return api.delete(`${API_URL}/trading/orders/${orderId}/cancel`);
  } catch (error) {
    console.error("Cannot cancel transaction:", error);
  }
};
