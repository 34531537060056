import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/svg/close-icon.svg";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { goToInfluencer } from "utils/index";

import ErrorAlert from "../../../ErrorAlert";
import { RewardModalIcon } from "./icon";
import styles from "./RewardModal.module.scss";

export const RewardModal = ({
  open,
  setOpen,
  submit,
  loading,
  error,
  influencer,
}) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => setOpen(false);

  if (!open) {
    return <></>;
  }
  return (
    <>
      <Box className={styles.overlay} onClick={handleClose} />
      <Box className={`${styles.wrapper} ${error ? styles.wrapperError : ""}`}>
        <Box className={styles.close} onClick={handleClose}>
          <CloseIcon />
        </Box>
        <Box className={styles.mainIcon}>
          <RewardModalIcon />
        </Box>
        <Typography className={styles.title} variant="h4_24" component="p">
          {t("default.rewardModalTitle")}
        </Typography>
        <Box className={styles.descriptions}>
          <Typography variant="subtitle1">
            {t("default.rewardModalDescriptionItem1")}
          </Typography>
          <Typography variant="subtitle1">
            {t("default.rewardModalDescriptionItem2")}
          </Typography>
          <Typography variant="subtitle1">
            {t("default.rewardModalDescriptionItem3")}
          </Typography>
        </Box>
        {error ? (
          <ErrorAlert>
            <Typography variant="subtitle1" className={styles.errorAlert}>
              {error?.error === "ErrNotSubscribed" ||
              error?.error === "ErrNotFollowing"
                ? t("error.errNotSubscribed", {
                    creator: influencer?.handle || influencer?.name,
                  })
                : error?.message}
            </Typography>
            {error?.error === "ErrNotSubscribed" ||
            error?.error === "ErrNotFollowing" ? (
              <Button
                className={styles.errorAlertButton}
                onClick={goToInfluencer({
                  id: influencer?.id,
                  handle: influencer?.handle,
                  navigate,
                  location,
                })}
              >
                {t("default.clickToSubscribe")}
              </Button>
            ) : null}
          </ErrorAlert>
        ) : null}
        <LoadingButton
          className={styles.submit}
          variant="contained"
          onClick={submit}
          loading={loading}
          disabled={error}
        >
          {t("default.unlockRewardButton")}
        </LoadingButton>
      </Box>
    </>
  );
};
