import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/svg/close-icon.svg";
import { ChatWidget } from "components/ChatWidget/ChatWidget";
import React, { Suspense, useContext } from "react";
import { useDispatch } from "react-redux";
import { LocalizationContext } from "services/localizationContext";

import { fetchCardTokens } from "../../../redux/cardSlice";
import styles from "./ChatWidgetModal.module.scss";

export const ChatWidgetModal = ({
  holdings,
  cardTokens,
  influencer,
  cardId,
  rewardType,
  selectedToken,
  isOpen,
  setOpen,
  title,
}) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const closeHandler = () => {
    setOpen(false);
    //ToDo: Hot solution to check statuses
    dispatch(fetchCardTokens({ params: { cardId } }));
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal open={isOpen} onClose={closeHandler} classes={{ root: styles.root }}>
      <Suspense
        fallback={
          <Box>
            <CircularProgress />
          </Box>
        }
      >
        <Box className={styles.wrapper}>
          <IconButton className={styles.close} onClick={closeHandler}>
            <CloseIcon className={styles.btnIcon} />
          </IconButton>
          <ChatWidget
            holdings={holdings}
            cardTokens={cardTokens}
            influencer={influencer}
            cardId={cardId}
            title={t("default.followBack")}
            rewardType={rewardType}
            selectedToken={selectedToken}
            customTitle={<Typography variant="h4_24">{title}</Typography>}
            classWrapper={styles.widgetWrapper}
            classList={styles.widgetList}
          />
        </Box>
      </Suspense>
    </Modal>
  );
};
