export const HeartIcon = () => (
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8426 17.8218L10.8411 17.8205C8.01493 15.2577 5.74971 13.1984 4.17935 11.2772C2.61998 9.36944 1.85059 7.72029 1.85059 5.99455C1.85059 3.18018 4.03928 1 6.84514 1C8.43929 1 9.98588 1.74729 10.9909 2.91831C11.1809 3.13966 11.4581 3.26703 11.7498 3.26703C12.0415 3.26703 12.3186 3.13966 12.5086 2.91831C13.5137 1.74729 15.0602 1 16.6544 1C19.4603 1 21.649 3.18018 21.649 5.99455C21.649 7.72029 20.8796 9.36944 19.3202 11.2772C17.7498 13.1984 15.4846 15.2577 12.6584 17.8205L12.657 17.8218L11.7498 18.6477L10.8426 17.8218Z"
      stroke="#111322"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);
