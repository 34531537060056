import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import classNames from "classnames/bind";
import CountdownDate from "components/CountdownDate/CountdownDate";
import CountdownProgress from "components/CountdownProgress";
import stylesGeneral from "components/NewCard/Card.module.scss";
import { format } from "date-fns";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { useLocale } from "utils/hooks";

export const UpcomingDrop = ({
  className,
  dropStartDate,
  createdAt,
  overrideStyles,
  dropStartDateFormat,
}) => {
  const styles = overrideStyles || stylesGeneral;
  const cx = classNames.bind(styles);
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const locale = useLocale();
  if (!dropStartDate) return null;
  return (
    <Box className={cx(className, styles.droppingIn)}>
      <Box className={styles.droppingInBlock}>
        <Typography variant="textNormal_14" className={styles.label}>
          {t("edition.releaseDate")}
        </Typography>
        <Typography variant="textMediumBold">
          {format(
            new Date(dropStartDate),
            dropStartDateFormat || "dd MMM yyyy",
            {
              locale,
            }
          )}
        </Typography>
      </Box>
      <Box className={styles.droppingInBlock}>
        <Typography
          variant="textNormal_14"
          className={styles.label}
          style={{ whiteSpace: "nowrap" }}
        >
          {t("drops.droppingIn")}
        </Typography>
        <Typography variant="textMediumBold" style={{ whiteSpace: "nowrap" }}>
          <CountdownDate
            endDate={dropStartDate}
            hoursStyling={{
              variant: "textMediumBold",
              color: theme.palette.tertiary,
            }}
          />
        </Typography>
      </Box>
      <CountdownProgress
        className={styles.droppingInTimer}
        startDate={createdAt}
        endDate={dropStartDate}
        color={theme.palette.tertiary}
      />
    </Box>
  );
};
