import { Avatar, Box, Typography } from "@mui/material";
import { ReactComponent as Crown } from "assets/svg/feed/crown.svg";
// import TimeAgo from "components/TimeAgo";
import { MODAL_TYPE, PATHS } from "constants/index";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setError } from "redux/appSlice";
import {
  deleteComments,
  likeComment,
  setComentIsLiked,
  unlikeComment,
} from "redux/feedSlice";
import { openModal } from "redux/modalSlice";
import { postTranslate } from "services/api/feedApi";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";

import { isMobileDevice } from "../../../utils";
import { STATE_REPLIES_COUNT } from "../../../views/Post/utils";
import { Level } from "../../Level/Level";
import CustomTooltip from "../../Tooltip";
import { ActionCommentTooltip } from "./ActionCommentTooltip/ActionCommentTooltip";
import styles from "./Comment.module.scss";
import { CommentMainSection } from "./CommentMainSection/CommentMainSection";
import { CommentMessageTooltip } from "./CommentMessageTooltip/CommentMessageTooltip";
import Likes from "./Likes/Likes";
import Reply from "./Reply/Reply";
import ReplyLoad from "./Reply/ReplyLoad";

const CommentItem = ({
  comment,
  isNested,
  handleReply,
  activeCommentReplyIds,
  setActiveCommentReplyIds,
  setActiveReplyId,
  isReplyLoading,
  appUserId,
  setDeleteParentMode,
  setSnackBarDelete,
  startExecute,
  setDeleteMode,
  setActionLoading,
  isActionLoading,
  isDeleteMode,
  moderationCommentData,
  accessGranted,
}) => {
  const {
    id,
    appUser,
    message,
    countReplies,
    isLiked,
    likes: likesCount,
    isEdited = false,
    replyToName: replyToUser,
    replyToId,
  } = comment;

  const isPhone = isMobileDevice();

  const [isEditMode, setEditMode] = useState(false);
  const [isCommentEdited, setCommentEdited] = useState(isEdited);

  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = isAuthenticated();
  const profile = useSelector((state) => state.users.entities);
  const post = useSelector((state) => state.feed.post.entities);
  const [currentMessage, setCurrentMessage] = useState(message);
  const [isTranslated, setIsTranslated] = useState(false);
  const [isMessageLoading, setIsMessageLoading] = useState(false);

  const isMyComment = appUserId === profile.appUserID;
  const { id: postId, allowComments } = post;

  const author = appUser || {
    id: profile?.appUserID,
    profileImageUrl: profile?.userPicCroppedUrl,
    username: profile?.username,
  };

  const backToOriginal = () => {
    setIsTranslated(false);
    setCurrentMessage(message);
  };

  const translationHandler = () => {
    setIsMessageLoading(true);
    postTranslate({
      type: "comment",
      id,
      destinationLanguage: profile?.language || navigator.language,
    })
      .then((data) => {
        setIsTranslated(true);
        setCurrentMessage(data?.message);
      })
      .catch((err) => {
        if (err?.status !== 401) {
          dispatch(
            setError({
              open: true,
              subtitle: errorResponseMessages(err.data || err, t),
            })
          );
        }
        console.log(err?.data || err);
      })
      .finally(() => setIsMessageLoading(false));
  };

  const onLikeChanged = (val) => {
    dispatch(setComentIsLiked({ commentId: id, isLiked: val }));
    val ? dispatch(likeComment(id)) : dispatch(unlikeComment(id));
  };

  const activeHandler = () => {
    if (activeCommentReplyIds.includes(id)) {
      setActiveCommentReplyIds((prevState) =>
        prevState.filter((commentId) => commentId !== id)
      );
      setActiveReplyId(null);
    } else {
      setActiveCommentReplyIds((prevState) => [...prevState, id]);
      setActiveReplyId(id);
    }
  };

  const deleteHandler = () => {
    if (isNested) {
      setDeleteMode(true);
    } else {
      setDeleteParentMode(true);
    }
    setSnackBarDelete(true);
    setActionLoading(true);
    startExecute(() =>
      dispatch(deleteComments({ id: postId, commentId: id })).finally(() => {
        setActionLoading(false);
        console.log("Comment deleted: ", id);
      })
    );
  };

  const reportHandler =
    ({ recordId }) =>
    () => {
      dispatch(
        openModal({
          type: MODAL_TYPE.REPORT_MODAL,
          recordId,
          contentType: "comment",
        })
      );
    };

  const LikesBlock = () => (
    <Likes
      count={likesCount}
      isLiked={isLiked}
      onChange={onLikeChanged}
      accessGranted={accessGranted}
    />
  );

  const pathToUser =
    profile?.appUserID === author.id
      ? PATHS.PROFILE
      : `/public/user/${author.username}`;

  const userName =
    profile?.appUserID === author.id
      ? t("discover.you")
      : author.name || author.username;

  const goToProfile = () => {
    if (appUser.type === "influencer") {
      navigate(`/${appUser.handle}`);
    } else {
      navigate(pathToUser);
    }
  };

  if (!comment) return null;

  return (
    <>
      {!isDeleteMode ? (
        <Box
          id={id}
          className={`${styles.commentItemContainer} ${
            isEditMode ? styles.commentItemContainerEdit : ""
          }`}
        >
          <Box className={styles.commentItem}>
            {!isPhone ? (
              <CustomTooltip
                isArrow={false}
                title={<CommentMessageTooltip {...(author || {})} />}
              >
                <Avatar
                  src={author.profileImageUrl}
                  className={`${styles.commentAvatar} ${
                    author.type === "influencer"
                      ? styles.commentAvatarAuthor
                      : ""
                  }`}
                  onClick={goToProfile}
                />
              </CustomTooltip>
            ) : (
              <Avatar
                src={author.profileImageUrl}
                className={`${styles.commentAvatar} ${
                  author.type === "influencer" ? styles.commentAvatarAuthor : ""
                }`}
                onClick={goToProfile}
              />
            )}
            <Box className={styles.commentItemWrapper}>
              <Box className={styles.commentItemInfoContainer}>
                <Box className={styles.commentItemInfo}>
                  <Box
                    className={styles.commentItemInfoProfile}
                    onClick={goToProfile}
                  >
                    {author.type === "influencer" ? <Crown /> : null}
                    <Typography variant="body2" className={styles.username}>
                      {userName} <Level level={author.level} />
                    </Typography>
                  </Box>
                  {/*<Typography*/}
                  {/*  variant="body2"*/}
                  {/*  className={styles.commentItemDate}*/}
                  {/*>*/}
                  {/*  <TimeAgo*/}
                  {/*    value={comment?.createdAt}*/}
                  {/*    locale={profile.language}*/}
                  {/*  />*/}
                  {/*</Typography>*/}
                </Box>
                {!isEditMode ? (
                  <ActionCommentTooltip
                    isMyComment={!isMyComment}
                    reportHandler={reportHandler({ recordId: id })}
                    deleteHandler={deleteHandler}
                    editHandler={() => setEditMode(true)}
                    isLoading={isActionLoading}
                    translationHandler={translationHandler}
                    isTranslated={isTranslated}
                    backToOriginal={backToOriginal}
                    disabled={!isAuth || !accessGranted}
                  />
                ) : null}
              </Box>
              <CommentMainSection
                isEditMode={isEditMode}
                setEditMode={setEditMode}
                message={currentMessage}
                postId={postId}
                commentId={id}
                setActionLoading={setActionLoading}
                isCommentEdited={isCommentEdited}
                setCommentEdited={setCommentEdited}
                moderationCommentData={moderationCommentData}
                tipAmount={comment.tipAmount}
                isMessageLoading={isMessageLoading}
                replyToUser={replyToUser}
                replyToId={replyToId}
              />
            </Box>
          </Box>
          <Box className={styles.commentItemReactions}>
            <LikesBlock />
            <Reply
              handleReply={handleReply}
              comment={comment}
              isHidden={!handleReply || !allowComments}
            />
          </Box>
          <ReplyLoad
            repliesCount={countReplies}
            isNested={isNested}
            activeHandler={activeHandler}
            isReplyLoading={isReplyLoading}
            handleReply={handleReply}
            comment={comment}
            isHidden={!handleReply || !allowComments}
          />
        </Box>
      ) : null}
    </>
  );
};
export default CommentItem;
