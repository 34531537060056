import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FollowersBlock } from "components/FollowersBlock/FollowersBlock";
import React from "react";
import { useSelector } from "react-redux";

import { AboutInfluencer } from "../AboutInfluencer";
import { ClubChallenges } from "../ClubChallenges/ClubChallenges";
import { MainInfo } from "./components/MainInfo/MainInfo";

export const Sidebar = ({
  influencerData,
  handleOnSubscribe,
  subscriptionPrice,
  influencerId,
  isSubscriptionAvailable,
  subscriptionData,
  onFollowCallback,
  // seasons,
}) => {
  const isMobile = useMediaQuery("(max-width:840px)");
  const followers = useSelector((state) => state.users.followers.influencers);

  return (
    <Box>
      <MainInfo
        subscriptionData={subscriptionData}
        influencerData={influencerData}
        handleOnSubscribe={handleOnSubscribe}
        subscriptionPrice={subscriptionPrice}
        influencerId={influencerId}
        isSubscriptionAvailable={isSubscriptionAvailable}
        onFollowCallback={onFollowCallback}
        // seasons={seasons}
      />
      {!isMobile ? (
        <FollowersBlock influencerData={influencerData} followers={followers} />
      ) : null}
      {!isMobile ? <AboutInfluencer {...influencerData} /> : null}
      {!isMobile ? <ClubChallenges influencerId={influencerId} /> : null}
    </Box>
  );
};
