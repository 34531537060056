import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import classnames from "classnames/bind";
import * as React from "react";

import styles from "./CustomSelect.module.scss";

const Arrow = () => (
  <svg
    width="14"
    height="9"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.5L7 7.5L13 1.5"
      stroke="#70E8FC"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CustomSelect = ({
  variant,
  options,
  defaultValue,
  controlClass,
  onChange,
  ...rest
}) => {
  const cx = classnames.bind(styles);

  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event);
  };

  return (
    <FormControl variant={variant} className={cx(styles.control, controlClass)}>
      <Select
        value={value}
        onChange={handleChange}
        className={cx(variant === "standard" && styles.selectStandard)}
        IconComponent={Arrow}
        MenuProps={{
          classes: { list: styles.list, paper: styles.popoverRoot },
        }}
        {...rest}
      >
        {options.map(({ value, label }) => (
          <MenuItem value={value} key={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
