import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as Visibility } from "assets/svg/visibility.svg";
import { ReactComponent as VisibilityOff } from "assets/svg/visibility-off.svg";
import React, { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { LocalizationContext } from "services/localizationContext";
import roundedInputStyles from "styles/input/rounded.module.scss";
import * as Yup from "yup";

import styles from "./ResetPassword.module.scss";

const Password = ({ onSubmit }) => {
  const { t } = useContext(LocalizationContext);

  const loading = useSelector((state) => state.app.loader);
  const refConfirm = useRef(null);
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("error.required"))
      .min(8, t("error.shortText"))
      .matches(/\d/, t("error.shouldContainNumbers"))
      .matches(/^(?=(.*[a-z]){1})/, t("error.shouldContainLowercaseLetter"))
      .matches(/^(?=(.*[A-Z]){1})/, t("error.shouldContainUppercaseLetter"))
      .matches(
        /^(?=(.*[!@#$%^&*()_+\-=\[\]{};~`':"\\|,.<>\/?]){1})/,
        t("error.shouldContainSpecialCharacter")
      ),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("auth.passwordsDontMatch")
    ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  const passwordValue = watch("password") || "";
  const onPasswordSubmit = (data) => {
    onSubmit(data.password);
  };

  return (
    <form
      onSubmit={handleSubmit(onPasswordSubmit)}
      autoComplete="off"
      className={styles.form}
    >
      <FormControl className={styles.inputWrap}>
        <TextField
          label={t("auth.nwPassword")}
          error={!!errors.password}
          className={`${roundedInputStyles.input} ${
            !!passwordValue.length ? roundedInputStyles.filled : ""
          }`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  className={styles.showPasswordToggle}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          autoComplete="off"
          type={showPassword ? "text" : "password"}
          autoFocus
          {...register("password")}
        />

        {!!errors.password ? (
          <Typography variant="error" sx={{ mt: 1 }}>
            {errors.password?.message}
          </Typography>
        ) : null}
      </FormControl>

      <FormControl className={styles.inputWrap}>
        <TextField
          label={t("auth.confirmNewPassword")}
          error={!!errors.confirm_password}
          className={`${roundedInputStyles.input} ${
            !!confirmPasswordValue.length ? roundedInputStyles.filled : null
          }`}
          inputProps={{
            ref: refConfirm,
            onChange: () =>
              setConfirmPasswordValue(refConfirm.current?.value || ""),
          }}
          autoComplete="off"
          type={showPassword ? "text" : "password"}
          {...register("confirm_password")}
        />
        {!!errors.confirm_password ? (
          <Typography variant="error" sx={{ mt: 1 }}>
            {errors.confirm_password?.message}
          </Typography>
        ) : null}
      </FormControl>

      <FormControl className={styles.inputWrap}>
        <LoadingButton
          type="submit"
          variant="contained"
          className={styles.submitBtn}
          loading={loading}
          disabled={!!errors.password || !!errors.confirm_password}
        >
          {t("auth.createNewPassword")}
        </LoadingButton>
      </FormControl>
    </form>
  );
};

export default Password;
