import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import { ReactComponent as Kebab } from "assets/svg/kebab-icon.svg";
import { ReactComponent as MessageIcon } from "assets/svg/message-icon.svg";
import { ReactComponent as ShareIcon } from "assets/svg/share-icon2.svg";
import { ReactComponent as UnfriendIcon } from "assets/svg/unfriend-icon.svg";
import React, { useContext, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { getInfluencerProfileLink } from "utils";

import { CHAT_CATEGORIES, PATHS } from "../../constants";
import { setSelectedUser } from "../../redux/messengerSlice";
import { Level } from "../Level/Level";
import styles from "./connection-item.module.scss";

const ConnectionItem = ({
  connection,
  handleUnfriend,
  handleShare,
  showOptionsBtn,
  handleAccept,
  handleReject,
  isPublic,
  isRequest,
  isInfluencer,
  textBlockClass,
  isClub,
}) => {
  const friend = connection?.friend;
  const influencer = connection?.influencer;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation(event);
    setAnchorEl(null);
  };

  const removeUserConnection = async (event) => {
    event.stopPropagation();
    handleClose(event);
    await handleUnfriend();
  };

  const copyUserLink = (event) => {
    event.stopPropagation();
    handleClose(event);
    handleShare();
  };

  const acceptRequest = (event) => {
    event.stopPropagation();
    handleAccept();
  };

  const rejectRequest = (event) => {
    event.stopPropagation();
    handleReject();
  };

  const handleCardClick = () => {
    navigate(
      isInfluencer
        ? getInfluencerProfileLink({
            id: influencer.id,
            handle: influencer.handle,
          })
        : `/public/user/${friend.username}`,
      { state: { backRoute: location.pathname } }
    );
  };

  const goToMessenger = (e) => {
    e.stopPropagation();
    const data = friend || influencer;
    dispatch(
      setSelectedUser({ ...data, profileImageUrl: data.userPicCroppedUrl })
    );
    navigate({
      pathname: PATHS.CHAT_DIRECT.replace(":userId", data.id),
      search: createSearchParams({
        category: CHAT_CATEGORIES.GENERAL,
      }).toString(),
    });
  };

  const name = isInfluencer ? influencer?.name : `${friend?.username}`;
  const level = isInfluencer ? influencer?.level : friend?.level;

  return (
    <Box
      className={`${styles.container} ${
        isRequest ? styles.requestContainer : ""
      }`}
      onClick={handleCardClick}
      sx={{
        pr: showOptionsBtn ? "64px" : "30px",
      }}
    >
      <Box
        className={`${styles.image} ${
          isInfluencer ? styles.influencerAvatar : ""
        }`}
      >
        <img
          src={
            isInfluencer ? influencer.profileImageUrl : friend.userPicCroppedUrl
          }
          alt={name}
          loading="lazy"
        />
      </Box>

      <Box
        className={`${styles.leftTextBlock} ${
          textBlockClass ? textBlockClass : ""
        }`}
      >
        <Typography variant="h5" component="span" className={styles.title}>
          <Box className={styles.titleValue}>{name}</Box>
          {!isClub ? <Level level={level} /> : null}
        </Typography>
        {isInfluencer && (
          <Typography variant="h6" className={styles.followers}>
            <NumberFormat
              value={influencer?.followers}
              displayType={"text"}
              thousandSeparator={true}
            />
            &nbsp;
            {isClub ? t("discover.members") : t("account.followers")}
          </Typography>
        )}
      </Box>
      {isRequest && (
        <Box className={styles.requestButtons}>
          <Button
            variant="contained"
            className={styles.requestItemBtn}
            onClick={acceptRequest}
          >
            {t("account.addFriend")}
          </Button>
          <Button
            variant="contained"
            className={`${styles.requestItemBtn} ${styles.requestItemRejectBtn}`}
            onClick={rejectRequest}
          >
            {t("account.remove")}
          </Button>
        </Box>
      )}
      {showOptionsBtn && (
        <>
          <IconButton className={styles.optsBtn} onClick={handleClick}>
            <Kebab className={styles.optsBtnIcon} />
          </IconButton>
          <Popover
            id={"connection-options-popover"}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            className={styles.optsPaper}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {!isPublic && !isClub ? (
              <Button
                onClick={removeUserConnection}
                variant="text"
                className={styles.popoverBtn}
              >
                <UnfriendIcon className={styles.popoverBtnIcon} />
                <Typography variant="body3" className={styles.popoverBtnText}>
                  {t("account.unfriend")}
                </Typography>
              </Button>
            ) : null}
            <Button
              onClick={goToMessenger}
              variant="text"
              className={styles.popoverBtn}
            >
              <MessageIcon className={styles.popoverBtnIcon} />
              <Typography variant="body3" className={styles.popoverBtnText}>
                {t("messages.sendMessage")}
              </Typography>
            </Button>
            <Button
              onClick={copyUserLink}
              variant="text"
              className={styles.popoverBtn}
            >
              <ShareIcon className={styles.popoverBtnIcon} />
              <Typography variant="body3" className={styles.popoverBtnText}>
                {t("account.share")}
              </Typography>
            </Button>
          </Popover>
        </>
      )}
    </Box>
  );
};

export default ConnectionItem;
