import { api } from "./api.service";
const API_URL = process.env.REACT_APP_API_HOST;

export const getDrops = ({ params, isAuth = false }) => {
  const url = isAuth ? `${API_URL}/drops/feed` : `${API_URL}/public/drops/feed`;
  return api
    .get(url, { params })
    .then((response) =>
      isAuth ? response.data?.cards || response.data?.drops : response.data
    );
};

export const switchReminder = ({ cardId, state }) => {
  return api
    .post(`${API_URL}/drops/card/${cardId}/reminder`, { state })
    .then((response) => response.data);
};

export const addToWatchList = (cardId) => {
  return api
    .post(`${API_URL}/app-users/me/watchlist`, { cardId })
    .then((response) => response.data);
};

export const removeFromWatchList = (cardId) => {
  return api
    .delete(`${API_URL}/app-users/me/watchlist/${cardId}`)
    .then((response) => response.data);
};

export const getDropStatus = (id, isAuth) => {
  const url = isAuth
    ? `${API_URL}/drops/card/${id}/status`
    : `${API_URL}/public/drops/card/${id}/status`;
  return api.get(url).then((response) => response.data);
};

export const getCard = (id) => {
  return api
    .get(`${API_URL}/discover/card/${id}`)
    .then((response) => response.data);
};

export const postBid = (id, params) => {
  return api
    .post(`${API_URL}/drops/card/${id}/bid`, params)
    .then((response) => response.data);
};

export const getKycStatus = () => {
  return api.get(`${API_URL}/kyc/status`).then((response) => response.data);
};

export const cancelBid = (cardId) => {
  return api
    .delete(`${API_URL}/trading/orders/${cardId}/cancel`)
    .then((response) => response.data);
};

export const buyCard = (id, params) => {
  return api
    .post(`${API_URL}/drops/card/${id}/buy`, params)
    .then((response) => response.data);
};

export const getDropHistory = async ({ cardId, period = "all" }) => {
  try {
    const response = await fetch(
      `${API_URL}/card/${cardId}/price-history?period=${period}`,
      {
        method: "GET",
      }
    );

    return response.json();
  } catch (e) {
    console.log("getDropHistory", e);
    return [];
  }
};
