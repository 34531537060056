import { LoadingButton } from "@mui/lab";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Post from "components/Feed/Post/Post";
import { HeaderSection } from "components/HeaderSection/HeaderSection";
import { PATHS } from "constants/index";
import { orderBy } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "redux/appSlice";
import { deletePostInReview, fetchMorePosts } from "redux/feedSlice";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { LocalizationContext } from "services/localizationContext";

import styles from "./ReviewPosts.module.scss";

export const ReviewPosts = ({
  isHide,
  setHide,
  isFollowing,
  influencerId,
  level,
}) => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const isXS = useMediaQuery("(max-width:480px)");
  const posts = useSelector((state) => state.feed.postsInReview);
  const hasMore = useSelector((state) => state.feed.hasPostInReviewMore);
  const hasPostInReviewTotal = useSelector(
    (state) => state.feed.hasPostInReviewTotal
  );
  const postsInReviewLoading = useSelector(
    (state) => state.feed.postsInReviewLoading
  );

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fakePosts = hasPostInReviewTotal >= 4 ? 3 : hasPostInReviewTotal - 1;
  const postsArr = orderBy(Object.values(posts), "createdAt", "desc");

  const toggleShow = () => setHide((prevState) => !prevState);

  const callbackDeleteFunc = (id) => {
    dispatch(deletePostInReview({ postId: id }));
  };

  useEffect(() => {
    if (page > 1) {
      setLoading(true);
      dispatch(
        fetchMorePosts({
          id: influencerId,
          params: { limit: 20, page, inReview: true },
        })
      )
        .unwrap()
        .catch((err) => {
          if (err?.status !== 401) {
            dispatch(
              setError({
                open: true,
                subtitle: errorResponseMessages(err.data || err, t),
              })
            );
          }
          console.log(err?.data || err);
        })
        .finally(() => setLoading(false));
    }
  }, [page]);

  if (!postsArr?.length && !postsInReviewLoading) {
    return null;
  }

  return (
    <Box className={styles.wrapper}>
      {hasPostInReviewTotal > 1 ? (
        <HeaderSection
          wrapperClass={styles.headerWrapper}
          customTitle={
            <Box className={styles.headerTitle}>
              <Typography variant="textMediumBold" component="span">
                {t("default.headerPendingPostsTitle")}
              </Typography>
              <Typography variant="body2" component="span">
                ({hasPostInReviewTotal})
              </Typography>
            </Box>
          }
          customButton={
            <Button className={styles.headerButton} onClick={toggleShow}>
              <Typography
                variant="textMediumBold"
                className={styles.headerButtonTitle}
              >
                {!isHide ? t("default.viewLess") : t("default.viewAll")}
              </Typography>
            </Button>
          }
        />
      ) : null}
      {postsInReviewLoading && !postsArr?.length ? (
        <Skeleton
          variant="rectangular"
          style={{ marginBottom: "16px", height: "200px" }}
        />
      ) : null}
      <Box className={styles.postsListWrapper}>
        <Box
          className={styles.postsList}
          style={{
            marginBottom: isHide
              ? `${isXS ? fakePosts + 14 : 8 * fakePosts + 16}px`
              : 0,
          }}
        >
          {postsArr.map((item, index) =>
            isHide ? (
              <Post
                {...item}
                key={item?.id}
                inReview={true}
                className={styles.postCollapsed}
                stylesWrapper={{
                  zIndex: postsArr.length - index,
                }}
                userName={item?.influencer?.name}
                postIndex={index}
                originalMessage={item?.message}
                callbackDeleteFunc={() => callbackDeleteFunc(item?.id)}
                level={level}
                pathToProfile={PATHS.PROFILE}
              />
            ) : (
              <Post
                {...item}
                key={item?.id}
                inReview={true}
                callbackDeleteFunc={() => callbackDeleteFunc(item?.id)}
                postIndex={index}
                level={level}
                pathToProfile={PATHS.PROFILE}
              />
            )
          )}
        </Box>
      </Box>
      {isFollowing && hasMore && !isHide ? (
        <LoadingButton
          loading={loading}
          variant="outlined"
          size="small"
          onClick={() => setPage((prevState) => prevState + 1)}
          className={styles.loadMoreBtn}
        >
          {t("discover.loadMore")}
        </LoadingButton>
      ) : null}
    </Box>
  );
};
