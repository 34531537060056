import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { ReactComponent as CheckedIcon } from "assets/svg/success-icon.svg";
import { LANGUAGES_LIST } from "constants/index";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "redux/appSlice";
import { fetchUser, updateUserProfile } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "./Language.module.scss";

const getObjKeyByValue = (obj, value) => {
  return Object.keys(obj)[Object.values(obj).indexOf(value)];
};

const Language = ({ successChangeCb }) => {
  const { t, setLocalization } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.app.loader);
  const user = useSelector((state) => state.users.entities);

  const [language, setLanguage] = useState(LANGUAGES_LIST.english);

  const langChanged = useMemo(() => {
    if (!user?.language === language) {
      return false;
    }

    const langItem = Object.entries(LANGUAGES_LIST).find(
      (item) => item[1] === user?.language
    );
    return language !== langItem[0];
  }, [language, user?.language]);

  useEffect(() => {
    let unmounted = false;

    dispatch(fetchUser())
      .unwrap()
      .then((d) => {
        const langItem = Object.entries(LANGUAGES_LIST).find(
          (item) => item[1] === d.language
        );
        !unmounted && setLanguage(langItem[0]);
      });

    return () => {
      unmounted = true;
    };
  }, []);

  const handleChangeLanguage = (_event, newLang) => {
    setLanguage(newLang);
  };

  const handleSubmitLanguage = () => {
    dispatch(setLoader(true));
    dispatch(updateUserProfile({ language: LANGUAGES_LIST[language] }))
      .then(() => {
        setLocalization(LANGUAGES_LIST[language]);
        successChangeCb(
          getObjKeyByValue(LANGUAGES_LIST, LANGUAGES_LIST[language])
        );
      })
      .finally(() => dispatch(setLoader(false)));
  };

  return (
    <Box className={styles.container}>
      <ToggleButtonGroup
        value={language}
        exclusive
        onChange={handleChangeLanguage}
        aria-label="language"
        className={styles.options}
      >
        {Object.keys(LANGUAGES_LIST).map((l) => (
          <ToggleButton
            key={l}
            value={l}
            aria-label={l}
            className={styles.option}
          >
            <Typography variant="subtitle1">{t(`account.${l}`)}</Typography>
            <CheckedIcon className={styles.checkedIcon} />
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {langChanged ? (
        <LoadingButton
          variant="contained"
          className={styles.btn}
          loading={loading}
          onClick={handleSubmitLanguage}
        >
          {t("default.apply")}
        </LoadingButton>
      ) : null}
    </Box>
  );
};

export default Language;
