import { Box, Typography } from "@mui/material";
import UserListItem from "components/UserListItem/UserListItem";
import { PATHS } from "constants/index";
import React, { useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateChallengeDetailsUser } from "redux/challengesSlice";
import { sendConnectionRequest, unfriendUser } from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";

import parentStyles from "../NewChallengeDetails.module.scss";
import styles from "./UsersList.module.scss";
export const UsersList = ({ data, loader, loadMore, hasMore }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);

  const handleAddFriend = (id) => {
    return dispatch(sendConnectionRequest(id)).then((res) => {
      dispatch(
        updateChallengeDetailsUser({
          id,
          connection: res?.payload,
          pendingConnection: true,
        })
      );
    });
  };
  const handleRemoveFriend = ({ id, connectionId }) => {
    return dispatch(unfriendUser(connectionId)).then(() => {
      dispatch(
        updateChallengeDetailsUser({
          id,
          connection: null,
          pendingConnection: false,
        })
      );
    });
  };

  const goToUser =
    ({ id, username }) =>
    (e) => {
      if (!e.target.closest("button")) {
        navigate(PATHS.PUBLIC_USER.replace(":id", username || id));
      }
    };

  return (
    <Box>
      <Typography
        className={`${parentStyles.containerLabel} ${parentStyles.containerLabelUsers}`}
        variant="body1"
      >
        {t("challenges.userListTitle")}
      </Typography>
      <InfiniteScroll
        dataLength={data.length}
        next={loadMore}
        hasMore={hasMore}
        loader={loader}
        scrollThreshold={"100px"}
        scrollableTarget="scrollableEl"
        className={styles.container}
      >
        {data.map(({ appUserId, appUserProfile, ...rest }) => (
          <UserListItem
            key={appUserId}
            user={{
              id: appUserId,
              userPicCroppedUrl: appUserProfile.imageUrl,
              username: appUserProfile.name,
              ...rest,
            }}
            handleAddFriend={handleAddFriend}
            handleRemoveFriend={handleRemoveFriend}
            onClick={goToUser({ id: appUserId, username: appUserProfile.name })}
          />
        ))}
      </InfiniteScroll>
    </Box>
  );
};
