import { api } from "./api.service";
const API_URL = process.env.REACT_APP_API_HOST;

export const getEditionData = (id) => {
  return api
    .get(`${API_URL}/discover/card/${id}`)
    .then((response) => response.data);
};

export const getEditionStats = async (params) => {
  try {
    return api.get(`${API_URL}/discover/card/${params.id}/stats`, {
      params: { ...params },
    });
  } catch (error) {
    console.error(error);
  }
};

export const getEditionActivity = async (params) => {
  try {
    return api.get(`${API_URL}/public/discover/card/${params.id}/activity`, {
      params: { ...params },
    });
  } catch (error) {
    console.error(error);
  }
};

export const withdrawCard = async (params) => {
  try {
    return api
      .post(`${API_URL}/cards/withdraw-request`, params)
      .then((response) => response.data);
  } catch (error) {
    console.error("withdraw card error:", error);
  }
};
export const getSeason = ({ influencerId, seasonNum }) => {
  return api
    .get(`${API_URL}/discover/influencer/${influencerId}/season?${seasonNum}`)
    .then((res) => res.data);
};
