import { Box, CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AuthLeftSide from "components/AuthLeftSide";
import styles from "./EmailVerification.module.scss";

import { fetchUser } from "redux/usersSlice";
import { useIsMobile } from "utils/hooks";

import EmailVerifySteps from "../KYC/Step2";
import Analytics from "services/Analytics.service";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../constants";

const EmailVerification = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(fetchUser()).finally(() => setLoading(false));
    Analytics.track("Start Email Verification");
  }, []);

  const handleBack = () => {
    navigate(PATHS.HOME);
  };

  return (
    <Box className={styles.container}>
      {!isMobile && <AuthLeftSide />}

      <Box className={styles.rightSide}>
        {loading ? (
          <Box className={styles.center}>
            <CircularProgress />
          </Box>
        ) : (
          <EmailVerifySteps handleBack={handleBack} isSingle={true} />
        )}
      </Box>
    </Box>
  );
};
export default EmailVerification;
