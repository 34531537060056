import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Popover,
  useTheme,
} from "@mui/material";
import { LocalizationContext } from "services/localizationContext";
import { MATCH_STATUSES, TOOLTIP_SHOWN } from "constants/";
import Toaster from "components/Toaster";

import styles from "./match-tab-item.module.scss";

import { ReactComponent as Kebab } from "assets/svg/kebab-icon.svg";
import { ReactComponent as CheckedIcon } from "assets/svg/success-icon.svg";

function tabProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const MatchTabItem = ({ data, handleHide, onClick, isActive, index }) => {
  const { card, barterCard, status, matches } = data;
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [tooltipAnchorEl, setTooltipAnchorEl] = useState(null);
  const matchTabRef = useRef(null);

  const [matchTabTooltip1Shown, setMatchTabTooltip1Shown] = useState(
    localStorage.getItem("matchTabTooltip1") === TOOLTIP_SHOWN
  );

  const [matchTabTooltip2Shown, setMatchTabTooltip2Shown] = useState(
    localStorage.getItem("matchTabTooltip2") === TOOLTIP_SHOWN
  );

  const showTutorialPopups = index === 0;

  useEffect(() => {
    if (!matchTabTooltip1Shown || !matchTabTooltip2Shown) {
      setTooltipAnchorEl(matchTabRef.current);
    }
  }, []);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation(event);
    setAnchorEl(null);
  };

  const hide = async (event) => {
    event.stopPropagation();
    handleClose(event);
    handleHide();
  };

  const handleOnclick = (event) => {
    handleClose(event);
    onClick();
  };

  const handleCloseTooltip = () => {
    if (!matchTabTooltip1Shown) {
      localStorage.setItem("matchTabTooltip1", TOOLTIP_SHOWN);
      setMatchTabTooltip1Shown(true);

      return;
    }
    if (!matchTabTooltip2Shown) {
      localStorage.setItem("matchTabTooltip2", TOOLTIP_SHOWN);
      setMatchTabTooltip2Shown(true);

      setTooltipAnchorEl(null);
    }
  };

  const colorByStatus = (() => {
    switch (status) {
      case MATCH_STATUSES.pending:
        return theme.palette.purple.main;

      case MATCH_STATUSES.cancelled:
        return theme.palette.coral.main;

      case MATCH_STATUSES.completed:
        return theme.palette.tertiary.main;

      default:
        return "transparent";
    }
  })();

  return (
    <Box
      role="tab"
      className={`${styles.container} ${isActive ? styles.active : null}`}
      {...tabProps(index)}
      onClick={handleOnclick}
      ref={matchTabRef}
    >
      <Box className={styles.content}>
        <Box className={styles.leftSide}>
          <img
            className={styles.cardImage}
            src={barterCard.imageUrl}
            alt={barterCard.assetLabel}
            loading="lazy"
          />
          <Box className={styles.status} sx={{ borderColor: colorByStatus }}>
            <Typography
              className={styles.statusText}
              sx={{ color: colorByStatus }}
            >
              {t(`barter.${MATCH_STATUSES[status]}`)}
            </Typography>
            {status === MATCH_STATUSES.completed ? (
              <CheckedIcon className={styles.statusIcon} />
            ) : null}
          </Box>
        </Box>
        <Box className={styles.rightSide}>
          {status === MATCH_STATUSES.completed ? (
            <Typography variant="body2" className={styles.username}>
              @
              {
                matches?.find((m) => m.status === MATCH_STATUSES.completed)
                  ?.appUser.username
              }
            </Typography>
          ) : (
            <Typography variant="body2" className={styles.matchesCount}>
              {t("barter.matchesCount", { count: matches.length })}
            </Typography>
          )}
          <img
            className={styles.cardImage}
            src={card.imageUrl}
            alt={card.assetLabel}
            loading="lazy"
          />
        </Box>
      </Box>
      <IconButton onClick={handleClick} className={styles.optionsBtn}>
        <Kebab width={24} height={24} />
      </IconButton>
      <Popover
        id={"match-item-popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        className={styles.hideBtnPaper}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Button onClick={hide} variant="contained" className={styles.hideBtn}>
          {t("barter.hideBtn")}
        </Button>
      </Popover>

      {showTutorialPopups ? (
        <Toaster
          name="tooltip-popover"
          isOpen={!!tooltipAnchorEl}
          anchorEl={tooltipAnchorEl}
          message={
            !matchTabTooltip1Shown
              ? t("barter.matchTabTooltip1")
              : t("barter.matchTabTooltip2")
          }
          handleClose={handleCloseTooltip}
          isTooltip
          isAbove={!matchTabTooltip1Shown}
          isOnRight={matchTabTooltip1Shown}
          anchorOrigin={
            matchTabTooltip1Shown
              ? {
                  vertical: "center",
                }
              : null
          }
          transformOrigin={
            matchTabTooltip1Shown
              ? {
                  vertical: "center",
                }
              : null
          }
        />
      ) : null}
    </Box>
  );
};

export default MatchTabItem;
