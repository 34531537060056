import { Box, Button, IconButton } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/svg/close-mobile-menu.svg";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import classNames from "classnames/bind";
import betaStyle from "components/Layout/BetaLabel.module.scss";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { closeMobileMenu } from "redux/appSlice";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";

import { PATHS } from "../../../constants";
import { NavMenu } from "../Header/components/NavMenu/NavMenu";
import { NotificationsIcon } from "../Header/components/NotificationsIcon";
import { ProfileNav } from "../Header/components/ProfileNav/ProfileNav";
import { ProfileNavMenu } from "../Header/components/ProfileNav/ProfileNavMenu";
// import { InstallApp } from "../InstallApp/InstallApp";
import styles from "./MobileMenu.module.scss";

export default function MobileMenu() {
  const { t } = useContext(LocalizationContext);

  const isMobileMenuVisible = useSelector(
    (state) => state.app.isMobileMenuVisible
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeMenu = () => {
    document.body.classList.remove("mobile-menu-open");
    dispatch(closeMobileMenu());
  };

  if (!isMobileMenuVisible) return null;

  const handleClickProfileNav = () => {
    closeMenu();
    navigate(PATHS.PROFILE);
  };
  const handleSignUp = () => {
    closeMenu();
    navigate(PATHS.SIGN_UP);
  };
  const handleSignIn = () => {
    closeMenu();
    navigate(PATHS.LOGIN);
  };
  const cx = classNames.bind(styles);
  const isLoggedIn = isAuthenticated();
  return (
    <Box className={styles.mobileMenu}>
      <Box className={styles.menuContainer}>
        <Box className={styles.menuHeader}>
          <Box className={betaStyle.labelContainer}>
            <Link onClick={closeMenu} to="/" className={styles.logo}>
              <Logo />
            </Link>
          </Box>
          <IconButton
            className={styles.closeBtn}
            onClick={closeMenu}
            sx={{ p: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <ProfileNav onClick={handleClickProfileNav} overrideStyles={styles} />
        {isLoggedIn ? (
          <>
            <NavMenu
              overrideStyles={styles}
              className={styles.menuWrap}
              handleClose={closeMenu}
            >
              <Box className={styles.profileMenu}>
                {/*<InstallApp />*/}
                <ProfileNavMenu overrideStyles={styles} handleClose={closeMenu}>
                  <NotificationsIcon
                    className={cx(
                      styles.popoverMenuItem,
                      styles.notificationsIcon
                    )}
                    text={t("notifications.notifications")}
                    onClick={closeMenu}
                  />
                </ProfileNavMenu>
              </Box>
            </NavMenu>
          </>
        ) : (
          <Box className={styles.navNotAuth}>
            <Box className={styles.navNotAuthBottom}>
              <Button
                onClick={handleSignUp}
                variant="contained"
                className={styles.navNotAuthBtn}
              >
                {t("auth.signUp")}
              </Button>
              <Button
                onClick={handleSignIn}
                variant="outlined"
                className={styles.navNotAuthBtn}
              >
                {t("default.login")}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
