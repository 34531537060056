import { Box, Tab, Tabs } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TUTORIALS_PROPS } from "constants/index";
import React, { useContext, useState } from "react";
import { LocalizationContext } from "services/localizationContext";

import { HeaderTooltip } from "../../components/HeaderTooltip/HeaderTooltip";
import { PageLayout } from "../PageLayout/PageLayout";
import { DiscoverBody } from "./components/DiscoverBody/DiscoverBody";
import { DiscoverGroupsBody } from "./components/DiscoverGroupsBody/DiscoverGroupsBody";
import styles from "./discover.module.scss";

const a11yProps = (index) => {
  return {
    id: `discover-tab-${index}`,
    "aria-controls": `discover-tabpanel-${index}`,
  };
};

const tabsIndex = {
  creators: 0,
  groups: 1,
};

const Discover = () => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg_small"));

  const [tabsValue, setTabsValue] = useState(tabsIndex.creators);

  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  return (
    <PageLayout
      isSearch={true}
      pageClassName={styles.page}
      pageWrapperClassName={styles.pageWrapper}
      title={
        <HeaderTooltip
          title={t("default.creatorsTutorial1Title")}
          subtitle={t("default.creatorsTutorial1SubTitle")}
          seenTutorialsValue={TUTORIALS_PROPS.CREATORS_TUTORIAL_1}
          header={isMobile ? t("discover.discover") : t("discover.creators")}
        />
      }
      headerClassName={styles.header}
    >
      {isMobile ? (
        <>
          <Tabs
            value={tabsValue}
            onChange={handleTabChange}
            className={`${styles.tabs} global-tabs-class`}
          >
            <Tab
              label={t("discover.creators")}
              {...a11yProps(tabsIndex.creators)}
            />
            <Tab
              label={t("discover.groups")}
              {...a11yProps(tabsIndex.groups)}
            />
          </Tabs>
          {tabsValue === tabsIndex.creators ? (
            <Box>
              <DiscoverBody />
            </Box>
          ) : (
            <Box>
              <DiscoverGroupsBody />
            </Box>
          )}
        </>
      ) : (
        <DiscoverBody />
      )}
    </PageLayout>
  );
};

export default Discover;
