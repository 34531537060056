import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import { ProfileBox } from "../../../components/Telegram/components/ProfileBox/ProfileBox";
import { PageLayout } from "../../PageLayout/PageLayout";
import { OverlayImages } from "../components/OverlayImages/OverlayImages";
import { TelegramPageWrapper } from "../TelegramPageWrapper";
import { InfoBlock } from "./components/InfoBlock/InfoBlock";
import { images } from "./data";
import styles from "./TelegramAirdrop.module.scss";

export const TelegramAirdrop = () => {
  const { t } = useContext(LocalizationContext);

  return (
    <TelegramPageWrapper>
      <PageLayout
        isShowHeader={true}
        isBottomMenuHide={false}
        pageWrapperClassName={styles.pageWrapper}
        pageClassName={styles.page}
        title={t("default.airdrop")}
        customMobileHeaderMenu={<ProfileBox />}
      >
        <OverlayImages images={images} />
        <InfoBlock />
      </PageLayout>
    </TelegramPageWrapper>
  );
};
