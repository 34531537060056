import { Box, Typography } from "@mui/material";
import classNames from "classnames/bind";
import styles from "components/NewCard/Card.module.scss";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

export const NotReleased = () => {
  const cx = classNames.bind(styles);
  const { t } = useContext(LocalizationContext);
  return (
    <Box className={cx(styles.block, styles.notReleased)}>
      <Typography variant="textNormal_14" className={styles.notReleasedText}>
        {t("edition.comingSoon")}...
      </Typography>
    </Box>
  );
};
