import { IconButton, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import { ReactComponent as CloseIcon } from "assets/svg/close-icon.svg";
import StripeCheckoutForm from "components/Stripe/CheckoutForm";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import styles from "./SubscribeInfluencerModal.module.scss";

const SubscribeInfluencerModal = ({ open, onClose }) => {
  const location = useLocation();
  const { t } = useContext(LocalizationContext);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        classes={{
          root: styles.modal,
        }}
      >
        <Box className={styles.modalBody}>
          <IconButton onClick={onClose} className={styles.close}>
            <CloseIcon />
          </IconButton>
          <Box className={styles.modalBodyContent}>
            <Typography variant="h4" className={styles.title}>
              {t("discover.subscribeModalTitle")}
            </Typography>

            <StripeCheckoutForm
              returnUrl={`${window.origin}${location.pathname}`}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default SubscribeInfluencerModal;
