import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as IconClose } from "assets/svg/icon-close.svg";
import EditionCard from "components/Card/Edition/EditionCard";
import { PATHS } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";

import styles from "./ModalChooseTrophyCard.module.scss";

const ModalChooseTrophyCard = ({
  open,
  cards,
  trophyCard,
  handleClick,
  handleClose,
}) => {
  const location = useLocation();
  const { t } = useContext(LocalizationContext);
  const [selectedCard, setSelectedCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (trophyCard) {
      setActiveStep(2);
    } else {
      setActiveStep(0);
    }
    setLoading(false);
    return () => {
      setActiveStep(0);
      setLoading(false);
    };
  }, [open, trophyCard]);

  const handleSelectCard = (card) => {
    setSelectedCard(card);
    handleNext();
  };

  const handleProcessed = (id) => {
    setLoading(true);
    handleClick(id);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderSteps = () => {
    switch (activeStep) {
      case 0: {
        return (
          <Box className={[styles.trophyContainer, styles.stepFirst]}>
            {cards.map((card) => (
              <Box
                className={styles.trophyModalCardWrapper}
                key={`trophy-${card.id}`}
              >
                <EditionCard
                  key={`edition-${card.id}`}
                  {...card}
                  className={styles.trophyModalCard}
                  cardImage={card.imageUrl}
                  editionsDropped={card.series}
                  wrapClassName={styles.trophyCardWrap}
                  onClick={() => handleSelectCard(card)}
                />
              </Box>
            ))}
          </Box>
        );
      }
      case 1: {
        return (
          <Box className={[styles.trophyContainer, styles.stepSecond]}>
            <Box>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <EditionCard
                  key={`edition-${selectedCard.id}`}
                  {...selectedCard}
                  className={styles.trophyModalCard}
                  cardImage={selectedCard.imageUrl}
                  editionsDropped={selectedCard.series}
                  wrapClassName={styles.trophyCardWrap}
                  onClick={() => handleSelectCard(selectedCard)}
                />
              </Box>
              <Box className={styles.textContainer}>
                <Typography variant="h4">
                  {t("account.setTrophyCardTitle")}
                </Typography>
                <Typography
                  className={styles.textDescription}
                  variant="textNormal"
                >
                  {t("account.setTrophyCardDescription")}
                </Typography>
              </Box>
              <Box className={styles.stepSecondButtons}>
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  onClick={() => handleProcessed(selectedCard.id)}
                >
                  {t("default.proceed")}
                </LoadingButton>
                <Button variant="outlined" onClick={handleBack}>
                  {t("default.goBack")}
                </Button>
              </Box>
            </Box>
          </Box>
        );
      }
      case 2: {
        return (
          <Box className={[styles.trophyButtonContainer]}>
            <Box className={styles.stepThirdButtons}>
              <Button
                variant="contained"
                onClick={() =>
                  navigate(PATHS.CARD_ID.replace(":id", trophyCard.id), {
                    state: { backRoute: location.pathname + location.search },
                  })
                }
              >
                {t("account.seeEdition")}
              </Button>
              <Button variant="outlined" onClick={() => setActiveStep(0)}>
                {t("account.changeTrophyCard")}
              </Button>
            </Box>
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={styles.modalTrophyWrap}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          height: "100%",
          width: 695,
          background: "#111322",
          outline: "none",
          overflowY: "auto",
        }}
      >
        {activeStep !== 2 && (
          <Typography
            className={styles.modalTitle}
            id="modal-modal-title"
            variant="textMediumBold"
          >
            {t("account.chooseYourTrophyCard")}
            {!isMobile && (
              <IconButton className={styles.iconClose} onClick={handleClose}>
                <IconClose />
              </IconButton>
            )}
          </Typography>
        )}
        {isMobile && (
          <IconButton className={styles.iconClose} onClick={handleClose}>
            <IconClose />
          </IconButton>
        )}
        {renderSteps()}
      </Box>
    </Modal>
  );
};

export default ModalChooseTrophyCard;
