import { Box, IconButton, Modal, Typography } from "@mui/material";
import { PostCreator } from "components/PostCreator/PostCreator";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import { IconClose } from "./icons";
import styles from "./ModalPostCreator.module.scss";

export const ModalPostCreator = ({
  isOpen,
  setOpenModal,
  post,
  onSubmitHandler,
  title,
  isUpdate,
  influencerId,
}) => {
  const { t } = useContext(LocalizationContext);

  const closeHandler = () => setOpenModal(false);

  if (!isOpen) {
    return <></>;
  }

  return (
    <Modal open={isOpen} onClose={() => setOpenModal(false)}>
      <Box className={styles.wrapper}>
        <IconButton onClick={closeHandler} className={styles.close}>
          <IconClose />
        </IconButton>
        <Typography className={styles.title} variant="h4_24" component="p">
          {title}
        </Typography>
        <PostCreator
          isUpdate={isUpdate}
          sendButtonTitle={
            !isUpdate
              ? t("default.postCreatorSubmitButton")
              : t("default.submit")
          }
          formClassname={styles.form}
          turnOffClass={styles.turnOffClass}
          post={post}
          onSubmitHandler={onSubmitHandler}
          influencerId={influencerId}
        />
      </Box>
    </Modal>
  );
};
