import { PATHS } from "constants/index";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import { EmptyStateChallengesIcon } from "../icons";
import { SidebarBox } from "./SidebarBox/SidebarBox";

export const BadgesWrapper = ({ convertedData }) => {
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);

  return (
    <SidebarBox
      title={t("account.badges")}
      amount={convertedData.length}
      path={PATHS.BADGES}
      data={convertedData}
      imgStyle={{ borderRadius: 0 }}
      emptyStateIcon={<EmptyStateChallengesIcon />}
      emptyStateTitle={t("default.profileBadgesEmptyStateTitle")}
      emptyStateActionText={t("default.profileBadgesEmptyStateAction")}
      emptyStateAction={() => navigate(PATHS.CHALLENGES)}
    />
  );
};
