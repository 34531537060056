import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import NumberFormat from "react-number-format";
import { LocalizationContext } from "services/localizationContext";

import styles from "./OrderDetails.module.scss";

const OrderDetails = ({
  influencerName,
  series,
  currencySymbol,
  currentSalePrice,
  paymentMethodName,
}) => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const accentColor = theme.palette.accent.main;
  return (
    <>
      <Box className={styles.gridItem}>
        <Typography variant="body1" className="fontDisplay">
          {t("drops.bidInfluencer")}
        </Typography>
        <Typography variant="body1" className="fontDisplay" color={accentColor}>
          {influencerName}
        </Typography>
      </Box>

      <Box className={styles.gridItem}>
        <Typography variant="body1" className="fontDisplay">
          {t("drops.paymentMethod")}
        </Typography>
        <Typography variant="body1" className="fontDisplay" color={accentColor}>
          {paymentMethodName}
        </Typography>
      </Box>

      {series ? (
        <Box className={styles.gridItem}>
          <Typography variant="body1" className="fontDisplay">
            {t("default.edition")}
          </Typography>
          <Typography
            variant="body1"
            className="fontDisplay"
            color={accentColor}
          >
            #{series}
          </Typography>
        </Box>
      ) : null}

      <Box className={styles.divider}></Box>

      <Box className={styles.gridItem}>
        <Typography variant="h6" className={styles.fontMedium}>
          {t("drops.total")}
        </Typography>
        <Typography
          variant="h6"
          className={styles.fontMedium}
          color={accentColor}
        >
          <NumberFormat
            value={currentSalePrice}
            displayType={"text"}
            thousandSeparator={true}
            prefix={currencySymbol}
          />
        </Typography>
      </Box>
    </>
  );
};
export default OrderDetails;
