import { Box, CircularProgress, Skeleton } from "@mui/material";
import { SCROLL_THRESHOLD } from "constants/index";
import InfiniteScroll from "react-infinite-scroll-component";

import styles from "./Chats.module.scss";
import { ChatItem } from "./components/ChatItem/ChatItem";

export const Chats = ({
  chats,
  isLoading,
  isSearchLoader,
  loadMore,
  hasMore,
  className,
  chatCategory,
}) => {
  return (
    <Box className={styles.chats}>
      {isSearchLoader ? <CircularProgress className={styles.loader} /> : null}
      {!chats?.length && isLoading ? (
        <>
          <Skeleton className={styles.skeleton} variant="rectangular" />
          <Skeleton className={styles.skeleton} variant="rectangular" />
          <Skeleton className={styles.skeleton} variant="rectangular" />
        </>
      ) : chats?.length ? (
        <InfiniteScroll
          dataLength={chats.length}
          next={loadMore}
          hasMore={hasMore}
          loader={isLoading}
          scrollThreshold={SCROLL_THRESHOLD}
          scrollableTarget="scrollableEl"
        >
          <Box className={`${styles.list} ${className}`}>
            {chats.map((item) => (
              <ChatItem
                key={item.timestamp || item.id}
                {...item}
                chatCategory={chatCategory}
              />
            ))}
          </Box>
        </InfiniteScroll>
      ) : null}
    </Box>
  );
};
