import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { Box, Typography } from "@mui/material";
import { ACTIVITY_DEVICE_TYPES } from "constants/";
import TimeAgo from "components/TimeAgo";

import styles from "./ActivityDevice.module.scss";

import { ReactComponent as MobileIcon } from "assets/svg/mobile-icon.svg";
import { ReactComponent as TabletIcon } from "assets/svg/tablet-icon.svg";
import { ReactComponent as DesktopIcon } from "assets/svg/desktop-icon.svg";

const ActivityDevice = (props) => {
  const { browser, location, device, date, locale, isActive } = props;
  const { t } = useContext(LocalizationContext);

  // TODO: get device type
  const deviceType = ACTIVITY_DEVICE_TYPES.desktop;

  const noLocation = !location || location === "No Data";

  const getIconByType = () => {
    switch (deviceType) {
      case ACTIVITY_DEVICE_TYPES.desktop:
        return <DesktopIcon className={styles.activityDeviceIcon} />;
      case ACTIVITY_DEVICE_TYPES.tablet:
        return <TabletIcon className={styles.activityDeviceIcon} />;
      case ACTIVITY_DEVICE_TYPES.mobile:
        return <MobileIcon className={styles.activityDeviceIcon} />;
      default:
        return <DesktopIcon className={styles.activityDeviceIcon} />;
    }
  };

  return (
    <Box className={styles.activityDevice}>
      <Box className={styles.activityDeviceContainer}>
        <Box className={styles.activityDeviceInfo}>
          {getIconByType()}
          <Box className={styles.activityDeviceInfoRow}>
            <Typography variant="body2">{device}</Typography>
            {!noLocation && (
              <>
                <Box className={styles.activityDeviceDivider} />
                <Typography variant="body2">{location}</Typography>
              </>
            )}
          </Box>
          <Box className={styles.activityDeviceInfoRow}>
            <Typography variant="body3" className={styles.grayText}>
              {browser}
            </Typography>
            <Box className={`${styles.activityDeviceDivider} ${styles.gray}`} />
            {isActive ? (
              <Typography variant="body3" className={styles.activeNow}>
                {t("settings.activeNow")}
              </Typography>
            ) : (
              <Typography variant="body3" className={styles.grayText}>
                <TimeAgo value={date} locale={locale} />
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ActivityDevice;
