import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { LocalizationContext } from "services/localizationContext";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MenuTitle from "components/MenuTitle";
import { SUSCRIPTION_STATES, VIP_STATUS } from "constants/";
import { ReactComponent as CheckedIcon } from "assets/svg/success-icon.svg";
import { ReactComponent as IconNever } from "assets/svg/icon-never.svg";
import { ReactComponent as IconInfo } from "assets/svg/icon-info.svg";
import styles from "./Settings.module.scss";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#21264E",
    color: "fff",
    boxShadow: "none",
    fontSize: 14,
    padding: "16px",
    maxWidth: "180px",
    fontFamily: "ClashGrotesk-Regular",
  },
}));

const Subscription = () => {
  const { t } = useContext(LocalizationContext);
  const user = useSelector((state) => state.users.entities);

  const renderStatus = () => {
    switch (user?.vipStatus) {
      case VIP_STATUS.PENDING: {
        return (
          <Typography variant="h6">
            {t("settings.verificationPendingTitle")}
          </Typography>
        );
      }
      case VIP_STATUS.YES: {
        return (
          <Typography variant="h6" className={styles.statusText}>
            {t("default.active")} <CheckedIcon className={styles.iconChecked} />
          </Typography>
        );
      }
      default: {
        return (
          <Typography variant="h6" className={styles.statusText}>
            {t("default.inactive")}{" "}
            <CustomTooltip
              placement="bottom-end"
              title={t("settings.zoopProTooltip")}
            >
              <IconInfo className={styles.icon} />
            </CustomTooltip>
          </Typography>
        );
      }
    }
  };

  const renderDateRenewal = () => {
    switch (user?.vipStatus) {
      case VIP_STATUS.PENDING: {
        return (
          <Typography variant="h6" className={styles.statusText}>
            {t("default.na")}
          </Typography>
        );
      }
      case VIP_STATUS.YES: {
        return (
          <Typography variant="h6" className={styles.statusText}>
            {t("default.never")} <IconNever className={styles.icon} />
          </Typography>
        );
      }
      default: {
        return (
          <Typography variant="h6" className={styles.statusText}>
            {t("default.na")}
          </Typography>
        );
      }
    }
  };

  return (
    <Box className={styles.subscriptionContainer}>
      <Box className={styles.subscriptionContent}>
        <MenuTitle
          states={SUSCRIPTION_STATES}
          currentState={SUSCRIPTION_STATES.default}
        />
        <Box className={styles.proWrapper}>
          <Typography variant="h4">{t("default.zoopPro")}</Typography>
          <Box className={styles.subscriptionList}>
            <Box className={styles.subscriptionItem}>
              <Typography variant="h6" className={styles.subscriptionItemTitle}>
                {t("discover.status")}:
              </Typography>
              {renderStatus()}
            </Box>
            <Box className={styles.subscriptionItem}>
              <Typography variant="h6" className={styles.subscriptionItemTitle}>
                {t("settings.dateOfRenewal")}:
              </Typography>
              {renderDateRenewal()}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Subscription;
