import { Box } from "@mui/material";
import React from "react";

import styles from "./OverlayImages.module.scss";

export const OverlayImages = ({ images }) => {
  return (
    <Box className={styles.overlay}>
      {images.map(({ src, style }) => (
        <img alt="coin" src={src} className={styles.coin} style={style} />
      ))}
    </Box>
  );
};
