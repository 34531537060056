import { LANGUAGES_LIST } from "constants/";
import _ from "lodash";
import isEmpty from "lodash/isEmpty";
import React, { useReducer } from "react";
import { getUserLocale } from "utils/langHelper";

import localize from "./localize_641629ad6f098.json";

const defaultState = localize[LANGUAGES_LIST.english];
const initialState =
  localize[localStorage.getItem("ii-language") || getUserLocale()];

const reducer = (localization, locale) => {
  if (isEmpty(localize[locale])) {
    console.warn("no locale provided for", locale);
    return localize[LANGUAGES_LIST.english];
  }
  return localize[locale];
};

const getLocalizedValue = (usedKey, localization) => {
  let str = _.get(localization, usedKey);
  if (str && str.startsWith("__")) str = _.get(initialState, usedKey);
  if (str && str.startsWith("__")) str = _.get(defaultState, usedKey);
  if (!str) str = _.get(defaultState, usedKey);
  return str;
};

const LocalizationContext = React.createContext({});

function LocalizationProvider({ children }) {
  const [localization, setLocalization] = useReducer(reducer, initialState);
  const t = (key, placeholders) => {
    const isSingular = placeholders?.count == 1 && placeholders?.useSingular;
    const usedKey = (isSingular && `${key}Singular`) || key;
    let str = getLocalizedValue(usedKey, localization);

    if (placeholders && str) {
      _.forOwn(placeholders, (value, key) => {
        str = str.replace(`{{${key}}}`, value);
      });
    }

    return str || usedKey;
  };

  const timerLocalize = {
    seconds: t("drops.secondShort"),
    minutes: t("drops.minuteShort"),
    hours: t("drops.hourShort"),
    days: t("drops.dayShort"),
  };

  const timerLocalizeFull = {
    seconds: t("default.seconds"),
    minutes: t("default.minutes"),
    hours: t("default.hours"),
    days: t("default.days"),
  };

  return (
    <LocalizationContext.Provider
      value={{ setLocalization, t, timerLocalize, timerLocalizeFull }}
    >
      {children}
    </LocalizationContext.Provider>
  );
}

export { LocalizationContext, LocalizationProvider };
