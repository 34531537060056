import React, { useContext } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import styles from "./blocked-account-item.module.scss";

const BlockedAccountItem = ({ user, onUnblockClick }) => {
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);

  const handleUnblock = (event) => {
    event.stopPropagation();
    onUnblockClick();
  };

  const handleCardClick = () => {
    navigate(`/public/user/${user.username}`);
  };

  return (
    <Box className={styles.container} onClick={handleCardClick}>
      <img
        className={styles.image}
        src={user.userPicCroppedUrl}
        alt={user.username}
        loading="lazy"
      />
      <Typography variant="body1" component="span" className={styles.title}>
        {user.username}
      </Typography>
      <Button
        variant="contained"
        className={styles.btn}
        onClick={handleUnblock}
      >
        {t("account.unblock")}
      </Button>
    </Box>
  );
};

export default BlockedAccountItem;
