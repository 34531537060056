import { Button, Typography } from "@mui/material";
import { ReactComponent as IconYoutube } from "assets/svg/youtube-icon.svg";
import MediaResetPostModal from "components/PostCreator/components/ResetModal/MediaResetPostModal";
import React, { useContext, useEffect, useState } from "react";
import { LocalizationContext } from "services/localizationContext";
import { useIsMobile } from "utils/hooks";

import styles from "./AddMediaButton.module.scss";

export const YoutubeButton = ({
  isAnotherMediaType,
  isYoutubeSection,
  setYoutubeSection,
  handleMediaChange,
  setShouldHideTooltips,
}) => {
  const isMobile = useIsMobile();
  const { t } = useContext(LocalizationContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setShouldHideTooltips && setShouldHideTooltips(isModalOpen);
  }, [isModalOpen]);

  const handleModalConfirmation = () => {
    handleMediaChange();
    setYoutubeSection((prevState) => !prevState);
  };

  const handleConfirmationModal = () => {
    if (isAnotherMediaType) {
      setIsModalOpen(true);
    } else {
      setYoutubeSection((prevState) => !prevState);
    }
  };

  return (
    <>
      <MediaResetPostModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={t("discover.alertReplaceMediaTitle")}
        description={t("discover.alertReplaceMediaBody")}
        handleModalConfirmation={handleModalConfirmation}
      />
      <Button
        variant="contained"
        size="small"
        className={`${styles.addPhotoBtn} ${
          isYoutubeSection ? styles.addPhotoBtnActive : ""
        }`}
        startIcon={<IconYoutube />}
        onClick={handleConfirmationModal}
      >
        <Typography variant="body4_groteskMedium" component="span">
          {isMobile ? t("default.youtubeVideo") : t("default.addYoutubeVideo")}
        </Typography>
      </Button>
    </>
  );
};
