import React, { useContext, useEffect, useState, useRef } from "react";
import { LocalizationContext } from "services/localizationContext";
import {
  Box,
  FormControl,
  Typography,
  TextField,
  InputLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { setLoader, setError } from "redux/appSlice";
import SeparateDateInput from "components/SeparateDateInput";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { isValid } from "date-fns";
import {
  fetchUser,
  updateUserProfile,
  updateZendeskUserInfo,
} from "redux/usersSlice";
import { errorResponseMessages } from "services/apiErrorHelper.tsx";
import { SUCCESS_MSG_LIFETIME } from "constants/";

import styles from "./PersonalInformation.module.scss";
import roundedInputStyles from "styles/input/rounded.module.scss";

import { ReactComponent as CheckedIcon } from "assets/svg/success-icon.svg";

function parseDateString(value) {
  const date = new Date(value);

  if (!isValid(date) || date > new Date()) {
    return false;
  }
  return true;
}

const transformDate = (date) => {
  if (!isValid(new Date(date)) || !date) return "";

  const newBirthday = date.split("-");
  return `${newBirthday[1]}/${newBirthday[2]}/${newBirthday[0]}`;
};

const PersonalInformation = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.users.entities);
  const loading = useSelector((state) => state.app.loader);
  const timer = useRef(null);
  const [firstNameInputFocused, setFirstNameInputFocused] = useState(false);
  const [lastNameInputFocused, setLastNameInputFocused] = useState(false);
  const [currentFirstName, setCurrentFirstName] = useState("");
  const [currentLastName, setCurrentLastName] = useState("");
  const [successfulEdits, setSuccessfulEdits] = useState(false);

  const validationSchemaPersonalInformation = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(t("error.required"))
      .test("is-valid-string", t("error.onlyEnglishLetter"), (value) => {
        const result = /^[A-Za-z-_. ]+$/i.test(value);
        return result;
      }),
    lastName: Yup.string()
      .trim()
      .required(t("error.required"))
      .test("is-valid-string", t("error.onlyEnglishLetter"), (value) => {
        const result = /^[A-Za-z-_. ]+$/i.test(value);
        return result;
      }),
    dateOfBirth: Yup.string()
      .test("is-valid-date", t("error.birthdayNotValid"), (value) => {
        const isFull = value.split("/").every((item) => item.length > 0);
        return isFull && parseDateString(value);
      })
      .required(t("error.required")),
  });

  const {
    register,
    setValue,
    handleSubmit: handleSubmitPersonalInformation,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchemaPersonalInformation),
    defaultValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      dateOfBirth: transformDate(user?.dateOfBirth),
    },
  });

  const handleSubmit = (data) => {
    dispatch(setLoader(true));
    const payload = data;

    const newBirthday = payload.dateOfBirth.split("/");
    payload.dateOfBirth = `${newBirthday[2]}-${newBirthday[0]}-${newBirthday[1]}`;

    dispatch(updateUserProfile(payload))
      .unwrap()
      .then(() => {
        setSuccessfulEdits(true);
        dispatch(fetchUser());
        dispatch(
          updateZendeskUserInfo({
            email: user.email,
            ...payload,
          })
        );
        dispatch(setLoader(false));
        timer.current = setTimeout(() => {
          setSuccessfulEdits(false);
        }, SUCCESS_MSG_LIFETIME);
      })
      .catch((e) => {
        dispatch(setLoader(false));
        dispatch(
          setError({
            open: true,
            title: t("error.error"),
            subtitle: errorResponseMessages(e, t),
          })
        );
      });
  };

  useEffect(() => {
    setValue("firstName", user?.firstName || "");
    setValue("lastName", user?.lastName || "");
    setValue("dateOfBirth", transformDate(user?.dateOfBirth));

    setCurrentFirstName(user?.firstName || "");
    setCurrentLastName(user?.lastName || "");
  }, [user?.dateOfBirth, user?.firstName, user?.lastName]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <Box className={styles.container}>
      <form
        onSubmit={handleSubmitPersonalInformation(handleSubmit)}
        autoComplete="off"
        className={styles.form}
      >
        <Box className={styles.column}>
          <Typography variant="h4" className={styles.columnLabel}>
            {t("default.name")}
          </Typography>
          <Box className={styles.row}>
            <FormControl className={styles.inputWrap}>
              {!firstNameInputFocused && !currentFirstName.length ? (
                <InputLabel
                  className={roundedInputStyles.label}
                  id="firstName-input-label"
                >
                  {user?.firstName || t("default.firstName")}
                </InputLabel>
              ) : null}
              <TextField
                id="firstName-input"
                label={t("default.firstName")}
                error={!!errors.firstName}
                className={`${roundedInputStyles.input} ${
                  !!currentFirstName?.length ? roundedInputStyles.filled : null
                }`}
                inputProps={{
                  onChange: (ev) => setCurrentFirstName(ev.target?.value),
                  onFocus: () => setFirstNameInputFocused(true),
                  onBlur: () => setFirstNameInputFocused(false),
                }}
                type="text"
                autoComplete="off"
                name="firstName-input"
                disabled={user.isCompleted}
                {...register("firstName")}
              />
              {!!errors.firstName ? (
                <Typography variant="error" sx={{ mt: 1 }}>
                  {errors.firstName?.message}
                </Typography>
              ) : null}
            </FormControl>
            <FormControl className={styles.inputWrap}>
              {!lastNameInputFocused && !currentLastName.length ? (
                <InputLabel
                  className={roundedInputStyles.label}
                  id="lastName-input-label"
                >
                  {user?.lastName || t("default.lastName")}
                </InputLabel>
              ) : null}
              <TextField
                id="lastName-input"
                label={t("default.lastName")}
                error={!!errors.lastName}
                className={`${roundedInputStyles.input} ${
                  !!currentLastName?.length ? roundedInputStyles.filled : null
                }`}
                inputProps={{
                  onChange: (ev) => setCurrentLastName(ev.target?.value),
                  onFocus: () => setLastNameInputFocused(true),
                  onBlur: () => setLastNameInputFocused(false),
                }}
                type="text"
                autoComplete="off"
                name="lastName-input"
                disabled={user.isCompleted}
                {...register("lastName")}
              />
              {!!errors.lastName ? (
                <Typography variant="error" sx={{ mt: 1 }}>
                  {errors.lastName?.message}
                </Typography>
              ) : null}
            </FormControl>
          </Box>
        </Box>
        <Box className={styles.column}>
          <Typography variant="h4" className={styles.columnLabel}>
            {t("default.dateOfBirth")}
          </Typography>
          <Box className={styles.row}>
            <FormControl className={styles.inputWrap}>
              <SeparateDateInput
                defaultValue={transformDate(user?.dateOfBirth)}
                onChange={(value) => setValue("dateOfBirth", value)}
                validation={{ ...register("dateOfBirth") }}
                disabled={user.isCompleted}
              />
              {!!errors.dateOfBirth ? (
                <Typography variant="error" sx={{ mt: 1 }}>
                  {errors.dateOfBirth?.message}
                </Typography>
              ) : null}
            </FormControl>
          </Box>
        </Box>
        {!user.isCompleted ? (
          <Box className={styles.row}>
            {successfulEdits ? (
              <Box className={`${styles.btn} ${styles.changesSavedMsg}`}>
                <CheckedIcon className={styles.changesSavedMsgIcon} />
                <Typography component="span" variant="h6">
                  {t("settings.changesSaved")}
                </Typography>
              </Box>
            ) : (
              <LoadingButton
                type="submit"
                variant="contained"
                className={styles.btn}
                loading={loading}
              >
                {t("settings.saveChanges")}
              </LoadingButton>
            )}
          </Box>
        ) : (
          <Typography variant="body3">
            {t("settings.toChangePersonalInfoContact")}
          </Typography>
        )}
      </form>
    </Box>
  );
};

export default PersonalInformation;
