import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/svg/close-icon.svg";
import classNames from "classnames/bind";

import styles from "./SlideTutorial.module.scss";

export const SlideTutorial = ({
  title,
  description,
  buttonText,
  action,
  activeIndex,
  indicators = [],
  wrapperClassName,
  closeAction,
}) => {
  const wrapperClass = classNames(styles.wrapper, {
    [wrapperClassName]: wrapperClassName,
  });

  return (
    <Box className={wrapperClass}>
      <Box className={styles.info}>
        <Box className={styles.infoHeader}>
          <Typography variant="h5_medium_20">{title}</Typography>
          <Button onClick={closeAction}>
            <CloseIcon />
          </Button>
        </Box>
        <Typography variant="body1">{description}</Typography>
        <Box className={styles.indicators}>
          {indicators.map((item, index) => (
            <Box
              key={item.id}
              className={`${styles.indicator} ${
                index === activeIndex ? styles.indicatorActive : ""
              }`}
              onClick={item.action}
            />
          ))}
        </Box>
      </Box>
      <Button variant="contain" className={styles.actionBtn} onClick={action}>
        {buttonText}
      </Button>
    </Box>
  );
};
