import { Box, Button, Skeleton, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ReactComponent as CheckedIcon } from "assets/svg/checked-icon.svg";
import { ReactComponent as FriendIcon } from "assets/svg/friend-icon.svg";
import { ReactComponent as MessageIcon } from "assets/svg/message-icon.svg";
import UnfriendModal from "components/Modal/UnfriendModal";
import {
  CHAT_CATEGORIES,
  CONNECTION_REQUEST_TYPE,
  CONNECTIONS_LOAD_LIMIT,
  PATHS,
} from "constants/index";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { setLoader } from "redux/appSlice";
import { setSelectedUser } from "redux/messengerSlice";
import { closeModal, openModal } from "redux/modalSlice";
import { getPublicUser } from "redux/selectors/usersSelectors";
import {
  acceptFriendRequest,
  fetchBlacklist,
  fetchUserConnections,
  fetchUserFriendRequests,
  rejectFriendRequest,
  selectConnectionById,
  sendConnectionRequest,
  unfriendUser,
} from "redux/usersSlice";
import { LocalizationContext } from "services/localizationContext";
import { usePublicUserConnect } from "utils/hooks/usePublicUserConnect";

import styles from "./FriendActionBox.module.scss";
import { AddFriendIcon } from "./icons";

export const FriendActionBox = ({ meId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);
  const isSm = useMediaQuery("(max-width:960px)");

  const publicUser = useSelector(getPublicUser);
  const connection = useSelector((state) => selectConnectionById(state, meId));
  const { isBlocked, handleUnblock } = usePublicUserConnect();

  const [outgoingFriendRequests, setOutgoingFriendRequests] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);
  const [friendRequestLoader, setFriendRequestLoader] = useState(false);

  const isFriend = !!connection;
  const isSent =
    outgoingFriendRequests.findIndex((r) => r.friend.id === publicUser.id) >= 0;

  const handleUnfriend = () => {
    if (!connection) return;

    const handleConfirm = () => {
      dispatch(setLoader(true));
      dispatch(unfriendUser(connection.id));

      dispatch(setLoader(false));
      dispatch(closeModal());
    };

    dispatch(
      openModal({
        children: (
          <UnfriendModal
            title={`Do you want to unfriend ${connection.friend.username}?`}
            description={`If you unfriend ${connection.friend.username} now you will have to send request to the user to become friends again`}
            onConfirm={handleConfirm}
            onCancel={() => dispatch(closeModal())}
          />
        ),
      })
    );
  };

  const goToMessenger = () => {
    dispatch(setSelectedUser(publicUser));
    navigate({
      pathname: PATHS.CHAT_DIRECT.replace(":userId", publicUser.id),
      search: createSearchParams({
        category: CHAT_CATEGORIES.GENERAL,
      }).toString(),
    });
  };

  const handleRejectRequest = useCallback(
    (connectionId) => {
      if (!connectionId) return;

      dispatch(setLoader(true));
      dispatch(rejectFriendRequest(connectionId)).then(() => {
        setFriendRequests([
          ...friendRequests.filter((c) => c.id !== connectionId),
        ]);
      });
      dispatch(setLoader(false));
    },
    [friendRequests]
  );

  const handleAcceptRequest = useCallback(
    (connectionId) => {
      if (!connectionId) return;

      dispatch(setLoader(true));
      dispatch(acceptFriendRequest(connectionId)).then(() => {
        dispatch(
          fetchUserConnections({
            limit: CONNECTIONS_LOAD_LIMIT,
            page: 1,
          })
        );
        setFriendRequests([
          ...friendRequests.filter((c) => c.id !== connectionId),
        ]);
      });
      dispatch(setLoader(false));
    },
    [friendRequests]
  );

  const handleSendRequest = async () => {
    if (!publicUser?.id) {
      return;
    }
    dispatch(setLoader(true));
    await dispatch(sendConnectionRequest(publicUser?.id));
    dispatch(
      fetchUserFriendRequests({ type: CONNECTION_REQUEST_TYPE.outgoing })
    )
      .unwrap()
      .then((d) => setOutgoingFriendRequests(d));
    dispatch(setLoader(false));
  };

  useEffect(() => {
    let unmounted = false;
    if (publicUser?.id) {
      setFriendRequestLoader(true);
    }
    dispatch(
      fetchUserConnections({
        limit: CONNECTIONS_LOAD_LIMIT,
        page: 1,
      })
    );

    dispatch(
      fetchUserFriendRequests({ type: CONNECTION_REQUEST_TYPE.incoming })
    )
      .unwrap()
      .then((d) => !unmounted && setFriendRequests(d));
    dispatch(
      fetchUserFriendRequests({ type: CONNECTION_REQUEST_TYPE.outgoing })
    )
      .unwrap()
      .then((d) => !unmounted && setOutgoingFriendRequests(d))
      .finally(() => setFriendRequestLoader(false));

    dispatch(fetchBlacklist());

    return () => {
      unmounted = true;
    };
  }, [publicUser?.id]);

  const renderFriendButtons = () => {
    const isRequest = friendRequests?.find(
      (request) => request.friend.id === publicUser.id
    );
    if (isRequest) {
      return (
        <Box className={styles.requestFriendButtons}>
          <Button
            className={styles.requestBtn}
            onClick={() => handleRejectRequest(isRequest.id)}
            variant="outlined"
          >
            <Typography variant="body1" className={styles.friendTitle}>
              {t("account.remove")}
            </Typography>
          </Button>

          <Button
            variant="contained"
            className={`${styles.requestBtn} ${styles.acceptRequestBtn}`}
            onClick={() => handleAcceptRequest(isRequest.id)}
          >
            <Typography variant="body1" className={styles.friendTitle}>
              {t("account.acceptAsFriend")}
            </Typography>
          </Button>
        </Box>
      );
    }
    return (
      <Box className={styles.btnsWrap}>
        {friendRequestLoader ? (
          <Skeleton className={styles.smallBtn} variant="rectangular" />
        ) : (
          <Button
            className={styles.addFriendBtn}
            onClick={handleSendRequest}
            variant="contained"
          >
            <AddFriendIcon />
            {t("account.addFriend")}
          </Button>
        )}
        <Button
          className={`${styles.smallBtn} ${styles.outlined}`}
          onClick={goToMessenger}
          variant="outlined"
        >
          <MessageIcon className={styles.friendIcon} />
          <Typography variant="body1" className={styles.friendTitle}>
            {isSm ? t("discover.message") : t("messages.sendMessage")}
          </Typography>
        </Button>
      </Box>
    );
  };

  return (
    <Box className={styles.actions}>
      {isBlocked ? (
        <Button
          variant="contained"
          className={styles.unblockBtn}
          onClick={handleUnblock}
        >
          {t("account.unblock")}
        </Button>
      ) : (
        <Box className={styles.friendBlock}>
          {isFriend ? (
            <Box className={styles.btnsWrap}>
              <Button
                className={`${styles.smallBtn} ${styles.outlined}`}
                onClick={handleUnfriend}
                variant="outlined"
              >
                <FriendIcon className={styles.friendIcon} />
                <Typography variant="body1" className={styles.friendTitle}>
                  <Box className={styles.friendTitleText}>
                    {t("account.friend")}
                  </Box>
                  <Box className={styles.friendTitleTextHover}>
                    {t("account.unfriend")}
                  </Box>
                </Typography>
              </Button>
              <Button
                className={`${styles.smallBtn} ${styles.outlined}`}
                onClick={goToMessenger}
                variant="outlined"
              >
                <MessageIcon className={styles.friendIcon} />
                <Typography variant="body1" className={styles.friendTitle}>
                  {isSm ? t("discover.message") : t("messages.sendMessage")}
                </Typography>
              </Button>
            </Box>
          ) : !isSent ? (
            renderFriendButtons()
          ) : (
            <Box className={styles.btnsWrap}>
              <Button
                className={`${styles.smallBtn} ${styles.outlined}`}
                variant="outlined"
              >
                <CheckedIcon className={styles.friendRequestSentIcon} />
                <Typography variant="body1">
                  {t("account.friendRequestWasSent")}
                </Typography>
              </Button>
              <Button
                className={`${styles.smallBtn} ${styles.outlined}`}
                onClick={goToMessenger}
                variant="outlined"
              >
                <MessageIcon className={styles.friendIcon} />
                <Typography variant="body1" className={styles.friendTitle}>
                  {isSm ? t("discover.message") : t("messages.sendMessage")}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
