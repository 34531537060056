import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";
import { FormControl, Typography, Button, Box } from "@mui/material";

import styles from "./ResetPassword.module.scss";

const Success = ({ onContinue }) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Box className={styles.container}>
      <Typography variant="h6">
        {t("settings.passwordChangeSuccess")}
      </Typography>
      <FormControl className={styles.inputWrap}>
        <Button
          variant="contained"
          className={styles.submitBtn}
          onClick={() => onContinue()}
        >
          {t("kyc.continueButton")}
        </Button>
      </FormControl>
    </Box>
  );
};

export default Success;
