import { Box, Tab, Tabs } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AllStars } from "components/InfluencersRows/AllStars/AllStars";
import { EmptyState } from "components/InfluencersRows/EmptyState/EmptyState";
import { Groups } from "components/InfluencersRows/Groups/Groups";
import { RisingStars } from "components/InfluencersRows/RisingStars/RisingStars";
import { throttle } from "lodash";
import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./SearchResultList.module.scss";

const a11yProps = (index) => {
  return {
    id: `search-tab-${index}`,
    "aria-controls": `search-tabpanel-${index}`,
  };
};

const tabsIndex = {
  creators: 0,
  groups: 1,
};

export const SearchResultList = ({ data, loaders }) => {
  const { t } = useContext(LocalizationContext);
  const refWrapper = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg_small"));

  const [tabsValue, setTabsValue] = useState(tabsIndex.creators);

  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  const isEmptyCreators =
    !data.allStars?.length &&
    !data.risingStars?.length &&
    !loaders.allStarsLoader &&
    !loaders.risingStarsLoader;

  const isEmptyGroups = !data.groups?.length && !loaders.groupsLoader;

  const isEmpty = isEmptyCreators && isEmptyGroups;

  useLayoutEffect(() => {
    const setHeaderHeight = () => {
      if (refWrapper.current) {
        const { top } = refWrapper.current.getBoundingClientRect();
        if (isMobile) {
          refWrapper.current.style.height = `${
            window.innerHeight - top - 54
          }px`; //(64 - 10) - 64 - height of the bottom menu, 10 - space
        } else {
          refWrapper.current.style.height = `${window.innerHeight - top}px`;
        }
      }
    };
    setHeaderHeight();
    const throttledSetHeaderHeight = throttle(setHeaderHeight, 300);

    window.addEventListener("resize", throttledSetHeaderHeight);
    document.body.style.overflow = "hidden";
    document.body.classList.add("search-result-opened");

    return () => {
      document.body.style.overflow = "initial";
      document.body.classList.remove("search-result-opened");
      window.removeEventListener("resize", throttledSetHeaderHeight);
    };
  }, [isMobile]);

  return (
    <Box ref={refWrapper} className={styles.wrapper}>
      {isEmpty && !isMobile ? (
        <EmptyState />
      ) : (
        <>
          {isMobile ? (
            <>
              <Tabs
                value={tabsValue}
                onChange={handleTabChange}
                className={`${styles.tabs} global-tabs-class`}
              >
                <Tab
                  label={t("discover.creators")}
                  {...a11yProps(tabsIndex.creators)}
                />
                <Tab
                  label={t("discover.groups")}
                  {...a11yProps(tabsIndex.groups)}
                />
              </Tabs>
              <Box className={styles.tabsContent}>
                <Box
                  className={styles.tabsValue}
                  style={{
                    display:
                      tabsValue === tabsIndex.creators ? "block" : "none",
                  }}
                >
                  {isEmptyCreators ? (
                    <EmptyState />
                  ) : (
                    <RisingStars
                      data={[...data.allStars, ...data.risingStars]}
                      loading={
                        loaders.risingStarsLoader || loaders.allStarsLoader
                      }
                      isHideHeader={true}
                      isHideViewAll
                    />
                  )}
                </Box>
                <Box
                  className={styles.tabsValue}
                  style={{
                    display: tabsValue === tabsIndex.groups ? "block" : "none",
                  }}
                >
                  {isEmptyGroups ? (
                    <EmptyState />
                  ) : (
                    <Groups
                      data={data.groups}
                      loading={loaders.groupsLoader}
                      isHideHeader={true}
                      isHideViewAll
                    />
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <>
              <AllStars
                data={data.allStars}
                loading={loaders.allStarsLoader}
                className={styles.allStars}
                isHideViewAll
                subHeaderTitle={t("discover.creators")}
              />
              <RisingStars
                data={data.risingStars}
                loading={loaders.risingStarsLoader}
                isHideHeader={data.allStars?.length}
                isHideViewAll
              />
              <Groups
                data={data.groups}
                loading={loaders.groupsLoader}
                isHideViewAll
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};
