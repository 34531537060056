import { api } from "./api.service";

const API_URL = process.env.REACT_APP_API_HOST;

export const createVideoMeeting = async (title) => {
  try {
    return api.post(`${API_URL}/meetings/create-video`, { title });
  } catch (error) {
    console.error("Cannot add the item to watch list", error);
  }
};

export const joinVideoMeeting = async (extMeetingId, name) => {
  try {
    return api.post(`${API_URL}/meetings/join-video`, { extMeetingId, name });
  } catch (error) {
    console.error("Cannot add the item to watch list", error);
  }
};
