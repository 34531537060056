import { Box, Typography } from "@mui/material";
import TimeAgo from "components/TimeAgo";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";
import { v4 as uuidv4 } from "uuid";

import { DropDown } from "../DropDown/DropDown";
import { EmptyOrders } from "./components/EmptyOrders/EmptyOrders";
import styles from "./OrderHistory.module.scss";

const OrderHistory = ({ data, locale }) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const filteredData = data.filter((d) => d.price !== 0 && !!d.username.length);

  return (
    <DropDown
      title={t("drops.orderHistory")}
      className={`${data?.length ? "" : styles.empty}`}
    >
      <Box className={styles.list}>
        {filteredData.length ? (
          filteredData.map((item) => (
            <Box key={uuidv4()} className={styles.listItem}>
              <Typography
                variant="h6"
                component="p"
                className={`fontDisplay ${styles.username}`}
                onClick={() => navigate(`/public/user/${item.username}`)}
              >
                {item.username}
              </Typography>
              <Typography
                variant="h6"
                component="span"
                className="fontDisplay"
                textAlign="right"
              >
                <TimeAgo value={item.date} locale={locale} />
              </Typography>
            </Box>
          ))
        ) : (
          <EmptyOrders />
        )}
      </Box>
    </DropDown>
  );
};
export default OrderHistory;
