import { Box, Container, Grid, Skeleton } from "@mui/material";
import { Card } from "components/NewCard";
import { CARD_VARIANTS } from "constants/card";
import { PATHS } from "constants/index";
import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./PhaseListItem.module.scss";

const Phase = ({ loading, cards, className, classNameGrid }) => {
  const { t } = useContext(LocalizationContext);

  const loader = (
    <Grid item xs={12}>
      <Grid container justifyContent="left" spacing="15px">
        {Array.from(new Array(4)).map((item, index) => (
          <Grid item key={index} className={classNameGrid}>
            <Skeleton className={styles.drop} variant="rectangular" />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
  const content = (
    <Grid item xs={12}>
      <Grid container justifyContent="left" spacing="15px">
        {cards.map((drop) => (
          <Grid key={drop.id} item className={classNameGrid}>
            <Card
              variant={CARD_VARIANTS.FULL}
              data={drop}
              link={PATHS.CARD_ID.replace(":id", drop.id)}
              overrideStyles={{ root: styles.drop, img: styles.dropImg }}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  return (
    <Box className={[styles.phase, className]}>
      {!cards.length && !loading && (
        <Container className={styles.emptyPhase}>
          {t("default.noData")}
        </Container>
      )}
      {loading ? loader : content}
    </Box>
  );
};
export default Phase;
