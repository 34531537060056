import { useTheme } from "@emotion/react";
import { Button, Typography } from "@mui/material";
import { ReactComponent as ArrowRight } from "assets/svg/arrow-right.svg";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "services/localizationContext";

import styles from "./ViewAll.module.scss";

export const ViewAll = ({ path, handler, text, isArrow }) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const clickHandler = () => {
    if (handler) {
      return handler;
    }
    if (path) {
      return () => navigate(path);
    }
  };

  return (
    <Button onClick={clickHandler()} size="small" className={styles.btn}>
      <Typography
        variant="textMediumBold1"
        style={{ color: theme.palette.accent.main }}
        className={styles.btnText}
      >
        {text ? text : t("account.viewAll")}
      </Typography>
      {isArrow ? <ArrowRight /> : null}
    </Button>
  );
};
