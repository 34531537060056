import { initialChallengeDetailsSubState } from "../challengesSlice";

export const clubChallengesById = (id) => (state) =>
  state.challenges.challengesList.club[id];
export const clubChallengesByIdLoader = (state) =>
  state.challenges.challengesList.clubLoading;
export const getChallengeDetails = (id) => (state) =>
  state.challenges.challengeDetails[id] || initialChallengeDetailsSubState;
export const getChallengesSelector = (state) =>
  state.challenges.challengesList.entities;
export const getChallengesCategorySelector = (category) => (state) =>
  state.challenges.challengesList.entities[category];
export const getChallengesLoadingSelector = (state) =>
  state.challenges.challengesList.loading;
