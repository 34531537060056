import { format, isSameDay } from "date-fns";
import { fromZonedTime } from "date-fns-tz";

export const getFormattedTime = (data) => {
  const newDate = new Date(data);
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  return `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
};

export const convertTime = ({ times, from, startDate, comparableDate }) => {
  const newTimesSlots = [];
  const day = new Date(startDate).getDay();
  times.forEach((time) => {
    const [startHours, startMinutes] = time.start.split(":");
    const [endHours, endMinutes] = time.end.split(":");
    const dateStart = new Date(comparableDate || startDate);
    const dateEnd = new Date(comparableDate || startDate);
    dateStart.setHours(startHours, startMinutes);
    dateEnd.setHours(endHours, endMinutes);
    const dateStartConverted = fromZonedTime(dateStart, from);
    const dateEndConverted = fromZonedTime(dateEnd, from);
    if (
      // isSameDay(dateStartConverted, dateEndConverted) &&
      new Date(dateStartConverted).getDay() === day
    ) {
      newTimesSlots.push({
        start: getFormattedTime(dateStartConverted),
        end: getFormattedTime(dateEndConverted),
        serverStartDate: format(dateStart, "yyyy-MM-dd HH:mm"),
      });
    }
  });

  return newTimesSlots;
};
