import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { ReactComponent as IconKyc } from "assets/svg/kyc.svg";
import { ReactComponent as IconZoop } from "assets/svg/zoop-icon-with-bg.svg";
import classNames from "classnames/bind";
import { MODAL_TYPE } from "constants/index";
import React, { lazy, Suspense, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "redux/modalSlice";
import { LocalizationContext } from "services/localizationContext";

import { DonationModal } from "./DonationModal/DonationModal";
import styles from "./Modal.module.scss";
import { OnboardingModal } from "./OnboardingModal";
const cx = classNames.bind(styles);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 286,
  bgcolor: "#111322",
  border: "2px solid #21264E",
  boxSizing: "border-box",
  borderRadius: "10px",
  padding: "16px",
};
const MODALS = {
  [MODAL_TYPE.SUBSCRIBE_CREATOR_SUCCESS_MODAL]: React.lazy(() =>
    import("./SubscriptionSuccessModal/SuccessModal")
  ),
  [MODAL_TYPE.STRIPE_SUCCESS_MODAL]: React.lazy(() =>
    import("../Stripe/SuccessModal/SuccessModal")
  ),
  [MODAL_TYPE.REPORT_MODAL]: React.lazy(() => import("./ReportModal/index")),
};

const Popup = () => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modal);
  const { t } = useContext(LocalizationContext);
  const handleClose = () => {
    dispatch(closeModal());
  };

  const renderModalByType = () => {
    switch (modal.type) {
      case MODAL_TYPE.WITH_DYNAMIC_CONTENT: {
        const Content = lazy(() => {
          return import(
            `components/Modal/Content/${modal.modalBody?.children}.jsx`
          ).catch(() => import(`components/Modal/Content/DefaultModal.jsx`));
        });

        return (
          <Box className={styles.modalWrap}>
            <Suspense fallback={<></>}>
              <Content />
            </Suspense>
          </Box>
        );
      }
      case MODAL_TYPE.SUBSCRIBE_CREATOR_SUCCESS_MODAL: {
        return (
          <MODALS.SUBSCRIBE_CREATOR_SUCCESS_MODAL
            {...modal.modalBody.payload}
          />
        );
      }
      case MODAL_TYPE.STRIPE_SUCCESS_MODAL: {
        return <MODALS.STRIPE_SUCCESS_MODAL {...modal.modalBody.payload} />;
      }
      case MODAL_TYPE.REPORT_MODAL: {
        return <MODALS.REPORT_MODAL {...modal.modalBody.payload} />;
      }
      case MODAL_TYPE.VERIFY: {
        const {
          onClick,
          modalBody: { children, title },
        } = modal || {};
        return (
          <Box sx={style}>
            <IconKyc />
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className={styles.modalTitle}
            >
              {title}
            </Typography>
            <Box className={styles.modalBody}>{children}</Box>
            <Button
              variant="verify"
              className={styles.buttonOkay}
              onClick={() => {
                handleClose();
                onClick();
              }}
            >
              {t("kyc.okay")}
            </Button>
          </Box>
        );
      }
      case MODAL_TYPE.VERIFY_2: {
        const {
          onClick,
          modalBody: { children },
        } = modal || {};

        return (
          <Box sx={{ ...style, width: 632 }} className={styles.modalVerifyWrap}>
            <IconZoop />
            <Box className={styles.modalBody}>{children}</Box>
            <Button
              variant="contained"
              className={styles.buttonOkay}
              onClick={() => {
                handleClose();
                onClick();
              }}
            >
              {t("kyc.okay")}
            </Button>
          </Box>
        );
      }
      case MODAL_TYPE.MODAL: {
        return (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "16px",
              right: "16px",
              transform: "translateY(-50%)",
              margin: "0 auto",
              maxWidth: 600,
              width: "calc(100% - 32px)",
              bgcolor: "#111322",
              border: "2px solid #21264E",
              boxSizing: "border-box",
              borderRadius: "10px",
              padding: "16px",
            }}
          >
            {modal?.modalBody?.children}
          </Box>
        );
      }
      case MODAL_TYPE.USERS: {
        return (
          <Box className={styles.participatingUsersModal}>
            {modal?.modalBody?.children}
          </Box>
        );
      }
      case MODAL_TYPE.ONBOARDING_TUTORIAL: {
        return <OnboardingModal>{modal?.modalBody?.children}</OnboardingModal>;
      }
      case MODAL_TYPE.DONATE_MODAL: {
        return <DonationModal {...modal.modalBody.payload} />;
      }
      case MODAL_TYPE.CHALLENGE: {
        return (
          <Box
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 491,
              maxWidth: "90%",
              bgcolor: "#111322",
              boxSizing: "border-box",
              borderRadius: "10px",
              outline: "none",
            }}
          >
            {modal?.modalBody?.children}
          </Box>
        );
      }
      case MODAL_TYPE.BLOCK_USER: {
        return (
          <Box
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 286,
              maxWidth: "90%",
              bgcolor: "#111322",
              boxSizing: "border-box",
              borderRadius: "10px",
              outline: "none",
            }}
          >
            {modal?.modalBody?.children}
          </Box>
        );
      }
      case MODAL_TYPE.BARTER_MATCH:
      case MODAL_TYPE.BARTER_TUTS: {
        return <>{modal?.modalBody?.children}</>;
      }
      default: {
        return (
          <Box
            style={{ ...modal?.styles }}
            className={cx(
              "modalWrap",
              "modalWrapAbsolute",
              modal?.modalBody?.className
            )}
          >
            {modal?.modalBody?.children}
          </Box>
        );
      }
    }
  };
  return (
    <Modal
      open={modal.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.modal}
      classes={modal?.modalBody?.overrideClasses}
    >
      <Suspense
        fallback={
          <Box>
            <CircularProgress />
          </Box>
        }
      >
        {renderModalByType()}
      </Suspense>
    </Modal>
  );
};

export default Popup;
