import { Box } from "@mui/material";
import classnames from "classnames/bind";
import React, { useMemo } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import { useIsMobile } from "utils/hooks";

import styles from "./pricechart.module.scss";

const cx = classnames.bind(styles);

export default function PriceChart({ data, currencySymbol, className }) {
  const cleanupData = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        ...{ price: parseFloat(item.price) },
      })),
    [data]
  );
  const longestLabelLength = useMemo(() => {
    const longest = data
      .map((c) => c.price.toString().split(".")[0])
      .reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0);
    return longest + currencySymbol.length;
  }, [data, currencySymbol]);

  const isMobile = useIsMobile();
  return (
    <>
      <Box className={cx(styles.graphWrap, className)}>
        <ResponsiveContainer
          margin={0}
          minWidth="100%"
          padding={0}
          width="100%"
          height="100%"
        >
          <AreaChart
            margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
            data={cleanupData}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop stopColor="#70E8FC" stopOpacity={0.15} />
                <stop offset="1" stopColor="#70E8FC" stopOpacity={0.02} />
              </linearGradient>
            </defs>
            <CartesianGrid
              stroke="#272937"
              strokeWidth={1}
              vertical={false}
              strokeLinecap="round"
            />
            <YAxis
              fontSize={isMobile ? "10px" : "13px"}
              dx={0}
              dy={0}
              interval="preserveStartEnd"
              tickMargin={0}
              tickLine={false}
              axisLine={false}
              tickCount={5}
              width={longestLabelLength * (isMobile ? 8 : 10)}
              tickFormatter={(value) => {
                return `${currencySymbol}${value}`;
              }}
            />
            <Area
              type="linear"
              dataKey="price"
              stroke="#70E8FC"
              fillOpacity={1}
              fill="url(#colorUv)"
              strokeWidth={1.5}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
}
