import { Box, ClickAwayListener, Tooltip, Typography } from "@mui/material";
import React from "react";

import styles from "./tooltip.module.scss";

const CustomTooltip = ({
  title,
  isCustomTitleLayout,
  shouldHideTooltip,
  children,
  enterDelay = 0,
  isArrow = true,
  isDisabled = false,
  placement = "top",
  tooltipClassName,
  isClick,
  open,
  setOpen,
  ...props
}) => {
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const getTitle = () => {
    if (shouldHideTooltip) {
      return "";
    }
    if (isCustomTitleLayout) {
      return title;
    }
    return <Typography variant="body3_grotesk_medium">{title}</Typography>;
  };

  if (isDisabled) {
    return <>{children}</>;
  }

  return (
    <>
      {isClick ? (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={getTitle()}
              classes={{
                tooltipArrow: `${styles.customTooltip} ${tooltipClassName}`,
                tooltip: `${styles.customTooltip} ${tooltipClassName}`,
                arrow: styles.customTooltipArrow,
              }}
            >
              {children}
            </Tooltip>
          </div>
        </ClickAwayListener>
      ) : (
        <Tooltip
          title={getTitle()}
          placement={placement}
          arrow={isArrow}
          classes={{
            tooltipArrow: `${styles.customTooltip} ${tooltipClassName}`,
            tooltip: `${styles.customTooltip} ${tooltipClassName}`,
            arrow: styles.customTooltipArrow,
          }}
          enterDelay={enterDelay}
          {...props}
        >
          {children}
        </Tooltip>
      )}
    </>
  );
};

export default CustomTooltip;
