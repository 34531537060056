export const DollarIcon = () => (
  <svg
    width="17"
    height="22"
    viewBox="0 0 17 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3157 11C14.4669 10.3294 13.388 9.97336 12.2808 9.9987H5.16415C4.83967 10.0064 4.51687 9.95235 4.21508 9.83971C3.91329 9.72707 3.63871 9.55819 3.40778 9.34317C3.17685 9.12815 2.99432 8.87142 2.87112 8.58834C2.74792 8.30526 2.68657 8.00166 2.69076 7.69571C2.68285 7.57662 2.68285 7.45719 2.69076 7.3381C2.78644 6.7914 3.09054 6.29643 3.54596 5.94613C4.00138 5.59583 4.57679 5.4143 5.16415 5.43563H12.1746C12.7465 5.43516 13.2999 5.62617 13.7363 5.97462C14.1726 6.32306 14.4634 6.80628 14.557 7.3381H16.8331C16.7378 6.23515 16.2057 5.20639 15.3426 4.45635C14.4795 3.70631 13.3485 3.28993 12.1746 3.28999H9.77709V0H7.88032V3.28999H5.16415C3.99025 3.28993 2.85931 3.70631 1.99619 4.45635C1.13306 5.20639 0.600961 6.23515 0.505677 7.3381C0.498108 7.4572 0.498108 7.57661 0.505677 7.69571C0.505677 8.2724 0.626172 8.84344 0.860282 9.37623C1.09439 9.90902 1.43753 10.3931 1.87011 10.8009C2.74374 11.6245 3.92864 12.0871 5.16415 12.0871H12.3567C12.6786 12.0795 12.9989 12.1327 13.2987 12.2436C13.5984 12.3544 13.8716 12.5207 14.1021 12.7327C14.3326 12.9447 14.5158 13.198 14.6408 13.4777C14.7658 13.7575 14.8302 14.058 14.8301 14.3615C14.8458 14.5233 14.8458 14.6861 14.8301 14.8479C14.7096 15.3512 14.4112 15.8009 13.9838 16.1232C13.5564 16.4455 13.0254 16.6213 12.4781 16.6216H5.34624C4.7989 16.6213 4.26795 16.4455 3.84056 16.1232C3.41316 15.8009 3.11472 15.3512 2.99424 14.8479H0.702942C0.833345 15.9226 1.37865 16.9146 2.23495 17.6347C3.09125 18.3549 4.19869 18.7529 5.34624 18.7529H7.88032V22H9.77709V18.7529H12.3567C13.5043 18.7529 14.6117 18.3549 15.468 17.6347C16.3243 16.9146 16.8696 15.9226 17 14.8479V14.3615C16.9982 13.719 16.8463 13.0848 16.5552 12.5038C16.2641 11.9229 15.8409 11.4094 15.3157 11Z"
      fill="white"
    />
  </svg>
);

export const CloseIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L7 7M13 13L7 7M7 7L13 1M7 7L1 13"
      stroke="#ABB3F3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
