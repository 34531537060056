import { Box, Skeleton, Tab, Tabs } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ShareFooter from "components/Layout/ShareFooter/ShareFooter";
import { MultipleTutorialTooltip } from "components/MultipleTutorialTooltip/MultipleTutorialTooltip";
import SubscribeInfluencer from "components/SubscribeInfluencer";
import {
  ANALYTICS_EVENTS,
  CHANNELS,
  EVENTS,
  INFLUENCER_ACTIVE_ID_STORAGE_KEY,
  PATHS,
  TUTORIALS_PROPS,
} from "constants/index";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  approveAllPostsInReview,
  deletePost,
  fetchPosts,
  fetchPrevPosts,
  fetchPrevPostsInReview,
  postReviewed,
  rejectAllPostsInReview,
  resetFeed,
  resetPostsInReview,
  updateFeedCommentCount,
} from "redux/feedSlice";
import {
  changePostNumbers,
  fetchInfluencerActivity,
  fetchInfluencerData,
  fetchInfluencerStats,
  resetInfluencerData,
} from "redux/influencerSlice";
import {
  clearInfluencerFollower,
  fetchInfluencerFollowers,
  fetchMyFollows,
  updateUserEntities,
  updateUserProfile,
} from "redux/usersSlice";
import Analytics from "services/Analytics.service";
import { getInfluencerMetadata } from "services/api/metatagsApi";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import PusherService from "services/Pusher.service";
import { getInfluencerProfileLink } from "utils";
import { LEVEL_GROUP } from "utils/getFilteredInfluencers";

import UnsubscribeInfluencer from "../../components/UnsubscribeInfluencer/ConfirmDlg";
import { getUserSeenTutorials } from "../../redux/selectors/usersSelectors";
import { useInfluencerSubscribe } from "../../utils/hooks/useInfluencerSubscribe";
import { PageLayout } from "../PageLayout/PageLayout";
import { AboutTab, FeedTab, SeasonTab, Sidebar } from "./components";
import { ClubChallenges } from "./components/ClubChallenges/ClubChallenges";
import { SidebarMenuTooltip } from "./components/SidebarMenuTooltip/SidebarMenuTooltip";
import styles from "./influencer.module.scss";

const DEFAULT_TAB = 0;
const ABOUT_TAB_INDEX = 6;
const CHALLENGES_TAB_INDEX = 7;

const Influencer = () => {
  const { id } = useOutletContext() || {};

  const params = useParams();
  const influencerId = id || params.id;
  const theme = useTheme();
  const { t } = useContext(LocalizationContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:840px)");
  const mountedRef = useRef(false);

  const influencerData = useSelector((state) => state.influencer.entities);
  const seenTutorials = useSelector(getUserSeenTutorials);

  const {
    subscriptionData,
    isFollowing,
    community,
    isSubscribed,
    category,
    handle,
    level,
    isAllowedToPost,
  } = influencerData || {};

  const [value, setValue] = React.useState(
    parseInt(params?.activeTab) || DEFAULT_TAB
  );

  const { isCancelled } = subscriptionData || {};
  //TODO::commented code related to Stats tab
  // const activity = useSelector((state) => state.influencer.activity);
  // const statistic = useSelector((state) => state.influencer.statistic);
  const userId = useSelector((state) => state.users.me.entities?.id);
  const subscriptionPrice = community?.subscriptionPrice;
  const [clientSecret, setClientSecret] = useState(null);
  const [showUnsubscribeConfirm, setShowUnsubscribeConfirm] = useState(false);
  const isSubscriptionAvailable = subscriptionPrice;
  const [titleTag, setTitleTag] = useState(null);

  const { onResubscribe, onSubscribe } = useInfluencerSubscribe({
    influencerData,
    influencerId,
    setClientSecret,
  });

  const isHistoryExist = location.key !== "default";

  const sendAnalytics = () => {
    Analytics.page(location.pathname, {
      event: ANALYTICS_EVENTS.VIEW_CELEBRITY,
      subscribed: isSubscribed ? "yes" : "no",
      category,
    });
  };

  const getFeed = (payload) => {
    const data = payload || {};

    dispatch(
      fetchPosts({
        id: influencerId,
        params: {
          limit: 20,
          page: 1,
          ...data,
        },
      })
    ).catch((err) => {
      console.error("Cannot get influencer feed", err);
    });
  };

  const onStripeSubscriptionChanged = () => {
    dispatch(
      fetchInfluencerData({
        params: { id: influencerId },
        isAuth: isAuthenticated(),
      })
    ).then((data) => {
      if (level === LEVEL_GROUP) {
        getFeed({ inReview: true });
      }
    });
    getFeed();
  };

  useEffect(() => {
    // check if Influencer is able to post
    dispatch(
      fetchInfluencerData({
        params: { id: influencerId },
        isAuth: isAuthenticated(),
      })
    );
  }, [isFollowing]);

  // const [seasons, setSeasons] = useState([]);
  // const [isSeasonsLoading, setSeasonsLoading] = useState(false);

  // const getSeasons = (id) => {
  //   setSeasonsLoading(true);
  //   getInfluencerSeason({ id })
  //     .then((data) => {
  //       const { seasonNum = 1 } = data?.season;
  //       if (seasonNum > 1) {
  //         Promise.all(
  //           [...Array(seasonNum - 1)].map((item, index) => {
  //             return getInfluencerSeason({
  //               id,
  //               params: { seasonNum: index + 1 },
  //             });
  //           })
  //         ).then((result) => {
  //           const sortedResult = result.sort(
  //             (a, b) => b?.season.seasonNum - a?.season.seasonNum
  //           );
  //           setSeasons([data, ...sortedResult]);
  //           setSeasonsLoading(false);
  //         });
  //       } else {
  //         setSeasons([data]);
  //         setSeasonsLoading(false);
  //       }
  //     })
  //     .catch(() => setSeasonsLoading(false));
  // };
  //
  // useEffect(() => {
  //   if (level === LEVEL_BIGGER) {
  //     getSeasons(influencerId);
  //   }
  // }, [influencerId, level]);

  useEffect(() => {
    sendAnalytics();
  }, []);

  useEffect(() => {
    const bannerRedirect = new URLSearchParams(location.search).get(
      "bannerRedirect"
    );
    if (bannerRedirect && !isSubscribed) {
      handleOnSubscribe();
    }
  }, [isSubscribed]);

  useEffect(() => {
    const pusher = PusherService.pusher;
    if (!pusher || !userId || !influencerData?.id) return;
    const channelPosts = PusherService.subscribe(CHANNELS.POSTS);
    channelPosts.bind(EVENTS.POST_CREATED, (data) => {
      const { influencerId, numPosts, postId, appUserId } = data;
      if (influencerData?.id === influencerId) {
        dispatch(
          fetchPrevPosts({
            id: influencerId,
          })
        );
      }
      if (appUserId === userId && influencerData?.id === influencerId) {
        dispatch(fetchPrevPostsInReview({ postId }));
      }

      dispatch(changePostNumbers({ numPosts }));
    });

    // ToDo: need to be implemented
    channelPosts.bind(EVENTS.POST_UPDATED, () => {});

    channelPosts.bind(EVENTS.COMMENT_CREATED, (data) => {
      dispatch(updateFeedCommentCount(data));
    });

    channelPosts.bind(EVENTS.COMMENT_DELETED, (data) => {
      dispatch(updateFeedCommentCount(data));
    });

    channelPosts.bind(EVENTS.POST_REVIEWED, (data) => {
      dispatch(postReviewed(data));
    });
    channelPosts.bind(EVENTS.POST_APPROVED_ALL, () => {
      dispatch(approveAllPostsInReview());
      getFeed();
    });
    channelPosts.bind(EVENTS.POST_REJECTED_ALL, () => {
      dispatch(rejectAllPostsInReview());
    });
    channelPosts.bind(EVENTS.POST_DELETED, (data) => {
      const { postId, numPosts } = data;
      dispatch(
        deletePost({
          postId,
        })
      );
      dispatch(changePostNumbers({ numPosts }));
    });
    pusher.user.bind(EVENTS.INFLUNCER_SUBSCRIPTION_UPDATED, (data) => {
      if (data?.dataValues?.influencerId == influencerId)
        onStripeSubscriptionChanged();
    });
    pusher.user.bind(EVENTS.INFLUNCER_SUBSCRIPTION_DELETED, (data) => {
      if (data?.dataValues?.influencerId == influencerId)
        onStripeSubscriptionChanged();
    });
    return () => {
      pusher.unsubscribe(CHANNELS.POSTS);
      pusher.user.unbind(EVENTS.INFLUNCER_SUBSCRIPTION_UPDATED);
      pusher.user.unbind(EVENTS.INFLUNCER_SUBSCRIPTION_DELETED);
      dispatch(resetPostsInReview());
    };
  }, [PusherService.pusher, userId, influencerData?.id]);

  useEffect(() => {
    if (location.state?.backRouteInfluencer) {
      localStorage.setItem("from", location.state?.backRouteInfluencer);
    }
  }, [location.state?.backRouteInfluencer]);

  useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true;
      const getMetaData = async () => {
        try {
          const res = await getInfluencerMetadata(influencerId);
          setTitleTag(res?.title);
        } catch (e) {}
      };
      if (influencerId) getMetaData();
      dispatch(fetchInfluencerFollowers({ influencerId, page: 1, limit: 5 }));
      dispatch(fetchMyFollows());
      dispatch(
        fetchInfluencerData({
          params: { id: influencerId },
          isAuth: isAuthenticated(),
        })
      )
        .unwrap()
        .then((data) => {
          if (data?.level === LEVEL_GROUP) {
            getFeed({ inReview: true });
          }
        })
        .catch((err) => {
          console.error("Cannot get influencer data", err);
        });

      dispatch(fetchInfluencerStats({ id: influencerId }))
        .unwrap()
        .catch((err) => {
          console.error("Cannot get influencer stats", err);
        });

      dispatch(fetchInfluencerActivity({ id: influencerId }))
        .unwrap()
        .catch((err) => {
          console.error("Cannot get influencer activity", err);
        });
      getFeed();
    }
    return () => {
      mountedRef.current = false;
      dispatch(resetFeed());
      dispatch(resetInfluencerData());
      dispatch(clearInfluencerFollower());
    };
  }, [influencerId]);

  useEffect(() => {
    setValue(+params?.activeTab || DEFAULT_TAB);
  }, [params?.activeTab]);

  function tabProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }
  const influencerProfileLink = getInfluencerProfileLink({
    id: influencerId,
    handle,
  });
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    navigate(`${influencerProfileLink}/tab/${newValue.toString()}`, {
      replace: true,
    });
  };
  const handleOnSubscribe = () => {
    isSubscribed
      ? isCancelled
        ? onResubscribe()
        : setShowUnsubscribeConfirm(true)
      : onSubscribe();
  };

  const tutorialUpdate = (params) => () => {
    dispatch(updateUserEntities(params));
    dispatch(updateUserProfile(params));
  };

  const tutorialSkip = () => {
    const params = {
      seenTutorials: {
        ...(seenTutorials && seenTutorials),
        [TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_1]: true,
        [TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_2]: true,
      },
    };
    dispatch(updateUserEntities(params));
    dispatch(updateUserProfile(params));
  };

  return (
    <PageLayout
      isBack={isAuthenticated()}
      pageClassName={styles.container}
      pageWrapperClassName={styles.wrapper}
      isBottomMenuHide={true}
      backBtnProps={{ link: localStorage.getItem("from") || PATHS.DISCOVER }}
      title={
        isMobile && isAuthenticated() && !isHistoryExist
          ? influencerData?.name
          : null
      }
      customMobileHeaderMenu={
        isMobile ? (
          <SidebarMenuTooltip
            influencerData={influencerData}
            btnClassName={styles.headerTooltipBtn}
          />
        ) : null
      }
      titleTag={titleTag}
    >
      <Box className={styles.content}>
        <Box className={styles.leftSide}>
          <Sidebar
            influencerData={influencerData}
            subscriptionData={subscriptionData}
            handleOnSubscribe={handleOnSubscribe}
            subscriptionPrice={subscriptionPrice}
            influencerId={influencerId}
            isSubscriptionAvailable={isSubscriptionAvailable}
            onFollowCallback={getFeed}
            // seasons={seasons}
          />
        </Box>
        {influencerData ? (
          <Box className={styles.rightSide}>
            {isMobile ? (
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className={styles.tabsWrap}
              >
                <Tabs
                  value={value}
                  onChange={handleChangeTab}
                  className={styles.tabsPanel}
                  variant="fullWidth"
                >
                  <Tab label={t("discover.feed")} {...tabProps(0)} />
                  {level !== LEVEL_GROUP ? (
                    <Tab
                      label={t("default.cards")}
                      {...tabProps(1)}
                      disabled={!isAuthenticated()}
                    />
                  ) : null}
                  <Tab
                    label={t("discover.about")}
                    {...tabProps(ABOUT_TAB_INDEX)}
                    disabled={!isAuthenticated()}
                    value={ABOUT_TAB_INDEX}
                  />

                  <Tab
                    label={
                      <MultipleTutorialTooltip
                        isOpen={
                          seenTutorials?.[
                            TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_1
                          ] &&
                          !seenTutorials?.[
                            TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_2
                          ]
                        }
                        title={t("default.tutorialCreatorTitle2")}
                        subTitle={t("default.tutorialCreatorSubTitle2")}
                        skipButtonText={t("default.skip")}
                        nextButtonText={t("default.gotIt")}
                        prevButtonText={t("default.back")}
                        isArrow={true}
                        placement={"top"}
                        next={tutorialUpdate({
                          seenTutorials: {
                            ...(seenTutorials && seenTutorials),
                            [TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_2]: true,
                          },
                        })}
                        prev={tutorialUpdate({
                          seenTutorials: {
                            ...(seenTutorials && seenTutorials),
                            [TUTORIALS_PROPS.CREATOR_MULTIPLE_TUTORIAL_1]: false,
                          },
                        })}
                        skip={tutorialSkip}
                      >
                        {t("home.challengesMobile")}
                      </MultipleTutorialTooltip>
                    }
                    {...tabProps(CHALLENGES_TAB_INDEX)}
                    disabled={!isAuthenticated()}
                    value={CHALLENGES_TAB_INDEX}
                  />

                  {/*<Tab label={t("discover.stats")} {...tabProps(3)} disabled />*/}
                  {/*<Tab label={t("discover.editionsTitle")} {...tabProps(3)} />*/}
                </Tabs>
              </Box>
            ) : level !== LEVEL_GROUP ? (
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className={styles.tabsWrap}
              >
                <Tabs
                  value={value}
                  onChange={handleChangeTab}
                  className={styles.tabsPanel}
                  variant="fullWidth"
                >
                  <Tab label={t("discover.feed")} {...tabProps(0)} />
                  <Tab
                    label={t("default.cards")}
                    {...tabProps(1)}
                    disabled={!isAuthenticated()}
                  />
                  {/*<Tab label={t("discover.stats")} {...tabProps(3)} disabled />*/}
                  {/*<Tab label={t("discover.editionsTitle")} {...tabProps(3)} />*/}
                </Tabs>
              </Box>
            ) : null}

            <Box
              role="tabpanel"
              hidden={value !== 0}
              id={`tabpanel-${0}`}
              aria-labelledby={`tab-${0}`}
              className={`${styles.tabPanel} ${styles.tabPanelFeed} ${
                value === 0 ? styles.tabPanelActive : ""
              }`}
            >
              {influencerData ? (
                <>
                  {influencerData?.heroImageUrl ? (
                    <Box className={styles.bannerWrapper}>
                      <Box className={styles.banner}>
                        <img
                          src={influencerData.heroImageUrl}
                          alt="influencer banner"
                        />
                      </Box>
                    </Box>
                  ) : null}
                  <FeedTab
                    influencerId={influencerId}
                    community={community}
                    isFollowing={
                      isSubscriptionAvailable ? isSubscribed : isFollowing
                    }
                    isAllowedToPost={isAllowedToPost}
                    isSubscriptionAvailable={isSubscriptionAvailable}
                    onFollowCallback={getFeed}
                    handleOnSubscribe={handleOnSubscribe}
                    subscriptionPrice={subscriptionPrice}
                    level={level}
                  />
                </>
              ) : null}
            </Box>
            {level !== LEVEL_GROUP ? (
              <Box
                role="tabpanel"
                hidden={value !== 1}
                id={`tabpanel-${1}`}
                aria-labelledby={`tab-${1}`}
                className={`${styles.tabPanel} ${
                  value === 1 ? styles.tabPanelActive : ""
                }`}
              >
                <SeasonTab
                  // seasons={seasons}
                  // isSeasonsLoading={isSeasonsLoading}
                  influencerId={influencerId}
                />
              </Box>
            ) : null}
            {isMobile ? (
              <Box
                role="tabpanel"
                hidden={value !== ABOUT_TAB_INDEX}
                id={`tabpanel-${ABOUT_TAB_INDEX}`}
                aria-labelledby={`tab-${ABOUT_TAB_INDEX}`}
                className={`${styles.tabPanel} ${
                  value === ABOUT_TAB_INDEX ? styles.tabPanelActive : ""
                }`}
              >
                {influencerData ? (
                  <AboutTab influencerData={influencerData} />
                ) : null}
              </Box>
            ) : null}
            {isMobile ? (
              <Box
                role="tabpanel"
                hidden={value !== CHALLENGES_TAB_INDEX}
                id={`tabpanel-${CHALLENGES_TAB_INDEX}`}
                aria-labelledby={`tab-${CHALLENGES_TAB_INDEX}`}
                className={`${styles.tabPanel} ${
                  value === CHALLENGES_TAB_INDEX ? styles.tabPanelActive : ""
                }`}
              >
                <ClubChallenges influencerId={influencerId} />
              </Box>
            ) : null}
            {/* <Box
              role="tabpanel"
              hidden={value !== 3}
              id={`tabpanel-${3}`}
              aria-labelledby={`tab-${3}`}
              className={styles.tabPanel}
            >
              <StatsTab
                statistic={statistic}
                activity={activity}
                influencerData={influencerData}
              />
            </Box> */}
            {/*<Box*/}
            {/*  role="tabpanel"*/}
            {/*  hidden={value !== 3}*/}
            {/*  id={`tabpanel-${3}`}*/}
            {/*  aria-labelledby={`tab-${3}`}*/}
            {/*  className={styles.tabPanel}*/}
            {/*>*/}
            {/*  <EditionsTab editionCards={editionCards} loading={loading} />*/}
            {/*</Box>*/}
          </Box>
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{ bgcolor: theme.palette.surface.main }}
            style={{ height: "300px" }}
            className={styles.rightSide}
          />
        )}
      </Box>
      <Outlet />
      <SubscribeInfluencer
        clientSecret={clientSecret}
        influencerData={influencerData}
        onCloseSubscribeModal={() => {
          setClientSecret(null);
          sessionStorage.removeItem(INFLUENCER_ACTIVE_ID_STORAGE_KEY);
        }}
      />
      {subscriptionData && (
        <UnsubscribeInfluencer
          open={showUnsubscribeConfirm}
          influencerId={influencerId}
          onClose={() => setShowUnsubscribeConfirm(false)}
          influencerData={influencerData}
          {...subscriptionData}
        />
      )}
      {/*<OnboardingTutorial />*/}
      {!isAuthenticated() ? <ShareFooter name={influencerData?.name} /> : null}
    </PageLayout>
  );
};

export default Influencer;
