import { Box, Typography } from "@mui/material";
import { SeasonSlider } from "components/SeasonSlider/SeasonSlider";
import React, { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

import styles from "./CardsList.module.scss";

export const CardsList = ({ seasonCards, seasonNum, title }) => {
  const { t } = useContext(LocalizationContext);

  if (!seasonCards?.length) {
    return null;
  }

  return (
    <Box className={styles.framedBox}>
      <Typography variant="h5_medium" className={styles.title} component="h2">
        {title ? title : t("drops.cardsFromSeason", { count: seasonNum })}
      </Typography>
      <SeasonSlider
        seasonCards={seasonCards}
        cardProps={{ description: null }}
      />
    </Box>
  );
};
