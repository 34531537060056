import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as IconRight } from "assets/svg/icon-right.svg";
import MenuTitle from "components/MenuTitle";
import { SECURITY_STATES } from "constants/";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isEmulationMode } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";

import ActivityHistory from "./security/ActivityHistory";
import MFA from "./security/MFA";
import ResetPassword from "./security/ResetPassword";
import styles from "./Settings.module.scss";

const Security = () => {
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();
  const is2faEnabled = useSelector(
    (state) => state.users.entities.is2faEnabled
  );
  const location = useLocation();

  const [state, setState] = useState(
    location.state?.settingsState || SECURITY_STATES.default
  );

  const getOptions = () => (
    <Box className={styles.tabs}>
      {Object.keys(SECURITY_STATES).map((option) => {
        if (option === Object.keys(SECURITY_STATES).at(0)) {
          return null;
        }

        return (
          <Box
            key={option}
            onClick={() => setState(SECURITY_STATES[option])}
            className={styles.tabOption}
          >
            <Typography variant="h6">
              {t(`settings.${option.toLowerCase()}`)}
            </Typography>
            {SECURITY_STATES[option] === SECURITY_STATES.mfa ? (
              <Box className={styles.iconWrap}>
                <Typography
                  variant="h6"
                  className={styles.tabOptionLabel}
                  sx={{
                    color: is2faEnabled
                      ? theme.palette.tertiary.main
                      : theme.palette.coral.main,
                  }}
                >
                  {is2faEnabled
                    ? t("settings.activated")
                    : t("settings.disabled")}
                </Typography>
                <IconRight className={styles.icon} />
              </Box>
            ) : (
              <IconRight className={styles.icon} />
            )}
          </Box>
        );
      })}
    </Box>
  );

  const emulationModeContent = (
    <Typography>Security functions disabled for emulation</Typography>
  );

  const getContent = () => {
    switch (state) {
      case SECURITY_STATES.activityHistory:
        return <ActivityHistory />;
      case SECURITY_STATES.resetPassword:
        return (
          <ResetPassword
            onSuccessCb={() => setState(SECURITY_STATES.default)}
          />
        );
      case SECURITY_STATES.mfa:
        return <MFA />;
      default:
        return isEmulationMode() ? emulationModeContent : getOptions();
    }
  };

  return (
    <Box className={styles.tabContainer}>
      <MenuTitle
        states={SECURITY_STATES}
        currentState={state}
        onBack={() => setState(SECURITY_STATES.default)}
      />
      {getContent()}
    </Box>
  );
};

export default Security;
