export const cards = [
  {
    id: 1,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Legendary Club Pass",
    activeImgIndex: 0,
  },
  {
    id: 2,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Epic Club Pass",
    activeImgIndex: 1,
  },
  {
    id: 3,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Rare Club Pass",
    activeImgIndex: 2,
  },
  {
    id: 4,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Mythic Club Pass",
    activeImgIndex: 3,
  },
  {
    id: 5,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Legendary Club Pass",
    activeImgIndex: 0,
  },
  {
    id: 6,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Epic Club Pass",
    activeImgIndex: 1,
  },
  {
    id: 7,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Mythic Club Pass",
    activeImgIndex: 2,
  },
  {
    id: 8,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Legendary Club Pass",
    activeImgIndex: 0,
  },
  {
    id: 9,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Epic Club Pass",
    activeImgIndex: 1,
  },
  {
    id: 10,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Rare Club Pass",
    activeImgIndex: 2,
  },
  {
    id: 11,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Mythic Club Pass",
    activeImgIndex: 3,
  },
  {
    id: 12,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Legendary Club Pass",
    activeImgIndex: 0,
  },
  {
    id: 13,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Epic Club Pass",
    activeImgIndex: 1,
  },
  {
    id: 14,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Mythic Club Pass",
    activeImgIndex: 3,
  },
  {
    id: 15,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Mythic Club Pass",
    activeImgIndex: 1,
  },
  {
    id: 16,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Legendary Club Pass",
    activeImgIndex: 0,
  },
  {
    id: 17,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Epic Club Pass",
    activeImgIndex: 1,
  },
  {
    id: 18,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Mythic Club Pass",
    activeImgIndex: 3,
  },
  {
    id: 19,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Epic Club Pass",
    activeImgIndex: 1,
  },
  {
    id: 20,
    title: "Mystery card",
    activeTitle: "Hedera",
    description: "One will be on your collection",
    activeDescription: "Mythic Club Pass",
    activeImgIndex: 3,
  },
];
