import { useContext } from "react";
import { LocalizationContext } from "services/localizationContext";

export const usePolicies = () => {
  const { t } = useContext(LocalizationContext);

  return [
    {
      title: t("default.amlSafeguards"),
      link: "https://policy.zoop.club/en/aml-safeguards",
    },
    {
      title: t("default.privacyPolicy"),
      link: "https://policy.zoop.club/en/privacy-policy",
    },
    {
      title: t("default.termsofService"),
      link: "https://policy.zoop.club/en/terms-of-service",
    },
    {
      title: t("default.purchaseTerms"),
      link: "https://policy.zoop.club/en/nft-purchase-terms",
    },
    {
      title: t("default.cookiePolicy"),
      link: "https://policy.zoop.club/en/cookie-policy",
    },
    {
      title: t("default.communityGuidelines"),
      link: "https://policy.zoop.club/community-guidelines",
    },
  ];
};
