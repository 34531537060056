import { Button, Skeleton, styled, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box } from "@mui/system";
import ChallengeIcon from "assets/img/reward-hero.png";
import { dateLocales } from "constants/";
import { format, isThisYear, isToday } from "date-fns";
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchMoreRewards, fetchRewards } from "redux/rewardsSlice";
import { LocalizationContext } from "services/localizationContext";

import styles from "./Rewards.module.scss";

const getDateTimeFormatted = (date, locale) => {
  let formatLoc = "MMM dd yyyy";
  const dateTime = new Date(date);
  if (isToday(dateTime)) formatLoc = "hh:mm";
  if (isThisYear(dateTime)) formatLoc = "MMM dd";
  return format(dateTime, formatLoc, {
    locale,
  });
};

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} enterDelay={500} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.surface.main,
    maxWidth: 220,
  },
}));

const LIMIT = 20;

const RewardsList = () => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const data = useSelector((state) => state.rewards.entities);
  const hasMore = useSelector((state) => state.rewards.hasMore);
  const loading = useSelector((state) => state.rewards.loading);
  const profile = useSelector((state) => state.users.entities);

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    dispatch(fetchRewards({ offset, limit: LIMIT }));
  }, []);

  const LoadMore = () => {
    setOffset((prevState) => prevState + LIMIT);
  };

  useEffect(() => {
    if (offset > 0) {
      dispatch(fetchMoreRewards({ offset, limit: LIMIT }));
    }
  }, [offset]);

  return (
    <Box className={styles.rightBlock} id="scrollableDiv">
      {!loading && !data.length && (
        <Box className={styles.rewardsListEmpty}>
          <img src={ChallengeIcon} alt="challenges" />
          <Box className={styles.rewardsListEmptyTitle}>
            {t("rewards.noRewardsTitle")}
          </Box>
          <Button component={Link} to="/challenges" variant="outlined">
            {t("rewards.noRewardsBtn")}
          </Button>
        </Box>
      )}
      <InfiniteScroll
        next={LoadMore}
        dataLength={data?.length}
        hasMore={hasMore}
        scrollableTarget="scrollableDiv"
      >
        <Box className={styles.rewardsList}>
          {loading &&
            !data.length &&
            Array.from(new Array(10)).map((_item, i) => (
              <Skeleton
                key={i}
                className={styles.rewardsListItem}
                sx={{
                  height: "65px",
                  transform: "none",
                  background: "#21264e",
                  borderRadius: "10px",
                }}
              />
            ))}

          {data.length &&
            data.map((item, idx) => (
              <Box key={idx} className={styles.rewardsListItem}>
                <Box className={styles.rewardsListItemLeft}>
                  <Box className={styles.rewardsListItemPoints}>
                    +{item.amount}
                    {item.isPro && (
                      <BootstrapTooltip title={t("rewards.zoopProDesc")}>
                        <Box className={`${styles.proLabel}`}>
                          <span className={styles.x}>x</span>2
                        </Box>
                      </BootstrapTooltip>
                    )}
                  </Box>
                  <Typography variiant="body1">
                    {t(`challenges.${item.titleKey}`, {
                      stageNum: item?.metadata?.stageNum,
                      challengeTitle: t(
                        `challenges.${item?.metadata?.challenge}-title`
                      ),
                    })}
                  </Typography>
                </Box>
                {item.createdAt && (
                  <Box className={styles.rewardsListItemDate}>
                    {getDateTimeFormatted(
                      item.createdAt,
                      !!profile.language ? dateLocales[profile.language] : null
                    )}
                  </Box>
                )}
              </Box>
            ))}
        </Box>
      </InfiniteScroll>
    </Box>
  );
};
export default RewardsList;
