import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ExpandMoreIcon } from "assets/svg/arrow-down.svg";
import FAQ_CONTENT from "./faq-content";
import MenuTitle from "components/MenuTitle";

import styles from "./Settings.module.scss";
import { HELP_STATES } from "constants/";

const FaqItem = ({ item }) => (
  <Accordion className={styles.faqAccordion}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6">{item.title}</Typography>
    </AccordionSummary>
    <AccordionDetails className={styles.faqAccordionBody}>
      <Typography variant="body1">{item.desc}</Typography>
    </AccordionDetails>
  </Accordion>
);

const Faq = () => {
  const navigate = useNavigate();

  return (
    <Box className={styles.faqContainer}>
      <Box className={styles.faqContent}>
        <MenuTitle
          states={HELP_STATES}
          currentState={HELP_STATES.faq}
          onBack={() => navigate("/settings/help")}
        />
        <Box className={styles.faqList}>
          {FAQ_CONTENT.map((item) => (
            <FaqItem key={item.title} item={item} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Faq;
