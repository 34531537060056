import { Box } from "@mui/material";
import classNames from "classnames/bind";
import { CardsSlider } from "components/CardsSlider/CardsSlider";
import { EmailBanner } from "components/EmailBanner/EmailBanner";
import { Card } from "components/NewCard";
import { CARD_VARIANTS } from "constants/card";
import { CHALLENGE_CATEGORIES, PATHS } from "constants/index";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { fetchChallenges } from "redux/challengesSlice";
import {
  clubChallengesByIdLoader,
  getChallengesCategorySelector,
} from "redux/selectors/challengesSelector";
import { getMarketplaceCards } from "services/discoverApi";
import { LocalizationContext } from "services/localizationContext";
import { useTelegram } from "utils/hooks/useTelegram";

import { PageLayout } from "../PageLayout/PageLayout";
// import { OnboardingTutorial } from "./components";
import { BannersCarousel } from "./components/BannersCarousel/BannersCarousel";
import { Main } from "./components/Main/Main";
import { SimpleChallenge } from "./components/SimpleChallenge/SimpleChallenge";
import { SlideBox } from "./components/SlideBox/SlideBox";
import styles from "./Home.module.scss";

const Home = () => {
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useTelegram();

  const userProfile = useSelector((state) => state.users.entities);
  const userProfileLoading = useSelector((state) => state.users.loading);
  // const is2faEnabled = useSelector(
  //   (state) => state.users.entities.is2faEnabled
  // );
  const challenges = useSelector(
    getChallengesCategorySelector(CHALLENGE_CATEGORIES.SOCIAL)
  );
  const challengesLoader = useSelector(clubChallengesByIdLoader);

  const [cards, setCards] = useState([]);
  const [cardsLoader, setCardsLoader] = useState(true);

  const goToEmailVerification = () => {
    localStorage.setItem("completeProfileBackRoute", location.pathname);
    navigate(PATHS.EMAIL_VERIFICATION);
  };

  // const handleActivateMFA = () => {
  //   navigate(PATHS.SETTINGS_SECURITY, {
  //     state: { settingsState: SECURITY_STATES.mfa },
  //   });
  // };

  const isConditionProfile = !userProfile?.emailVerified;
  // const isConditionMFA = userProfile?.isCompleted && !is2faEnabled;

  const pageWrapperClass = classNames(styles.home, {
    [styles.homeEmail]:
      isConditionProfile && !userProfileLoading && userProfile?.id && !user,
  });

  useEffect(() => {
    getMarketplaceCards({ type: "followings", limit: 12 })
      .then((res) => {
        if (res?.cards) {
          setCards(res.cards);
        }
      })
      .finally(() => setCardsLoader(false));
    dispatch(fetchChallenges({ category: CHALLENGE_CATEGORIES.SOCIAL }));
  }, []);

  return (
    <PageLayout
      isLogo={true}
      pageWrapperClassName={pageWrapperClass}
      headerChildren={
        !user ? (
          <EmailBanner
            condition={isConditionProfile}
            title={t("home.verifyKYC")}
            buttonText={t("default.verifyNow")}
            onClick={goToEmailVerification}
            loading={userProfileLoading && !userProfile?.id}
          />
        ) : null
      }
    >
      <Box>
        <Box className={styles.topContainer}>
          <BannersCarousel />
        </Box>
        <Box className={styles.container}>
          <Box className={styles.containerLeft}>
            <SlideBox
              isShow={challenges?.length > 0}
              title={t("home.challenges")}
              path={PATHS.CHALLENGES}
              isLoading={challengesLoader}
              buttonText={t("home.viewChallenges")}
              className={styles.challengesListWrap}
            >
              <Box className={styles.challengesList}>
                {challenges?.slice(0, 5)?.map((item) => (
                  <Box
                    className={styles.challengesListItem}
                    component={Link}
                    key={item.id}
                    to={PATHS.CHALLENGES_ID.replace(":id", item.id)}
                  >
                    <SimpleChallenge {...item} key={item.id} />
                  </Box>
                ))}
              </Box>
            </SlideBox>
            <SlideBox
              title={t("home.newCardsTitle")}
              subtitle={t("home.newCardsDesc")}
              isLoading={cardsLoader}
              isShow={cards.length > 0}
              path={PATHS.DROPS}
              buttonText={t("home.viewCards")}
            >
              <CardsSlider
                spaceBetweenSlides={1}
                spaceBetweenSlidesMobile={0}
                cardWidth={274}
                adaptiveHeight={true}
              >
                {cards.map((item) => (
                  <Card
                    key={item.id}
                    variant={CARD_VARIANTS.FULL}
                    data={item}
                    link={PATHS.CARD_ID.replace(":id", item.id)}
                  />
                ))}
              </CardsSlider>
            </SlideBox>
          </Box>
          <Box className={styles.containerRight}>
            <Main />
          </Box>
        </Box>
        <Outlet />
        {/*<OnboardingTutorial />*/}
      </Box>
    </PageLayout>
  );
};

export default Home;
