import { yupResolver } from "@hookform/resolvers/yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, FormControl, IconButton, Input, Typography } from "@mui/material";
import { ReactComponent as IconBack } from "assets/svg/arrow-left.svg";
import Alert from "components/ErrorAlert";
import CountriesJson from "constants/Countries.json";
import { STATE_KEYS } from "constants/states";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUserProfile } from "redux/usersSlice";
import Analytics from "services/Analytics.service";
import { LocalizationContext } from "services/localizationContext";
import { transliterate } from "transliteration";
import { useIsMobile } from "utils/hooks";
import { transliterateToAlphaNum } from "utils/stringHelper";
import * as Yup from "yup";

import { errorResponseTypes, MODAL_TYPE, PATHS } from "../../constants";
import { setError } from "../../redux/appSlice";
import { openModal } from "../../redux/modalSlice";
import { formatPostMessage } from "../../utils";
import styles from "./KYC.module.scss";
import PlaceAutocomplete from "./PlaceAutocomplete";

const StepAddress = ({ handleBack, selectedCountry, setMainError }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const dispatch = useDispatch();
  const { t } = useContext(LocalizationContext);
  const countries = CountriesJson.Countries;
  const user = useSelector((state) => state.users.entities);
  const userCountry = countries.find((c) => c.id === user?.countryId);

  const completeProfileBackRoute = localStorage.getItem(
    "completeProfileBackRoute"
  );

  const validationSchemaAddress = Yup.object().shape({
    addressLine1: Yup.string()
      .trim()
      .required(t("error.required"))
      .isAlphanumeric(t("error.isAlphanumeric")),
    city: Yup.string()
      .trim()
      .required(t("error.required"))
      .validateLatinLetter(t("error.onlyEnglishLetter")),
    county: Yup.string()
      .trim()
      .when(["country"], {
        is: (country) => {
          return country === "US" || country === "United States";
        },
        then: (schema) => {
          return Yup.string()
            .required(t("error.stateError"))
            .oneOf(STATE_KEYS, t("error.isUsState"));
        },
        otherwise: Yup.string().required(t("error.required")),
      })
      .validateLatinLetter(t("error.onlyEnglishLetter")),
    zipPostcode: Yup.string()
      .trim()
      .required(t("error.required"))
      .isAlphanumeric(t("error.isAlphanumeric")),
  });

  const {
    register: registerAddress,
    handleSubmit: handleSubmitAddress,
    formState: { errors: errorsAddress },
    getValues: getValuesAddress,
    setValue: setAdressValue,
    control,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchemaAddress),
    defaultValues: {
      addressLine1: user?.addressLine1 || "",
      addressLine2: user?.addressLine2 || "",
      city: user?.city,
      county: user?.county,
      country: selectedCountry?.name || userCountry?.name || "",
      zipPostcode: user?.zipPostcode || "",
    },
  });

  useEffect(() => {
    setAdressValue("addressLine1", user?.addressLine1 || "");
    setAdressValue("addressLine2", user?.addressLine2 || "");
    setAdressValue("city", user?.city || "");
    setAdressValue("county", user?.county || "");
    setAdressValue("country", selectedCountry?.name || userCountry?.name || "");
    setAdressValue("zipPostcode", user?.zipPostcode || "");
  }, []);

  const handleMessageError = (e) => {
    let message = "";
    if (e.error === "OnfidoApiError") {
      const error = e.message.split("|");
      if (error.length > 1) {
        const parsedMessage = JSON.parse(error[1]);
        message = parsedMessage;
        if (parsedMessage?.id_numbers) {
          message = parsedMessage?.id_numbers[0]?.value;
          return setMainError({ ssn: message });
        }
        if (parsedMessage?.address) {
          const firstEl = parsedMessage?.address[0];
          const firsKey = _.values(firstEl)[0];
          if (firsKey) message = firsKey[0];
        }
      }
    } else {
      message = e.message;
    }
    setFormError(message.toString());
  };

  const ErrorMessageBody = (
    <>
      <Typography variant="h6" component="h2" className={styles.modalTitle}>
        {t("kyc.whoopsey")}
      </Typography>
      <Typography variant="body1" className={styles.modalDescription}>
        {formatPostMessage(t("kyc.unsupportedErrorMsg1"))}
      </Typography>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        className={styles.modalTitle}
      >
        {t("kyc.butDontWorry")}
      </Typography>
      <Typography variant="body1" className={styles.modalDescription}>
        {formatPostMessage(t("kyc.unsupportedErrorMsg2"))}
      </Typography>
    </>
  );

  const handleOpenErrorModal = (err) => {
    const { UnsupportedTerritory, RestrictedTerritory } = errorResponseTypes;
    if (err === UnsupportedTerritory) {
      dispatch(
        openModal({
          title: t("kyc.verifyModalTitle"),
          type: MODAL_TYPE.VERIFY_2,
          children: ErrorMessageBody,
          onClick: () => navigate(PATHS.HOME),
        })
      );
    }

    if (err === RestrictedTerritory) {
      dispatch(
        openModal({
          title: t("kyc.sorry"),
          type: MODAL_TYPE.VERIFY,
          children: (
            <Typography variant="body1" className={styles.modalDescription}>
              {t("kyc.sooryDescription")}
            </Typography>
          ),
          onClick: () => navigate(PATHS.HOME),
        })
      );
    }
  };

  const handleFormSubmit = (data) => {
    setLoading(true);
    const payload = data;

    payload.countryId = selectedCountry.id;
    payload.isCompleted = true;
    dispatch(updateUserProfile(payload))
      .then(
        (data) => {
          if (data?.payload?.error) {
            dispatch(
              setError({
                open: true,
                title: "Error",
                subtitle: data.payload?.message,
              })
            );
          } else {
            Analytics.track("Finish Complete Profile");
            navigate(completeProfileBackRoute || "/", { replace: true });
          }
        },
        ({ error }) => handleOpenErrorModal(error)
      )
      .catch((e) => {
        handleMessageError(e);
      })
      .finally(() => {
        setLoading(false);
        localStorage.removeItem("completeProfileBackRoute");
      });
  };

  const onAdressChange = (adressDetails) => {
    clearErrors();
    if (!adressDetails) return;
    const { city, streetNumber, streetAddress, region } = adressDetails;
    setAdressValue("city", transliterate(city || ""));
    setAdressValue("county", transliterate(region || ""));
    setFormError("");
    if (streetNumber || streetAddress) {
      setAdressValue(
        "addressLine1",
        `${streetNumber || ""} ${
          transliterateToAlphaNum(streetAddress || "") || ""
        }`
      );
    } else {
      setAdressValue("addressLine1", " ");
    }
  };

  return (
    <>
      {!isMobile && (
        <IconButton className={styles.back} onClick={handleBack}>
          <IconBack />
        </IconButton>
      )}
      <Box className={[styles.containerStep1, styles.containerCenter]}>
        <>
          <Typography variant="h3" component="h1" className={styles.title}>
            {t("default.address")}
          </Typography>
          <Box className={[styles.form, styles.step3]}>
            <form
              onSubmit={handleSubmitAddress(handleFormSubmit)}
              autoComplete="off"
            >
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("kyc.streetAddressField")}
                </label>
                <Controller
                  control={control}
                  name="addressLine1"
                  rules={{ required: t("error.required") }}
                  render={({
                    field: { onChange, onBlur, value, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                  }) => (
                    <Box>
                      <PlaceAutocomplete
                        placeholder={t("kyc.streetAddressField")}
                        onChange={onChange}
                        onOptionPress={onAdressChange}
                        error={!!errorsAddress.addressLine1}
                        errorText={errorsAddress.addressLine1?.message}
                        defaultValue={getValuesAddress("addressLine1")}
                      />
                    </Box>
                  )}
                />
              </FormControl>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("kyc.streetAddress2Field")}
                </label>
                <Input
                  placeholder={t("kyc.streetAddress2Field")}
                  {...registerAddress("addressLine2", {
                    onChange: (e) => {
                      setAdressValue(
                        "addressLine2",
                        transliterateToAlphaNum(e.target.value)
                      );
                    },
                  })}
                  error={!!errorsAddress.addressLine2}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error">
                  {errorsAddress.addressLine2?.message}
                </Typography>
              </FormControl>

              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>{t("kyc.cityField")}</label>
                <Input
                  placeholder={t("kyc.cityField")}
                  {...registerAddress("city")}
                  error={!!errorsAddress.city}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error">
                  {errorsAddress.city?.message}
                </Typography>
              </FormControl>
              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("kyc.stateRegionField")}
                </label>
                <Input
                  placeholder={t("kyc.stateRegionField")}
                  {...registerAddress("county")}
                  error={!!errorsAddress.county}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error">
                  {errorsAddress.county?.message}
                </Typography>
              </FormControl>

              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>
                  {t("kyc.postalCodeField")}
                </label>
                <Input
                  placeholder={t("kyc.postalCodeField")}
                  {...registerAddress("zipPostcode")}
                  error={!!errorsAddress.zipPostcode}
                  autoComplete="off"
                  className={styles.input}
                />
                <Typography variant="error">
                  {errorsAddress.zipPostcode?.message}
                </Typography>
              </FormControl>

              <FormControl className={styles.formControlInput}>
                <label className={styles.label}>{t("kyc.countryField")}</label>
                <Input
                  placeholder={t("kyc.countryField")}
                  {...registerAddress("country")}
                  disabled
                  error={!!errorsAddress.country}
                  autoComplete="off"
                  className={styles.input}
                  readOnly
                />
                <Typography variant="error">
                  {errorsAddress.country?.message}
                </Typography>
              </FormControl>

              <FormControl>
                {formError && <Alert>{formError}</Alert>}

                <LoadingButton
                  type="submit"
                  variant="outlined"
                  className={styles.btnContinue}
                  loading={loading}
                >
                  {t("kyc.continueButton")}
                </LoadingButton>
              </FormControl>
            </form>
          </Box>
        </>
      </Box>
    </>
  );
};

export default StepAddress;
