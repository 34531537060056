import { Box, Button, Typography } from "@mui/material";

import styles from "./EmptyState.module.scss";

export const EmptyState = ({
  icon,
  title,
  subTitle,
  buttonText,
  action,
  isPublic,
}) => {
  const clickHandler = () => {
    action();
  };

  return (
    <Box className={styles.empty}>
      <Box className={styles.emptyIcon}>{icon}</Box>
      <Typography
        className={styles.title}
        variant={!isPublic ? "h5" : "body1Display"}
      >
        {title}
      </Typography>
      {subTitle ? (
        <Typography className={`${styles.subTitle} opacity_07`} variant="body2">
          {subTitle}
        </Typography>
      ) : null}
      {action ? (
        <Button onClick={clickHandler} variant="contained">
          {buttonText}
        </Button>
      ) : null}
    </Box>
  );
};
