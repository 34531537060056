import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
// import { ReactComponent as IconCancelled } from "assets/svg/feed/subscription-cancelled.svg";
// import { ReactComponent as IconAddFriend } from "assets/svg/icon-add-friend.svg";
// import { ReactComponent as IconAddFriendSuccess } from "assets/svg/icon-follow-success.svg";
import { ANALYTICS_EVENTS, PATHS } from "constants/index";
import { format } from "date-fns";
import DOMPurify from "dompurify";
import React, { useContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Analytics from "services/Analytics.service";
import { isAuthenticated } from "services/auth.service";
import { LocalizationContext } from "services/localizationContext";
import { useLocale } from "utils/hooks";

import { getProcessingSubscription } from "../../../../../../redux/selectors/influencerSelectors";
import styles from "./MainInfo.module.scss";

export const PaidButton = ({
  handleOnSubscribe,
  subscriptionPrice,
  currencySymbol,
  subscriptionData,
  isSubscribed,
  influencerName,
}) => {
  const { t } = useContext(LocalizationContext);
  const locale = useLocale();
  const navigate = useNavigate();
  const { currentPeriodEndAt, isCancelled } = subscriptionData || {};
  const [isHover, setIsHover] = useState(false);
  const isProcessingSubscription = useSelector(getProcessingSubscription);
  const endDateFormatted = currentPeriodEndAt
    ? format(new Date(currentPeriodEndAt), "MMMM dd", {
        locale,
      })
    : null;
  const isFlippedSideVisible = isHover;
  const variant =
    isSubscribed && !isFlippedSideVisible ? "outlined" : "contained";
  const onHover = () => {
    setIsHover(true);
  };
  const onLeave = () => {
    setIsHover(false);
  };
  const subscribeBtnText = t("subscription.subscribeBtn", {
    price: subscriptionPrice,
    currencySymbol,
  });
  const resubscribeBtnText = t("subscription.endsOn", {
    endDate: endDateFormatted,
  });
  const unsubscribeBtnText = t("subscription.unsubscribeBtn");

  const buttonText = useMemo(() => {
    if (isSubscribed && isCancelled)
      return isFlippedSideVisible ? subscribeBtnText : resubscribeBtnText;
    if (isSubscribed && !isCancelled)
      return isFlippedSideVisible
        ? unsubscribeBtnText
        : t("subscription.subscribed");
    return subscribeBtnText;
  }, [isSubscribed, isCancelled, isFlippedSideVisible]);

  // const isSubscribeText = buttonText === subscribeBtnText;

  // const ButtonIcon = ({ className }) => {
  //   if ([resubscribeBtnText, unsubscribeBtnText].includes(buttonText))
  //     return <IconCancelled className={className} />;
  //   if (isSubscribeText) return <IconAddFriend className={className} />;
  //   return <IconAddFriendSuccess className={className} />;
  // };

  const onSubscribe = () => {
    if (!isAuthenticated()) {
      navigate(PATHS.SIGN_UP);
    } else {
      Analytics.track(ANALYTICS_EVENTS.SUBSCRIBE_CELEBRITY, {
        celebrity: influencerName,
      });
      setIsHover(false);
      handleOnSubscribe();
    }
  };

  return (
    <LoadingButton
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      variant={variant}
      className={`${styles.subscribeBtn}
          ${isSubscribed ? styles.subscribeBtnCancel : ""}`}
      onClick={onSubscribe}
      loading={isProcessingSubscription}
    >
      <Box
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(buttonText),
        }}
        className={styles.subscribeBtnTxt}
      />
      {/*<ButtonIcon className={styles.icon} />*/}
    </LoadingButton>
  );
};
