import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as BANNER_API from "services/api/bannerApi";

const initialState = {
  entities: [],
  loading: false,
};

export const fetchBanners = createAsyncThunk("banners", async () => {
  const response = await BANNER_API.getBanners();
  return response;
});

const bannersSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBanners.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBanners.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(fetchBanners.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default bannersSlice.reducer;
